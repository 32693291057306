import { Grid, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { addNote } from 'actions/action-notes';
import { setTherapyId, editTasks } from 'actions/action-tasks';
import { refreshPatientTherapiesAndTasks } from 'actions/action-patient';
import { editTherapy } from 'actions/action-therapies';
import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import { renderDatePicker } from 'components/form/datepicker/datetime-picker';
import { renderDropdown, renderTextField, renderCheckbox } from 'components/form/field/redux-field';
import { required, validateDate } from 'components/form/validation/validation';
import NoteArea from 'components/note/NoteArea';
import {
  EDIT_THERAPY_ADMINISTRATION,
  THERAPY_ADMIN_STATUS_DISCONTINUED,
  THERAPY_ADMIN_STATUS_NO_GO,
  THERAPY_ADMIN_STATUS_ON_HOLD,
  THERAPY_START_DATE_MUST_BE_AFTER_REFERRAL_DATE,
  AUDIT_NOT_RESOLVED,
  THERAPY_ADMIN_STATUS_ON_THERAPY,
  THERAPY_MEDICATION_STATUS_EXISTING,
  THERAPY_ADMIN_STATUS_PRE_THERAPY,
  THERAPY_SHOULD_HAVE_START_DATE,
} from 'constants/index';
import { auditCategories, auditResolutionStates, auditContext } from 'constants/lists';
import { resolveAudit } from 'actions/action-audit';
import { convertToArborDate } from 'models/time/arbor-date';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { Field, formValueSelector, reduxForm, change, getFormValues } from 'redux-form';
import { parseNoteToDb } from 'services/utils/note-service';
import {
  getReasonsByStatus,
  getTherapyStatuses,
  getTherapyStatusId,
  statusArrayForEdit,
  getAdminStatusesByCurrentStatus,
  isStatusCompletedTherapy,
  isStartDateBeforeReferralDate,
} from 'services/utils/therapy-service';
import {
  therapyAdministrationAuditDetector,
  findAuditByCategoryRuleAndStatus,
} from 'services/utils/audit-service';

import { translateUsers } from 'services/utils/users-service';
import { formatPatientName } from 'services/utils/demographic-service';
import TasksCancelForm from 'components/dynamic-form/therapy-forms/tasks-cancel-form';
import { styles } from './therapy-detail.styles';
import TherapyAdministrationHistory from './therapy-administration-history';

const RESTART_ADMIN_STATUSES = [
  THERAPY_ADMIN_STATUS_ON_HOLD,
  THERAPY_ADMIN_STATUS_DISCONTINUED,
  THERAPY_ADMIN_STATUS_NO_GO,
];

function needReason(selectedAdminStatus) {
  return [
    THERAPY_ADMIN_STATUS_ON_HOLD,
    THERAPY_ADMIN_STATUS_DISCONTINUED,
    THERAPY_ADMIN_STATUS_NO_GO,
  ].includes(selectedAdminStatus);
}

class EditAdministration extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleChangeUnknownStartDate = this.handleChangeUnknownStartDate.bind(this);
    this.submit = this.submit.bind(this);

    this.state = {
      payload: null,
      tasksCancel: false,
    };
  }

  componentDidMount() {
    const { formRef } = this.props;

    if (formRef?.current) {
      formRef.current?.addEventListener(
        'submit',
        () => {
          const { formValues } = this.props;
          (async () => this.handleSubmit(formValues))();
        },
        false,
      );
    }
  }

  handleSubmit(values) {
    const {
      patient,
      therapy,
      therapyStatuses,
      addNote, // eslint-disable-line
      resolveAudit, // eslint-disable-line
      tagTypeId,
      tagResourceId,
      initialValues,
      audit,
      selectedAdminStatus,
    } = this.props;
    const { therapyAdministrationStatuses } = therapyStatuses;

    const payload = {
      patient_id: patient.id,
      ndc: therapy.ndc,
      therapy_id: therapy.id,
      administration_status_additional_reason: values.administration_status_additional_reason,
      medication_id: therapy.medication_id,
      recheck_date: convertToArborDate(values.recheck_date).getUtcDate(),
      restart_date: convertToArborDate(values.restart_date).getUtcDate(),
      no_go_date: convertToArborDate(values.no_go_date).getUtcDate(),
    };
    let adminStatusId;
    if (needReason(values.administration_status)) {
      adminStatusId = getTherapyStatusId(
        therapyAdministrationStatuses,
        values.administration_status,
        values.administration_status_reason,
      );
    } else {
      adminStatusId = getTherapyStatusId(
        therapyAdministrationStatuses,
        values.administration_status,
      );
    }
    if (values.note_text) {
      const newValues = parseNoteToDb(values);
      const tags = [{ tag_type_id: tagTypeId, resource_id: tagResourceId }];

      const payloadNotes = {
        patient_id: patient.id,
        note_type_id: 1,
        note_text: newValues.note_text,
        is_pinned: 0,
        mentions: newValues.mentions,
        tags,
      };
      addNote(payloadNotes);
    }
    const therapyAdminStatusArr = getReasonsByStatus(
      therapyAdministrationStatuses,
      selectedAdminStatus,
    )
      ? getReasonsByStatus(therapyAdministrationStatuses, selectedAdminStatus).map(t => t.id)
      : [];

    // We need to check all medication related field, if these are not changed don't send to service
    // otherwise it will trigger the medication workflow
    if (adminStatusId !== initialValues.administration_status_id) {
      payload.administration_status_id = adminStatusId;
    }

    if (
      therapyAdministrationAuditDetector(
        audit,
        patient,
        initialValues,
        payload,
        therapyAdminStatusArr,
      )
    ) {
      const auditToResolve = therapyAdministrationAuditDetector(
        audit,
        patient,
        initialValues,
        payload,
        therapyAdminStatusArr,
      );

      if (auditToResolve) {
        const resolvedAudit = {
          ...auditToResolve,
          resolution_state_id: auditResolutionStates.Resolved,
        };

        resolveAudit(resolvedAudit);
      }
    }

    const startUtcDate = convertToArborDate(values.start_date).getUtcDate();
    if (startUtcDate !== convertToArborDate(initialValues.start_date).getUtcDate()) {
      payload.start_date = startUtcDate;
    }
    const discontinuedUtcDate = convertToArborDate(values.discontinued_date).getUtcDate();
    if (
      discontinuedUtcDate !== convertToArborDate(initialValues.discontinued_date).getUtcDate() &&
      values.administration_status === THERAPY_ADMIN_STATUS_DISCONTINUED
    ) {
      payload.discontinued_date = discontinuedUtcDate;
    }
    if (values.administration_status !== THERAPY_ADMIN_STATUS_DISCONTINUED) {
      payload.discontinued_date = null;
    }

    if (payload.start_date || values.start_date_unknown) {
      const auditToResolve = findAuditByCategoryRuleAndStatus(
        audit,
        patient,
        auditCategories.Therapies,
        auditContext.Warning,
        THERAPY_SHOULD_HAVE_START_DATE,
        AUDIT_NOT_RESOLVED,
      );
      if (auditToResolve) {
        const resolvedAudit = {
          ...auditToResolve,
          resolution_state_id: auditResolutionStates.Resolved,
        };
        resolveAudit(resolvedAudit);
      }
    }
    if (
      payload.start_date &&
      therapy.referral_dt &&
      !isStartDateBeforeReferralDate(
        convertToArborDate(payload.start_date).getUtcDate(),
        convertToArborDate(therapy.referral_dt).getUtcDate(),
      )
    ) {
      const auditToResolve = findAuditByCategoryRuleAndStatus(
        audit,
        patient,
        auditCategories.Therapies,
        auditContext.Warning,
        THERAPY_START_DATE_MUST_BE_AFTER_REFERRAL_DATE,
        AUDIT_NOT_RESOLVED,
      );
      if (auditToResolve) {
        const resolvedAudit = {
          ...auditToResolve,
          resolution_state_id: auditResolutionStates.Resolved,
        };
        resolveAudit(resolvedAudit);
      }
    }

    if (isStatusCompletedTherapy(therapyAdministrationStatuses, adminStatusId)) {
      return this.showTasksCancel(payload);
    }

    return new Promise(resolve => {
      this.setState({ payload }, () => {
        this.submit([]).then(resolve);
      });
    });
  }

  submit(tasksToCancel) {
    const {
      editTherapy, // eslint-disable-line no-shadow
      editTasks, // eslint-disable-line no-shadow
      refreshPatientTherapiesAndTasks, // eslint-disable-line no-shadow
    } = this.props;
    const { payload } = this.state;
    return new Promise((resolve, reject) => {
      editTherapy(
        payload,
        () => {
          if (tasksToCancel.length) {
            editTasks(payload.patient_id, tasksToCancel).then(() =>
              refreshPatientTherapiesAndTasks(payload.patient_id),
            );
          }
          resolve();
          this.handleCancel();
        },
        reject,
      );
    });
  }

  showTasksCancel(payload) {
    return new Promise(resolve => {
      this.setState({
        payload,
        tasksCancel: true,
        tasksCancelOnComplete: resolve,
      });
    });
  }

  handleCancel() {
    const { reset, toggleCallback } = this.props;
    reset();
    if (toggleCallback) {
      toggleCallback();
    }
  }

  handleChangeUnknownStartDate() {
    const { change } = this.props; // eslint-disable-line
    change('start_date', null);
  }

  render() {
    const {
      handleSubmit,
      submitting,
      // eslint-disable-next-line no-unused-vars
      pristine,
      classes,
      selectedAdminStatus,
      therapyStatuses,
      users,
      adminStatus,
      administrationStatusReason,
      administrationStatusAdditionalReason,
      therapy,
      patient,
      selectedUnknownStartDateForm,
      therapyIds,
      simplified,
    } = this.props;
    const { tasksCancel, tasksCancelOnComplete } = this.state;

    const userData = users && users.length > 0 && translateUsers(users);

    const selectedUnknownStartDate =
      selectedUnknownStartDateForm && therapy.rx_type_id === THERAPY_MEDICATION_STATUS_EXISTING;

    const { therapyAdministrationStatuses } = therapyStatuses;
    const statusArray = getTherapyStatuses(therapyAdministrationStatuses);
    const statusDropdown = statusArrayForEdit(statusArray);
    const therapyCurrentStatus = therapy.administration_status;

    const reasonDropdown = getReasonsByStatus(therapyAdministrationStatuses, selectedAdminStatus);

    const clinics = patient.clinics
      ? patient.clinics.map(it => it.name).join(', ')
      : 'No Active Clinics';
    const instructions = (
      <>
        <Typography variant="subtitle2">Patient information</Typography>
        <Typography variant="body2">{formatPatientName(patient)}</Typography>
        <Typography variant="body2">{`Status ${patient.status}`}</Typography>
        <Typography variant="body2">{`Clinics: ${clinics}`}</Typography>
      </>
    );

    return (
      <Grid container spacing={7}>
        {tasksCancel && (
          <TasksCancelForm
            title="A therapy has been discontinued. Cancel associated tasks?"
            forceCancelIntervention
            instructions={instructions}
            therapyIds={[therapy.id]}
            onClose={() => {
              this.setState({
                tasksCancel: false,
              });
              this.handleCancel();
              if (tasksCancelOnComplete) {
                tasksCancelOnComplete();
              }
            }}
            reason={
              administrationStatusReason === 'Other' && administrationStatusAdditionalReason
                ? administrationStatusAdditionalReason
                : administrationStatusReason
            }
            onSubmit={tasksToCancel =>
              this.submit(tasksToCancel).then(() => {
                if (tasksCancelOnComplete) {
                  tasksCancelOnComplete();
                }
              })
            }
          />
        )}
        <Grid item xs={12}>
          <form
            ref={this.props.formRef}
            onSubmit={e => {
              e.preventDefault();
              return handleSubmit(this.handleSubmit)(e);
            }}
            autoComplete="off"
          >
            <Grid container>
              <Grid
                display="flex"
                alignItems="center"
                gap={1.5}
                item
                xs={3}
                onClick={e => e.stopPropagation()}
                className={classes.administrationField}
              >
                <Field
                  name="administration_status"
                  label="Administration Status"
                  component={renderDropdown}
                  className={classes.input}
                  validate={[required]}
                  fields={getAdminStatusesByCurrentStatus(
                    therapyCurrentStatus,
                    statusDropdown,
                    selectedUnknownStartDate,
                  )}
                />
                <Grid marginTop={2.5}>
                  <TherapyAdministrationHistory
                    therapyIds={therapyIds}
                    patientId={therapy.patient_id}
                  />
                </Grid>
              </Grid>
              {needReason(selectedAdminStatus) && (
                <Grid item xs={3} className={classes.administrationField}>
                  <Field
                    name="administration_status_reason"
                    label="Reason *"
                    component={renderDropdown}
                    className={classes.input}
                    validate={[required]}
                    fields={reasonDropdown}
                  />
                </Grid>
              )}
              {needReason(selectedAdminStatus) && administrationStatusReason === 'Other' && (
                <Grid item xs={3} className={classes.administrationField}>
                  <Field
                    label="Reason *"
                    name="administration_status_additional_reason"
                    component={renderTextField}
                    validate={[required]}
                  />
                </Grid>
              )}

              {selectedAdminStatus === THERAPY_ADMIN_STATUS_ON_HOLD && (
                <Grid item xs={3} className={classes.administrationField}>
                  <Field
                    name="recheck_date"
                    label="Recheck Date * "
                    component={renderDatePicker}
                    validate={[validateDate, required]}
                  />
                </Grid>
              )}
              {Boolean(therapy.restart_date) ||
              (selectedAdminStatus === THERAPY_ADMIN_STATUS_ON_THERAPY &&
                RESTART_ADMIN_STATUSES.includes(adminStatus.status)) ? (
                <Grid item xs={3} className={classes.administrationField}>
                  <Field
                    name="restart_date"
                    label="Restart Date * "
                    component={renderDatePicker}
                    validate={[validateDate, required]}
                  />
                </Grid>
              ) : null}
              {selectedAdminStatus === THERAPY_ADMIN_STATUS_ON_THERAPY && (
                <Grid item xs={3} className={classes.administrationField}>
                  <Field
                    name="start_date"
                    label={`Start Date${!selectedUnknownStartDate ? ' *' : ''}`}
                    component={renderDatePicker}
                    disabled={selectedUnknownStartDate}
                    validate={selectedUnknownStartDate ? [validateDate] : [validateDate, required]}
                  />
                </Grid>
              )}
              {selectedAdminStatus === THERAPY_ADMIN_STATUS_ON_THERAPY &&
                therapy.rx_type_id === THERAPY_MEDICATION_STATUS_EXISTING && (
                  <Grid item xs={3} className={classes.administrationField}>
                    <Field
                      name="start_date_unknown"
                      label="Unknown Start Date"
                      component={renderCheckbox}
                      onChange={this.handleChangeUnknownStartDate}
                    />
                  </Grid>
                )}
              {selectedAdminStatus === THERAPY_ADMIN_STATUS_DISCONTINUED && (
                <Grid item xs={3} className={classes.administrationField}>
                  <Field
                    name="discontinued_date"
                    label="Discontinued Date *"
                    component={renderDatePicker}
                    validate={[validateDate, required]}
                  />
                </Grid>
              )}
              {selectedAdminStatus === THERAPY_ADMIN_STATUS_NO_GO && (
                <Grid item xs={3} className={classes.administrationField}>
                  <Field
                    name="no_go_date"
                    label="No go Date * "
                    component={renderDatePicker}
                    validate={[validateDate, required]}
                  />
                </Grid>
              )}
              {!simplified && selectedAdminStatus !== adminStatus.status && (
                <Grid item xs={3} className={classes.administrationField}>
                  <Field name="note_text" data={userData} component={NoteArea} />
                </Grid>
              )}
            </Grid>
            {!simplified && (
              <Grid item xs={12}>
                <ConfirmationPanel
                  cancelButtonName="edit_administration_cancel_button"
                  submitButtonName="edit_administration_submit_button"
                  handleCancel={this.handleCancel}
                  disableSubmit={submitting}
                />
              </Grid>
            )}
          </form>
        </Grid>
      </Grid>
    );
  }
}

export function handleFormName(therapy) {
  let formName = `${EDIT_THERAPY_ADMINISTRATION}_status`;
  if (therapy) {
    formName += `_${therapy.patient_id}_${therapy.id}`;
  }

  return formName;
}

function mapStateToProps(state, props) {
  const { patient, therapyStatuses, audit } = state;
  const { adminStatus } = props;
  const formName = handleFormName(patient.therapy);
  const selector = formValueSelector(formName);
  const formValues = getFormValues(formName)(state);
  const selectedAdminStatus = selector(state, 'administration_status');
  const administrationStatusReason = selector(state, 'administration_status_reason');
  const administrationStatusAdditionalReason = selector(
    state,
    'administration_status_additional_reason',
  );

  const selectedUnknownStartDateForm = selector(state, 'start_date_unknown');
  return {
    users: state.lookups.users,
    patient,
    audit,
    therapyStatuses,
    form: formName,
    formValues,
    administrationStatusReason,
    administrationStatusAdditionalReason,
    initialValues: {
      administration_status: adminStatus?.status,
      administration_status_id: adminStatus?.id,
      administration_status_reason: adminStatus?.reason,
      administration_status_additional_reason:
        props.therapy.administration_status_additional_reason,
      start_date: convertToArborDate(props.therapy.start_date, true).getUtcDate(true),
      recheck_date: convertToArborDate(props.therapy.recheck_date, true).getUtcDate(true),
      restart_date: convertToArborDate(props.therapy.restart_date, true).getUtcDate(true),
      no_go_date: convertToArborDate(props.therapy.no_go_date, true).getUtcDate(true),
      discontinued_date: convertToArborDate(props.therapy.discontinued_date, true).getUtcDate(true),
      start_date_unknown:
        adminStatus?.status === THERAPY_ADMIN_STATUS_PRE_THERAPY
          ? false
          : !props.therapy.start_date,
    },
    selectedAdminStatus,
    selectedUnknownStartDateForm,
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    editTherapy,
    editTasks,
    setTherapyId,
    addNote,
    refreshPatientTherapiesAndTasks,
    resolveAudit,
    change,
  }),
)(reduxForm({})(EditAdministration));
