import { Button, Grid, Modal, Typography, Switch as SwitchButton, Paper } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import {
  verifyMedications,
  markEmrConfirmed,
  markPatientConfirmedMedications,
} from 'actions/action-medications';
import { windowFeatureIsEnabled } from 'config/window-features';
import { IAudit } from 'interfaces/redux/IAudit';
import AddPatientTherapy from 'containers/patient/therapy/add-patient-therapy/add-patient-therapy';
import { combineTherapyTasksAndLinks } from 'services/utils/therapy-service';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { withRouter } from 'react-router-dom';
import History from 'containers/common/history';
import compose from 'recompose/compose';
import { getModalStyle } from 'services/utils/styles-service';
import { resolveAudit } from 'actions/action-audit';
import { resolveAuditDefinition } from 'services/utils/audit-service';
import { taskStatusCheckboxes, auditCategories } from 'constants/lists';
import AccessRole from 'components/user/access-role';
import { useLatestDur } from 'containers/tasks/counseling/counseling-transition/forms/hooks';
import { ENSURE_MEDICATION_LIST_VERIFIED, MED_SYNC_STATUS, DUR_FETCH_BY_ID } from 'constants/index';
import { MedicationStatus } from 'constants/enums';
import TherapyDetail from 'containers/patient/therapy/therapy-detail';
import { EditAdd, EditHistory } from 'components/icons/icons';
import { QuaternaryVerificationPanel } from 'components/form/verification/verification-panel';
import { ReactSelect } from 'components/form/field/react-select';
import { getUserById } from 'services/utils/users-service';
import { addMedsIntoMedGroups } from 'services/utils/medication-service';
import { ToggleDisplayAddTherapyModal } from 'actions/action-form-displays';
import { styles } from '../patient-clinical-styles';
import NdcMedicationGroupList from './medication-group-list';
import { GpiMedicationGroup } from './gpi-medication-group/gpi-medication-group';
import { IProps } from './types';

const MedicationList: React.FC<IProps> = (props: IProps): JSX.Element => {
  const {
    isLoading,
    classes,
    patient,
    showMedicationList,
    medicationList,
    overwriteGroups,
    minimal,
    options,
    readOnly = false,
    hideChildren = false,
    cancelAddForm = null,
    postSave = null,
    // If true, the New button won't be displayed neither the modal for adding new med.
  } = props;

  const dispatch = useDispatch();
  const [selectedTherapy, setSelectedTherapy] = useState<any>(null);
  const [selectedMedication, setSelectedMedication] = useState(null);
  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);
  const [displayMedicationGroup, setDisplayMedicationGroup] = useState(true);

  const formDisplays = useTypedSelector(state => state.formDisplays);
  const therapies = useTypedSelector(state => state.therapies.data);
  const tasks = useTypedSelector(state => state.tasks.data);
  const links = useTypedSelector(state => state.links.data);
  const auditState = useTypedSelector(state => state.audit);
  const currentUser = useTypedSelector(state => state.auth.currentUser);
  const users = useTypedSelector(state => state.lookups.users);
  const medicationStatus = useTypedSelector(state => state.lookups.medicationStatus);
  const durTask = useLatestDur();
  const [statusFilter, setStatusFilter] = useState<MedicationStatus[] | null>([
    MedicationStatus.Active,
  ]);

  const medicationGroups = addMedsIntoMedGroups(overwriteGroups, medicationList);

  const opts = {
    showTableViewToggle: true,
    showMedicationStatus: true,
    showVerificationPanel: true,
    showTopActionsBar: true,
    showMedicationsList: true,
    showAddMedicationForm: false,
    ...options,
  };

  const therapy =
    selectedTherapy && combineTherapyTasksAndLinks(therapies[selectedTherapy?.id], tasks, links);

  const onToggleFormDisplay = (bool: boolean) => {
    dispatch(new ToggleDisplayAddTherapyModal(bool));
  };

  const handleCloseAddForm = () => {
    onToggleFormDisplay(false);
  };

  const handleToggleAddMedication = () => {
    onToggleFormDisplay(true);
  };

  const toggleHistoryModal = () => {
    setIsHistoryModalOpen(!isHistoryModalOpen);
  };

  const toggleTherapyModal = (row?: any) => {
    if (!selectedTherapy) {
      setSelectedTherapy(row);
    } else {
      setSelectedTherapy(null);
    }
  };

  const toggleAddTherapyForExistingMedModal = (medication: any) => {
    if (!selectedMedication) {
      setSelectedMedication(medication);
    } else {
      setSelectedMedication(null);
    }
  };

  const handleSectionVerified = async (isReviewed = false) => {
    resolveAuditDefinition({
      auditDefinitionType: ENSURE_MEDICATION_LIST_VERIFIED,
      auditState,
      patient,
      resolverFunction: (resolvedAudit: IAudit) => {
        dispatch(resolveAudit(resolvedAudit));
      },
    });

    await dispatch(
      verifyMedications(
        {
          patient_id: patient.id,
        },
        isReviewed === true,
      ),
    );

    if (durTask?.task.id) {
      await dispatch({ type: DUR_FETCH_BY_ID, payload: { id: durTask.task.id } });
    }
  };

  const handleMarkPatientConfirmedMedications = () => {
    if (patient) {
      (dispatch(markPatientConfirmedMedications(patient.id, true)) as any).then(() => {
        handleSectionVerified();
      });
    }
  };

  const handleMarkEmrConfirmed = () => {
    if (patient) {
      dispatch(markEmrConfirmed(patient.id, true));
    }
  };

  const handleMedicationStatusChange = (e: any) => {
    if (e && e.length > 0) {
      setStatusFilter(e.map((o: any) => o.value));
    } else {
      setStatusFilter(null);
    }
  };

  const listOfTaskStatusesToDisplay = taskStatusCheckboxes.filter(
    t => t.id, // eslint-disable-line
  );

  const cellStyle = {
    lineHeight: '280%',
    fontSize: 12,
    textAlign: 'center',
    overflow: 'hidden',
  };

  const editCellStyle = {
    ...cellStyle,
    overflow: 'visible',
    cursor: 'pointer',
  };

  const verifiedUser = patient
    ? getUserById(patient.medications_verification_verified_by, users)
    : patient;

  return (
    <>
      <Modal
        open={formDisplays ? Boolean(formDisplays.displayAddTherapyModal) : false}
        onClose={handleCloseAddForm}
        data-modal="therapy"
      >
        <div style={getModalStyle()} className={classes.addMedicationModal}>
          <AddPatientTherapy
            displayDocumentOnlyOption
            patientId={patient ? patient.id : null}
            medSync={patient?.med_sync === MED_SYNC_STATUS.OPT_IN}
            handleCloseAddForm={handleCloseAddForm}
            fromMedicationList
          />
        </div>
      </Modal>
      <Modal
        open={!!selectedTherapy}
        onClick={e => e.stopPropagation()}
        onClose={toggleTherapyModal}
      >
        <div style={getModalStyle()} className={classes.therapyModal}>
          {selectedTherapy && (
            <TherapyDetail
              key={selectedTherapy.id}
              // TODO: need to fetch from
              // state to get the latest therapy after updated
              therapy={therapy}
              expanded
              selectedServiceGroup={therapy.service_group_id}
              taskStatusCategoriesToDisplay={listOfTaskStatusesToDisplay}
              index={0}
              addTherapyHandler={() => {
                toggleTherapyModal();
                handleToggleAddMedication();
              }}
            />
          )}
        </div>
      </Modal>
      <Modal open={!!selectedMedication} onClick={e => e.stopPropagation()} data-modal="therapy">
        <div style={getModalStyle()} className={classes.addTherapyModal}>
          <AddPatientTherapy
            displayDocumentOnlyOption
            selectedDrug={selectedMedication}
            patientId={patient ? patient.id : null}
            medSync={patient?.med_sync === MED_SYNC_STATUS.OPT_IN}
            handleCloseAddForm={toggleAddTherapyForExistingMedModal}
          />
        </div>
      </Modal>
      <Modal open={isHistoryModalOpen} onClose={toggleHistoryModal}>
        <div style={getModalStyle()} className={classes.historyModal}>
          <History
            medications
            url={`/patients/${patient ? patient.id : null}/medications`}
            subType="patient"
          />
        </div>
      </Modal>

      {!showMedicationList && (
        <Grid item xs={12}>
          <Typography variant="h6" component="span">
            Medication List
          </Typography>
        </Grid>
      )}
      {windowFeatureIsEnabled('med_list') && opts.showTopActionsBar && (
        <Grid container className={classes.addDataButton}>
          <>
            <Grid xs={12} item>
              {opts.showTableViewToggle && (
                <AccessRole roles={[]} resource="medication_list" actions={['toggle_table_view']}>
                  <Typography component="span">Table View</Typography>
                  <SwitchButton
                    checked={displayMedicationGroup}
                    onChange={() => setDisplayMedicationGroup(!displayMedicationGroup)}
                    value="showTree"
                    color="primary"
                  />
                  <Typography component="span">Group View</Typography>
                </AccessRole>
              )}
            </Grid>
            <Grid item xs>
              {opts.showMedicationStatus && (
                <ReactSelect
                  name="medicationStatus"
                  label="Medication Status"
                  handleOnChange={(e: any) => handleMedicationStatusChange(e)}
                  defaultValues={[{ value: 'active', label: 'Active' }]}
                  fields={medicationStatus.map(item => ({
                    value: item.code,
                    label: item.status,
                  }))}
                  id="medication_status"
                />
              )}
            </Grid>
          </>
          <Grid item>
            <Button onClick={toggleHistoryModal} id="med_history_button">
              <EditHistory />
            </Button>
          </Grid>
          {!readOnly && (
            <Grid item>
              <Button
                name="add_data_button"
                id="add_medication_button"
                variant="outlined"
                onClick={() => handleToggleAddMedication()}
              >
                <EditAdd />
                <Typography variant="body2">New</Typography>
              </Button>
            </Grid>
          )}
        </Grid>
      )}
      {opts.showMedicationsList && (
        <Paper elevation={2} className={classes.allergyContainer}>
          <Grid container>
            <Grid item xs={12} className={minimal ? undefined : classes.tableContainer}>
              {displayMedicationGroup && (
                <GpiMedicationGroup
                  medications={medicationList}
                  medicationGroups={overwriteGroups}
                  readOnly={readOnly}
                  statusFilters={statusFilter}
                  toggleTherapyHandler={toggleTherapyModal}
                  toggleAddTherapyHandler={toggleAddTherapyForExistingMedModal}
                  hideChildren={hideChildren}
                />
              )}
              {/* / React table v6 was removed since it was deprecated / not used due to feature flag "displayMedicationGroup" is on for all envs.  */}
              {opts.showVerificationPanel && !readOnly && (
                <QuaternaryVerificationPanel
                  type="patient_medications"
                  verificationId={patient.medications_verification_status_id}
                  verifiedBy={verifiedUser}
                  // @ts-ignore
                  verifiedDate={patient ? patient.medications_verification_verified_dt : null}
                  confirmedWithPatientDate={
                    patient ? patient.medications_confirmed_with_patient_date : null
                  }
                  handleVerify={handleSectionVerified}
                  handleMarkPatientConfirmed={handleMarkPatientConfirmedMedications}
                  emrConfirmed={patient && !!patient.medications_confirmed_with_emr}
                  handleMarkEmrConfirmed={handleMarkEmrConfirmed}
                  auditRules={[ENSURE_MEDICATION_LIST_VERIFIED]}
                  auditCategory={auditCategories.Therapies}
                  user={currentUser}
                />
              )}
            </Grid>
          </Grid>
        </Paper>
      )}
      {opts.showAddMedicationForm && (
        <Paper elevation={2} className={classes.allergyContainer}>
          <Grid container>
            <Grid item xs={12}>
              <AddPatientTherapy
                formTitle="Add New Medication"
                displayDocumentOnlyOption
                initialDocumentingOnly
                bordered={false}
                patientId={patient ? patient.id : null}
                medSync={patient?.med_sync === MED_SYNC_STATUS.OPT_IN}
                handleCloseAddForm={() => cancelAddForm && cancelAddForm()}
                submitCallback={() => postSave && postSave()}
                showEligibilityCheck={false}
              />
            </Grid>
          </Grid>
        </Paper>
      )}
    </>
  );
};

export default compose<any, any>(withRouter, withStyles(styles))(MedicationList);
