import React, { useEffect, useState } from 'react';
import moment from 'moment-business-days';
import dataCollectFollowUpJson from 'config/tasks/data-collect-follow-up.json';
import { Typography, Box } from '@mui/material';
import HTTP from 'services/http';
import ModalForm from '../modal-form';

const getNewFollowUpDate = (date, days) => {
  const newFollowUpDate = moment(date);
  newFollowUpDate.add(days, 'days');
  if (!newFollowUpDate.isBusinessDay()) {
    return newFollowUpDate.nextBusinessDay();
  }
  return newFollowUpDate;
};

const FollowUpDataCollectModal = ({
  therapyId,
  onClose,
  open,
  onSubmit,
  dataCollectedDate,
  minAssessmentDate,
  therapyDuration,
}) => {
  const [protocolDataTypes, setProtocolDataTypes] = useState(null);
  const [nextFollowupInDays, setNextFollowupInDays] = useState(null);

  useEffect(() => {
    if (therapyId) {
      HTTP.post(`/pmp/therapies/${therapyId}/protocols/next`, {
        dataCollectedDate,
        minAssessmentDate,
        therapyDuration,
      }).then(response => {
        setProtocolDataTypes(
          response.data.protocols.reduce((acc, curr) => {
            acc.push({
              clinicalDataTypeId: curr[0],
              required: true,
            });
            setNextFollowupInDays(curr[1]); // # of days till next protocol
            return acc;
          }, []),
        );
      });
    }
  }, [therapyId]);

  return protocolDataTypes ? (
    <ModalForm // TODO: replace with existing dyamic form modal
      title="Create new Data Collect"
      instructions={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <Typography component="span">
          <Box fontWeight={500}>Task settings</Box>
        </Typography>
      }
      open={open}
      jsonForm={dataCollectFollowUpJson}
      formId="new-task-form"
      formName="select"
      data={{
        follow_up_date: getNewFollowUpDate(moment(), nextFollowupInDays),
        data_collect: { valid: true, values: protocolDataTypes },
      }}
      providers={{
        task: {},
      }}
      onSubmit={updatedTask => {
        onSubmit({
          follow_up_date: updatedTask.follow_up_date,
          follow_up_data_collect: updatedTask.data_collect.values,
        });
        return true;
      }}
      submitButtonText="Create Task"
      cancelButtonText="Cancel"
      alwaysEnabled
      onCancel={() => {
        if (onClose) {
          onClose();
        }
      }}
      marginTop
    />
  ) : (
    therapyId && <div>Loading...</div>
  );
};

export default FollowUpDataCollectModal;
