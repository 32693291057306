import React, { FC as FCReact, useState } from 'react';
import moment from 'moment';
import { Grid, Button, Typography } from '@mui/material';

import {
  PA,
  FA,
  FC,
  DC,
  TPR,
  INT,
  CSL,
  MR,
  OR,
  AR,
  FDC,
  THERAPIES,
  DUR,
  SMS,
  STAR,
  QRE,
  RS,
  CDM_WORKFLOW,
} from 'constants/index';
import { InitialTaskStatuses } from 'constants/initial-task-statuses';
import Dropdown from 'components/form/field/dropdown';
import { windowFeatureIsEnabled } from 'config/window-features';
import AddIntervention from 'containers/tasks/interventions/add-intervention';
import AddOutreach from 'containers/tasks/outreach/add-outreach';
import AddAppointmentReferral from 'containers/tasks/ar/add-appointment-referral';
import SubHeader from 'components/form/header/subheader';
import TaskAddForm from 'components/dynamic-form/task-forms/task-add-form';
import { getTaskInitialValues } from 'helpers/automation-tasks';
import { convertToArborDate } from 'models/time/arbor-date';
import { ITherapy, AdministrationStatus } from 'interfaces/redux/ITherapy';
import { useTypedSelector } from 'hooks/use-typed-selector';
import AddRsForm from 'containers/tasks/risk-strat/add-rs';
import { UserUtils } from 'utils/user-utils';
import { UserPermissions, CustomerFeatures } from 'interfaces/user/Permissions';
import { Styles } from './task-detail-styles';
import { PatientTherapiesClient } from '../../clients/patient-therapies';
import { ApplicationManagerClient } from '../../clients/application-manager-client';

interface IAddTaskProps {
  handleCancel(): void;
  therapyIndex: number | null;
  availableTherapies: ITherapy[];
}

interface IInitialTaskAddType {
  taskType: string;
  service_group_id?: number;
  status_id: number;
  followup_dt?: string | null;
  days_supply?: number;
  completed_details?: number;
  additional_reason?: string | null;
  needsby_date?: string | null;
}

const AddTask: FCReact<IAddTaskProps> = (props): JSX.Element => {
  // #region state
  const { handleCancel, therapyIndex, availableTherapies = [] } = props;
  const shouldSelectTherapyManually =
    availableTherapies && Object.keys(availableTherapies).length > 1;

  const [selectedType, setSelectedType] = useState<string | number>(-1);
  const [selectedTherapy, setSelectedTherapy] = useState<ITherapy | null>(
    shouldSelectTherapyManually && availableTherapies.length > 0 ? null : availableTherapies[0],
  );

  const [initialValues, setInitialValues] = useState<Record<string, any>>({});
  const taskCounts = useTypedSelector(state => state.taskCounts.data);
  const patient = useTypedSelector(state => state.patient);
  const patientId = useTypedSelector(state => state.selectedPatientId);
  const serviceGroups = useTypedSelector(state => state.lookups.serviceGroups);
  const [cdmTherapies, setCdmTherapies] = useState<{ therapyId: number, diagnosisCode: string, workflowId: number }[]>([]);
  const [diseaseStateData, setDiseaseStateData] = useState<{ id: number, name: string, icd: string }[]>([]);

  // #endregion state
  // #region useEffect
  React.useEffect(() => {
    if (!shouldSelectTherapyManually && availableTherapies.length > 0) {
      setSelectedTherapy(availableTherapies[0]);
    }
  }, [selectedType]);
  React.useEffect(() => {
    setInitialValues({
      [PA]: {
        taskType: PA,
        service_group_id: selectedTherapy?.service_group_id,
        followup_dt: getTaskInitialValues(selectedTherapy, PA),
        status_id: InitialTaskStatuses.PA,
      },
      [FA]: {
        taskType: FA,
        service_group_id: selectedTherapy?.service_group_id,
        followup_dt: getTaskInitialValues(selectedTherapy, FA),
        status_id: InitialTaskStatuses.FA,
      },
      [FC]: {
        taskType: FC,
        service_group_id: selectedTherapy?.service_group_id,
        status_id: InitialTaskStatuses.FC,
        needsby_date: selectedTherapy?.needsby_date,
        days_supply: selectedTherapy?.days_supply,
      },
      [TPR]: {
        taskType: TPR,
        service_group_id: selectedTherapy?.service_group_id,
        status_id: InitialTaskStatuses.TPR,
      },
      [DC]: {
        taskType: DC,
        service_group_id: selectedTherapy?.service_group_id,
        status_id: InitialTaskStatuses.DC,
      },
      [INT]: {
        taskType: INT,
        service_group_id: selectedTherapy?.service_group_id,
        status_id: InitialTaskStatuses.INT,
        followup_dt: convertToArborDate(moment()).getCustomerDatetime(true),
      },
      [CSL]: {
        taskType: CSL,
        service_group_id: selectedTherapy?.service_group_id,
        additional_reason: null,
        status_id: InitialTaskStatuses.CSL,
        completed_details: 0,
      },
      [OR]: {
        taskType: OR,
        service_group_id: selectedTherapy?.service_group_id,
        additional_reason: null,
        status_id: InitialTaskStatuses.OR,
      },
      [RS]: {
        taskType: RS,
        service_group_id: selectedTherapy?.service_group_id,
        additional_reason: null,
        status_id: InitialTaskStatuses.RS,
      },
    });
  }, [selectedTherapy]);
  // #endRegion useEffect

  const filterCdmTherapies = React.useMemo<void>(() => {
    (async () => {
      const therapiesToFilter = await Promise.all(
        availableTherapies
          .filter(item => item.diagnosis_code)
          .map(async item => {
            const workflowId = await PatientTherapiesClient.getTherapyWorkflow(item.diagnosis_code, item.specialty_type);
            return {
              therapyId: item.id,
              diagnosisCode: item.diagnosis_code,
              workflowId: workflowId.data?.therapyWorkflow,
            };
          }),
      );
      const filteredTherapies = [];
      for (const item of therapiesToFilter) {
        if (Number.isInteger(item.workflowId) && item.workflowId === CDM_WORKFLOW) {
          filteredTherapies.push(item);
        }
      }
      setCdmTherapies(filteredTherapies);
    })();
  }, [availableTherapies]);

  const fetchDiseaseState = React.useMemo<void>(() => {
    (async () => {
      const diseaseState = await Promise.all(
        cdmTherapies.map(async item => {
          const res = await ApplicationManagerClient.fetchDiseaseStatesNameByDiagnosisCode(item.diagnosisCode);
          return {
            id: res.data[0].id,
            name: res.data[0].name,
            icd: res.data[0].icd,
          };
        }),
      );
      setDiseaseStateData(diseaseState);
    })();
  }, [cdmTherapies]);

  // #region handlers
  const handleTherapy = (e: any) => {
    const selectedTherapyId = e.target.value;
    if (selectedTherapyId && availableTherapies.length > 0) {
      setSelectedTherapy(
        availableTherapies.find((t: ITherapy) => t.id === selectedTherapyId) || null,
      );
    }
  };

  const handleTaskType = (e: any) => {
    setSelectedType(e.target.value);
  };

  const resetTaskStatus = () => {
    setSelectedTherapy(null);
    setSelectedType('');

    handleCancel();
  };
  // #endregion handlers
  // #region renderers

  // Filter out AR task as it cannot be created by user
  let filterOutTaskArr = [FDC, THERAPIES, DUR, SMS, STAR, QRE];
  if (!UserUtils.isPermissionGrantedOnCustomerFeature(
    UserPermissions.RsTasksManagement,
    CustomerFeatures.CdmCustomer,
  )) {
    filterOutTaskArr.push(RS);
  }
  if (windowFeatureIsEnabled('hide_mr')) {
    filterOutTaskArr = [...filterOutTaskArr, MR];
  }
  if (availableTherapies.length === 0) {
    filterOutTaskArr = [...filterOutTaskArr, PA, FA, FC, DC, TPR, CSL];
  }
  const statusArrForSelect = Object.keys(taskCounts)
    .filter(task => filterOutTaskArr.indexOf(task) === -1)
    .map(field => ({
      key: field,
      value: taskCounts[field].task_description,
    }));
  const availableTherapiesForSelect = availableTherapies.filter(therapyVar => ![AdministrationStatus.NoGo, AdministrationStatus.Discontinued].includes(therapyVar.administration_status)).map(therapyVar => {
    return {
      key: therapyVar.id,
      value: `${therapyVar.drug_name} - ${therapyVar.strength} ${therapyVar.strength_unit_of_measure} ${therapyVar.administration_status}`,
    };
  });

  const shouldRenderTherapy = selectedType !== -1 && selectedType !== AR && selectedType !== OR && selectedType !== RS;
  const therapiesOptional = selectedType === INT || selectedType === OR;
  const shouldDisplayFieldsWithoutTherapy = shouldRenderTherapy && !therapiesOptional;

  const renderConditionalDetailFields = (
    statusSelectIdParam: string | number,
    statusSelectedTherapyIdParam: number,
    shouldSelectTherapy = false,
    shouldRenderTherapy: boolean,
  ) => {
    const buttonIdPrefix = `${therapyIndex}_newTaskButton`;
    if (
      statusSelectIdParam === null ||
      (shouldSelectTherapy && statusSelectedTherapyIdParam === -1 && shouldRenderTherapy)
    ) {
      return (
        <Grid container justifyContent="flex-end">
          <Button
            name="add_task_cancel_button"
            type="button"
            onClick={handleCancel}
            sx={Styles.button}
            id={`${buttonIdPrefix}_cancel`}
          >
            Cancel
          </Button>
        </Grid>
      );
    }
    if (statusSelectIdParam) {
      switch (statusSelectIdParam) {
        case AR:
          return <AddAppointmentReferral patientId={patientId} cancel={resetTaskStatus} />;
        case INT:
          if (therapiesOptional) {
            return (
              <Grid container>
                <AddIntervention
                  patient={patient}
                  cancel={resetTaskStatus}
                  isTaskSidebar={false}
                  serviceGroups={serviceGroups}
                  therapy={
                    shouldSelectTherapyManually
                      ? availableTherapies.find(t => t.id === statusSelectedTherapyIdParam)
                      : null
                  }
                />
              </Grid>
            );
          }
          break;
        case OR:
          if (therapiesOptional) {
            return (
              <Grid container>
                <AddOutreach
                  patient={patient}
                  cancel={resetTaskStatus}
                  isTaskSidebar={false}
                  serviceGroups={serviceGroups}
                  therapy={
                    shouldSelectTherapyManually
                      ? availableTherapies.find(t => t.id === statusSelectedTherapyIdParam)
                      : null
                  }
                />
              </Grid>
            );
          }
          break;
        case RS:
          // if (therapiesOptional) {
          return (
            <Grid container>
              <AddRsForm
                patient={patient}
                cancel={resetTaskStatus}
                isTaskSidebar={false}
                serviceGroups={serviceGroups}
                therapy={
                  shouldSelectTherapyManually
                    ? availableTherapies.find(t => t.id === statusSelectedTherapyIdParam)
                    : null
                }
                cdmTherapies={cdmTherapies}
                diseaseStateData={diseaseStateData}
              />
            </Grid>
          );
        // }
        /* falls through */
        // eslint-disable-next-line no-fallthrough
        default:
          if (initialValues && initialValues[statusSelectIdParam]) {
            const therapyForTaskAdd = availableTherapies.find(
              t => t.id === statusSelectedTherapyIdParam,
            );
            if (
              selectedTherapy &&
              selectedTherapy.service_group_id ===
              initialValues[statusSelectIdParam].service_group_id
            ) {
              return (
                <TaskAddForm
                  key={`${statusSelectIdParam}-add`}
                  therapy={therapyForTaskAdd}
                  taskType={statusSelectIdParam}
                  therapyId={
                    shouldSelectTherapyManually ? statusSelectedTherapyIdParam : selectedTherapy?.id
                  }
                  onCancel={resetTaskStatus}
                  task={initialValues[statusSelectIdParam]}
                  buttonIdPrefix={buttonIdPrefix}
                />
              );
            }
          }
          break;
      }
    }
    return (
      <Grid container>
        {statusSelectIdParam !== -1 && (
          <Typography>{`${statusSelectIdParam} not supported`}</Typography>
        )}
        <Grid container justifyContent="flex-end">
          <Button
            name="add_task_cancel_button"
            type="button"
            onClick={handleCancel}
            sx={Styles.button}
            id={`${buttonIdPrefix}_cancel`}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container sx={Styles.addForm}>
      <Grid container spacing={1} sx={Styles.statusContainer}>
        <Grid item xs={12}>
          <SubHeader name="New Task" />
        </Grid>
        <Grid item xs={shouldRenderTherapy || therapiesOptional ? 6 : 12}>
          <Dropdown
            label="Task Type *"
            state={selectedType}
            handler={handleTaskType}
            fields={statusArrForSelect}
            name="task_type"
            width={shouldSelectTherapyManually ? '90%' : '50%'}
            id={`addTherapyTaskDropdown_${therapyIndex}`}
          />
        </Grid>
        {shouldSelectTherapyManually && shouldRenderTherapy && (
          <Grid item xs={6}>
            <Dropdown
              label={therapiesOptional ? 'Therapy' : 'Therapy *'}
              state={selectedTherapy ? selectedTherapy?.id : -1}
              handler={handleTherapy}
              fields={availableTherapiesForSelect}
              name="therapy"
              width="90%"
              id={`addTherapyTaskTherapiesDropdown_${therapyIndex}`}
              isClearable={therapiesOptional}
              clearValue={-1}
            />
          </Grid>
        )}
        {!shouldSelectTherapyManually && shouldRenderTherapy && (
          <Grid item xs={6}>
            <Dropdown
              label={therapiesOptional ? 'Therapy' : 'Therapy *'}
              state={selectedTherapy ? selectedTherapy?.id : -1}
              handler={handleTherapy}
              fields={availableTherapiesForSelect}
              name="therapy"
              width="90%"
              id={`addTherapyTaskTherapiesDropdown_${therapyIndex}`}
              isClearable={therapiesOptional}
              clearValue={-1}
              enabled={therapiesOptional}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          {renderConditionalDetailFields(
            selectedType,
            selectedTherapy?.id || -1,
            shouldSelectTherapyManually,
            shouldDisplayFieldsWithoutTherapy,
          )}
        </Grid>
      </Grid>
    </Grid>
  );
  // #endregion renderers
};

export default AddTask;
