/* eslint-disable arrow-body-style */
import { Grid, Divider, Modal, Button, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';
import AccordionHeader from 'components/form/header/accordion-header';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import { accordianStyles } from 'components/accordian/accordian-styles';
import DocumentDisplay from 'components/document/document-display';
import { combineStyles, getModalStyle } from 'services/utils/styles-service';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SmallSpacer, LargeSpacer } from 'components/spacer/spacer';
import { editMedicalInsurance, fetchMedicalInsurances } from 'actions/action-financials';
import History from 'containers/common/history';
import moment from 'moment';
import compose from 'recompose/compose';
import { groupBy } from 'helpers/misc';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getMedicalInsuranceHeader } from 'services/utils/financials-service';
import { getDocumentsForDisplay } from 'services/utils/document-service';
import { convertToArborDate } from 'models/time/arbor-date';
import { VerificationPanel } from 'components/form/verification/verification-panel';
import { getUserById } from 'services/utils/users-service';
import classNames from 'classnames';
import TalkdeskPhoneNumber from 'components/talkdesk-phone-number/talkdesk-phone-number';
import { getActionedByUserText } from '../../../../utils/user-utils';
import SubHeader from '../../../../components/form/header/subheader';
import { styles } from '../financials-styles';
import DetailField from '../../../../components/form/field/field';
import EditMedicalInsurance from './edit-medical-insurance';
import { MEDICAL_INSURANCE, EDIT_MEDICAL_INSURANCE_FORM, COMMERCIAL } from '../../../../constants';
import { noteTagTypes } from '../../../../constants/lists';
import { EditHistory, EditPencilMd } from '../../../../components/icons/icons';

const tagType = noteTagTypes.find(tag => tag.label === MEDICAL_INSURANCE);

class MedicalDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // this contains a list of insurances currently being edited
      displayEdit: new Set(),
      editHistoryModalOpen: false,
    };

    this.handleEdit = this.handleEdit.bind(this);
    this.handleEditClose = this.handleEditClose.bind(this);
    this.handleEditHistoryModal = this.handleEditHistoryModal.bind(this);
    this.handleVerify = this.handleVerify.bind(this);
    this.handleExpand = this.handleExpand.bind(this);
  }

  handleExpand(e) {
    e.stopPropagation();
    const { expand } = this.props;
    if (expand) return;

    const { expanded } = this.state;
    this.setState({
      expanded: !expanded,
    });
  }

  handleEdit(e, insuranceId) {
    e.stopPropagation();
    this.setState(prevState => ({
      displayEdit: new Set(prevState.displayEdit).add(insuranceId),
    }));
  }

  handleEditClose(insuranceId) {
    this.setState(prevState => {
      const newDisplayEditState = new Set(prevState.displayEdit);
      newDisplayEditState.delete(insuranceId);

      return {
        displayEdit: newDisplayEditState,
      };
    });
  }

  handleEditHistoryModal(e) {
    e.stopPropagation();
    this.setState(prevState => ({
      editHistoryModalOpen: !prevState.editHistoryModalOpen,
    }));
  }

  handleVerify(medicalInsurance) {
    const {
      editMedicalInsurance, //eslint-disable-line
      fetchMedicalInsurances, //eslint-disable-line
      patientId,
    } = this.props;
    const payload = {
      ...medicalInsurance,
      no_insurance: medicalInsurance.is_no_insurance,
      policy_holder_dob: medicalInsurance.policy_holder_dob
        ? convertToArborDate(medicalInsurance.policy_holder_dob).getUtcDate()
        : null,
      start_date: medicalInsurance.start_date
        ? convertToArborDate(medicalInsurance.start_date).getUtcDate()
        : null,
      end_date: medicalInsurance.end_date
        ? convertToArborDate(medicalInsurance.end_date).getUtcDate()
        : null,
      is_verified: 1, // set is_verified to 1
      verified_dt: convertToArborDate(moment()).getUtcDatetime(),
    };

    editMedicalInsurance(payload).then(() => {
      fetchMedicalInsurances(patientId);
    });
  }

  displayDetail(medicalInsurance) {
    const { classes, users, planSponsors, stretch } = this.props;
    const isNoInsurance = Boolean(medicalInsurance.is_no_insurance);
    const verifiedUser = getUserById(medicalInsurance.verified_by, users);

    const containerDivClassname = classNames(classes.containerDiv, {
      [classes.containerDivStretched]: stretch,
    });

    if (isNoInsurance) {
      return (
        <div className={containerDivClassname}>
          <Grid container>
            <Grid item xs={12}>
              <VerificationPanel
                type="pbm"
                isVerified={medicalInsurance.is_verified}
                handleVerify={() => this.handleVerify(medicalInsurance)}
                verifiedBy={verifiedUser}
                verifiedDate={medicalInsurance.verified_dt}
              />
            </Grid>
          </Grid>
        </div>
      );
    }
    const planSponsorName = planSponsors.find(o => o.id === medicalInsurance.plan_sponsor)?.type;
    return (
      <div className={containerDivClassname}>
        <Grid container alignItems="flex-end">
          <Grid item xs={3}>
            <DetailField fieldName="Insurance Plan Name" field={medicalInsurance.plan_name} />
          </Grid>
          <Grid item xs={3}>
            <TalkdeskPhoneNumber
              number={medicalInsurance.plan_phone_number}
              headerText="Insurance Plan Phone Number"
            />
          </Grid>
          <Grid item xs={3}>
            <DetailField fieldName="Group ID" field={medicalInsurance.group_number} variant="raw" />
          </Grid>
          <Grid item xs={3}>
            <DetailField fieldName="Member ID" field={medicalInsurance.member_id} variant="raw" />
          </Grid>
        </Grid>
        <SmallSpacer />
        <Divider light />
        <SmallSpacer />
        <Grid container alignItems="flex-end">
          <Grid item xs={12}>
            <SubHeader name="Policy Holder" />
            <SmallSpacer />
          </Grid>
          <Grid item xs={3}>
            <DetailField
              fieldName="Policy Holder Name"
              field={`${
                medicalInsurance.policy_holder_last_name
                  ? medicalInsurance.policy_holder_last_name
                  : ''
              }, ${
                medicalInsurance.policy_holder_first_name
                  ? medicalInsurance.policy_holder_first_name
                  : ''
              }`}
            />
          </Grid>
          <Grid item xs={3}>
            <DetailField
              fieldName="Relationship to Patient"
              field={medicalInsurance.relationship_name}
            />
          </Grid>
          <Grid item xs={3}>
            <DetailField
              fieldName="Policy Holder Date of Birth"
              field={
                medicalInsurance.policy_holder_dob &&
                convertToArborDate(medicalInsurance.policy_holder_dob, true).getUtcDate(true)
              }
            />
          </Grid>
        </Grid>
        <SmallSpacer />
        <Grid container alignItems="flex-end">
          <Grid item xs={2}>
            <DetailField fieldName="Plan Sponsor" field={planSponsorName} />
          </Grid>
          {planSponsorName === COMMERCIAL && (
            <Grid item xs={3}>
              <DetailField fieldName="Employer" field={medicalInsurance.policy_holder_sponsor} />
            </Grid>
          )}
        </Grid>
        <LargeSpacer />
        <Grid container>
          <Grid item xs={12}>
            <VerificationPanel
              type="medical"
              isVerified={medicalInsurance.is_verified}
              handleVerify={() => this.handleVerify(medicalInsurance)}
              verifiedBy={verifiedUser}
              verifiedDate={medicalInsurance.verified_dt}
            />
          </Grid>
        </Grid>
      </div>
    );
  }

  renderPanel(medicalInsurance, inactiveInsurances) {
    const { classes, uploadedDocuments, insuranceType, expand, stretch } = this.props;
    const { displayEdit, editHistoryModalOpen, expanded } = this.state;

    const getDocuments = (resourceId, tagTypeId) =>
      getDocumentsForDisplay(uploadedDocuments.documents, resourceId, tagTypeId);

    const getHistoryURL = (patientId, resourceId) =>
      `/patients/${patientId}/financials/patient-medical-insurances/${resourceId}`;

    const key = `${insuranceType}_${medicalInsurance && medicalInsurance.id}`;
    const panelClassNames = classNames({
      [classes.stretchedPbmPanel]: stretch,
      [classes.inactivePanel]: medicalInsurance && !medicalInsurance.valid,
      [classes.inactiveHeader]: medicalInsurance && !medicalInsurance.valid,
    });

    return (
      <div>
        <Accordion elevation={0} key={key} expanded={expand || expanded} onClick={this.handleExpand}>
          <AccordionSummary
            classes={{
              expandIconWrapper: classes.expandIcon,
              content: classes.summaryContent,
            }}
            expandIcon={!expand ? <ExpandMoreIcon /> : null}
            className={panelClassNames}

          >
            {medicalInsurance ? (
              <>
                <AccordionHeader
                  smallHeader
                  name={getMedicalInsuranceHeader(
                    medicalInsurance,
                    Boolean(medicalInsurance.is_no_insurance),
                  )}
                  updatedDt={medicalInsurance.updated}
                  updatedBy={medicalInsurance.updated_by_user}
                  editHandler={e => this.handleEdit(e, medicalInsurance.id)}
                  withHistory
                  minimal
                  historyHandler={this.handleEditHistoryModal}
                  id={`${medicalInsurance.plan_name} (${convertToArborDate(
                    medicalInsurance.start_date,
                    true,
                  ).getCustomerDate(true)} -
                          ${
                            medicalInsurance.end_date === null
                              ? 'Present'
                              : convertToArborDate(medicalInsurance.end_date, true).getCustomerDate(
                                  true,
                                )
                          })`}
                  noLeftMargin={expand}
                  inactiveView={!medicalInsurance.valid}
                />
                <Modal open={editHistoryModalOpen} onClose={this.handleEditHistoryModal}>
                  <div style={getModalStyle()} className={classes.Modal}>
                    <History
                      url={getHistoryURL(medicalInsurance.patient_id, medicalInsurance.id)}
                      subType="patient-medical-insurances"
                    />
                  </div>
                </Modal>
              </>
            ) : (
              <AccordionHeader smallHeader noLeftMargin={expand} name={insuranceType} hideHistory hideEdit />
            )}
          </AccordionSummary>
          <AccordionDetails
            classes={{
              root: classes.compactPanel,
            }}
          >
            <Grid container>
              <Grid item xs={12}>

                {medicalInsurance &&
                  (!displayEdit.has(medicalInsurance.id) ? (
                    <Grid container direction="column">
                      <Grid container justifyContent="flex-end">
                        <Grid item xs={3}>
                          <DocumentDisplay
                            containerClass={classes.innerDocumentContainer}
                            taskIdResourceIds={[
                              {
                                tagTypeId: tagType.value,
                                resourceId: medicalInsurance.id,
                              },
                            ]}
                            tagTypeId={tagType.value}
                            resourceId={medicalInsurance.id}
                            drugName={MEDICAL_INSURANCE}
                            tagTypeLabel={medicalInsurance.insurance_type}
                            documents={getDocuments(medicalInsurance.id, tagType.value)}
                          />
                        </Grid>
                      </Grid>
                      <Grid item>
                        {this.displayDetail(medicalInsurance)}
                      </Grid>
                    </Grid>
                  ) : (
                    <EditMedicalInsurance
                      form={`${EDIT_MEDICAL_INSURANCE_FORM}_${medicalInsurance.id}`}
                      medicalInsurance={medicalInsurance}
                      cancel={() => this.handleEditClose(medicalInsurance.id)}
                    />
                  ))}
                {inactiveInsurances && inactiveInsurances.length > 0 && (
                  <Grid container>
                    <Grid item xs={12}>
                      {this.renderInactivePanel(inactiveInsurances)}
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>

          </AccordionDetails>
        </Accordion>
      </div>
    );
  }

  renderInactivePanel(inactiveInsurances) {
    const { classes, insuranceType } = this.props;
    return (
      <Accordion className={classes.inactivePanel} elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          classes={{
            expandIconWrapper: classes.expandIcon,
          }}
          className={classes.inativeHeader}
        >
          <AccordionHeader
            name={`Inactive ${insuranceType} Medical Benefit`}
            hideHistory
            hideEdit
            smallHeader
            inactiveView
          />
        </AccordionSummary>
        <AccordionDetails
          classes={{
            root: classes.compactPanel,
          }}
        >
          <Grid item xs={12}>
            {inactiveInsurances.map(inactive => this.renderPanel(inactive))}
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  }

  render() {
    const { medicalInsurances, nogroup } = this.props;
    const groupedMedicalInsurances = groupBy(medicalInsurances, 'valid');
    const activeMedicals =
      groupedMedicalInsurances['1'] && groupedMedicalInsurances['1'].length
        ? groupedMedicalInsurances['1']
        : [];
    const firstActiveMedical = activeMedicals ? activeMedicals[0] : null;
    const otherActiveMedicals =
      activeMedicals && activeMedicals.length > 1 ? activeMedicals.slice(1) : [];
    const inactiveInsurances = groupedMedicalInsurances['0'];
    return (
      <>
        {this.renderPanel(firstActiveMedical, inactiveInsurances)}
        {otherActiveMedicals.map(x => {
          return this.renderPanel(x);
        })}
      </>
    );
  }
}

const combinedStyles = combineStyles(styles, accordianStyles);

function mapStateToProps(state) {
  const { lookups, uploadedDocuments } = state;
  return {
    users: lookups.users,
    uploadedDocuments,
    planSponsors: lookups.planSponsors,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchMedicalInsurances,
      editMedicalInsurance,
    },
    dispatch,
  );
}

export default compose(
  withStyles(combinedStyles),
  connect(mapStateToProps, mapDispatchToProps),
)(MedicalDetail);
