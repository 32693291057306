import React, { useEffect, useState } from 'react';
import withStyles from '@mui/styles/withStyles';
import { compose } from 'recompose';
import { useSelector, useDispatch } from 'react-redux';
import { DownloadFileUtils } from 'utils/download-file-utils';
import UploadedDocumentRecord from './uploaded-document-record';
import { styles } from './uploaded-document-styles';
import { getDownloadUrl } from '../../services/utils/upload-document-service';
import { notifyError } from '../../actions/action-notifications';
import { findMatchingDocuments, getFileExtension } from '../../services/utils/document-service';
import { setDocumentViewer } from '../../actions/action-document-viewer';

const UploadedDocument = props => {
  const { isArchived, taskIdResourceIds } = props;
  const dispatch = useDispatch();
  const documentsData = useSelector(state => state.uploadedDocuments.documents) || [];
  const customerId = useSelector(state => state.filters.selectedCustomerId);
  const patientId = useSelector(state => state.selectedPatientId);

  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    const theseDocuments = findMatchingDocuments(documentsData, taskIdResourceIds);
    setDocuments(theseDocuments || []);
  }, [documentsData]);

  const handleClick = async (documentId, isDocumentArchived) => {
    if (!isDocumentArchived) {
      try {
        const response = await getDownloadUrl(customerId, patientId, documentId);
        const { url, filename } = response.data;
        const extension = getFileExtension(filename).fileExtension;
        dispatch(setDocumentViewer(true, url, extension, documentId, filename));
        DownloadFileUtils.downloadUrl(url, filename);
      } catch (error) {
        dispatch(notifyError('Unable to download document'));
      }
    }
  };

  return documents
    .sort((a, b) => b.id - a.id)
    .filter(isArchived || (item => !item.is_archived))
    .map(documentData => (
      <UploadedDocumentRecord documentData={documentData} handleClick={handleClick} />
    ));
};

export default compose(withStyles(styles))(UploadedDocument);
