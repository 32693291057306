import React, { useState } from 'react';
import { Grid, Button, ListItem, List, Typography, ListItemIcon, Box } from '@mui/material';
import moment from 'moment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ReviewItem from 'components/fc-self-service/components/review-item';

const EmptyCircle = (
  <div style={{ height: '21px', width: '21px', border: '2px solid green', borderRadius: '50%' }} />
);

const DeliveryDates = (props: any) => {
  const { isReview, currentAnswers, onEdit, answerOptions, response, classes } = props;
  const [displayOneDateOption, setDisplayOneDateOption] = useState(true);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(-1);
  const keyName = 'delivery_date';
  const questionText = 'What date do you want your order delivered?';

  if (isReview && currentAnswers.pickup_or_delivery === 'pickup') {
    return null;
  }
  if (isReview && currentAnswers.preferred_shipping_address.answerOptionsIndex < 0) {
    return null;
  }

  const answerIndex = currentAnswers.preferred_shipping_address.answerOptionsIndex;
  const zip = answerOptions.addresses[answerIndex].zip;
  const singleChoiceIndex = answerOptions.delivery_dates[zip].length > 1 ? 1 : 0;
  const list: any[] = [...answerOptions.delivery_dates[zip]];
  const disableNextButton = selectedOptionIndex === -1;
  const changeChoice = (index: number) => {
    if (index === list.length && displayOneDateOption) {
      setDisplayOneDateOption(!displayOneDateOption);
      setSelectedOptionIndex(-1);
    } else {
      setSelectedOptionIndex(index);
    }
  };
  const { controlled_substance_count, controlled_therapy } = currentAnswers.therapies.reduce(
    (acc: any, therapy: any) => {
      if (therapy.included && therapy.is_controlled) {
        acc.controlled_substance_count += 1;
        acc.controlled_therapy = therapy.name;
      }
      return acc;
    },
    { controlled_substance_count: 0, controlled_therapy: null },
  );

  const handleResponse = () => {
    const val = selectedOptionIndex === list.length ? null : list[selectedOptionIndex];
    response({ keyName, value: val });
  };

  if (isReview && currentAnswers.pickup_or_delivery === 'delivery') {
    const currentAddressAnswerIndex = currentAnswers.preferred_shipping_address.answerOptionsIndex;
    const currentZip =
      currentAddressAnswerIndex > -1
        ? answerOptions.addresses[currentAddressAnswerIndex].zip
        : null;
    const numberOfDeliveryDateOptions = answerOptions.delivery_dates[currentZip].length;
    if (!numberOfDeliveryDateOptions) {
      return null;
    }
    const item = currentAnswers[keyName];
    const current_answer = item
      ? `${moment(item).format('dddd')} - ${moment(item).format('M/D/YYYY')}`
      : 'Call me for delivery date';

    return (
      <ReviewItem
        questionText={questionText}
        onEdit={onEdit}
        currentAnswer={current_answer}
        keyName={keyName}
      />
    );
  }

  const controlledSubstanceNoticeText =
    controlled_substance_count === 1
      ? `${controlled_therapy} is a controlled substance. A signature will be required upon delivery.`
      : `${controlled_substance_count} items in your refill are controlled substances. A signature will be required upon delivery.`;

  const controlledSubstanceNotice = (
    <>
      <Typography className={classes.title} style={{ fontWeight: 500 }}>
        {controlledSubstanceNoticeText}
      </Typography>
      <br />
    </>
  );
  return (
    <Box className={classes.container} data-qa-id={`page-${keyName}`}>
      <Grid container spacing={8.5}>
        <Grid item>
          {controlled_substance_count > 0 ? controlledSubstanceNotice : null}
          <Typography className={classes.title} data-qa-id="delivery-date-question">
            {questionText}
          </Typography>
          <List
            data-qa-id="delivery-dates-list"
            style={{ maxHeight: 'calc(100vh - 230px)', overflowY: 'auto' }}
          >
            {list.map((item, index) => {
              if (displayOneDateOption && index == singleChoiceIndex) {
                return (
                  <ListItem
                    disableGutters
                    key={`${index}-${item}`}
                    data-qa-id={`delivery-date-${item}`}
                  >
                    <ListItemIcon className={classes.iconWrapper}>
                      <Button onClick={() => changeChoice(index)}>
                        {index === selectedOptionIndex ? (
                          <CheckCircleIcon className={classes.icon} />
                        ) : (
                          EmptyCircle
                        )}
                      </Button>
                    </ListItemIcon>
                    <Typography className={classes.itemListText} component="span">
                      {`${moment(item).format('dddd')} - ${moment(item).format('M/D/YYYY')}`}
                    </Typography>
                  </ListItem>
                );
              }
              if (displayOneDateOption && index !== singleChoiceIndex) {
                return null;
              }
              return (
                <ListItem
                  disableGutters
                  key={`${index}-${item}`}
                  data-qa-id={`delivery-date-${item}`}
                >
                  <ListItemIcon className={classes.iconWrapper}>
                    <Button onClick={() => changeChoice(index)}>
                      {index === selectedOptionIndex ? (
                        <CheckCircleIcon className={classes.icon} />
                      ) : (
                        EmptyCircle
                      )}
                    </Button>
                  </ListItemIcon>
                  <Typography className={classes.itemListText} component="span">
                    {`${moment(item).format('dddd')} - ${moment(item).format('M/D/YYYY')}`}
                  </Typography>
                </ListItem>
              );
            })}
            <ListItem disableGutters key="static_1" data-qa-id="delivery_date_static_1">
              <ListItemIcon className={classes.iconWrapper}>
                <Button onClick={() => changeChoice(list.length)}>
                  {selectedOptionIndex === list.length ? (
                    <CheckCircleIcon className={classes.icon} />
                  ) : (
                    EmptyCircle
                  )}
                </Button>
              </ListItemIcon>
              <Typography className={classes.itemListText} component="span">
                {displayOneDateOption ? 'Show Other Options' : 'None of these work'}
              </Typography>
            </ListItem>
          </List>
        </Grid>
      </Grid>
      <Grid className={classes.btnContainer}>
        <Grid item xs={12}>
          <Button
            size="large"
            className={classes.btnLarge}
            onClick={handleResponse}
            data-qa-id="delivery-dates-next"
            disabled={disableNextButton}
          >
            Next
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DeliveryDates;
