import React from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { Grid, Button } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import DateTimePicker from 'components/form/datepicker/datetime-picker';
import { Moment } from 'moment';
import { nameOfFactory } from 'utils/types-util';
import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import { DATABASE_DATETIME_FORMAT } from '../../../constants/index';
import { DateFormStyles } from './date-form.styles';

interface IDateFormProps extends WithStyles<typeof DateFormStyles> {
  initialValue: string;
  handleCancel: () => void;
  handleSubmit: (value: string | null) => void;
}

interface IFormFields {
  selectedValue: Moment;
}
const getFieldName = nameOfFactory<IFormFields>();

const DateForm: React.FC<IDateFormProps> = (props: IDateFormProps): JSX.Element => {
  // #region component state
  const {
    handleSubmit,
    control,
    setValue,

    formState: { errors },
  } = useForm<any>();
  // #endregion

  // #region helpers
  const onSubmit: SubmitHandler<any> = (formValues: IFormFields) => {
    const formattedValue = formValues.selectedValue.format(DATABASE_DATETIME_FORMAT);
    props.handleSubmit(formattedValue);
  };

  const onCancel = () => {
    props.handleCancel();
  };
  // #endregion

  return (
    <Grid container direction="column">
      <Grid item>
        <form>
          <Controller
            name={getFieldName('selectedValue')}
            control={control}
            rules={{ required: true }}
            defaultValue={props.initialValue}
            render={(renderProps: any) => (
              <DateTimePicker
                value={renderProps.value}
                onChange={(value: Moment) => renderProps.onChange(renderProps.value)}
                inline
                useCustomContainer
              />
            )}
          />
        </form>
      </Grid>
      <Grid item>
        <ConfirmationPanel
          handleSubmit={handleSubmit(onSubmit)}
          handleCancel={() => {
            onCancel();
          }}
          otherButtons={[
            {
              button: (
                <Button
                  className={props.classes.otherButton}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    props.handleSubmit(null);
                  }}
                >
                  None
                </Button>
              ),
            },
          ]}
        />
      </Grid>
    </Grid>
  );
};

export const DateFormStyled = withStyles(DateFormStyles)(DateForm);
