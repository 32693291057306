const TEXT_MIDDLE = 'text-middle';

export const styles = theme => ({
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  titleContainerWithMargin: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    marginBottom: 15,
  },
  title: {
    verticalAlign: TEXT_MIDDLE,
  },
  pageHeaderTitle: {
    marginLeft: theme.spacing(4),
    height: 52,
  },
  accordionHeaderContainer: {
    'paddingRight': 0,
    '&:last-child': {
      paddingRight: 0,
    },
  },
  accordionHeaderContainerInactive: {
    'paddingRight': 0,
    '&:last-child': {
      paddingRight: 0,
    },
    'backgroundColor': theme.palette.primary.summaryPanel,
  },
  accordionHeaderTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  accordionHeaderTitleNoMarginLeft: {
    marginLeft: '0 !important',
    verticalAlign: TEXT_MIDDLE,
    color: theme.palette.navigation.main,
  },
  accordionHeaderTitle: {
    marginLeft: 20,
    verticalAlign: TEXT_MIDDLE,
    color: theme.palette.navigation.main,
  },
  accordionHeaderTitleInactive: {
    marginLeft: 20,
    verticalAlign: TEXT_MIDDLE,
    color: theme.palette.primary.formLabel,
    fontSize: theme.font.formLabelSize,
  },
  minimalAccordion: {
    fontSize: theme.font.formLabelSize,
  },
  actionIconContainer: {
    textAlign: 'right',
  },
  accordionSubheaderContainer: {
    marginLeft: 20,
  },
  accordionSubheaderText: {
    fontSize: theme.font.smallFontSize,
    color: theme.palette.primary.formItem,
  },
  accordionSubheaderTextWarning: {
    fontSize: theme.font.smallFontSize,
    color: theme.palette.primary.valenciaRed,
    paddingLeft: 5,
  },
  accordionUpdatedText: {
    color: theme.palette.primary.formLabel,
  },
  headerButton: {
    minWidth: 50,
  },
  headerButtonMinimal: {
    minWidth: 35,
  },
  accordionHeaderButton: {
    minWidth: 'unset',
  },
  accordionHeaderAudit: {
    display: 'inline',
    marginTop: 2,
  },
});
