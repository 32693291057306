/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable arrow-body-style */
import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import { debounce } from 'lodash';
import {
  Checkbox,
  Grid,
  Backdrop,
  InputAdornment,
  Popover,
  Typography,
  Button,
} from '@mui/material';
import compose from 'recompose/compose';
import { bindActionCreators } from 'redux';
import { Field, reduxForm, change, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import DetailField from 'components/form/field/field';
import { renderDatePicker } from 'components/form/datepicker/datetime-picker';
import {
  ADD_PBM_INSURANCE_FORM,
  ENSURE_PBM_INSURANCE_ADDRESSED,
  AUDIT_NOT_RESOLVED,
  INSURANCE_RELATION_CODE_MAPPING,
} from 'constants/index';
import { findAuditByCategoryRuleAndStatus } from 'services/utils/audit-service';
import {
  auditCategories,
  auditResolutionStates,
  insuranceTypes,
  auditContext,
  relationCodes,
} from 'constants/lists';
import {
  addPbmInsurance,
  fetchPbmInsurances,
  fetchHasPrimaryPbmInsurance,
} from 'actions/action-financials';
import { StringUtils } from 'utils/string-utils';
import { resolveAudit } from 'actions/action-audit';
import {
  validateDate,
  validatePhone,
  required,
  validateNumeric,
  length6,
  maxLength20,
  validateSinglePrimaryInsurance,
} from 'components/form/validation/validation';
import { convertToArborDate } from 'models/time/arbor-date';
import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import Divider from 'components/divider';
import { SmallSpacer } from 'components/spacer/spacer';
import FormHeader from 'components/form/header/header';
import SubHeader from 'components/form/header/subheader';
import { convertMapToList } from 'reducers/helper-reducer';
import { PatientOverviewCopy } from 'components/icons/icons';
import { buildQaId } from 'utils/build-qa-id';
import classesName from 'classnames';
import { findInsuranceTypeName } from 'services/utils/financials-service';
import {
  renderTextField,
  renderPhoneTextField,
  renderDropdown,
  renderCheckboxHorizontal,
} from '../../../../components/form/field/redux-field';
import { styles } from '../financials-styles';
import { isValidInsuranceToResolveAudit } from '../utils';

const qaIdBuilder = buildQaId('add-pbm-insurance', '.');

class AddPbmInsurance extends Component {
  constructor(props) {
    super(props);
    this.requiredValidation = [required];
    this.state = {
      endTime: null,
      requiredAndSinglePrimary: null,
      noInsurance: false,
      noGroupNumberCheckbox: false,
      open: false,
      noPcn: false,
      popoverAnchorEl: null,
    };
    this.setEndTime = this.setEndTime.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setNoInsurance = this.setNoInsurance.bind(this);
    this.handleGroupNumberCheckbox = this.handleGroupNumberCheckbox.bind(this);
    this.handleInsurancePopover = this.handleInsurancePopover.bind(this);
    this.handleCloseInsurancePopover = this.handleCloseInsurancePopover.bind(this);
    this.handleInsuranceButtonPopover = this.handleInsuranceButtonPopover.bind(this);
    this.renderMedicalInsurancesPopover = this.renderMedicalInsurancesPopover.bind(this);
    this.handleNoPCN = this.handleNoPCN.bind(this);
  }

  componentDidMount() {
    const {
      fetchHasPrimaryPbmInsurance, // eslint-disable-line
      fetchPbmInsurances, // eslint-disable-line
      patientId,
    } = this.props; // eslint-disable-line

    fetchPbmInsurances(patientId);
    fetchHasPrimaryPbmInsurance(patientId);
  }

  componentDidUpdate(prevProps, prevState) {
    const { pbmInsurances } = this.props;
    const { endTime, requiredAndSinglePrimary } = this.state;
    const primaryInsurance = this.getPrimary(pbmInsurances);
    const isNewEndTimeUndefined = endTime && !prevState.endTime;
    const hasEndTimeLengthChanged =
      endTime &&
      prevState.endTime &&
      Object.keys(endTime).length !== Object.keys(prevState.endTime).length;

    if (
      (primaryInsurance && !this.getPrimary(prevProps.pbmInsurances)) ||
      !requiredAndSinglePrimary ||
      isNewEndTimeUndefined ||
      hasEndTimeLengthChanged
    ) {
      this.setRequiredAndSinglePrimaryValidation(primaryInsurance, endTime);
    }
  }

  setRequiredAndSinglePrimaryValidation(primary, endTime) {
    this.setState({
      requiredAndSinglePrimary: [required, validateSinglePrimaryInsurance(primary, endTime)],
    });
  }

  getPrimary(pbmInsurancesArr) {
    const { pbmInsurances } = this.props;
    const pInsurances = pbmInsurancesArr || pbmInsurances;
    return pInsurances.find(insurance => {
      const result =
        !insurance.is_no_insurance &&
        insurance.insurance_type === 'Primary' &&
        (insurance.end_date === null || convertToArborDate(insurance.end_date).isAfterNow());
      return result;
    });
  }

  setEndTime(value) {
    this.setState({
      endTime: value,
    });
  }

  setNoInsurance() {
    this.setState(prevState => ({
      noInsurance: !prevState.noInsurance,
    }));
  }

  handleInsurancePopover = event => {
    this.setState({
      open: true,
      popoverAnchorEl: event.currentTarget,
    });
  };

  handleCloseInsurancePopover = () => {
    this.setState({
      open: false,
      popoverAnchorEl: null,
    });
  };

  handleInsuranceTypeNameSearch = () => {
    const { noGroupNumberCheckbox, noPcn } = this.state;
    const { dispatch, binFormVal: bin, pcnFormVal: pcn, groupFormVal: group } = this.props;
    if (noGroupNumberCheckbox || noPcn || !bin || !pcn || !group) return;

    findInsuranceTypeName({ bin, pcn, group }).then(response => {
      const { insurance_type_name, pbm_name } = response.data;
      if (insurance_type_name || pbm_name) {
        dispatch(change(ADD_PBM_INSURANCE_FORM, 'insurance_type_name', insurance_type_name));
        dispatch(change(ADD_PBM_INSURANCE_FORM, 'pbm_name', pbm_name));
      }
    });
  };

  // eslint-disable-next-line react/sort-comp
  debouncedInsuranceTypeNameSearch = debounce(this.handleInsuranceTypeNameSearch, 500);

  handleSubmit(values) {
    const {
      addPbmInsurance, // eslint-disable-line
      fetchPbmInsurances, // eslint-disable-line
      fetchHasPrimaryPbmInsurance, // eslint-disable-line
      resolveAudit, // eslint-disable-line
      patient,
      audit,
      patientId,
      cancel,
    } = this.props;
    const { noGroupNumberCheckbox, noPcn } = this.state;

    const payload = {
      ...values,
      patient_id: patientId || values.patient_id,
      insurance_type_id: values.insurance_type_id,
      no_insurance: values.no_insurance,
      pbm_name: values.pbm_name,
      pbm_phone_number: values.pbm_phone_number,
      pbm_patient_id: values.pbm_patient_id,
      pbm_bin_number: values.pbm_bin_number,
      pbm_relation_code: values.pbm_relation_code,
      pbm_rx_group_number: noGroupNumberCheckbox ? null : values.pbm_rx_group_number,
      pbm_processor_control_number: noPcn ? null : values.pbm_processor_control_number,
      insurance_type_name: values.insurance_type_name,
      lis_effective_date: convertToArborDate(values.lis_effective_date).getUtcDate(),
      lis_effective_date_mbi: convertToArborDate(values.lis_effective_date_mbi).getUtcDate(),
      lis_termination_date: convertToArborDate(values.lis_termination_date).getUtcDate(),
      lis_date_of_service: convertToArborDate(values.lis_date_of_service).getUtcDate(),
      lis_next_medicare_part_d_termination: convertToArborDate(
        values.lis_next_medicare_part_d_termination,
      ).getUtcDate(),
      lis_next_medicare_part_d_effective_start: convertToArborDate(
        values.lis_next_medicare_part_d_effective_start,
      ).getUtcDate(),
      start_date: convertToArborDate(values.start_date).getUtcDate(),
      end_date: convertToArborDate(values.end_date).getUtcDate(),
      is_verified: 1, // Consider verified if entering in UI
      verified_dt: convertToArborDate(new Date()).getUtcDatetime(),
    };

    addPbmInsurance(payload).then(() => {
      const pbmAuditToResolve = findAuditByCategoryRuleAndStatus(
        audit,
        patient,
        auditCategories.Financials,
        auditContext.Warning,
        ENSURE_PBM_INSURANCE_ADDRESSED,
        AUDIT_NOT_RESOLVED,
      );

      if (pbmAuditToResolve && isValidInsuranceToResolveAudit(payload, insuranceTypes)) {
        const resolvedAudit = {
          ...pbmAuditToResolve,
          resolution_state_id: auditResolutionStates.Resolved,
        };
        resolveAudit(resolvedAudit);
      }

      fetchPbmInsurances(patientId).then(() => fetchHasPrimaryPbmInsurance(patientId));
    });

    if (cancel) {
      cancel();
    }
  }

  handleGroupNumberCheckbox() {
    this.setState(prevState => ({
      noGroupNumberCheckbox: !prevState.noGroupNumberCheckbox,
    }));
  }

  handleNoPCN() {
    this.setState(prevState => ({
      noPcn: !prevState.noPcn,
    }));
  }

  // eslint-disable-next-line no-unused-vars
  handleInsuranceButtonPopover(medicalInsurance) {
    const { change, touch } = this.props;
    const medicalRelationCode = medicalInsurance.policy_holder_relationship_id;
    const mappedPbmRelationCode = INSURANCE_RELATION_CODE_MAPPING[medicalRelationCode];
    const newRelationCode = mappedPbmRelationCode || '4';

    touch('pbm_name');
    touch('pbm_phone_number');
    touch('pbm_patient_id');
    touch('pbm_rx_group_number');
    touch('pbm_relation_code');

    // This part changes the fields
    change('pbm_name', medicalInsurance.plan_name);
    change('pbm_phone_number', medicalInsurance.plan_phone_number);
    change('pbm_patient_id', medicalInsurance.member_id);
    change('pbm_rx_group_number', medicalInsurance.group_number);
    change('pbm_relation_code', newRelationCode);

    this.setState({
      open: false,
      popoverAnchorEl: null,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  renderMedicalInsurancesPopover() {
    const { financials } = this.props;
    const medicalInsurances = Object.values(financials.medical_insurances.data || {});
    return medicalInsurances.map(medInsurance => {
      return (
        <>
          {medInsurance ? (
            <div>
              <Button
                onClick={() => this.handleInsuranceButtonPopover(medInsurance)}
                data-qa-id={qaIdBuilder('popup-button')}
              >
                {medInsurance.plan_name}
              </Button>
            </div>
          ) : null}
        </>
      );
    });
  }

  render() {
    const { handleSubmit, pristine, submitting, classes, cancel, lookups, financials, stretch } =
      this.props;
    const medicalInsurances = Object.values(financials.medical_insurances.data || {});

    const {
      requiredAndSinglePrimary,
      singlePrimary,
      noInsurance,
      noGroupNumberCheckbox,
      open,
      popoverAnchorEl,
      noPcn,
    } = this.state;
    const addFormContainerClassname = classesName(classes.addFormContainer, {
      [classes.addFormContainerStretched]: stretch,
    });
    return (
      <Grid
        container
        justifyContent="center"
        spacing={7}
        className={(classes.formRowContainer, classes.formContainer)}
      >
        <Grid item xs={12}>
          <form
            onSubmit={handleSubmit(this.handleSubmit)}
            className={addFormContainerClassname}
            autoComplete="off"
          >
            <Grid container>
              <Grid item xs={12} className={classes.addFormHeader}>
                <FormHeader header="New PBM Insurance" />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={3}>
                <Field
                  name="no_insurance"
                  label="No Insurance"
                  component={renderCheckboxHorizontal}
                  onChange={this.setNoInsurance}
                  displayInline
                />
              </Grid>
              <Grid item xs={3}>
                <Grid container alignItems="center" justifyContent="flex-start">
                  <Checkbox
                    checked={noPcn}
                    color="primary"
                    onChange={this.handleNoPCN}
                    value="noPcn"
                    label="No PCN"
                  />
                  <DetailField defaultValue="No PCN" />
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Grid container alignItems="center" justifyContent="flex-start">
                  <Checkbox
                    checked={noGroupNumberCheckbox}
                    color="primary"
                    onChange={this.handleGroupNumberCheckbox}
                    value="checkedGroupNumber"
                    label="Secondary"
                  />
                  <DetailField defaultValue="No Rx Group Number" />
                </Grid>
              </Grid>
            </Grid>
            <div className={classes.noInsuranceBackdropWrapper}>
              <Backdrop open={noInsurance} className={classes.noInsuranceBackdrop} />
              <Grid item xs={3} className={classes.formDropdown}>
                <Field
                  name="insurance_type_id"
                  label="Type *"
                  component={renderDropdown}
                  fields={lookups.medicalInsuranceTypes
                    .map(type => ({
                      label: type.insurance_type,
                      value: type.id,
                    }))
                    .reverse()}
                  validate={noInsurance ? null : requiredAndSinglePrimary}
                  disabled={noInsurance}
                />
              </Grid>
              <Grid container alignItems="flex-end">
                <Grid item xs={3}>
                  <Field
                    name="pbm_rx_group_number"
                    label={!noGroupNumberCheckbox ? 'Rx Group Number *' : 'Rx Group Number'}
                    validate={noInsurance || noGroupNumberCheckbox ? null : [required]}
                    component={renderTextField}
                    normalize={StringUtils.toUpper}
                    disabled={noInsurance || noGroupNumberCheckbox}
                    onChange={this.debouncedInsuranceTypeNameSearch}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    name="pbm_bin_number"
                    label="Bin Number *"
                    component={renderTextField}
                    validate={
                      noInsurance
                        ? [validateNumeric, length6]
                        : [required, validateNumeric, length6]
                    }
                    onChange={this.debouncedInsuranceTypeNameSearch}
                    disabled={noInsurance}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    name="pbm_processor_control_number"
                    label="Processor Control Number *"
                    component={renderTextField}
                    normalize={StringUtils.toUpper}
                    onChange={this.debouncedInsuranceTypeNameSearch}
                    validate={noInsurance || noPcn ? [maxLength20] : [required, maxLength20]}
                    disabled={noInsurance || noPcn}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    name="pbm_name"
                    label="PBM Name *"
                    component={renderTextField}
                    validate={noInsurance ? null : [required]}
                    disabled={noInsurance}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          {medicalInsurances.length ? (
                            <Button
                              onClick={this.handleInsurancePopover}
                              className={classes.insurancePopoverButton}
                              data-qa-id={qaIdBuilder('button')}
                            >
                              <PatientOverviewCopy />
                            </Button>
                          ) : null}
                          <Popover
                            open={open}
                            anchorEl={popoverAnchorEl}
                            onClose={this.handleCloseInsurancePopover}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'center',
                            }}
                          >
                            <div className={classes.insurancePopoverBox}>
                              <Typography className={classes.insurancePopoverBoxText}>
                                List of insurances to copy:
                              </Typography>
                              {this.renderMedicalInsurancesPopover()}
                            </div>
                          </Popover>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    name="start_date"
                    label="Start Date"
                    component={renderDatePicker}
                    validate={[validateDate]}
                    disabled={noInsurance}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    name="end_date"
                    label="End Date"
                    component={renderDatePicker}
                    validate={[validateDate]}
                    disabled={noInsurance}
                  />
                </Grid>
              </Grid>
              <Grid container alignItems="flex-end">
                <Grid item xs={3}>
                  <Field
                    name="pbm_phone_number"
                    label="Phone Number"
                    component={renderPhoneTextField}
                    validate={[validatePhone]}
                    disabled={noInsurance}
                  />
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="pbm_relation_code"
                  label="Relation Code"
                  component={renderDropdown}
                  fields={relationCodes}
                  disabled={noInsurance}
                />
              </Grid>

              <Grid container alignItems="flex-end">
                <Grid item xs={3}>
                  <Field
                    name="pbm_patient_id"
                    label="Patient ID *"
                    component={renderTextField}
                    normalize={StringUtils.toUpper}
                    validate={noInsurance ? null : [required]}
                    disabled={noInsurance}
                  />
                </Grid>
                <Grid container alignItems="flex-end">
                  <Grid item xs={3}>
                    <Field
                      name="insurance_type_name"
                      label="Insurance Type"
                      component={renderTextField}
                      disabled
                    />
                  </Grid>
                </Grid>
              </Grid>
            </div>
            <Grid container>
              <Grid item xs={12}>
                <SubHeader name="Low Income Subsidy" />
                <SmallSpacer />
              </Grid>
              <Grid item xs={3}>
                <Field name="lis_level" label="LIS Level" component={renderTextField} />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="lis_effective_date"
                  label="LIS Effective Date"
                  component={renderDatePicker}
                  validate={[validateDate]}
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="lis_termination_date"
                  label="LIS Termination Date"
                  component={renderDatePicker}
                  validate={[validateDate]}
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="lis_medicare_plan_type"
                  label="Medicare Plan Type"
                  component={renderTextField}
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="lis_medicare_beneficiary_id"
                  label="Medicare Beneficiary Identifier"
                  component={renderTextField}
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="lis_effective_date_mbi"
                  label="Effective Date for MBI"
                  component={renderDatePicker}
                  validate={[validateDate]}
                />
              </Grid>
              <Grid item xs={3}>
                <Field name="lis_benefit_id" label="Benefit Id" component={renderTextField} />
              </Grid>
              <Grid item xs={3}>
                <Field name="lis_formulary_id" label="Formulary Id" component={renderTextField} />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="lis_date_of_service"
                  label="Date of Service"
                  component={renderDatePicker}
                  validate={[validateDate]}
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="lis_contract_number"
                  label="Contract Number"
                  component={renderTextField}
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="lis_medicare_part_d_code"
                  label="Medicare Part D Code"
                  component={renderTextField}
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="lis_other_payor_id_count"
                  label="Other Payor Id Count"
                  component={renderTextField}
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="lis_cms_low_income_cost_sharing"
                  label="Cms Low Income Cost Sharing"
                  component={renderTextField}
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="lis_transaction_response_status"
                  label="Transaction Response Status"
                  component={renderTextField}
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="lis_next_medicare_part_d_termination"
                  label="Next Medicare Part D Termination"
                  component={renderDatePicker}
                  validate={[validateDate]}
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="lis_next_medicare_part_d_effective_start"
                  label="Next Medicare Part D Effective Start"
                  component={renderDatePicker}
                  validate={[validateDate]}
                />
              </Grid>
            </Grid>
            <Divider />
            <ConfirmationPanel handleCancel={cancel} disableSave={pristine || submitting} />
          </form>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  const { patient, lookups, audit, financials } = state;
  const selector = formValueSelector(ADD_PBM_INSURANCE_FORM);
  const binFormVal = selector(state, 'pbm_bin_number');
  const pcnFormVal = selector(state, 'pbm_processor_control_number');
  const groupFormVal = selector(state, 'pbm_rx_group_number');

  return {
    patient,
    lookups,
    audit,
    financials,
    insuranceTypes: state.lookups.insuranceTypes,
    pbmInsurances: convertMapToList(state.financials.pbm_insurances.data),
    hasPrimary: state.financials.has_primary_pbm_insurance,
    enableReinitialize: true,
    initialValues: {
      patient_id: null,
      insurance_type_id: null,
      pbm_name: null,
      pbm_phone_number: null,
      pbm_patient_id: null,
      pbm_bin_number: null,
      pbm_relation_code: relationCodes[0].value,
      pbm_rx_group_number: null,
      pbm_processor_control_number: null,
      start_date: null,
      end_date: null,
      is_verified: null,
      verified_dt: null,
      verified_by: null,
    },
    binFormVal,
    pcnFormVal,
    groupFormVal,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addPbmInsurance,
      fetchHasPrimaryPbmInsurance,
      fetchPbmInsurances,
      resolveAudit,
    },
    dispatch,
  );
}

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, mapDispatchToProps),
)(reduxForm({ form: ADD_PBM_INSURANCE_FORM })(AddPbmInsurance));
