/* © 2023 Trellis Rx, LLC */
import { hot } from 'react-hot-loader/root';
import React, { Component } from 'react';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { Provider } from 'react-redux';
import './index.scss';
import { withRouter, Route } from 'react-router-dom';
import { Switch, Redirect } from 'react-router';
import { configureAnchors } from 'utils/react-scrollable-anchor';
import { ConnectedRouter } from 'connected-react-router';
import { LastLocationProvider } from 'react-router-last-location';
import { ROUTES } from 'constants/index';
import { TaskTypesEnum } from 'constants/enums';
import { ArborSessionTimer } from './components/session-timer/session-timer';
import store from './store';
import { history } from './history';
import theme from './lib/themes/theme';

import Nav from './components/nav/nav';
import ErrorBoundary from './containers/shared/error/error-boundary';
import { configureWinston } from './winston-logger';
import CustomerCheck from './customer-check';
import { DataLoader } from './data-loader/data-loader';
import RouteWatcher from './route-watcher';
import Questionnaire from './components/questionnaire/questionnaire';
import FCSelfService from './components/fc-self-service/refills';
import SMSTermsAndConditions from './components/sms/pages/sms-terms-service';
import { BackgroundTimers } from './containers/background-timers/background-timers';

configureWinston('debug');
configureAnchors({ offset: -200, scrollDuration: 200 });

const redirectToDefaultView = path =>
  function () {
    const { selectedCustomerId } = store.getState().filters;
    return <Redirect from={path} to={`/customers/${selectedCustomerId}/patients?sidebar=tasks`} />;
  };

const redirectToApplicationManager = path =>
  function () {
    return <Redirect from={path} to={ROUTES.APPLICATION_MANAGER} />;
  };
function HelpContainer() {
  return (
    <iframe
      style={{ width: '100vw', height: '99vh', border: 'none', overflow: 'hidden' }}
      title="Arbor User Guide"
      src="/api/user-guide/"
    />
  );
}

const AppWithRouter = withRouter(() => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <Switch>
        <Route exact path="/refills/:urlShorten" component={FCSelfService} />
        <Route exact path="/questionnaires/:questionnaireLink" component={Questionnaire} />
        <Route exact path="/sms-terms-service/:smsTermsLink" component={SMSTermsAndConditions} />
        <CustomerCheck>
          <DataLoader>
            <RouteWatcher />
            <LastLocationProvider>
              <BackgroundTimers>
                <ArborSessionTimer>
                  <Route exact path="/" component={redirectToDefaultView('/')} />
                  <Route exact path="/user-guide/" component={HelpContainer} />
                  <Route
                    exact
                    path="/customers/:customerId"
                    component={redirectToDefaultView('/customers/:customerId')}
                  />
                  <Route
                    exact
                    path={ROUTES.APPLICATION_MANAGER}
                    component={redirectToApplicationManager(ROUTES.APPLICATION_MANAGER)}
                  />
                  <Nav />
                </ArborSessionTimer>
              </BackgroundTimers>
            </LastLocationProvider>
          </DataLoader>
        </CustomerCheck>
      </Switch>
    </ThemeProvider>
  </StyledEngineProvider>
));

// eslint-disable-next-line
class App extends Component {
  constructor(props) {
    super(props);
    this.store = store;
  }

  render() {
    return (
      <ErrorBoundary>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <AppWithRouter />
          </ConnectedRouter>
        </Provider>
      </ErrorBoundary>
    );
  }
}

export default hot(App);
