import React from 'react';
import withStyles, { WithStyles } from '@mui/styles/withStyles';
import { CheckCircle, Cancel, Help, Info } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { PatientStyles } from './patient.styles';

const optInStates = {
  noPhoneListed: 'SMS Opt-in, no mobile phones listed',
  phoneStatusNotSpecified: 'SMS Opt-in status has not been chosen',
  optInNotSent: 'SMS Opt-in, opt-in message has not been sent',
  optInSentWaiting: 'SMS Opt-in - Waiting for patient confirmation',
  optIn: 'SMS Opt-in',
  optOut: 'SMS Opt-out',
} as const;

type OptInStates = (typeof optInStates)[keyof typeof optInStates];

interface IconWithTooltipProps extends WithStyles<typeof PatientStyles> {
  state: 'provided' | 'notProvided' | 'iconInactive' | 'yellow' | 'red';
  tooltip: OptInStates | string;
  icon?: 'checkCircle' | 'cancel' | 'help' | 'info';
}
function iconWithTooltip({
  state = 'provided',
  tooltip = 'SMS Opt-in',
  icon = 'checkCircle',
  classes,
}: IconWithTooltipProps): JSX.Element {
  const renderIcon = () => {
    switch (icon) {
      case 'cancel':
        return <Cancel className={classes[state]} />;
      case 'help':
        return <Help className={classes[state]} />;
      case 'info':
        return <Info className={classes[state]} />;
      default:
        return <CheckCircle className={classes[state]} />;
    }
  };
  return <Tooltip title={tooltip}>{renderIcon()}</Tooltip>;
}

export default withStyles(PatientStyles)(iconWithTooltip);
