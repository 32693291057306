import Grid from '@mui/material/Grid';
import withStyles from '@mui/styles/withStyles';
import { editPatient, workListChanged } from 'actions/action-patient';
import Divider from 'components/divider';
import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import DetailField from 'components/form/field/field';
import ReactSelectForRedux from 'components/form/field/react-select';
import {
  renderCheckbox,
  renderDropdown,
  renderLast4ssnField,
  renderTextField,
} from 'components/form/field/redux-field';
import SubHeader from 'components/form/header/subheader';
import { RenderAddresses, renderEmails, renderPhones } from 'components/form/subform/subform';
import { required, validateDate, validateLast4ssn } from 'components/form/validation/validation';
import {
  EDIT_PATIENT_INFO_FORM,
  ENSURE_EMAIL_ADDRESSED,
  ENSURE_SMS_ADDRESSED,
  ENSURE_PATIENT_INFORMATION_VERIFIED,
  VERIFICATION_STATUS_UNTOUCHED,
  VERIFICATION_STATUS_TOUCHED,
  VERIFICATION_STATUS_VERIFIED,
  VERIFICATION_STATUS_REVERIFIED,
  NO_EMAIL,
  DECLINES_TO_PROVIDE_EMAIL,
  OTHER_PRONOUN,
} from 'constants/index';
import { PhoneUseEnum, MobilePhoneSmsEnum } from 'constants/enums';
import { genderList, raceList, pronounList, commonPronounList } from 'constants/lists';
import { convertToArborDate } from 'models/time/arbor-date';
import React, { Component } from 'react';
import { resolveAudit } from 'actions/action-audit';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Field, FieldArray, getFormValues, reduxForm, formValueSelector } from 'redux-form';
import {
  archiveInfo,
  assignRankingAndKeys,
  getLanguageList,
  processPhoneData,
  updatePhoneSmsAttributes,
} from 'services/utils/demographic-service';

import { resolveAuditDefinition } from 'services/utils/audit-service';
import { renderDatePicker } from 'components/form/datepicker/datetime-picker';
import { styles } from './patient-demographics-styles';

const shouldResolveSMSAudit = phones => {
  const phonesList = phones || [];

  const mobileNumbers = phonesList.filter(
    phone => phone.use === PhoneUseEnum.Mobile && !phone.deleted,
  );

  const hasMobilePhoneOptedIn = mobileNumbers.some(
    phone => phone.sms !== MobilePhoneSmsEnum.NotSpecified,
  );

  // If there are no mobile numbers anymore, we have to resolve the audit
  // or if any of the mobile numbers is opt-in
  return mobileNumbers.length === 0 || hasMobilePhoneOptedIn === true;
};

export class PatientForm extends Component {
  constructor(props) {
    super(props);
    this.initialMobileNumbers = (props?.initialValues?.phones || [])
      .filter(item => item.use === 'Mobile')
      .map(item => item.value);
    const { patient } = props;
    this.state = {
      isManuallyCreated: patient.is_manually_created,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  handleCancel() {
    const { cancelHandler, reset } = this.props;
    cancelHandler();
    reset();
  }

  handleSubmit(values) {
    const {
      patient,
      editPatient, // eslint-disable-line
      cancelHandler,
      workListChanged, // eslint-disable-line
      auditState,
      resolveAudit, // eslint-disable-line
      pronounFormVal,
    } = this.props;

    const payloadEmails = assignRankingAndKeys(
      archiveInfo(values.emails, patient.emails),
      values.preferred_email_index,
    );

    const payloadPhones = processPhoneData(
      assignRankingAndKeys(
        archiveInfo(values.phones, patient.phones),
        values.preferred_phone_index,
      ),
      true,
    );

    updatePhoneSmsAttributes(payloadPhones, this.initialMobileNumbers);

    const payload = {
      id: patient.id,
      first_name: values.first_name,
      last_name: values.last_name,
      middle_name: values.middle_name,
      suffix: values.suffix || null,
      nickname: values.nickname,
      gender: values.gender,
      dob: values.dob ? convertToArborDate(values.dob).getUtcDate() : null,
      ethnicity: values.ethnicity,
      ssn: values.ssn,
      source_patient_id: values.source_patient_id,
      languages: JSON.stringify(values.languages),
      need_interpreter: values.need_interpreter,
      addresses: JSON.stringify(
        assignRankingAndKeys(
          archiveInfo(values.addresses, patient.addresses),
          values.preferred_address_index,
        ),
      ),
      emails: JSON.stringify(payloadEmails),
      pronouns: pronounFormVal === OTHER_PRONOUN ? values.pronouns_freetext : values.pronouns,

      phones: JSON.stringify(payloadPhones),
      is_declined_to_provide_addresses: values.is_declined_to_provide_addresses,
      email_options: values.email_options,
      is_declined_to_provide_phones: values.is_declined_to_provide_phones,
      is_verified: 1, // If in UI, consider verified
      verified_dt: convertToArborDate(new Date()).getUtcDatetime(),
    };
    const patientEmails = patient && patient.emails ? patient.emails : null;

    if (
      // Now has emails or  Declines to provide/no email
      (payloadEmails && payloadEmails.length > 0) ||
      [DECLINES_TO_PROVIDE_EMAIL, NO_EMAIL].includes(values.email_options)
    ) {
      resolveAuditDefinition({
        auditDefinitionType: ENSURE_EMAIL_ADDRESSED,
        auditState,
        patient,
        resolverFunction: resolveAudit,
      });
    }

    if (shouldResolveSMSAudit(payloadPhones)) {
      resolveAuditDefinition({
        auditDefinitionType: ENSURE_SMS_ADDRESSED,
        auditState,
        patient,
        resolverFunction: resolveAudit,
      });
    }

    if (
      (!patient.is_verified || patient.is_verified === VERIFICATION_STATUS_UNTOUCHED) &&
      (payload.is_verified === VERIFICATION_STATUS_TOUCHED ||
        payload.is_verified === VERIFICATION_STATUS_VERIFIED ||
        payload.is_verified === VERIFICATION_STATUS_REVERIFIED)
    ) {
      resolveAuditDefinition({
        auditDefinitionType: ENSURE_PATIENT_INFORMATION_VERIFIED,
        auditState,
        patient,
        resolverFunction: resolveAudit,
      });
    }

    cancelHandler();
    return editPatient(payload).then(() => {
      if (values.suffix !== patient.suffix) {
        workListChanged();
      }
    });
  }

  render() {
    const {
      patient,
      classes,
      handleSubmit,
      submitting,
      pristine,
      isEditing,
      formValues,
      change,
      pronounFormVal,
      numberOfPotentialRxDeliveryAddresses,
    } = this.props;

    if (!formValues) {
      return null;
    }

    const { isManuallyCreated } = this.state;

    return (
      <form
        onSubmit={handleSubmit(this.handleSubmit)}
        className={classes.editForm}
        autoComplete="off"
      >
        <Grid container alignItems="center">
          <Grid item xs={12}>
            <Grid container spacing={7}>
              <Grid item xs={12}>
                <SubHeader name="Personal Information" />
              </Grid>
            </Grid>
            <Grid container spacing={7}>
              <Grid item xs={3}>
                {isManuallyCreated && !isEditing ? (
                  <Field
                    name="last_name"
                    onBlur={this.handleNameBlur}
                    label="Last Name *"
                    validate={[required]}
                    component={renderTextField}
                  />
                ) : (
                  <DetailField fieldName="Last Name" field={patient.last_name} />
                )}
              </Grid>
              <Grid item xs={3}>
                {isManuallyCreated && !isEditing ? (
                  <Field
                    name="first_name"
                    onBlur={this.handleNameBlur}
                    label="First Name *"
                    validate={[required]}
                    component={renderTextField}
                  />
                ) : (
                  <DetailField fieldName="First Name" field={patient.first_name} />
                )}
              </Grid>
              <Grid item xs={3}>
                <Field name="middle_name" label="Middle Initial" component={renderTextField} />
              </Grid>
              <Grid item xs={3}>
                <Field name="suffix" label="Suffix" component={renderTextField} />
              </Grid>
              <Grid item xs={3}>
                <Field name="nickname" label="Preferred Name" component={renderTextField} />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="pronouns"
                  label="Preferred Pronouns"
                  component={renderDropdown}
                  fields={pronounList}
                />
              </Grid>
              {pronounFormVal === OTHER_PRONOUN && (
                <Grid item xs={3}>
                  <Field
                    name="pronouns_freetext"
                    label="Preferred Pronouns"
                    component={renderTextField}
                  />
                </Grid>
              )}
            </Grid>
            <Grid container spacing={7}>
              <Grid item xs={3}>
                {isManuallyCreated && !isEditing ? (
                  <Field
                    name="dob"
                    label="Date of Birth"
                    onBlur={this.handleDobBlur}
                    component={renderDatePicker}
                    validate={[validateDate]}
                  />
                ) : (
                  <DetailField
                    fieldName="Date of Birth"
                    field={convertToArborDate(patient.dob, true).getUtcDate(true)}
                  />
                )}
              </Grid>
              <Grid item xs={3}>
                <Field
                  id="gender"
                  name="gender"
                  label="Gender"
                  validate={[required]}
                  component={renderDropdown}
                  fields={genderList}
                />
              </Grid>
              <Grid item xs={3}>
                <Field name="ethnicity" label="Race" component={renderDropdown} fields={raceList} />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="ssn"
                  label="Social Security Number"
                  adormentcontent="****-**-"
                  validate={[validateLast4ssn]}
                  component={renderLast4ssnField}
                />
              </Grid>
              <Grid item xs={3}>
                {isManuallyCreated && !isEditing ? (
                  <Field
                    name="source_patient_id"
                    label="MRN"
                    disabled
                    validate={[required]}
                    component={renderTextField}
                  />
                ) : (
                  <DetailField fieldName="MRN" field={patient.source_patient_id} />
                )}
              </Grid>
              <Grid item xs={6}>
                <Field
                  id="languages"
                  name="languages"
                  label={`Languages${formValues.need_interpreter ? ' *' : ''}`}
                  fields={getLanguageList()}
                  component={ReactSelectForRedux}
                  validate={formValues.need_interpreter ? [required] : []}
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="need_interpreter"
                  label="Needs Interpreter"
                  component={renderCheckbox}
                />
              </Grid>
            </Grid>
            <Divider />
            <Grid container>
              <FieldArray
                name="addresses"
                formValues={formValues}
                preferredField="preferred_address_index"
                declineField="is_declined_to_provide_addresses"
                component={RenderAddresses}
                change={change}
                classes={classes}
                idPrefix="patient_form"
                patient={patient}
                numberOfPotentialRxDeliveryAddresses={numberOfPotentialRxDeliveryAddresses}
              />
            </Grid>
            <Divider />
            <Grid container>
              <FieldArray
                name="emails"
                formValues={formValues}
                preferredField="preferred_email_index"
                declineField="email_options"
                component={renderEmails}
                change={change}
                classes={classes}
                idPrefix="patient_form"
              />
            </Grid>
            <Divider />
            <Grid container alignItems="center">
              <FieldArray
                name="phones"
                formValues={formValues}
                preferredField="preferred_phone_index"
                declineField="is_declined_to_provide_phones"
                component={renderPhones}
                change={change}
                classes={classes}
                idPrefix="patient_form"
              />
            </Grid>
          </Grid>

          <ConfirmationPanel
            handleCancel={this.handleCancel}
            disableSubmit={submitting || pristine}
            buttonIdPrefix="patient_form"
          />
        </Grid>
      </form>
    );
  }
}

function mapStateToProps(state, props) {
  const { patient } = props;
  const { audit, lookups } = state;

  const selector = formValueSelector(EDIT_PATIENT_INFO_FORM);
  const pronounFormVal = selector(state, 'pronouns');
  const hasCommonPronoun = commonPronounList.some(p => p.value === patient.pronouns);
  const initialValues = {
    first_name: patient.first_name,
    last_name: patient.last_name,
    middle_name: patient.middle_name,
    suffix: patient.suffix,
    nickname: patient.nickname,
    dob: convertToArborDate(patient.dob, true).getUtcDate() || null,
    gender: patient.gender,
    ethnicity: patient.ethnicity,
    languages: patient.languages,
    need_interpreter: patient.need_interpreter,
    ssn: patient.ssn,
    source_patient_id: patient.source_patient_id,
    addresses: patient.filteredAddresses,
    emails: patient.filteredEmails,
    phones: patient.filteredPhones,
    preferred_address_index: 0,
    preferred_email_index: 0,
    preferred_phone_index: 0,
    is_declined_to_provide_addresses: patient.is_declined_to_provide_addresses,
    email_options: patient.email_options,
    is_declined_to_provide_phones: patient.is_declined_to_provide_phones,
    pronouns: !patient.pronouns || hasCommonPronoun ? patient.pronouns : OTHER_PRONOUN,
    pronouns_freetext: hasCommonPronoun ? null : patient.pronouns,
  };
  const formValues = getFormValues(EDIT_PATIENT_INFO_FORM)(state) || initialValues;
  return {
    patient,
    form: EDIT_PATIENT_INFO_FORM,
    enableReinitialize: true,
    initialValues,
    formValues,
    auditState: audit,
    lookups,
    pronounFormVal,
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    editPatient,
    workListChanged,
    resolveAudit,
  }),
)(reduxForm({})(PatientForm));
