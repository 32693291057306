import React from 'react';
import { InterventionButton } from 'components/intervention-button/intervention-button';
import dcJson from 'config/task-types/dc.json';
import { getTreeMapFromDataTypes } from 'services/utils/data-collect';
import { THERAPY_CONTEXT, TASK_CONTEXT } from 'constants/index';
import { staticProviders, getCommonProviders } from './common-provider';

const DisabledInterventionButton = () => <InterventionButton disabled />;

export default (task, state) => ({
  ...staticProviders,
  ...getCommonProviders(task, state, dcJson),
  json: dcJson,
  initialValues: {
    canceled_dt: state.currentDateTime,
    completed_dt: state.currentDateTime,
    reviewed_dt: state.currentDateTime,
    data_collect_followup: true,
    data_collect_view_toggle: 1,
    data_collected_date: state.currentDateTime,
  },
  dataCollectFields: getTreeMapFromDataTypes(state.clinicalDataTypes),
  dataCollectInformation: {
    id: task.id,
    status_id: task.status_id,
    patientClinicalDataId: task.patient_clinical_data_id,
    context: state.therapy ? THERAPY_CONTEXT : TASK_CONTEXT,
    therapy: task.therapy_id ? state.therapies[task.therapy_id] : null,
  },
  dataCollectViewOptions: [
    { id: 1, name: 'Data Collect' },
    { id: 2, name: 'Previous Data Collect' },
  ],
  daysFormatter: days => {
    if (!days) {
      return 'N/A';
    }
    return days % 7 === 0 ? `${days / 7} weeks` : `${days} days`;
  },
  customElements: [
    {
      id: 'disabled-intervention-button',
      content: DisabledInterventionButton,
    },
  ],
});
