import React, { useCallback, useMemo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { touch, change } from 'redux-form';
import JsonForm from 'components/dynamic-form/json-form';
import useStore from 'hooks/useStore';
import moment from 'moment';
import useProvider from 'hooks/useProvider';
import { INT, INTERVENTION_TYPES, DC_INTERVENTION_FORM } from 'constants/index';
import { InterventionDescription } from 'models/tasks/index';
import { getRecommendationInterventionOfDc } from 'services/utils/data-collect';
import { InterventionButton } from 'components/intervention-button/intervention-button';
import { SmallSpacer } from 'components/spacer/spacer';
import { convertToArborDate } from 'models/time/arbor-date';
import providerBuilder from '../providers/provider-builder';
import taskProviderHoc from '../task-forms/task-form-hoc';
import { styles } from '../../form/field/field-styles';
import { shouldRenderDcInIntervention } from '../helpers-tasks';

const SELECTED_RECOMMENDATIONS_FIELD_NAME = 'selected_recommendation';
const filterFieldWhenNoRecommendationsGiven = (selectedRecommendations, fields) =>
  fields.filter(
    item => item.id !== SELECTED_RECOMMENDATIONS_FIELD_NAME || selectedRecommendations.length > 0,
  );

export const InterventionForm = props => {
  const { data, meta, onFormChange, onValidityChange, taskId, formName } = props;
  const auth = useSelector(state => state.auth);

  const providers = providerBuilder(INT)(data, { ...props, auth });
  const [recommendations] = useStore(`dcProtocols.data.${taskId}.recommendationTherapies`);
  const dispatch = useDispatch();

  const [initialRenderFlag, setInitialRenderFlag] = useState(false);

  useEffect(() => {
    if (props.meta.submitFailed) {
      dispatch(touch(formName, ...providers.json.fields.map(f => f.property)));
    }
  }, [meta.submitFailed]);

  useEffect(() => {
    if (initialRenderFlag) {
      dispatch(change(formName, 'reason', null));
      dispatch(change(formName, 'recommendation', null));
      dispatch(change(formName, 'outcome', null));
    } else {
      setInitialRenderFlag(true);
    }
  }, [data.type_id]);

  const providerWithRecommendations = {
    ...providers,
    selectedRecommendations: [
      ...(recommendations || []).map(recommendation => {
        const { planId, name } = recommendation;
        return { id: `${planId},${name}`, name };
      }),
    ],
  };

  // Removing selected_recommendation field if non is given ARBOR-6930
  const providersJson = {
    ...providers.json,
    fields: filterFieldWhenNoRecommendationsGiven(
      providerWithRecommendations.selectedRecommendations,
      providers.json.fields,
    ),
    forms: providers.json.forms.map(form =>
      form.name !== formName
        ? form
        : {
            ...form,
            fields: filterFieldWhenNoRecommendationsGiven(
              providerWithRecommendations.selectedRecommendations,
              form.fields,
            ),
          },
    ),
  };
  return (
    <JsonForm
      type="INT"
      formName={DC_INTERVENTION_FORM}
      formId={formName}
      jsonForm={providersJson}
      data={data}
      forceStatusId={InterventionDescription.statusMap.Required}
      providers={providerWithRecommendations}
      alwaysEnabled
      onFormChange={onFormChange}
      onValidityChange={onValidityChange}
    />
  );
};

export const WrappedInterventionForm = taskProviderHoc(InterventionForm);

const useStyles = makeStyles(styles);

export default ({ label, input, providers, meta }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const therapies = useSelector(state => state.therapies?.data);
  const forms = useSelector(state => state.form);
  const [disableFurtherUpdates, setDisableFurtherUpdates] = useState(false);

  const [tasks, links] = useStore('tasks.data', 'links.data');
  const { id, service_group_id: serviceGroupId } = useProvider(providers, 'task', {});
  const recommendationIntervention = getRecommendationInterventionOfDc(id, links, tasks);
  const { formValues } = providers;

  const {
    work_as_group: workAsGroup,
    therapy_non_appropriate_list: therapyNonAppropriateList,
  } = formValues;

  let allInterventionFormsForDCValid;
  let formsForDCInterventions = [];
  const getDCInterventionFromName = therapy =>
    therapy ? `${DC_INTERVENTION_FORM}_${therapy.id}` : DC_INTERVENTION_FORM;

  const therapyIdsForInterventions =
    formValues && formValues.therapy_non_appropriate_list
      ? formValues.therapy_non_appropriate_list.split(',')
      : [];
  const data = useMemo(() => {
    if (recommendationIntervention) {
      return {
        taskType: INT,
        ...recommendationIntervention,
        service_group_id: recommendationIntervention.service_group_id || serviceGroupId,
        type_id: recommendationIntervention.type_id || INTERVENTION_TYPES.REGIMEN,
      };
    }

    let tempInput = null;

    if (input && input.value && input.value.values && workAsGroup && !disableFurtherUpdates) {
      const newInterventionsValues = input.value.values.map(i => {
        let newFollowUpDate = i ? i.followup_dt : null;
        if (i && i.followup_dt && moment.isMoment(i.followup_dt)) {
          newFollowUpDate = convertToArborDate(i.followup_dt).getUtcDatetime();
          if (newFollowUpDate && !disableFurtherUpdates) {
            dispatch(
              change(`${DC_INTERVENTION_FORM}_${i.therapy_id}`, 'followup_dt', newFollowUpDate),
            );
            setDisableFurtherUpdates(true);
          }
        }
        return {
          ...i,
          followup_dt: newFollowUpDate,
        };
      });

      tempInput = {
        ...input,
        value: {
          ...input.value,
          values: newInterventionsValues,
        },
      };
    } else {
      setDisableFurtherUpdates(false);
    }

    const newInput = tempInput || input;
    return {
      taskType: INT,
      ...newInput.value.values,
      service_group_id: serviceGroupId,
      type_id:
        input.value.values && input.value.values.type_id
          ? input.value.values.type_id
          : INTERVENTION_TYPES.REGIMEN,
    };
  }, [input.value, tasks]);

  const [triggerIntervention, setTriggerIntervention] = useState(!!data.id);
  const handleOnChange = useCallback(
    (values, valid) => {
      if (workAsGroup) {
        input.onChange({
          values: formsForDCInterventions.map(form => form?.values || null),
          valid,
        });
      } else {
        input.onChange({
          values: {
            ...values,
            id: recommendationIntervention ? recommendationIntervention.id : null,
          },
          valid,
        });
      }
    },
    [input.onChange, tasks, workAsGroup, formsForDCInterventions],
  );

  const handleValidityChange = useCallback(
    valid => {
      if (valid !== input.value.valid) {
        if (workAsGroup && therapyIdsForInterventions.length > 0) {
          input.onChange({
            values: input.value.values,
            valid: valid && allInterventionFormsForDCValid,
          });
        } else {
          input.onChange({
            values: input.value.values,
            valid,
          });
        }
      }
    },
    [input.onChange, input.value, allInterventionFormsForDCValid],
  );

  const handleInteventionToggle = useCallback(() => {
    setTriggerIntervention(!triggerIntervention);
    if (triggerIntervention) {
      // reset form because it's off
      input.onChange(null);
    }
  }, [triggerIntervention]);

  const button = <InterventionButton onClick={handleInteventionToggle} />;

  const renderInterventionForm = therapy => {
    const labelVal = therapy ? `${label}: ${therapy.drug_name}` : label;
    const formName = getDCInterventionFromName(therapy);
    return (
      <>
        <Box className={classes.innerFormContainer}>
          <Typography className={classes.fieldLabelLarge}>{labelVal}</Typography>
          <WrappedInterventionForm
            data={
              therapy
                ? {
                    ...data,
                    therapy_id: therapy.id,
                  }
                : data
            }
            taskId={id}
            onValidityChange={handleValidityChange}
            onFormChange={handleOnChange}
            formValues={formValues}
            meta={meta}
            formName={formName}
          />
        </Box>
        <SmallSpacer />
      </>
    );
  };

  const shouldRender = !data.id || shouldRenderDcInIntervention(data, links, tasks);
  if (!shouldRender) {
    return null;
  }

  if (!triggerIntervention) {
    return button;
  }

  if (workAsGroup && workAsGroup.length > 0) {
    const therapiesForInterventions = therapyIdsForInterventions.map(idVal => therapies[idVal]);

    const interventionFormsForDCNames = therapiesForInterventions.map(therapy =>
      getDCInterventionFromName(therapy),
    );

    formsForDCInterventions = interventionFormsForDCNames.map(name => forms[name]);

    allInterventionFormsForDCValid = formsForDCInterventions.every(
      form => form && !form.syncErrors,
    );
    return (
      <>
        {button}
        <SmallSpacer />
        {therapiesForInterventions.map(t => renderInterventionForm(t))}
      </>
    );
  }

  return (
    <>
      {button}
      <SmallSpacer />
      {renderInterventionForm()}
    </>
  );
};
