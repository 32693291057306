import React, { useEffect, useState, useCallback } from 'react';
import { Mention, MentionsInput } from 'react-mentions';
import lodash from 'lodash';
import Validation from 'components/form/validation/validation';
import colors from '../../lib/themes/colors.module.scss';

const { patinaWhite4, white, steelGrey, grey23 } = colors;

const INPUT_DEBOUNCE_MS = 500;

const noteAreaStyle = {
  'control': {
    backgroundColor: white,
    borderRadius: 4,
  },
  'highlighter': {
    overflow: 'hidden',
  },
  'input': {
    margin: 0,
    color: steelGrey,
    fontSize: 14,
    fontWeight: '300',
    overflow: 'auto',
    height: '100%',
    zIndex: 1,
  },
  '&singleLine': {
    control: {
      display: 'inline-block',
      width: 130,
    },
    highlighter: {
      padding: 1,
      border: '2px inset transparent',
    },
    input: {
      padding: 1,
      border: '2px inset',
    },
    height: '100%',
  },
  '&multiLine': {
    control: {
      border: '1px solid',
      borderColor: grey23,
      color: steelGrey,
      minHeight: 100,
      maxHeight: 100,
      overflow: 'auto',
    },

    highlighter: {
      padding: 9,
    },

    input: {
      padding: 9,
      height: '100%',
      outline: 0,
      border: 0,
      margin: 0,
    },
  },
  'suggestions': {
    list: {
      backgroundColor: white,
      border: '1px solid rgba(0,0,0,0.15)',
      fontSize: 10,
    },
    item: {
      'padding': '5px 15px',
      'borderBottom': '1px solid rgba(0,0,0,0.15)',
      '&focused': {
        backgroundColor: patinaWhite4,
      },
    },
  },
};

const noteAreaMentionStyle = {
  backgroundColor: patinaWhite4,
};

const NoteArea = props => {
  const {
    input,
    data,
    disabled,
    maxLength,
    defaultPlaceholder,
    id,
    'data-qa-id': dataQaId,
    customStyles = {},
    meta,
  } = props;
  const [val, setVal] = useState(input.value);
  const debouncedOnChange = useCallback(
    lodash.debounce(e => {
      input.onChange(e.target.value);
    }, INPUT_DEBOUNCE_MS),
    [],
  );

  // Although we have setState during onChange event, there are
  // other cases which will change the input.value like reset, we need to
  // maintain this local state always be the "source of truth"
  useEffect(() => {
    setVal(input.value);
  }, [input.value]); // eslint-disable-line

  return (
    <>
      <MentionsInput
        value={val}
        onChange={e => {
          setVal(e.target.value);
          debouncedOnChange(e);
        }}
        disabled={disabled}
        style={{ ...noteAreaStyle, ...customStyles }}
        maxLength={maxLength}
        displayTransform={display => `[${display}]`}
        markup="<!@1|__id__|__display__!>"
        placeholder={defaultPlaceholder || 'Add a Note'}
        id={id}
        data-qa-id={dataQaId}
      >
        <Mention
          type="user"
          trigger="@"
          data={data}
          renderSuggestion={(suggestion, search, highlightedDisplay) => (
            <div>{highlightedDisplay}</div>
          )}
          style={noteAreaMentionStyle}
        />
      </MentionsInput>
      <Validation touched={meta.touched} error={meta.error} warning={meta.warning} />
    </>
  );
};

export default NoteArea;
