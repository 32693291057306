/* eslint-disable arrow-body-style */
import { Grid, Modal, Box } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import moment from 'moment';
import React, { Component } from 'react';
import compose from 'recompose/compose';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { accordianStyles } from 'components/accordian/accordian-styles';
import SubHeader from 'components/form/header/subheader';
import { combineStyles, getModalStyle } from 'services/utils/styles-service';
import AccordionHeader from 'components/form/header/accordion-header';
import DocumentDisplay from 'components/document/document-display';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SmallSpacer, LargeSpacer } from 'components/spacer/spacer';
import { editPbmInsurance, fetchPbmInsurances } from 'actions/action-financials';
import { groupBy } from 'helpers/misc';
import History from 'containers/common/history';
import { getPharmacyInsuranceHeader } from 'services/utils/financials-service';
import { getDocumentsForResource } from 'services/utils/document-service';
import { convertToArborDate } from 'models/time/arbor-date';
import { VerificationPanel } from 'components/form/verification/verification-panel';
import TalkdeskPhoneNumber from 'components/talkdesk-phone-number/talkdesk-phone-number';
import { getUserById } from 'services/utils/users-service';
import classNames from 'classnames';
import { styles } from '../financials-styles';
import DetailField from '../../../../components/form/field/field';
import EditPbmInsurance from './edit-pbm-insurance';
import { PBM_INSURANCE, EDIT_PBM_INSURANCE_FORM } from '../../../../constants';
import { noteTagTypes, relationCodes } from '../../../../constants/lists';

const tagType = noteTagTypes.find(tag => tag.label === PBM_INSURANCE);

class PbmDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // this contains a list of insurances currently being edited
      displayEdit: new Set(),
      editHistoryModalOpen: false,
    };

    this.handleEdit = this.handleEdit.bind(this);
    this.handleEditClose = this.handleEditClose.bind(this);
    this.handleEditHistoryModal = this.handleEditHistoryModal.bind(this);
    this.handleVerify = this.handleVerify.bind(this);
    this.handleExpand = this.handleExpand.bind(this);
  }

  handleExpand(e) {
    e.stopPropagation();
    const { expand } = this.props;
    if (expand) return;

    const { expanded } = this.state;
    this.setState({
      expanded: !expanded,
    });
  }

  handleEdit(e, insuranceId) {
    e.stopPropagation();
    this.setState(prevState => ({
      displayEdit: new Set(prevState.displayEdit).add(insuranceId),
    }));
  }

  handleEditClose(insuranceId) {
    this.setState(prevState => {
      const newDisplayEditState = new Set(prevState.displayEdit);
      newDisplayEditState.delete(insuranceId);

      return {
        displayEdit: newDisplayEditState,
      };
    });
  }

  handleEditHistoryModal(e) {
    e.stopPropagation();
    this.setState(prevState => ({
      editHistoryModalOpen: !prevState.editHistoryModalOpen,
    }));
  }

  handleVerify(pbmInsurance) {
    const {
      editPbmInsurance, //eslint-disable-line
      fetchPbmInsurances, //eslint-disable-line
    } = this.props;
    const payload = {
      ...pbmInsurance,
      start_date: pbmInsurance.start_date
        ? convertToArborDate(pbmInsurance.start_date).getUtcDate()
        : null,
      end_date: pbmInsurance.end_date
        ? convertToArborDate(pbmInsurance.end_date).getUtcDate()
        : null,
      is_verified: 1, // set is_verified to 1
      verified_dt: convertToArborDate(moment()).getUtcDatetime(),
    };
    editPbmInsurance(payload).then(() => {
      fetchPbmInsurances(pbmInsurance.patient_id);
    });
  }

  displayDetail(pbmInsurance) {
    const { classes, users, stretch } = this.props;
    const isNoInsurance = Boolean(pbmInsurance.is_no_insurance);
    const verifiedUser = getUserById(pbmInsurance.verified_by, users);

    const containerDivClassname = classNames(classes.containerDiv, {
      [classes.containerDivStretched]: stretch,
    });

    if (isNoInsurance) {
      return (
        <div className={containerDivClassname}>
          <Grid container>
            <Grid item xs={12}>
              <VerificationPanel
                type="pbm"
                isVerified={pbmInsurance.is_verified}
                handleVerify={() => this.handleVerify(pbmInsurance)}
                verifiedBy={verifiedUser}
                verifiedDate={pbmInsurance.verified_dt}
              />
            </Grid>
          </Grid>
        </div>
      );
    }
    return (
      <div className={containerDivClassname}>
        <Grid container>
          <Grid item xs={3}>
            <DetailField fieldName="PBM Name" field={pbmInsurance.pbm_name} />
          </Grid>
          <Grid item xs={3}>
            <TalkdeskPhoneNumber
              number={pbmInsurance.pbm_phone_number}
              headerText="PBM Phone Number"
            />
          </Grid>
          <Grid item xs={3}>
            <DetailField fieldName="BIN Number" field={pbmInsurance.pbm_bin_number} />
          </Grid>
        </Grid>
        <SmallSpacer />
        <Grid container>
          <Grid item xs={3}>
            <DetailField
              fieldName="Processor Control Number"
              field={pbmInsurance.pbm_processor_control_number}
              variant="raw"
            />
          </Grid>
          <Grid item xs={3}>
            <DetailField
              fieldName="Patient ID Number"
              field={pbmInsurance.pbm_patient_id}
              variant="raw"
            />
          </Grid>
          <Grid item xs={3}>
            <DetailField
              fieldName="RX Group Number"
              field={pbmInsurance.pbm_rx_group_number}
              variant="raw"
            />
          </Grid>
          <Grid item xs={3}>
            <DetailField
              fieldName="Relation Code"
              field={
                relationCodes.find(
                  relationCode => relationCode.value === pbmInsurance.pbm_relation_code,
                ).label
              }
              variant="raw"
            />
          </Grid>
        </Grid>
        <SmallSpacer />
        <Grid container>
          <Grid item xs={3}>
            <DetailField
              fieldName="Insurance Type"
              field={pbmInsurance.insurance_type_name}
              variant="raw"
            />
          </Grid>
        </Grid>
        <Box pb={3} />
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <SubHeader name="Low Income Subsidy" />
          </Grid>
          <Grid item xs={3}>
            <DetailField fieldName="LIS Level" field={pbmInsurance.lis_level} variant="raw" />
          </Grid>
          <Grid item xs={3}>
            <DetailField
              fieldName="LIS Effective Date"
              field={convertToArborDate(pbmInsurance.lis_effective_date, true).getUtcDate()}
              variant="raw"
            />
          </Grid>
          <Grid item xs={3}>
            <DetailField
              fieldName="LIS Termination Date"
              field={convertToArborDate(pbmInsurance.lis_termination_date, true).getUtcDate()}
              variant="raw"
            />
          </Grid>
          <Grid item xs={3}>
            <DetailField
              fieldName="Medicare Plan Type"
              field={pbmInsurance.lis_medicare_plan_type}
              variant="raw"
            />
          </Grid>
          <Grid item xs={3}>
            <DetailField
              fieldName="Medicare Beneficiary Identifier"
              field={pbmInsurance.lis_medicare_beneficiary_id}
              variant="raw"
            />
          </Grid>
          <Grid item xs={3}>
            <DetailField
              fieldName="Effective Date for MBI"
              field={convertToArborDate(pbmInsurance.lis_effective_date_mbi, true).getUtcDate()}
              variant="raw"
            />
          </Grid>
          <Grid item xs={3}>
            <DetailField fieldName="Benefit Id" field={pbmInsurance.lis_benefit_id} variant="raw" />
          </Grid>
          <Grid item xs={3}>
            <DetailField
              fieldName="Formulary Id"
              field={pbmInsurance.lis_formulary_id}
              variant="raw"
            />
          </Grid>
          <Grid item xs={3}>
            <DetailField
              fieldName="Date of Service"
              field={convertToArborDate(pbmInsurance.lis_date_of_service, true).getUtcDate()}
              variant="raw"
            />
          </Grid>
          <Grid item xs={3}>
            <DetailField
              fieldName="Contract Number"
              field={pbmInsurance.lis_contract_number}
              variant="raw"
            />
          </Grid>
          <Grid item xs={3}>
            <DetailField
              fieldName="Medicare Part D Code"
              field={pbmInsurance.lis_medicare_part_d_code}
              variant="raw"
            />
          </Grid>
          <Grid item xs={3}>
            <DetailField
              fieldName="Other Payor Id Count"
              field={pbmInsurance.lis_other_payor_id_count}
              variant="raw"
            />
          </Grid>
          <Grid item xs={3}>
            <DetailField
              fieldName="Cms Low Income Cost Sharing"
              field={pbmInsurance.lis_cms_low_income_cost_sharing}
              variant="raw"
            />
          </Grid>
          <Grid item xs={3}>
            <DetailField
              fieldName="Transaction Response Status"
              field={pbmInsurance.lis_transaction_response_status}
              variant="raw"
            />
          </Grid>
          <Grid item xs={3}>
            <DetailField
              fieldName="Next Medicare Part D Termination"
              field={convertToArborDate(
                pbmInsurance.lis_next_medicare_part_d_termination,
                true,
              ).getUtcDate()}
              variant="raw"
            />
          </Grid>
          <Grid item xs={3}>
            <DetailField
              fieldName="Next Medicare Part D Effective Start"
              field={convertToArborDate(
                pbmInsurance.lis_next_medicare_part_d_effective_start,
                true,
              ).getUtcDate()}
              variant="raw"
            />
          </Grid>
        </Grid>
        <LargeSpacer />
        <Grid container>
          <Grid item xs={12}>
            <VerificationPanel
              type="pbm"
              isVerified={pbmInsurance.is_verified}
              handleVerify={() => this.handleVerify(pbmInsurance)}
              verifiedBy={verifiedUser}
              verifiedDate={pbmInsurance.verified_dt}
            />
          </Grid>
        </Grid>
        <Box pb={2} />
      </div>
    );
  }

  renderPanel(pbmInsurance, inactiveInsurances) {
    const { classes, uploadedDocuments, insuranceType, expand, stretch } = this.props;
    const { displayEdit, editHistoryModalOpen, expanded } = this.state;

    const getDocuments = (resourceId, tagTypeId) =>
      getDocumentsForResource(uploadedDocuments.documents, [{ resourceId, tagTypeId }], 1);

    const getHistoryURL = (patientId, id) =>
      `/patients/${patientId}/financials/patient-pbm-insurances/${id}`;

    const key = `${insuranceType}_${pbmInsurance && pbmInsurance.id}`;
    const panelClassNames = classNames({
      [classes.stretchedPbmPanel]: stretch,
      [classes.inactivePanel]: pbmInsurance && !pbmInsurance.valid,
      [classes.inactiveHeader]: pbmInsurance && !pbmInsurance.valid,
    });

    return (
      <div>
        <Accordion
          elevation={0}
          key={key}
          expanded={expand || expanded}
          onClick={this.handleExpand}
        >
          <AccordionSummary
            classes={{
              expandIconWrapper: classes.expandIcon,
            }}
            expandIcon={!expand ? <ExpandMoreIcon /> : null}
            className={panelClassNames}
          >
            {pbmInsurance ? (
              <>
                <AccordionHeader
                  smallHeader
                  name={getPharmacyInsuranceHeader(
                    pbmInsurance,
                    Boolean(pbmInsurance.is_no_insurance),
                  )}
                  updatedDt={pbmInsurance.updated}
                  updatedBy={pbmInsurance.updated_by_user}
                  editHandler={e => this.handleEdit(e, pbmInsurance.id)}
                  withHistory
                  historyHandler={this.handleEditHistoryModal}
                  id={`${pbmInsurance.pbm_name} (${convertToArborDate(
                    pbmInsurance.start_date,
                    true,
                  ).getCustomerDate(true)} -
              ${
                pbmInsurance.end_date === null
                  ? 'Present'
                  : convertToArborDate(pbmInsurance.end_date, true).getCustomerDate(true)
              })`}
                  noLeftMargin={expand}
                  inactiveView={!pbmInsurance.valid}
                />
                <Modal open={editHistoryModalOpen} onClose={this.handleEditHistoryModal}>
                  <div style={getModalStyle()} className={classes.Modal}>
                    <History url={getHistoryURL(pbmInsurance.patient_id, pbmInsurance.id)} />
                  </div>
                </Modal>
              </>
            ) : (
              <AccordionHeader
                noLeftMargin={expand}
                name={insuranceType}
                hideHistory
                hideEdit
                smallHeader
              />
            )}
          </AccordionSummary>
          <AccordionDetails
            classes={{
              root: classes.compactPanel,
            }}
          >
            <Grid container>
              <Grid item xs={12}>
                {pbmInsurance &&
                  (!displayEdit.has(pbmInsurance.id) ? (
                    <Grid container direction="column">
                      <Grid container justifyContent="flex-end">
                        <Grid item xs={3}>
                          <DocumentDisplay
                            containerClass={classes.innerDocumentContainer}
                            taskIdResourceIds={[
                              {
                                tagTypeId: tagType.value,
                                resourceId: pbmInsurance.id,
                              },
                            ]}
                            tagTypeId={tagType.value}
                            resourceId={pbmInsurance.id}
                            drugName={PBM_INSURANCE}
                            tagTypeLabel={PBM_INSURANCE}
                            documents={getDocuments(pbmInsurance.id, tagType.value)}
                          />
                        </Grid>
                      </Grid>
                      <Grid item>{this.displayDetail(pbmInsurance)}</Grid>
                    </Grid>
                  ) : (
                    <EditPbmInsurance
                      pbmInsurance={pbmInsurance}
                      cancel={() => this.handleEditClose(pbmInsurance.id)}
                      form={`${EDIT_PBM_INSURANCE_FORM}_${pbmInsurance.id}`}
                    />
                  ))}
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        {inactiveInsurances && inactiveInsurances.length > 0 && (
          <Grid container>
            <Grid item xs={12}>
              {this.renderInactivePanel(inactiveInsurances)}
            </Grid>
          </Grid>
        )}
      </div>
    );
  }

  renderInactivePanel(inactiveInsurances) {
    const { classes, insuranceType } = this.props;
    return (
      <Accordion elevation={0} className={classes.inactivePanel}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          classes={{
            expandIconWrapper: classes.expandIcon,
          }}
          className={classes.inactiveHeader}
        >
          <AccordionHeader
            name={`Inactive ${insuranceType} Pharmacy Benefit`}
            hideHistory
            hideEdit
            smallHeader
            inactiveView
          />
        </AccordionSummary>
        <AccordionDetails
          classes={{
            root: classes.compactPanel,
          }}
        >
          <Grid item xs={12}>
            {inactiveInsurances.map(inactive => this.renderPanel(inactive))}
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  }

  render() {
    const { pbmInsurances } = this.props;
    const groupedPbmInsurances = groupBy(pbmInsurances, 'valid');
    const activeInsurances =
      groupedPbmInsurances['1'] && groupedPbmInsurances['1'].length
        ? groupedPbmInsurances['1']
        : [];
    const firstActiveInsurance = activeInsurances ? activeInsurances[0] : null;
    const otherActiveInsurances =
      activeInsurances && activeInsurances.length > 1 ? activeInsurances.slice(1) : [];
    const inactiveInsurances = groupedPbmInsurances['0'];
    return (
      <>
        {this.renderPanel(firstActiveInsurance, inactiveInsurances)}
        {otherActiveInsurances.map(x => {
          return this.renderPanel(x);
        })}
      </>
    );
  }
}

const combinedStyles = combineStyles(styles, accordianStyles);

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchPbmInsurances,
      editPbmInsurance,
    },
    dispatch,
  );
}

function mapStateToProps(state) {
  const { lookups, uploadedDocuments } = state;

  return {
    users: lookups.users,
    uploadedDocuments,
  };
}

export default compose(
  withStyles(combinedStyles),
  connect(mapStateToProps, mapDispatchToProps),
)(PbmDetail);
