import React, { useState, useEffect, useRef } from 'react';
import { RemoveRedEye } from '@mui/icons-material';
import { Tooltip } from '@mui/material';

export const MultiuserIndicator = () => {
  const [userConnections, setUserConnections] = useState<Record<string, any>>({});
  const lastState = useRef({});

  const processMessage = (event: any) => {
    if (event.data.source === 'multiuserConnections') {
      const currentState = Object.keys(lastState.current).sort();
      const newData = Object.keys(event.data.conns).sort();
      if (currentState.length !== newData.length) {
        lastState.current = event.data.conns;
        setUserConnections(event.data.conns);
        return;
      }
      const dataChanged = currentState.some(
        (entry: any, index: number) => newData[index] !== entry,
      );
      if (dataChanged) {
        lastState.current = event.data.conns;
        setUserConnections(event.data.conns);
      }
    }
  };

  const removeMessageHandler = () => {
    window.removeEventListener('message', processMessage);
  };

  useEffect(() => {
    window.addEventListener('message', processMessage);
    return removeMessageHandler;
  }, []);

  return Object.keys(userConnections).length > 0 ? (
    <Tooltip
      title={Object.keys(userConnections).map(userId => (
        <div key={`userConnection-${userId}`}>{userConnections[userId]}</div>
      ))}
    >
      <div style={{ position: 'relative', width: '26px', height: '20px' }}>
        <RemoveRedEye
          style={{
            fill: '#c78a80',
            fontSize: '20px',
            marginRight: 6,
            transform: 'translateY(-1px)',
          }}
        />
      </div>
    </Tooltip>
  ) : (
    <RemoveRedEye style={{ fill: '#70777a44', fontSize: '20px', marginRight: 6 }} />
  );
};
