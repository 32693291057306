import React from 'react';
import SecondaryTitleBar from 'components/secondary-title-bar/secondary-title-bar';
import TherapySubTitle from 'components/therapy-subtitle/therapy-subtitle';
import { Field } from 'redux-form';
import { Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { ICounselingTask } from 'interfaces/redux/task-types/ICounselingTask';
import { ITask } from 'interfaces/redux/ITasks';
import { nameOfFactory } from 'utils/types-util';
import { ReduxFormUtil } from 'utils/redux-form-util';
import { renderDatePicker } from 'components/form/datepicker/datetime-picker';
import {
  required,
  validateDate,
  validateDateIsBefore,
  validateDateIsTodayOrAfter,
} from 'components/form/validation/validation';
import { TherapyUtil } from 'utils/therapy-util';
import {
  renderNumberField as RenderNumberField,
  renderYesNoDropdown as RenderYesNoDropdown,
} from 'components/form/field/redux-field';
import HorizontalTaskList from 'components/horizontal-task-list';
import { TaskId } from 'interfaces/RecordTypes';
import { IProps } from './interfaces/IProps';
import { IFollowUpFormFields } from '../interfaces/IProps';
import { styles } from '../styles/counseling.styles';

const nameOfFormFields = nameOfFactory<IFollowUpFormFields>();
const fieldNamePerTask = (field: keyof IFollowUpFormFields, task: ITask) =>
  ReduxFormUtil.getFieldNamePerTask<IFollowUpFormFields>(field, task);

const FollowUp: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { classes, shortenTasks, tasksTherapies, tasks, readOnly } = props;

  const [nextAssessmentValidation, setNextAssessmentValidation] = React.useState<
    Record<TaskId, any[]>
  >({});

  React.useEffect(() => {
    const validations: Record<TaskId, any> = {};
    Object.entries(props.nextAssessmentMaxDates).forEach(([key, val]) => {
      validations[Number(key)] = [
        required,
        validateDate,
        validateDateIsBefore(val.clone().add(1, 'days')),
        validateDateIsTodayOrAfter,
      ];
    });
    setNextAssessmentValidation(validations);
  }, [props.nextAssessmentMaxDates]);

  const renderTaskSpecificFields = (task: ICounselingTask): JSX.Element | null => {
    const therapy = tasksTherapies[task.therapy_id];
    if (!therapy) {
      return null;
    }
    const label = TherapyUtil.getParagraphFormat(therapy);
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TherapySubTitle label={label} hideLeftMargin />
        </Grid>
        <Grid item xs={3}>
          <Field
            name={fieldNamePerTask('dcPrev', task)}
            label="Previous DC Date"
            component={renderDatePicker}
            disabled
            placeholder=""
            fullWidth
          />
        </Grid>
        <Grid item xs={3}>
          <Field
            name={fieldNamePerTask('dcNext', task)}
            label="Next DC Date"
            component={renderDatePicker}
            disabled
            placeholder=""
            fullWidth
          />
        </Grid>
        {((props.hideOngoingCounselingNeededQuestions &&
          Object.keys(props.hideOngoingCounselingNeededQuestions).length === 0) ||
          props.hideOngoingCounselingNeededQuestions?.[task.id] === false) && (
          <>
            <Grid item xs={3}>
              <div className={classes.onGoingCounselingField}>
                <Field
                  name={fieldNamePerTask('ongoingCounselingNeeded', task)}
                  label="Ongoing Counseling Needed *"
                  component={RenderYesNoDropdown}
                  validate={[required]}
                  disabled={readOnly}
                  fullWidth
                />
              </div>
            </Grid>
            {props.hideNextAssessmentDates && props.hideNextAssessmentDates[task.id] !== 0 && (
              <Grid item xs={3}>
                <Field
                  name={fieldNamePerTask('nextAssessmentDate', task)}
                  label="Next Assessment Date *"
                  component={renderDatePicker}
                  validate={nextAssessmentValidation[task.id]}
                  disabled={readOnly}
                  fullWidth
                />
              </Grid>
            )}
          </>
        )}
        <Grid item xs={3}>
          <Field
            name={fieldNamePerTask('ongoingCheckinNeeded', task)}
            label="Check-In Needed *"
            component={RenderYesNoDropdown}
            validate={[required]}
            disabled={readOnly}
          />
        </Grid>
        {props.hideNextCheckinDates && props.hideNextCheckinDates[task.id] !== 0 && (
          <Grid item xs={3}>
            <Field
              name={fieldNamePerTask('nextCheckinDate', task)}
              label="Next Check-In Date *"
              component={renderDatePicker}
              validate={[required]}
              disabled={readOnly}
              fullWidth
            />
          </Grid>
        )}
      </Grid>
    );
  };

  return (
    <>
      <div className={classes.secondaryTitleHeader}>
        <SecondaryTitleBar title="Follow up scheduling" />
      </div>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Field
            name={nameOfFormFields('sessionDuration')}
            label="Session Duration (minutes)"
            component={RenderNumberField}
            disabled={readOnly}
            width="100%"
          />
        </Grid>
        <Grid item xs={3}>
          <Field
            name={nameOfFormFields('counselingCompletedDate')}
            label="Counseling Completed Date *"
            component={renderDatePicker}
            validate={[validateDate, required]}
            disabled={readOnly}
            fullWidth
          />
        </Grid>
      </Grid>
      {tasks.map(task => renderTaskSpecificFields(task))}
      <div className={classes.secondaryTitleHeader}>
        <SecondaryTitleBar title="Upcoming Tasks" />
      </div>
      <Grid container>
        <Grid item xs={12}>
          <HorizontalTaskList numberOfCards={6} tasks={shortenTasks} />
        </Grid>
      </Grid>
    </>
  );
};

export default withStyles(styles)(FollowUp);
