import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { ITherapy } from 'interfaces/redux/ITherapy';
import { RunBiRightSide } from './run-bi-right-side';
import { Title } from './title';
import { useStyles } from './styles';

interface IProps {
  therapy: ITherapy;
  taskId?: number;
}

export const NoBiForTherapy: React.FC<IProps> = (props: IProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <Title />
      </Grid>

      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={7}>
          <Typography className={classes.content}>
            There are no previous Benefits Investigations for this therapy.
          </Typography>
        </Grid>
        <Grid item xs="auto">
          <RunBiRightSide
            therapyId={props.therapy.id}
            ndc={props.therapy.ndc}
            taskId={props.taskId}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
