/* eslint-disable no-confusing-arrow */
import {
  validateNumeric,
  validateNumber,
  maxNumber,
  minNumber,
  alwaysValid,
} from 'components/form/validation/validation';
import {
  INTERVENTION_TYPES,
  TASK_INTERVENTIONS,
  TASK_DATA_COLLECT,
  TASK_RISK_STRAT,
  INT,
} from 'constants/index';
import { DataCollectStatus } from 'interfaces/enums/TaskStatuses/DataCollectStatus';
import { convertToArborDate } from 'models/time/arbor-date';

export const getCategoryName = (clinicalDataTypes, categoryId) =>
  clinicalDataTypes[categoryId] ? clinicalDataTypes[categoryId].name : '';

export const getDataTypeName = (clinicalDataTypesMap, dataTypeId) =>
  clinicalDataTypesMap[dataTypeId] ? clinicalDataTypesMap[dataTypeId].name : '';

export const getClinicalDataTypesMap = clinicalDataTypes =>
  Object.keys(clinicalDataTypes).reduce((acc, categoryId) => {
    Object.keys(clinicalDataTypes[categoryId].dataTypes).forEach(dataTypeId => {
      acc[dataTypeId] = {
        ...clinicalDataTypes[categoryId].dataTypes[dataTypeId],
        categoryName: clinicalDataTypes[categoryId].name,
        dataTypeId,
        categoryId,
      };
    });
    return acc;
  }, {});

export const getActiveClinicalDataTypes = clinicalDataTypes => {
  const activeClinicalDataTypes = {};
  const activeClinicalDataTypesIds = Object.keys(clinicalDataTypes).filter(
    dataTypeId => clinicalDataTypes[dataTypeId]?.isActive,
  );
  activeClinicalDataTypesIds.forEach(dataTypeId => {
    activeClinicalDataTypes[dataTypeId] = clinicalDataTypes[dataTypeId];
  });
  return activeClinicalDataTypes;
};

export const getTreeMapFromDataTypes = clinicalDataTypes =>
  clinicalDataTypes
    ? Object.keys(clinicalDataTypes).map(categoryId => ({
        title: clinicalDataTypes[categoryId].name,
        value: categoryId,
        key: categoryId,
        children: Object.keys(clinicalDataTypes[categoryId].dataTypes).map(dataTypeId => {
          const { isActive } = clinicalDataTypes[categoryId].dataTypes[dataTypeId];
          return {
            parentTitle: clinicalDataTypes[categoryId].name,
            title: `${clinicalDataTypes[categoryId].dataTypes[dataTypeId].name} ${
              isActive === false ? '(deprecated)' : ''
            }`,
            value: `${categoryId}-${dataTypeId}`,
            key: `${categoryId}-${dataTypeId}`,
            disableCheckbox: isActive === false,
            disabled: isActive === false,
          };
        }),
      }))
    : [];

export const getFirstCategoryOfDataType = (clinicalDataTypes, dataTypeId) => {
  const clinicalDataTypesMap = getClinicalDataTypesMap(clinicalDataTypes);
  const allFields = Object.keys(clinicalDataTypesMap).map(dtId => clinicalDataTypesMap[dtId]);
  return (
    allFields.find(it => String(it.dataTypeId) === String(dataTypeId)) || {
      categoryId: 0,
    }
  );
};

export const getDataType = (clinicalDataTypes, dataTypeId) => {
  const clinicalDataTypesMap = getClinicalDataTypesMap(clinicalDataTypes);
  return clinicalDataTypesMap[dataTypeId] || {};
};

export const getFieldProperties = field => {
  switch (field.type) {
    case 'list':
      return {
        component: 'dropdown',
        displayFormat: 'idNameFormatter',
      };
    case 'multiselect':
      return {
        component: 'dropdownMultiple',
        displayFormat: 'idNameMultipleFormatter',
      };
    case 'integer':
      return {
        component: 'number',
        validation: [
          validateNumeric,
          field.max !== undefined && field.max !== null ? maxNumber(field.max) : alwaysValid,
          field.min !== undefined && field.min !== null ? minNumber(field.min) : alwaysValid,
        ],
      };
    case 'numeric':
    case 'decimal':
      return {
        component: 'number',
        decimal: true,
        validation: [
          validateNumber,
          field.max !== undefined && field.max !== null ? maxNumber(field.max) : alwaysValid,
          field.min !== undefined && field.min !== null ? minNumber(field.min) : alwaysValid,
        ],
      };
    case 'questionnaire':
      return {
        component: 'questionnaireValue',
        displayFormat: 'scoreFormatter',
      };
    case 'therapy':
      return {
        component: 'therapySelector',
        displayFormat: 'idNameMultipleFormatter',
        qaId: 'therapySelector',
      };
    default:
      return {
        component: 'text',
      };
  }
};

export const evaluateOperator = (dcItem, operator, value) => {
  switch (operator) {
    case 'eq': {
      return dcItem && String(dcItem.value) === String(value);
    }
    case 'in': {
      return dcItem && value.includes(String(dcItem.value));
    }
    case 'gteq': {
      return dcItem && Number(dcItem.value) >= Number(value);
    }
    default:
      return false;
  }
};

const removeDuplicatesFieldsToAdd = arr => {
  return arr.reduce((acc, item) => {
    if (acc.length === 0) {
      acc.push(item);
    } else if (!acc.some(x => x.id === item.id && x.key === item.key)) {
      acc.push(item);
    }
    return acc;
  }, []);
};

const removeDuplicatesForListOfConditionalFieldIds = arr => {
  return arr.filter((item, pos) => {
    return arr.indexOf(item) === pos;
  });
};

export const getFieldsToAdd = (
  fieldIdsWithoutCategory,
  allFields,
  clinicalDataItems,
  fieldsToAdd = [],
  listOfConditionalFieldIds = [],
) => {
  const tempListOfConditionalFieldIds = [];
  allFields.reduce((acc, field) => {
    if (field.conditionalDataType) {
      field.conditionalDataType.forEach(condition => {
        const [fieldId, operator, value] = condition;
        const index = Array.isArray(fieldId)
          ? fieldId.reduce((acc2, x) => {
              const newIndex = fieldIdsWithoutCategory.indexOf(String(x));
              if (newIndex > -1) {
                acc2 = newIndex;
              }
              return acc2;
            }, -1)
          : fieldIdsWithoutCategory.indexOf(String(fieldId));
        if (Array.isArray(fieldId) && Array.isArray(value) && fieldId.length === value.length) {
          if (
            index > -1 &&
            fieldId.every((fieldValue, i) => {
              return evaluateOperator(
                (clinicalDataItems || []).find(
                  dcItem => String(dcItem.clinicalDataTypeId) === String(fieldValue),
                ),
                operator,
                value[i],
              );
            })
          ) {
            fieldId.forEach(fieldValueId => {
              acc.push({ id: String(field.dataTypeId), conditional: true, key: fieldValueId });
              listOfConditionalFieldIds.push(field.dataTypeId);
              tempListOfConditionalFieldIds.push(field.dataTypeId);
            });
          }
        } else if (
          index > -1 &&
          evaluateOperator(
            (clinicalDataItems || []).find(
              dcItem => String(dcItem.clinicalDataTypeId) === String(fieldId),
            ),
            operator,
            value,
          )
        ) {
          acc.push({ id: String(field.dataTypeId), conditional: true, key: fieldId });
          listOfConditionalFieldIds.push(field.dataTypeId);
          tempListOfConditionalFieldIds.push(field.dataTypeId);
        }
      });
    }
    return acc;
  }, fieldsToAdd);

  if (tempListOfConditionalFieldIds.length > 0) {
    getFieldsToAdd(
      tempListOfConditionalFieldIds[0],
      allFields,
      clinicalDataItems,
      fieldsToAdd,
      listOfConditionalFieldIds,
    );
  }
  fieldsToAdd = removeDuplicatesFieldsToAdd(fieldsToAdd);
  listOfConditionalFieldIds =
    removeDuplicatesForListOfConditionalFieldIds(listOfConditionalFieldIds);
  return [fieldsToAdd, listOfConditionalFieldIds];
};

export const getFieldsIdsWithConditional = (fieldIds, allFields, clinicalDataItems) => {
  const fieldIdsWithoutCategory = fieldIds.map(f => f.split('-')[1]);
  const [fieldsToAdd, listOfConditionalFieldIds] = [
    ...getFieldsToAdd(fieldIdsWithoutCategory, allFields, clinicalDataItems),
  ];

  const parseIds =
    fieldsToAdd.length > 0
      ? fieldsToAdd
          .map(item => item.key)
          .reduce((acc, x) => {
            if (!acc.includes(x)) {
              acc.push(x);
            }
            return acc;
          }, [])
      : [];

  const fieldIdsData =
    parseIds.length > 0
      ? parseIds.concat(
          fieldIdsWithoutCategory.filter(item => {
            return parseIds.indexOf(parseInt(item, 10)) < 0;
          }),
        )
      : fieldIdsWithoutCategory;

  const newFieldList = fieldIdsData.reduce((acc, dataTypeId) => {
    if (!listOfConditionalFieldIds.includes(String(dataTypeId))) {
      acc.push({ id: String(dataTypeId) });
    }
    fieldsToAdd.forEach(item => {
      if (item.key === dataTypeId && !acc.some(x => x.id === item.id)) {
        acc.push({ id: item.id, conditional: item.conditional });
      }
    });
    return acc;
  }, []);
  return newFieldList;
};

export const getInterventionsOfDc = (dcId, links, tasks, extraCondition, includeNotLoadedFlag) => {
  const intLinks = (links || []).filter(l => {
    if (
      (l.resource_one_type === TASK_DATA_COLLECT || l.resource_one_type === TASK_RISK_STRAT) &&
      l.resource_one_id === dcId &&
      l.resource_two_type === TASK_INTERVENTIONS
    ) {
      const meta = typeof l.meta === 'string' ? JSON.parse(l.meta) : l.meta;
      if (!meta || !meta.cause) {
        return false;
      }
      if (extraCondition) {
        return extraCondition(l, meta);
      }
      return true;
    }
    return false;
  });
  if (tasks) {
    return intLinks.map(l => {
      const task = tasks[`${INT}${l.resource_two_id}`];
      if (task) {
        return task;
      }
      if (includeNotLoadedFlag) {
        return {
          notLoaded: true,
          id: l.resource_two_id,
          taskType: INT,
        };
      }
      return null;
    });
  }
  return intLinks;
};

export const getRecommendationInterventionOfDc = (dcId, links, tasks) => {
  const interventions = getInterventionsOfDc(
    dcId,
    links,
    tasks,
    (l, meta) => meta.cause !== 'efficacy_safety',
  );
  if (interventions.length) {
    return interventions[0];
  }
  return null;
};

export const getEfficacySafetyInterventionOfDc = (dcId, clinicalDataTypeId, links, tasks) => {
  const interventions = getInterventionsOfDc(
    dcId,
    links,
    tasks,
    (l, meta) =>
      meta.cause === 'efficacy_safety' && meta.clinical_data_type_id === clinicalDataTypeId,
  );
  if (interventions.length) {
    return interventions[0];
  }
  return null;
};

export const isDcTransitioningToIntervening = dcFormValues => {
  if (dcFormValues.status_id === DataCollectStatus.Reviewed) {
    if (dcFormValues.is_therapy_appropriate !== 1 && dcFormValues.intervention_json) {
      // for CDM, it is possible that some of the therapies are going to intervening
      // and others aren't. In these cases we want to continue as if it were not going
      // to intervening.
      if (
        dcFormValues.work_as_group &&
        dcFormValues.work_as_group.length > 1 &&
        dcFormValues.work_as_group.length !==
          (dcFormValues.therapy_non_appropriate_list || '').split(',').length
      ) {
        return false;
      }
      // goes to intervening if recommendation intervention has no outcome
      const recommendationInterventionValues =
        typeof dcFormValues.intervention_json === 'object'
          ? dcFormValues.intervention_json.values
          : JSON.parse(dcFormValues.intervention_json);
      if (
        recommendationInterventionValues &&
        !recommendationInterventionValues.outcome &&
        [
          INTERVENTION_TYPES.DDI,
          INTERVENTION_TYPES.ADR,
          INTERVENTION_TYPES.REGIMEN,
          INTERVENTION_TYPES.LAB,
          INTERVENTION_TYPES.DRUG_INFORMATION,
        ].includes(recommendationInterventionValues.type_id)
      ) {
        return true;
      }
    }
    if (dcFormValues.intervention_efficacy_safety_json) {
      // goes to intervening if any efficacy or safety intervention has no outcome
      if (typeof dcFormValues.intervention_efficacy_safety_json === 'object') {
        return dcFormValues.intervention_efficacy_safety_json.some(
          intervention =>
            !intervention.values.outcome &&
            [
              INTERVENTION_TYPES.DDI,
              INTERVENTION_TYPES.ADR,
              INTERVENTION_TYPES.REGIMEN,
              INTERVENTION_TYPES.LAB,
              INTERVENTION_TYPES.DRUG_INFORMATION,
            ].includes(intervention.values.type_id),
        );
      }
      return JSON.parse(dcFormValues.intervention_efficacy_safety_json).some(
        intervention =>
          !intervention.outcome &&
          [
            INTERVENTION_TYPES.DDI,
            INTERVENTION_TYPES.ADR,
            INTERVENTION_TYPES.REGIMEN,
            INTERVENTION_TYPES.LAB,
            INTERVENTION_TYPES.DRUG_INFORMATION,
          ].includes(intervention.type_id),
      );
    }
  }
  return false;
};

export const getDcReferenceAsText = (references, value) => {
  const currentValueReference = (references || []).find(
    reference => Number(value) >= reference.from && Number(value) <= reference.to,
  );
  return currentValueReference ? currentValueReference.value : '-';
};

export const mapValuesToClinicalDataType = (values, clinicalDataTypeId) => {
  return {
    assessmentDate: convertToArborDate(values[`${clinicalDataTypeId}-date`], true).getUtcDate(),
    cannotCompleteReason: values[`${clinicalDataTypeId}-cannot-complete-reason`],
    clinicalDataTypeId,
    required: Boolean(values[`${clinicalDataTypeId}-required`]),
    value: values[`${clinicalDataTypeId}-value`],
    id: values[`${clinicalDataTypeId}-id`],
  };
};

export const getAllFieldsToAdd = (
  fieldIdsWithoutCategory,
  allFields,
  passedValues,
  ind = 0,
  fieldsToAdd = [],
  listOfConditionalFieldIds = [],
  nextFieldId = undefined,
) => {
  const idToBeUsed = nextFieldId ?? fieldIdsWithoutCategory[ind];
  const tempListOfConditionalFieldIds = [];
  allFields.reduce((acc, field) => {
    if (field.conditionalDataType) {
      field.conditionalDataType.forEach(condition => {
        const [fieldId, operator, value] = condition;
        const index = Array.isArray(fieldId)
          ? fieldId.reduce((acc2, x) => {
              const newIndex = idToBeUsed.indexOf(String(x));
              if (newIndex > -1) {
                acc2 = newIndex;
              }
              return acc2;
            }, -1)
          : idToBeUsed.indexOf(String(fieldId));

        if (Array.isArray(fieldId) && Array.isArray(value) && fieldId.length === value.length) {
          if (
            index > -1 &&
            fieldId.every((fieldValue, i) => {
              const clinicalDataItems = [
                {
                  value: passedValues[`${fieldValue}-value`],
                  clinicalDataTypeId: fieldValue,
                },
              ];
              return evaluateOperator(
                (clinicalDataItems || []).find(
                  dcItem => String(dcItem.clinicalDataTypeId) === String(fieldValue),
                ),
                operator,
                value[i],
              );
            })
          ) {
            fieldId.forEach(fieldValueId => {
              acc.push({ id: String(field.dataTypeId), conditional: true, key: fieldValueId });
              listOfConditionalFieldIds.push(field.dataTypeId);
              tempListOfConditionalFieldIds.push(field.dataTypeId);
            });
          }
        } else {
          const clinicalDataItems = [
            {
              value: passedValues[`${idToBeUsed}-value`],
              clinicalDataTypeId: idToBeUsed,
            },
          ];

          if (
            index > -1 &&
            evaluateOperator(
              (clinicalDataItems || []).find(
                dcItem => String(dcItem.clinicalDataTypeId) === String(fieldId),
              ),
              operator,
              value,
            )
          ) {
            if (
              !acc.some(x => {
                return (
                  x.id === String(field.dataTypeId) && x.conditional === true && x.key === fieldId
                );
              })
            ) {
              acc.push({ id: String(field.dataTypeId), conditional: true, key: fieldId });
              listOfConditionalFieldIds.push(field.dataTypeId);
              tempListOfConditionalFieldIds.push(field.dataTypeId);
            }
          }
        }
      });
    }
    return acc;
  }, fieldsToAdd);

  if (tempListOfConditionalFieldIds.length > 0) {
    getAllFieldsToAdd(
      fieldIdsWithoutCategory,
      allFields,
      passedValues,
      ind,
      fieldsToAdd,
      listOfConditionalFieldIds,
      tempListOfConditionalFieldIds[0],
    );
  } else if (parseInt(ind, 10) + parseInt(1, 10) < fieldIdsWithoutCategory.length) {
    getAllFieldsToAdd(
      fieldIdsWithoutCategory,
      allFields,
      passedValues,
      parseInt(ind, 10) + parseInt(1, 10),
      fieldsToAdd,
      listOfConditionalFieldIds,
    );
  }
  fieldsToAdd = removeDuplicatesFieldsToAdd(fieldsToAdd);
  listOfConditionalFieldIds =
    removeDuplicatesForListOfConditionalFieldIds(listOfConditionalFieldIds);
  return [fieldsToAdd, listOfConditionalFieldIds];
};

export const getDataToUpdateClinicalDataItems = (values, fieldIds, allFields) => {
  const fieldIdsWithoutCategory = fieldIds.map(f => f.split('-')[1]);
  const [fieldsToAdd, listOfConditionalFieldIds] = [
    ...getAllFieldsToAdd(fieldIdsWithoutCategory, allFields, values),
  ];

  const fieldsToAddList =
    fieldsToAdd.length > 0
      ? fieldsToAdd
          .map(item => item.key)
          .reduce((acc, x) => {
            if (!acc.includes(x)) {
              acc.push(x);
            }
            return acc;
          }, [])
      : [];

  const fieldIdsData =
    fieldsToAddList.length > 0
      ? fieldsToAddList.concat(
          fieldIdsWithoutCategory.filter(item => {
            return fieldsToAddList.indexOf(parseInt(item, 10)) < 0;
          }),
        )
      : fieldIdsWithoutCategory;

  const newFieldList = fieldIdsData.reduce((acc, dataTypeId) => {
    if (!listOfConditionalFieldIds.includes(String(dataTypeId))) {
      acc.push({ id: String(dataTypeId) });
    }
    fieldsToAdd.forEach(item => {
      if (item.key === dataTypeId && !acc.some(x => x.id === item.id)) {
        acc.push({ id: item.id, conditional: item.conditional });
      }
    });
    return acc;
  }, []);

  return newFieldList;
};
