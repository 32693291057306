import React, { useState, useEffect } from 'react';
import { Grid, Typography, Checkbox } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { ReactSelectNarrow } from 'components/form/field/react-select';
import DatetimePicker from 'components/form/datepicker/datetime-picker';
import { styles } from './documents-styles';
import './react-datepicker-custom.css';
import DocumentDisplay from '../../../components/document/document-display';

const DocumentsFilter = props => {
  const {
    classes,
    handleDocumentsFilter,
    documentsCount,
    labels,
    tags,
    resourceId,
    tagTypeId,
    tagTypeLabel,
    drugName,
    documents,
  } = props;

  const [toDate, setToDate] = useState();
  const [fromDate, setFromDate] = useState();
  const [showArchived, setShowArchived] = useState(false);
  const [labelsSelected, setLabelsSelected] = useState();
  const [tagsSelected, setTagsSelected] = useState();

  useEffect(() => {
    handleDocumentsFilter({
      toDate,
      fromDate,
      showArchived,
      labelsSelected,
      tagsSelected,
    });
  }, [toDate, fromDate, showArchived, labelsSelected, tagsSelected]);

  const handleShowArchived = () => {
    setShowArchived(prevState => !prevState);
  };
  const onChangeLabelsSelected = e => {
    const labelValueSelection = e ? e.map(l => l.value) : [];
    setLabelsSelected(labelValueSelection);
  };
  const onChangeTagsSelected = e => {
    const labelTagSelection = e ? e.map(l => l.value) : [];
    setTagsSelected(labelTagSelection);
  };

  // temporary Type options
  const labelSelection = labels ? labels.map(l => ({ value: l, label: l })) : [];
  const tagSelection = tags ? tags.map(t => ({ value: t, label: `#${t}` })) : [];
  return (
    <Grid item xs={12}>
      <Grid container className={classes.documentTitle} alignItems="center">
        <Grid item className={classes.documentListHeadingItem}>
          <h2>{`Showing ${documentsCount} Documents`}</h2>
        </Grid>
        <Grid item>
          <DocumentDisplay
            taskIdResourceIds={[{ resourceId, tagTypeId }]}
            tagTypeId={tagTypeId}
            resourceId={resourceId}
            drugName={drugName}
            tagTypeLabel={tagTypeLabel}
            documents={documents}
          />
        </Grid>
      </Grid>
      <Grid container className={classes.documentFilter}>
        <Grid item xs={12}>
          <div className={classes.documentFilterWrapper}>
            <Typography component="div" className={classes.documentFilterLabel}>
              Type:
            </Typography>
            <div className={classes.documentFilterItem}>
              <ReactSelectNarrow
                handleOnChange={e => onChangeLabelsSelected(e)}
                name="document_labels"
                placeholder="All"
                fields={labelSelection}
                id="document_labels"
                isClearable={false}
              />
            </div>
          </div>
          <div className={classes.documentFilterWrapper}>
            <Typography component="div" className={classes.documentFilterLabel}>
              From
            </Typography>
            <DatetimePicker
              className="customInputDatePicker"
              onChange={e => setFromDate(e)}
              dateFormat="MM/dd/yyyy"
              placeholder="mm/dd/yyyy"
              value={fromDate}
            />
            <Typography component="div" className={classes.documentFilterLabel}>
              To
            </Typography>
            <DatetimePicker
              className="customInputDatePicker"
              onChange={e => setToDate(e)}
              dateFormat="MM/dd/yyyy"
              placeholder="mm/dd/yyyy"
              value={toDate}
            />
          </div>
          <div className={classes.documentFilterWrapper}>
            <Typography component="div" className={classes.documentFilterLabel}>
              Tags:
            </Typography>
            <div className={classes.documentFilterItem}>
              <ReactSelectNarrow
                handleOnChange={e => onChangeTagsSelected(e)}
                name="document_tags"
                placeholder="All"
                fields={tagSelection}
                id="document_tags"
                isClearable={false}
              />
            </div>
          </div>
          <div className={classes.documentFilterWrapper}>
            <div className={classes.documentFilterItem}>
              <Checkbox
                checked={showArchived}
                onChange={handleShowArchived}
                color="primary"
                value="archived"
              />
              <Typography component="div" className={classes.documentFilterLabel}>
                Show Archived
              </Typography>
            </div>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles, { withTheme: true })(DocumentsFilter);
