import React, { useEffect, useMemo, useState } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { change, Field } from 'redux-form';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { styles } from '../task-detail-styles';
// eslint-disable-next-line max-len
import { renderMultipleDropdown } from '../../../components/form/field/redux-field';
import DetailField from '../../../components/form/field/field';
import {
  CATEGORY_ARCHIVED,
  COVERAGE_ELIGIBILITIES,
  FA,
  FA_FUNDING_AVAILABLE_STATUS_ID,
} from '../../../constants';
import { useTypedSelector } from '../../../hooks/use-typed-selector';
import { AddFAData } from './add-fa-data';
import { convertMapToList } from '../../../reducers/helper-reducer';
import { MMITService } from '../../../clients/mmit-client';
import { IZHIProgramDetail } from '../../../models/mmit/IZHIProgramDetail';

const FaFundingDetail = props => {
  const { classes, formValues, task, targetStatusId } = props;

  const [open, setOpen] = useState(false);
  const loadingFAs = useTypedSelector(state => !state.financials.financial_assistances.loaded);
  const therapies = useSelector(state => state.therapies);
  const financials = useSelector(state => state.financials);
  const tasks = useTypedSelector(state => state.tasks.data);
  const statuses = useTypedSelector(state => state.lookups.lookupStatusReasons);
  const [zhiProgram, setZhiProgram] = useState();

  const handleToggle = () => {
    setOpen(!open);
  };

  const hideAddFABtn = useMemo(
    () =>
      statuses.some(
        status =>
          targetStatusId === status.id &&
          status.resource === FA.toLowerCase() &&
          status.category_id === CATEGORY_ARCHIVED,
      ),
    [statuses, targetStatusId],
  );

  const financialAssistances = convertMapToList(financials.financial_assistances.data);
  const hasFinancialAssistances = !!financialAssistances?.find(f => f.task_fa_id === task.id);

  const existingTask = tasks?.[`FA${task.existing_task_id}`];
  const therapy = therapies.data[task.therapy_id];
  const faLabel =
    formValues.fa_expiration_date !== undefined
      ? `FA Type (Available until - ${formValues.fa_expiration_date})`
      : 'FA Type';

  useEffect(() => {
    if (!formValues.zhi_program_id) {
      return;
    }

    MMITService.getProgramById(formValues.zhi_program_id).then(program => setZhiProgram(program));
  }, [formValues.zhi_program_id]);

  const isFAWithFundingAvailable = !!formValues.zhi_program_id;
  const zhiProgramDetails = useMemo(() => {
    const coverageEligibilities = Array.isArray(zhiProgram?.CoverageEligibilities)
      ? zhiProgram?.CoverageEligibilities.join(', ')
      : zhiProgram?.CoverageEligibilities;

    return {
      program_name: zhiProgram?.ProgramName,
      income_details: zhiProgram?.IncomeDetails,
      coverage_eligibilities: coverageEligibilities,
      annual_max: zhiProgram?.AnnualMax,
      therapeutic_area: zhiProgram?.TherapeuticAreas[0],
      fa_type: zhiProgram?.AssistanceType,
      enrollment_url: zhiProgram?.EnrollmentURL,
      fa_expiration_date: zhiProgram?.ExpirationDate,
    };
  }, [zhiProgram]);

  return isFAWithFundingAvailable ? (
    <Grid item xs={12}>
      <Grid container className={classes.formRowContainer}>
        <Grid item lg={3} className={classes.fieldContainer}>
          <DetailField
            name="coverage_eligibilities"
            fieldName="Coverage Eligibilities"
            field={zhiProgramDetails.coverage_eligibilities}
          />
        </Grid>
        <Grid item lg={3} className={classes.fieldContainer}>
          <DetailField name="fa_type" field={zhiProgramDetails.fa_type} fieldName={faLabel} />
        </Grid>
        <Grid item lg={3} className={classes.fieldContainer}>
          <DetailField
            name="program_name"
            field={zhiProgramDetails.program_name}
            fieldName="Program Name"
          />
        </Grid>
        <Grid item lg={3} className={classes.fieldContainer}>
          <DetailField fieldName="Enrollment URL" field={zhiProgramDetails.enrollment_url} isHref />
        </Grid>
        <Grid item lg={3} className={classes.fieldContainer}>
          <DetailField field={zhiProgramDetails.income_details} fieldName="Income Details" />
        </Grid>
        <Grid item lg={3} className={classes.fieldContainer}>
          <DetailField field={zhiProgramDetails.annual_max} fieldName="Annual Max" />
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <></>
  );
};

export default withStyles(styles)(FaFundingDetail);
