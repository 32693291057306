import React from 'react';
import { renderReactRadioGroup as RenderReactRadioGroup } from 'components/form/field/redux-field';
import { getObjectProperty } from 'helpers/misc';
import { TaskStatusUtil } from 'utils/task-status-util';

const isNextLogicalStatus = (jsonStatuses, statusName, currentStatusName) => {
  const currentStatus = jsonStatuses.find(it => it.name === currentStatusName);
  if (currentStatus && currentStatus.parent) {
    const status = jsonStatuses.find(it => it.name === statusName);
    return status.parent.indexOf(currentStatus.id) > -1;
  }
  return true;
};

export default ({ providers, label, input, meta, disabled, labelSize, qaId, initialValues }) => {
  const { statuses, formValues, task, lookups, currentUser } = providers;
  const currentValue = formValues.status_id || task.status_id;
  const initialValue = task.status_id;
  let initialStatusName = null;
  let nextLogicalStatus = null;

  const combinedStatuses = statuses
    ? statuses.filter((elem, pos) => statuses.findIndex(s => s.name === elem.name) === pos)
    : [];

  const taskTypeResourceStates = (lookups?.resourceStates || []).filter(
    resourceState => resourceState.resource_name === task.taskType,
  );

  // eslint-disable-next-line arrow-body-style
  const canTransition = React.useMemo(
    () =>
      TaskStatusUtil.buildCanTransitionFromPermissions(
        currentUser?.permissions,
        taskTypeResourceStates,
      ),
    [currentUser?.permissions, taskTypeResourceStates],
  );

  if (currentValue) {
    const selected = statuses.find(s => s.id === currentValue);
    const existingIndex = selected
      ? combinedStatuses.findIndex(it => it.name === selected.name)
      : 0;
    combinedStatuses[existingIndex] = selected;
  }
  if (initialValue) {
    initialStatusName = getObjectProperty(
      statuses.find(s => s.id === initialValue),
      'name',
      '',
    );
  }
  if (initialValues.status_id) {
    nextLogicalStatus = statuses.find(s => s.id === initialValues.status_id);
  }
  const visibleStatuses = combinedStatuses.filter(it => {
    if (it && it.status) {
      if (it.status.manuallyRestricted) {
        return false;
      }
      return it.status.visible !== false;
    }
    return false;
  });

  const radioMap = visibleStatuses.map(item => {
    const transitionAllowed = canTransition(task, item.id);
    const option = {
      value: item.id,
      label: item.name,
      disabled: providers.customStatusDisabled
        ? providers.customStatusDisabled(item.status.name)
        : false,
      less: !isNextLogicalStatus(providers.jsonStatuses, item.name, initialStatusName),
    };

    if (transitionAllowed === false && option.disabled === false) {
      option.disabled = !transitionAllowed;
    }

    return option;
  });

  /*
   * If the user has no permissions to transition to next status, then just
   * set the default selected status in the form to current status.
   */
  if (!canTransition(task, nextLogicalStatus?.id)) {
    input.value = initialValue;
    nextLogicalStatus = statuses.find(s => s.id === initialValue);
  }

  if (currentValue && currentValue !== initialValue) {
    input.value = currentValue;
  }

  return (
    <RenderReactRadioGroup
      label={label}
      input={input}
      meta={meta}
      disabled={disabled}
      labelSize={labelSize}
      qaId={qaId}
      radioMap={radioMap}
      initialValueName={nextLogicalStatus?.name || null}
      displayInline
      noPadding
      maxItemWidth={300}
    />
  );
};
