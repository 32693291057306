import React, { ReactNode } from 'react';
import moment from 'moment';
import axios, { AxiosError } from 'axios';
import { StatusCodes } from 'http-status-codes';

import { Grid, Typography, Divider } from '@mui/material';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import {
  ControlledDropdown,
  ControlledDatePicker,
  ControlledTextArea,
  ControlledDateTimePicker,
} from 'components/react-hook-form-fields';

import { MobilePhoneSmsEnum } from 'constants/enums';
import { noteTagTypes } from 'constants/lists';
import { PhoneUsage } from 'interfaces/redux/IPhone';

import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';

import { buildQaId } from 'utils/build-qa-id';
import { nameOfFactory, DeepPartial } from 'utils/types-util';

import { useTypedSelector } from 'hooks/use-typed-selector';

import { PatientSmsClient } from 'clients/patient-sms';
import { sendSmsMessageSuccess } from 'actions/action-patient';
import { updateTaskFollowupDate } from 'actions/action-tasks';
import { notifyError, notifySuccess } from 'actions/action-notifications';
import { usePatient } from 'hooks/usePatient';
import { ISendSmsErrorResponse, PhiEntity } from 'models/patient-sms/ISendSmsErrorResponse';
import { NoteTagTypes } from 'constants/note-tag-types';
import {
  DATABASE_DATETIME_FORMAT,
  PHI_ENTRIES_FOUND,
  PATIENT_WITH_RECEIVING_NUMBER_NOT_FOUND,
  PATIENT_IS_NOT_OPTED_IN,
} from 'constants/index';
import { useTypedStyles } from './send-form.styles';
import { mapSmsResponseToPatientSms, mapPhiEntityToInfoRow } from '../utils';
import { IFormFields, IProps, ContactTypeLabel, ContactTypeValue } from './types';
import { logger } from '../../../winston-logger';

const qaId = buildQaId('send-sms-form');
const getFieldName = nameOfFactory<IFormFields>();

const requiredErrorMessage = 'Required';
const foundPhiMessage = 'PHI content was detected in your message. Replace it to send.';

/*
 * For now SMS is implemented only for FC and Patient level messages

 * @TODO - When implementing a new one just add the corresponding NoteTagType here
 * finally when all task types and therapy are implemented, remove this array
 * and the conditions when sending the message in the onSubmit function
 */

const implSmsTaskTagTypes = [
  NoteTagTypes.Patient,
  NoteTagTypes.FC,
  NoteTagTypes.FA,
  NoteTagTypes.FDC,
];

// eslint-disable-next-line @typescript-eslint/naming-convention
export function SendForm(props: IProps) {
  const dispatch = useDispatch();
  const { classes } = useTypedStyles();

  const allSmsPredefinedMessages = useTypedSelector(state => state.lookups.smsPredefinedMessages);
  const { sourceNumber } = useTypedSelector(state => state.lookups);
  const noteBar = useTypedSelector(state => state.noteBar);
  const patient = useTypedSelector(state => state.patient);
  const userDisplayName = useTypedSelector(state => state.auth.currentUser.display_name);

  const noteTagType = (noteTagTypes || []).find(
    tagType => tagType.value === noteBar.tagTypeId,
  )?.label;
  const resourcePredefinedMessages = (allSmsPredefinedMessages || []).filter(predefMessage => {
    return (
      (implSmsTaskTagTypes.includes(noteBar.tagTypeId) &&
        noteBar.tagTypeId === predefMessage.resource_type.id) ||
      predefMessage.resource_type.id === NoteTagTypes.Patient
    );
  });

  const { patientFullName } = usePatient(patient);
  const initialFollowUpDate = noteBar?.initiatingTask?.followup_dt || moment();
  const predefinedMessagesAsDropdownOptions = (resourcePredefinedMessages || []).map(message => ({
    label: message.name,
    value: message.id,
  }));

  const {
    handleSubmit,
    control,
    setValue,
    setError,

    formState: { errors },
  } = useForm<any>();
  const [isSendingSms, setIsSendingSms] = React.useState<boolean>(false);
  const [foundPhiEntities, setFoundPhiEntities] = React.useState<PhiEntity[]>([]);

  const formValues = useWatch({ control });

  const sortRankPhones = (patient.phones || []).sort((phonea, phoneb) => phonea.rank - phoneb.rank);

  const smsPhoneNumber = sortRankPhones.find(
    phone =>
      phone.use === PhoneUsage.Mobile &&
      phone.patient_choice === true &&
      phone.sms === MobilePhoneSmsEnum.OptIn,
  );

  const smsPhoneNotAllow = sortRankPhones.find(
    phone =>
      phone.use === PhoneUsage.Mobile &&
      (!phone.patient_choice || (phone.patient_choice && MobilePhoneSmsEnum.OptOut)),
  );

  const shouldAllowToSendMessages = Boolean(smsPhoneNumber);

  const onSubmit: SubmitHandler<IFormFields> = async (formValues: IFormFields) => {
    setIsSendingSms(true);
    try {
      if (smsPhoneNumber) {
        // Fallback to patient id as resource if the tagType is not implemented yet
        const resourceId = implSmsTaskTagTypes.includes(noteBar.tagTypeId)
          ? noteBar.tagResourceId
          : patient.id;

        // Fallback to patient tagType if the current tagType is not implemented yet
        const tagTypeId = implSmsTaskTagTypes.includes(noteBar.tagTypeId)
          ? noteBar.tagTypeId
          : NoteTagTypes.Patient;

        let newFollowupDate = null;

        if (formValues.taskFollowUpDate && formValues.taskFollowUpDate !== initialFollowUpDate) {
          const timeZoneOffset = moment().utcOffset();
          // The date picker component returns a time with 0 time zone offset. We need to
          // adjust the date picker time to the local user time.
          newFollowupDate = moment(formValues.taskFollowUpDate)
            .subtract(timeZoneOffset, 'minutes')
            .format(DATABASE_DATETIME_FORMAT);
        }

        const { data } = await PatientSmsClient.sendMessageToPatient(patient.id, {
          msg: formValues?.message,
          phone: smsPhoneNumber.value,
          resource_id: resourceId,
          tag_type_id: tagTypeId,
          predefined_message_id: formValues?.messageType,
          followup_dt: newFollowupDate,
        });

        data.sms_response.sent_by = userDisplayName;

        const sentMessage = mapSmsResponseToPatientSms(
          patientFullName,
          smsPhoneNumber.value,
          data.sms_response,
        );

        setFoundPhiEntities([]);
        dispatch(sendSmsMessageSuccess(sentMessage, data.sms_response.note_id));
        const taskIdentifier = `${noteBar?.initiatingTask?.taskType}${noteBar?.initiatingTask?.id}`;
        if (newFollowupDate && taskIdentifier) {
          const updateTaskPayload = {
            taskIdentifier,
            followupDate: newFollowupDate,
          };
          dispatch(updateTaskFollowupDate(updateTaskPayload));
        }
        dispatch(notifySuccess('SMS message sent successfully'));
      }
    } catch (error) {
      let supplementalErrorMessage = '';
      if (axios.isAxiosError(error)) {
        const { response } = error as AxiosError<ISendSmsErrorResponse>;
        if (
          response &&
          response.data?.error_type === PHI_ENTRIES_FOUND &&
          response.status === StatusCodes.BAD_REQUEST &&
          response.data?.detected_phi?.length
        ) {
          setFoundPhiEntities(response.data.detected_phi);
          setError(getFieldName('message'), {
            message: foundPhiMessage,
          });
          supplementalErrorMessage = '. Potential PHI Information Detected';
        }
        if (
          response &&
          (response.data?.error_type === PATIENT_WITH_RECEIVING_NUMBER_NOT_FOUND ||
            response.data?.error_type === PATIENT_IS_NOT_OPTED_IN) &&
          response.status === StatusCodes.BAD_REQUEST
        ) {
          supplementalErrorMessage = `. ${response.data.message}`;
          setFoundPhiEntities([]);
        }
      } else {
        logger.error(`Error trying to send SMS message to patient ${error}`);
        setFoundPhiEntities([]);
      }
      logger.error(error);
      dispatch(notifyError(`Error sending SMS messsage${supplementalErrorMessage}`));
    } finally {
      setIsSendingSms(false);
    }
  };

  React.useEffect(() => {
    if (formValues.messageType) {
      const templateContent = (resourcePredefinedMessages || []).find(
        predefMessage => predefMessage.id === formValues.messageType,
      )?.content;

      if (templateContent) {
        setValue(getFieldName('message'), templateContent);
      }
    }
  }, [formValues.messageType]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography className={classes.title}>New Outgoing Message</Typography>
      </Grid>
      <Grid item xs={6}>
        <ControlledDateTimePicker
          label="Date *"
          defaultValue={moment()}
          control={control}
          name={getFieldName('date')}
          fullWidth
          qaId={qaId('message-date')}
        />
      </Grid>
      <Grid item xs={6}>
        <ControlledDropdown
          defaultValue={1}
          label="Contact *"
          name={getFieldName('contact')}
          percentWith={100}
          control={control}
          validations={{ required: true }}
          options={[{ label: ContactTypeLabel.Patient, value: ContactTypeValue.Patient }]}
          qaId={qaId('contact')}
          disabled
          inputMetaData={{
            touched: Boolean(errors.contact),
            error: requiredErrorMessage,
          }}
        />
      </Grid>
      {implSmsTaskTagTypes.includes(noteBar.tagTypeId) &&
        noteBar.tagTypeId !== NoteTagTypes.Patient && (
          <Grid item xs={6}>
            <ControlledDatePicker
              label={`${noteTagType} Task Follow Up Date`}
              defaultValue={initialFollowUpDate}
              control={control}
              name={getFieldName('taskFollowUpDate')}
              fullWidth
              qaId={qaId('task-followup-date')}
            />
          </Grid>
        )}
      <Grid item xs={6}>
        <ControlledDropdown
          defaultValue={null}
          label="Select Message Template *"
          name={getFieldName('messageType')}
          percentWith={100}
          control={control}
          validations={{ required: true }}
          options={predefinedMessagesAsDropdownOptions}
          qaId={qaId('message-type')}
          inputMetaData={{
            touched: Boolean(errors.messageType),
            error: requiredErrorMessage,
          }}
        />
      </Grid>
      {!shouldAllowToSendMessages && (
        <Grid item xs={12}>
          <Typography className={classes.errorLabel}>
            The patient has not opted in to receive SMS.
            {smsPhoneNotAllow && ` For Opt In Patient need to send start to ${sourceNumber}`}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <ControlledTextArea
          maxLength={500}
          hideLabel
          showLength
          defaultValue=""
          control={control}
          name={getFieldName('message')}
          validations={{
            required: true,
            validate: (content: string) => {
              const pendingReplacements = content.match(/\[\[(.*?)\]\]/gm);
              return pendingReplacements?.length
                ? `Replace all pending values ${pendingReplacements.join(', ')}`
                : true;
            },
          }}
          percentWith={100}
          placeHolder="Enter your message (Required)"
          qaId={qaId('message')}
          inputMetaData={{
            touched: Boolean(errors.message),
            error: `${
              errors && errors.message && errors.message.type && errors.message.type === 'required'
                ? `${requiredErrorMessage}`
                : errors?.message?.message || ''
            }`,
          }}
        />
      </Grid>
      {Boolean(foundPhiEntities.length) && (
        <Grid item xs={12}>
          {foundPhiEntities.map((phiEntity, idx) => {
            const phiInfoRow = mapPhiEntityToInfoRow(phiEntity);
            return (
              <Grid container spacing={0} key={idx}>
                <Grid item xs={2}>
                  <Typography className={classes.phiEntityTypeLabel}>
                    {phiInfoRow.phiType}:
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography className={classes.phiEntityTextLabel}>
                    {phiInfoRow.messagePortion}
                  </Typography>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      )}
      <Grid item xs={12}>
        <Divider />
        <ConfirmationPanel
          disableSubmit={!shouldAllowToSendMessages}
          cancelButtonName="send_sms_form_cancel_button"
          submitButtonName="send_sms_form_submit_button"
          hideSubmit={false}
          hideCancel={false}
          submitButtonText="Send"
          handleCancel={() => {
            props.handleCancel?.();
          }}
          handleSubmit={handleSubmit(onSubmit)}
          isLoading={isSendingSms}
          loadingText="Sending"
          saveButtonQaId={qaId('send')}
          cancelButtonQaId={qaId('cancel')}
        />
      </Grid>
    </Grid>
  );
}
