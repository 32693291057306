import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import {
  Typography,
  Grid,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Link,
  Switch as SwitchButton,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Dot } from 'components/icons/icons';
import {
  DUR_INTERACTION_TYPE_DDI,
  DUR_INTERACTION_TYPE_DAI,
  DUR_INTERACTION_TYPE_DFI,
  DUR_INTERACTION_TYPE_DDC,
  DUR_DUPLICATE_THERAPY_ALERT,
} from 'constants/index';
import { convertToArborDate } from 'models/time/arbor-date';
import { toggleShowNewInteractions } from 'actions/action-drug-utilization-review';
import compose from 'recompose/compose';
import { DurStatus } from 'interfaces/enums/TaskStatuses/DrugUtilizationReviewStatus';
import {
  UnknownSeverity,
  ModerateSeverity,
  SevereSeverity,
  SeriousSeverity,
  legendFromSeverity,
  filterInteractionsByType,
  sortAndFilterInteractions,
  formatConceptsNameForInteraction,
} from './common';

import { styles } from './drug-utilization-review-styles';

const DrugUtilizationReviewDrugInteractionAnalysis = props => {
  const { classes, interactions, newInteractions, durState, durId, isSnapshot } = props;

  const dispatch = useDispatch();

  const [expandedDDI, setExpandedDDI] = useState(true);
  const [expandedDAI, setExpandedDAI] = useState(false);
  const [expandedDFI, setExpandedDFI] = useState(false);
  const [expandedDDC, setExpandedDDC] = useState(false);
  const [expandedDPT, setExpandedDPT] = useState(false);

  (interactions || []).forEach(x => {
    x.isNew = newInteractions.some(i => i.id === x.id);
  });

  const [newInteractionsOnly, setNewInteractionsOnly] = useState(false);

  const taskStatuses = useSelector(state => state.taskStatuses);

  const toggleExpandedDDI = () => {
    setExpandedDDI(!expandedDDI);
  };
  const toggleExpandedDAI = () => {
    setExpandedDAI(!expandedDAI);
  };
  const toggleExpandedDFI = () => {
    setExpandedDFI(!expandedDFI);
  };
  const toggleExpandedDDC = () => {
    setExpandedDDC(!expandedDDC);
  };
  const toggleExpandedDPT = () => {
    setExpandedDPT(!expandedDPT);
  };

  const toggleNewInteractionsOnly = () => {
    setNewInteractionsOnly(!newInteractionsOnly);
    dispatch(toggleShowNewInteractions(durId, !newInteractionsOnly));
  };

  const interactionsToDisplay =
    newInteractionsOnly && !isSnapshot ? interactions.filter(x => x.isNew) : interactions;

  const DDIData = {
    title: 'Drug-Drug Interactions',
    interactions: sortAndFilterInteractions(
      filterInteractionsByType(interactionsToDisplay || [], DUR_INTERACTION_TYPE_DDI),
      newInteractionsOnly,
      false,
      true,
    ),
    id: DUR_INTERACTION_TYPE_DDI,
  };
  const DAIData = {
    title: 'Drug-Allergy Interactions',
    interactions: sortAndFilterInteractions(
      filterInteractionsByType(interactionsToDisplay || [], DUR_INTERACTION_TYPE_DAI),
      newInteractionsOnly,
      false,
      true,
    ),
    id: DUR_INTERACTION_TYPE_DAI,
  };
  const DFIData = {
    title: 'Drug-Food Interactions',
    interactions: sortAndFilterInteractions(
      filterInteractionsByType(interactionsToDisplay || [], DUR_INTERACTION_TYPE_DFI),
      newInteractionsOnly,
      false,
      true,
    ),
    id: DUR_INTERACTION_TYPE_DFI,
  };
  const DDCData = {
    title: 'Drug-Disease Interactions',
    interactions: sortAndFilterInteractions(
      filterInteractionsByType(interactionsToDisplay || [], DUR_INTERACTION_TYPE_DDC),
      newInteractionsOnly,
      false,
      true,
    ),
    id: DUR_INTERACTION_TYPE_DDC,
  };

  const DPTData = {
    title: 'Duplicate Therapies Alerts',
    interactions: sortAndFilterInteractions(
      filterInteractionsByType(interactionsToDisplay || [], DUR_DUPLICATE_THERAPY_ALERT),
      newInteractionsOnly,
      false,
      true,
    ),
    id: DUR_DUPLICATE_THERAPY_ALERT,
  };

  const getValue = ({ array, idProperty = 'id', valueProperty = 'name', id }) => {
    const found = array.find(
      element => element[idProperty] !== undefined && String(element[idProperty]) === String(id),
    );
    return found ? found[valueProperty] : null;
  };

  const renderPreviousIntervention = (intervention, interaction) => {
    const {
      recommendation,
      outcome,
      category_id: categoryId,
      updated_by_user: updatedByUser,
      followup_dt: followupDt,
      completed_date: completedDate,
      additional_reason: additionalReason,
    } = intervention;

    const severity = interaction.arbor_severity_description;
    const intDescription = formatConceptsNameForInteraction(interaction);

    if (intervention.interacting_drugs !== intDescription) {
      return null;
    }

    const renderCategory = getValue({
      array: taskStatuses.intervention_categories,
      valueProperty: 'category_name',
      id: categoryId,
    });

    const renderRecommendation = getValue({
      array: taskStatuses.intervention_recommendations,
      valueProperty: 'recommendation_name',
      id: recommendation,
    });

    const renderOutcome = getValue({
      array: taskStatuses.intervention_outcomes,
      valueProperty: 'outcome_name',
      id: outcome,
    });

    const renderFollowUpDate = followupDt ? convertToArborDate(followupDt).getCustomerDate() : null;
    const renderCompletedDate = completedDate
      ? convertToArborDate(completedDate).getCustomerDate()
      : null;

    return (
      <Grid container className={classes.interventionDetailsSection}>
        <Grid item xs={12}>
          <Typography variant="h6" className={classes.interventionDetailsSectionTitle}>
            {'Previous Interaction Evaluation '}
            {!!renderCompletedDate && `(${renderCompletedDate})`}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={4}>
              <Typography className={classes.interventionDataTitle} variant="caption">
                Follow Up Date
              </Typography>
              <Typography variant="body2">{renderFollowUpDate || '-'}</Typography>
              <Typography className={classes.interventionDataTitle} variant="caption">
                Completion Date
              </Typography>
              <Typography variant="body2">{renderCompletedDate || '-'}</Typography>
              <Typography className={classes.interventionDataTitle} variant="caption">
                User
              </Typography>
              <Typography variant="body2">{updatedByUser || '-'}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography className={classes.interventionDataTitle} variant="caption">
                Category
              </Typography>
              <Typography variant="body2">{renderCategory || '-'}</Typography>
              <Typography className={classes.interventionDataTitle} variant="caption">
                Recommendation
              </Typography>
              <Typography variant="body2">{renderRecommendation || '-'}</Typography>
              <Typography className={classes.interventionDataTitle} variant="caption">
                Details
              </Typography>
              <Typography variant="body2">{additionalReason || '-'}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography className={classes.interventionDataTitle} variant="caption">
                Severity
              </Typography>
              <Typography variant="body2">{severity || '-'}</Typography>
              <Typography className={classes.interventionDataTitle} variant="caption">
                Outcome
              </Typography>
              <Typography variant="body2">{renderOutcome || '-'}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const drugInteractionAnalysisDropdown = (expandedVar, expandedControl, data = {}) => (
    <Accordion
      expanded={expandedVar}
      onChange={expandedControl}
      square
      className={classes.drugInteractionAnalysisDropdown}
      id={data.id}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        className={classes.drugInteractionAnalysisDropdownHeader}
      >
        <Typography>
          {`${data.title} ${
            data.interactions && data.interactions.length > 0 ? `(${data.interactions.length})` : ''
          }`}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid item xs={12}>
          {data.interactions && data.interactions.length > 0 ? (
            data.interactions.map(interaction => (
              <>
                <Grid container className={classes.interactionContainter}>
                  <Grid item xs={4}>
                    <Typography variant="h6" component="span">
                      {interaction.concepts && formatConceptsNameForInteraction(interaction)}
                      <br />
                      {interaction.isNew && durState !== DurStatus.Reviewed && (
                        <Typography
                          component="span"
                          key="is_specialty"
                          className={classes.newInteractionLabel}
                        >
                          New
                        </Typography>
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    {legendFromSeverity({ severity: interaction.arbor_severity_id })}
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>
                      {`${interaction.screen_message || ''} ${
                        interaction.clinical_effects_narrative || ''
                      } ${interaction.clinical_result || ''}`.trim()}
                    </Typography>
                    {interaction.linked_documentation && (
                      <Typography className={classes.interactionDocumentation}>
                        <Link
                          href={interaction.linked_documentation}
                          variant="body2"
                          rel="noreferrer"
                        >
                          Interaction Documentation
                        </Link>
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                {/* Previous Interaction Evaluation */}
                {!!interaction.previous_interventions &&
                interaction.previous_interventions.length > 0
                  ? interaction.previous_interventions.map(x =>
                      renderPreviousIntervention(x, interaction),
                    )
                  : ''}
                {/* /Previous Interaction Evaluation */}
              </>
            ))
          ) : (
            <Typography>No Interaction Found in the Database</Typography>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );

  return (
    <>
      <Grid item container xs={12}>
        <Grid item xs={9}>
          <Typography>Drug Interaction Analysis</Typography>
        </Grid>
        {!isSnapshot && (
          <Grid item xs={3}>
            <Typography className={classes.inlineTypographyDots} component="span">
              All Interactions
            </Typography>
            <SwitchButton
              checked={newInteractionsOnly}
              onChange={toggleNewInteractionsOnly}
              value="showTree"
              color="primary"
            />
            <Typography className={classes.inlineTypographyDots} component="span">
              New Interactions
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid item container xs={12}>
        <Grid container justifyContent="flex-end" alignItems="center" className={classes.dotsRow}>
          <Grid item xs={9}>
            <Grid container>
              <UnknownSeverity />
              <ModerateSeverity />
              <SeriousSeverity />
              <SevereSeverity />
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Grid container justifyContent="flex-end" alignItems="center">
              {interactionsToDisplay && interactionsToDisplay.length > 0 && (
                <Typography className={classes.inlineTypographyDotsLarge}>
                  {`${interactionsToDisplay.length} Alert${
                    interactionsToDisplay.length > 1 ? 's' : ''
                  } `}
                  <Dot className={classes.seriousDot} />
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.dotsRow}>
          <Button
            className={classes.jumpButton}
            variant="outlined"
            disableElevation
            data-qa-id="DDI_jump_button"
            name="DDI_jump_button"
            href="#DDI"
          >
            {`Drug-Drug Interaction${
              DDIData.interactions.length ? ` (${DDIData.interactions.length})` : ''
            }`}
          </Button>
          <Button
            className={classes.jumpButton}
            variant="outlined"
            disableElevation
            data-qa-id="DAI_jump_button"
            name="Dai_jump_button"
            href="#DAI"
          >
            {`Drug-Allergy Interaction${
              DAIData.interactions.length ? ` (${DAIData.interactions.length})` : ''
            }`}
          </Button>
          <Button
            className={classes.jumpButton}
            variant="outlined"
            disableElevation
            data-qa-id="DFI_jump_button"
            name="DFI_jump_button"
            href="#DFI"
          >
            {`Drug-Food Interaction${
              DFIData.interactions.length ? ` (${DFIData.interactions.length})` : ''
            }`}
          </Button>
          <Button
            className={classes.jumpButton}
            variant="outlined"
            disableElevation
            data-qa-id="DDC_jump_button"
            name="DDC_jump_button"
            href={`#${DUR_INTERACTION_TYPE_DDC}`}
          >
            {`Drug-Disease Interaction${
              DDCData.interactions.length ? ` (${DDCData.interactions.length})` : ''
            }`}
          </Button>
          <Button
            className={classes.jumpButton}
            variant="outlined"
            disableElevation
            data-qa-id="DPT_jump_button"
            name="DPT_jump_button"
            href="#DPT"
          >
            {`Duplicate Therapy Alerts${
              DPTData.interactions.length ? ` (${DPTData.interactions.length})` : ''
            }`}
          </Button>
        </Grid>

        <Grid item xs={12}>
          {drugInteractionAnalysisDropdown(expandedDDI, toggleExpandedDDI, DDIData)}
        </Grid>
        <Grid item xs={12}>
          {drugInteractionAnalysisDropdown(expandedDAI, toggleExpandedDAI, DAIData)}
        </Grid>
        <Grid item xs={12}>
          {drugInteractionAnalysisDropdown(expandedDFI, toggleExpandedDFI, DFIData)}
        </Grid>
        <Grid item xs={12}>
          {drugInteractionAnalysisDropdown(expandedDDC, toggleExpandedDDC, DDCData)}
        </Grid>
        <Grid item xs={12}>
          {drugInteractionAnalysisDropdown(expandedDPT, toggleExpandedDPT, DPTData)}
        </Grid>
      </Grid>
    </>
  );
};

export default compose(withStyles(styles, { withTheme: true }))(
  DrugUtilizationReviewDrugInteractionAnalysis,
);
