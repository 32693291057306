import { ClinicalSupportStatusValue } from 'constants/enums';

export enum AdministrationStatus {
  PreTherapy = 'Pre-Therapy',
  OnTherapy = 'On Therapy',
  OnHold = 'On Hold',
  Discontinued = 'Discontinued',
  NoGo = 'No-Go',
}

export interface ITherapy {
  administration_status_id: number; // 1001
  administration_status: AdministrationStatus;
  administration_status_category_id: number;
  category_id?: number | string;
  clinical_support_additional_reason?: string;
  clinical_support_status_id: ClinicalSupportStatusValue;
  clinical_support_status_reason?: string;
  cold_chain_packing_required?: boolean;
  controlled_substance_code?: string;
  days_supply: number; // number
  diagnosis_code: string; // "L50.9"
  diagnosis_description: string; // "Urticaria, unspecified"
  dispensing_pharmacy_name?: string; // "MISSION COMMUNITY PHARMACY"
  dispensing_pharmacy_npi?: string;
  dispensing_status?: string;
  dosage_form: string; // "TABS"
  dosage_form_description: string;
  dosis_regimen?: string;
  drug_name: string; // "Daraprim"
  fill_cycle?: number; // 2
  gpi?: string;
  id: number;
  is_needsby_per_protocol?: boolean;
  is_specialty: boolean;
  managing_clinic_name?: string; // "AMC Med Center"
  ndc: string; // "69413033010"
  needsby_date: string; // "2020-10-02T00:00:00.000Z"
  medication_id?: number;
  patient_id: number;
  prescribing_physician_authorized_number?: string;
  prescribing_physician_business_mailing_number?: string;
  prescribing_physician_business_practice_number?: string;
  prescribing_physician_name?: string; // "CHRISCHILLES RUSSEL"
  prescribing_physician_npi?: string; // "1114263969"
  referring_clinic_name?: string; // "AMC Med Center"
  secondary_diagnosis_code: string; // "L50.9"
  secondary_diagnosis_description: string; // "Urticaria, unspecified"
  service_group_id?: number;
  service_groups_name?: string; // "NSC"
  therapy_type: number; // 1
  strength: string; // "0.25"
  strength_unit_of_measure: string; // "MG"
  specialty_type: string;
  tertiary_diagnosis_code: string; // "L50.9"
  tertiary_diagnosis_description: string; // "Urticaria, unspecified"
  start_date: string;
  dispensing_status_id?: number;
  in_med_sync?: number;
  as_needed?: boolean;
  clinic_administered?: boolean;
  adherence_packaging?: number;
  one_time_fill?: boolean;
}
