import React from 'react';
import { Grid, Typography, Popover } from '@mui/material';
import { useForm, useWatch } from 'react-hook-form';

import { ControlledDropdown } from 'components/react-hook-form-fields';
import { buildQaIdProp } from 'utils/build-qa-id';
import { useTypedSelector } from 'hooks/use-typed-selector';

import { DeepPartial, nameOfFactory } from 'utils/types-util';
import { IContactListItem } from 'interfaces/redux/IContactList';
import { IPhone } from 'interfaces/redux/IPhone';
import { IPatient } from 'interfaces/redux/IPatient';
import { IState } from 'interfaces/redux/IState';
import TalkdeskPhoneNumber from 'components/talkdesk-phone-number/talkdesk-phone-number';
import { IPhonePopupFormProps, IPhonePopupProps, IFormFields } from './interfaces';
import { isIContactListItem } from './utils';
import useStyles from './phone-popup-styles';

const getFieldName = nameOfFactory<IPhonePopupFormProps>();

const qaIdProp = buildQaIdProp('phone-popup-form');

export const PhonePopup: React.FC<IPhonePopupProps> = (props: IPhonePopupProps) => {
  const classes = useStyles();
  const { parentTagInfo } = props;
  const patient = useTypedSelector((state: IState) => state.patient);
  const contacts = useTypedSelector((state: IState) => state.contactList);
  const contactList = Object.values(contacts);
  const contactsArr = [patient, ...contactList];
  const { control, setValue } = useForm<any>();
  const contactOptions = contactsArr.map((contact: IContactListItem | IPatient) => {
    const labelSuffix1 = isIContactListItem(contact)
      ? ` - ${(contact as IContactListItem).relationship}`
      : '';

    // with getValues you can obtains the values selected on the dropdowns
    // EXAMPLE:
    // const { control } = useForm<any>();
    const labelSuffix2 =
      isIContactListItem(contact) && contact.id === patient.preferred_contact_id
        ? ' - Preferred'
        : '';

    return {
      label: `${contact.first_name} ${contact.last_name}${labelSuffix1}${labelSuffix2}`,
      value: isIContactListItem(contact) ? contact.id : 'patient',
    };
  });

  const formValues = useWatch({ control }) || null;

  let isPatient = true;
  if (formValues && formValues.contactId) {
    isPatient = formValues?.contactId === 'patient';
  }
  const contactObj = !isPatient
    ? contactList.find(
        (contact: IContactListItem | IPatient) => contact.id === Number(formValues.contactId),
      )
    : patient;

  const phoneArr =
    contactObj?.phones
      ?.filter((phone: IPhone) => !phone.deleted)
      ?.map((phone: IPhone) => ({
        label: phone.use as string,
        value: phone.value,
      })) || [];

  let phoneNumberToPass: any = phoneArr && phoneArr[0] ? phoneArr[0].value : [];
  if (formValues.contactId && !formValues.phoneType) {
    phoneNumberToPass = '';
  } else if (formValues.contactId && formValues.phoneType) {
    phoneNumberToPass = formValues.phoneType;
  }

  if (phoneNumberToPass?.value) {
    phoneNumberToPass = phoneNumberToPass?.value || '';
  }

  React.useEffect(() => {
    if (formValues.contactId) {
      setValue(getFieldName('phoneType'), '');
    }
  }, [contactObj]);

  const availability =
    contactObj &&
    contactObj?.phones &&
    contactObj?.phones.find((phone: IPhone) => phone?.value === phoneNumberToPass)
      ? contactObj?.phones.find((phone: IPhone) => phone?.value === phoneNumberToPass)?.period
      : '';

  const formComponent = (
    <form>
      <div className={classes.rootContainer}>
        <Grid spacing={1} container direction="column" className={classes.titleText}>
          <Typography variant="subtitle2" gutterBottom>
            Call {` ${patient.first_name} ${patient.last_name}`}
          </Typography>
          <Grid item container spacing={2} xs={12}>
            <Grid xs item>
              <ControlledDropdown
                label="Contact"
                control={control}
                name={getFieldName('contactId')}
                options={contactOptions}
                defaultValue="patient"
                {...qaIdProp('state')}
              />
            </Grid>
            <Grid xs item>
              <ControlledDropdown
                label="Type"
                control={control}
                name={getFieldName('phoneType')}
                options={phoneArr}
                defaultValue={phoneArr[0] ? phoneArr[0].value : null}
                {...qaIdProp('state')}
              />
            </Grid>
          </Grid>
          <Grid item>
            <TalkdeskPhoneNumber
              number={phoneNumberToPass}
              owner={contactObj}
              availability={availability}
              tagInfo={parentTagInfo || null}
            />
          </Grid>
        </Grid>
      </div>
    </form>
  );

  return <>{formComponent}</>;
};
