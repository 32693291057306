import HTTP, { HTTP as _http } from '../http';

const instance = new _http({}, false);

const getS3Url = (customerId, patientId, withRetry, uuid) => {
  const queryString = uuid ? `?uuid=${uuid}` : '';
  return HTTP.get(
    `/documents/customers/${customerId}/patients/${patientId}/${queryString}`,
    {},
    withRetry,
  );
};

const buildFormData = (file, params) => {
  // eslint-disable-next-line no-undef
  const formData = new FormData();

  Object.keys(params).forEach(key => {
    formData.append(key, params[key]);
  });
  formData.append('file', file);

  return formData;
};

export const postToS3 = async (url, formData) =>
  instance.post(url, formData, {
    headers: {
      'Content-Type': 'something',
    },
  });

export const uploadFile = async (
  file,
  fileName,
  customerId,
  patientId,
  withRetry = true,
  existingUuid,
) => {
  const s3UploadParams = await getS3Url(customerId, patientId, withRetry, existingUuid);

  const { url, fields } = s3UploadParams.data.upload_params;

  const formData = buildFormData(file, fields);
  await postToS3(url, formData);

  const { uuid } = s3UploadParams.data;

  return { uuid };
};

export const addDocuments = async (customerId, patientId, documents, withRetry = true) => {
  const url = '/documents';
  const payload = {
    patient_id: patientId,
    customer_id: customerId,
    documents,
  };

  return HTTP.post(url, payload, {}, withRetry);
};

export const addDocument = async (
  customerId,
  patientId,
  documentUuid,
  fileName,
  labels,
  tags,
  noteText,
  withRetry = true,
  metadata,
  safe,
  scanned,
) => {
  // const url = '/documents';
  const documents = [
    {
      uuid: documentUuid,
      file_name: fileName,
      labels,
      tags,
      noteText,
      metadata,
      safe,
      scanned,
    },
  ];
  return addDocuments(customerId, patientId, documents, withRetry);
};

export const updateDocument = async (
  customerId,
  patientId,
  documentId,
  fileName,
  labels,
  noteText = undefined,
  isArchived = false,
  archivedReason = undefined,
  withRetry = true,
) => {
  const url = '/documents';
  const payload = {
    patient_id: patientId,
    customer_id: customerId,
    document_id: documentId,
    file_name: fileName,
    labels,
    noteText,
    is_archived: isArchived,
    archived_reason: archivedReason,
  };
  return HTTP.patch(url, payload, {}, withRetry);
};

export const pinUnpinDocument = async (
  docId,
  isPinned,
  customerId,
  patientId,
  withRetry = true,
) => {
  const url = '/pin_documents';
  const payload = {
    documentId: docId,
    isPinned,
    customerId,
    patientId,
  };
  return HTTP.patch(url, payload, {}, withRetry);
};

export const getDownloadUrl = async (customerId, patientId, documentId, withRetry = true) => {
  const url = `/documents/customers/${customerId}/patients/${patientId}/${documentId}`;
  return HTTP.get(url, {}, withRetry);
};

export const getDocuments = async payload => {
  const url = '/documents/_search';
  return HTTP.post(url, payload, {});
};
