import React, { memo } from 'react';
import { ITask } from 'interfaces/redux/ITasks';

// Helper function to create the task equality string
function createTaskEqualityString(tasksList: ITask[], taskKeysToCheck: (keyof ITask)[]): string {
  return tasksList
    .map(task =>
      taskKeysToCheck.map(key => (task[key] !== undefined ? String(task[key]) : '')).join(','),
    )
    .sort()
    .join(',');
}

function createMemoizedTaskComponent<T>(
  BaseComponent: React.ComponentType<T>,
  taskKeysToCheck: (keyof ITask)[] = ['id'],
) {
  return memo(
    (props: T & { tasksList: ITask[] }) => <BaseComponent {...props} />,
    (prevProps, nextProps) => {
      const prevTasks = prevProps.tasksList || [];
      const nextTasks = nextProps.tasksList || [];

      const prevTaskEqualityString = createTaskEqualityString(prevTasks, taskKeysToCheck);
      const nextTaskEqualityString = createTaskEqualityString(nextTasks, taskKeysToCheck);

      return prevTaskEqualityString === nextTaskEqualityString;
    },
  );
}

export default createMemoizedTaskComponent;
