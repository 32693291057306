import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { compose } from 'recompose';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { styles } from './document-styles';
import { updateDocumentBar } from '../../actions/action-uploaded-documents';

const DocumentDisplay = props => {
  const {
    classes,
    taskIdResourceIds,
    drugName,
    tagTypeLabel,
    documents,
    tagTypeId,
    resourceId,
    containerClass,
  } = props;

  const [message, setMessage] = useState('+ Add Attachment');

  useEffect(() => {
    if (documents && documents.filteredCount) {
      let attachment = 'attachment';
      if (documents.filteredCount > 1) {
        attachment += 's';
      }
      setMessage(`View ${documents.filteredCount} ${attachment}`);
    }
  }, [documents]);

  const dispatch = useDispatch();
  const clickAdd = e => {
    e.stopPropagation();
    dispatch(
      updateDocumentBar({
        display: true,
        taskIdResourceIds,
        tagTypeId,
        resourceId,
        drugName,
        tagTypeLabel,
        update: false,
      }),
    );
  };

  return (
    <Grid
      container
      className={classNames(classes.document, containerClass)}
      onClick={e => clickAdd(e)}
    >
      <Grid container>
        <Grid item xs={12} className={classes.addNewDocumentDisplay}>
          <Typography variant="body2">{message}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default compose(withStyles(styles))(DocumentDisplay);
