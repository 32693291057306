import React from 'react';
import { Tooltip, Box } from '@mui/material';
import { HighlightStarProps, Variant } from './highlight-star.types';
import { ReactComponent as EmptyStar } from './empty-star.svg';
import { ReactComponent as FilledStar } from './filled-star.svg';
import { BadgeCounter } from '../badge-counter/badge-counter';
import { useStyles } from './highlight-star.styles';

export const HighlightStar: React.FC<HighlightStarProps> = (props): JSX.Element => {
  const defaultVariant = props.variant || 'default';
  const { container, star, badge } = useStyles({
    variant: `${defaultVariant}${props.isHighlight ? '-highlight' : ''}` as Variant,
  });
  const StarComponent = props.isHighlight ? FilledStar : EmptyStar;
  return (
    <div className={container}>
      <StarComponent onClick={props.onHighlight} className={star} />
      {props.additionalUsers && props?.additionalUsers?.length > 0 ? (
        <Tooltip
          title={
            props?.additionalUsers?.length > 0 ? (
              <>
                <Box flexDirection="column">
                  {props?.additionalUsers?.map((item: any) => (
                    <div key={item.user_id}>{item.display_name}</div>
                  ))}
                </Box>
              </>
            ) : (
              <></>
            )
          }
          placement="bottom-start"
        >
          <div className={badge}>
            <BadgeCounter additionalUsersCount={props.additionalUsers.length} />
          </div>
        </Tooltip>
      ) : null}
    </div>
  );
};
