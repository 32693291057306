import React, { Fragment, useState, useCallback } from 'react';
import { useMediaQuery, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import HTTP from 'services/http';
import lodash from 'lodash';
import Question from '../components/question';
import Container from '../components/container';
import WidthController from '../components/width-controller';
import ProgressBar from '../components/progress-bar';

const useStyles = makeStyles(theme => ({
  container: {
    overflowY: 'auto',
    [`${theme.breakpoints.down('xl')}`]: {
      scrollSnapType: 'y mandatory',
      scrollBehavior: 'smooth',
    },
    height: '100%',
    width: '100%',
  },
  questionContainer: {
    [`${theme.breakpoints.down('xl')}`]: {
      height: '100%',
      scrollSnapAlign: 'start',
      padding: 0,
    },
    padding: '60px 0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  questionDivider: {
    [`${theme.breakpoints.down('xl')}`]: {
      display: 'none',
    },
    maxWidth: 600,
  },
  questionDescription: {
    paddingBottom: '40px',
    textAlign: 'justify',
  },
  questionSection: {
    paddingBottom: '40px',
    textAlign: 'justify',
  },
}));

const getNewAnswersObject = (values, questionnaireId, index, value) => {
  const newArray = values[questionnaireId] || [];
  newArray[index] = value;
  const newValues = {
    ...values,
    [questionnaireId]: newArray,
  };
  return newValues;
};

export default ({
  store,
  onNext,
  onQuestionnaireChange,
  onLoading,
  onError,
  dispatchPatientAnswerQuestion,
}) => {
  const { questionnaire, answer, questionnaireLink, generateQuestionnaireAnswers } = store;

  const INPUT_DEBOUNCE_MS = 2000;
  const classes = useStyles();
  const [values, setValues] = useState(answer);
  const [inProgressAnswers, setInProgressAnswers] = useState([]);
  const containerRef = React.useRef(null);
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('xl'));

  const containerHeight = containerRef.current && containerRef.current.offsetHeight;

  const flattenedQuestions = Object.values(questionnaire).reduce(
    (acc, q, index) =>
      acc.concat(
        q.questions.map((question, i) => ({
          ...question,
          height: q.isActive === false ? 0 : containerHeight,
          questionnaireIndex: index,
          questionnaireId: q.id,
          questionIndex: i,
        })),
      ),
    [],
  );

  const flattenedQuestionsWithoutInactive = flattenedQuestions.filter(q => q.isActive !== false);

  const isQuestionAnswered = question => {
    const { questionnaireId, questionIndex } = question;
    return (
      values &&
      values[questionnaireId] &&
      values[questionnaireId][questionIndex] !== null &&
      values[questionnaireId][questionIndex] !== undefined
    );
  };

  const getFirstUnansweredQuestion = (fromIndex = 0, answers = values) => {
    // eslint-disable-next-line no-plusplus
    for (let i = fromIndex; i < flattenedQuestionsWithoutInactive.length; i++) {
      const { questionnaireId, questionIndex } = flattenedQuestionsWithoutInactive[i];
      if (
        !answers ||
        !answers[questionnaireId] ||
        answers[questionnaireId][questionIndex] === null ||
        answers[questionnaireId][questionIndex] === undefined ||
        answers[questionnaireId][questionIndex] === ''
      ) {
        return i;
      }
    }
    return -1;
  };

  const submit = async () => {
    onLoading(true);
    try {
      const result = await HTTP.put(
        `/public/questionnaires/${questionnaireLink}`,
        generateQuestionnaireAnswers(questionnaire, values, true),
      );
      // after submitting, if there is a next question, redirect to it
      if (result && result.data && result.data.questionnaireChanged) {
        onQuestionnaireChange(result.data);
      } else {
        onNext();
      }
    } catch (e) {
      onError('Error submitting questionnaire', e);
      return null;
    }
    onLoading(false);
    window.parent.postMessage(
      { status: 'dc_questionnaire_completed', url: window.location.href },
      window.location.origin,
    );
    return true;
  };

  const scrollToQuestion = index => {
    const scrollSum = flattenedQuestions
      .slice(0, index)
      .reduce((acc, question) => acc + question.height, 0);
    containerRef.current.scrollTop = scrollSum;
  };

  const getQuestionIndexFromScroll = scroll => {
    let scrollSum = 0;
    return flattenedQuestions.findIndex(question => {
      let isSame = false;
      if (scrollSum > scroll - 20 && scrollSum < scroll + 20) {
        isSame = true;
      }
      scrollSum += question.height;
      return isSame;
    });
  };

  const handleChange = async (questionnaireId, index, value) => {
    const newArray = values[questionnaireId] || [];
    dispatchPatientAnswerQuestion(index);
    newArray[index] = value;
    const newValues = {
      ...values,
      [questionnaireId]: newArray,
    };
    setValues(newValues);
    onLoading(true);
    try {
      await HTTP.put(
        `/public/questionnaires/${questionnaireLink}`,
        generateQuestionnaireAnswers(questionnaire, values),
      );
    } catch (e) {
      onError('Error saving partial answer', e);
      return null;
    }
    onLoading(false);
    // auto scroll to next question
    if (isMobile) {
      // find current question index
      const currentQuestionIndex = getQuestionIndexFromScroll(containerRef.current.scrollTop);
      // find next not answered question
      const nextQuestionToAnswerIndex = getFirstUnansweredQuestion(
        currentQuestionIndex + 1,
        newValues,
      );
      if (nextQuestionToAnswerIndex > -1) {
        scrollToQuestion(nextQuestionToAnswerIndex);
      } else {
        const questionWihoutAnswerIndex = getFirstUnansweredQuestion(0, newValues);
        if (questionWihoutAnswerIndex > -1) {
          scrollToQuestion(questionWihoutAnswerIndex);
        }
      }
    }
    return true;
  };

  const getAnswer = (questionnaireId, index) => (values[questionnaireId] || [])[index];

  const debouncedOnChange = useCallback(
    lodash.debounce((value, qid, index, question) => {
      setInProgressAnswers(getNewAnswersObject(inProgressAnswers, qid, index, null));
      handleChange(qid, index, question.maxLength ? value.slice(0, question.maxLength) : value);
    }, INPUT_DEBOUNCE_MS),
    [],
  );

  const getInProgressAnswer = (questionnaireId, index) =>
    (inProgressAnswers[questionnaireId] || [])[index];

  let numberCount = 0;

  const footer = (
    <ProgressBar
      total={flattenedQuestionsWithoutInactive.length}
      value={flattenedQuestionsWithoutInactive.filter(q => isQuestionAnswered(q)).length}
    />
  );

  return (
    <Container title="Medication effectiveness questionnaire" footer={footer}>
      <div className={classes.container} ref={containerRef}>
        {Object.values(questionnaire).map((q, questionnaireIndex) =>
          q.questions.map((question, index) => {
            if (question.isActive === false) {
              return null;
            }
            numberCount += 1;

            let isAbleToSubmit = false;
            const isLastQuestionnaire =
              questionnaireIndex === Object.values(questionnaire).length - 1;
            const isLastQuestion = index === q.questions.length - 1;
            const isLastQuestionOfLastQuestionnaire = isLastQuestionnaire && isLastQuestion;
            if (isLastQuestionOfLastQuestionnaire) {
              const questionWihoutAnswerIndex = getFirstUnansweredQuestion();
              if (questionWihoutAnswerIndex === -1) {
                isAbleToSubmit = true;
              } else if (
                questionWihoutAnswerIndex ===
                flattenedQuestionsWithoutInactive.length - 1
              ) {
                isAbleToSubmit = question.type === 'range';
              }
            }

            const currentAnswer = getAnswer(q.id, index);
            const currentInProgressAnswer = getInProgressAnswer(q.id, index);

            let selectedOption = question.options.find(option => {
              if (option.subQuestion) {
                return option.subQuestion.options.some(
                  it => String(it.value) === String(currentAnswer),
                );
              }
              return String(option.value) === String(currentAnswer);
            });

            if (currentInProgressAnswer !== undefined && currentInProgressAnswer !== null) {
              selectedOption = question.options.find(option => {
                if (option.subQuestion) {
                  return option.subQuestion.options.some(
                    it => String(it.value) === String(currentInProgressAnswer),
                  );
                }
                return String(option.value) === String(currentInProgressAnswer);
              });
            }
            const isSubQuestionOpen = selectedOption && selectedOption.subQuestion;
            return (
              <Fragment key={question.question}>
                <div className={classes.questionContainer}>
                  <WidthController>
                    {q.description && q.description.length && index === 0 && (
                      <Typography variant="h6" className={classes.questionDescription}>
                        {q.description}
                      </Typography>
                    )}
                    {index === 0 &&
                      q.sections &&
                      q.sections.length &&
                      question.sectionPos !== undefined &&
                      q.sections[question.sectionPos]?.title && (
                        <Typography variant="h6" className={classes.questionSection}>
                          {q.sections[question.sectionPos].title}
                        </Typography>
                      )}
                    {q.sections &&
                      q.sections.length &&
                      question.sectionPos !== undefined &&
                      q.sections[question.sectionPos]?.title &&
                      q.questions[index - 1]?.sectionPos !== undefined &&
                      q.sections[q.questions[index - 1]?.sectionPos]?.title !==
                        q.sections[question.sectionPos]?.title && (
                        <Typography variant="h6" className={classes.questionSection}>
                          {q.sections[question.sectionPos].title}
                        </Typography>
                      )}
                    <Question
                      question={question}
                      description=""
                      handleChange={value => {
                        const clickedOption = question.options.find(
                          option => String(option.value) === String(value),
                        );
                        if (question.type !== 'text' && clickedOption.subQuestion) {
                          setInProgressAnswers(
                            getNewAnswersObject(inProgressAnswers, q.id, index, value),
                          );
                          setValues(getNewAnswersObject(values, q.id, index, null));
                        } else if (question.type === 'text') {
                          if (value && value.length) {
                            if (value.length === 1) {
                              debouncedOnChange(value, q.id, index, question);
                            } else {
                              setInProgressAnswers(
                                getNewAnswersObject(inProgressAnswers, q.id, index, null),
                              );
                              handleChange(
                                q.id,
                                index,
                                question.maxLength ? value.slice(0, question.maxLength) : value,
                              );
                            }
                          }
                        } else {
                          setInProgressAnswers(
                            getNewAnswersObject(inProgressAnswers, q.id, index, null),
                          );
                          handleChange(q.id, index, value);
                        }
                      }}
                      initialValue={
                        question.type === 'text' && currentAnswer !== null
                          ? currentAnswer
                          : question.type === 'text' && currentAnswer === null
                          ? ''
                          : selectedOption
                          ? String(selectedOption.value)
                          : null
                      }
                      number={numberCount}
                      onSubmit={isAbleToSubmit ? () => submit() : null}
                      shrink={isSubQuestionOpen || isAbleToSubmit}
                    />
                    {selectedOption && selectedOption.subQuestion && (
                      <Question
                        question={selectedOption.subQuestion}
                        isSubQuestion
                        handleChange={value => {
                          setInProgressAnswers(
                            getNewAnswersObject(inProgressAnswers, q.id, index, null),
                          );
                          handleChange(q.id, index, value);
                        }}
                        initialValue={currentAnswer}
                        number={null}
                        shrink={isSubQuestionOpen || isAbleToSubmit}
                      />
                    )}
                  </WidthController>
                </div>
                {!isLastQuestionOfLastQuestionnaire && <hr className={classes.questionDivider} />}
              </Fragment>
            );
          }),
        )}
      </div>
    </Container>
  );
};
