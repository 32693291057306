/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import { Grid, Radio, Button, Typography, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import Divider from 'components/divider';
import Audit from 'components/display/audit';
import AddressSearchBar from 'components/form/address/address_search_bar';
import { renderTimePicker } from 'components/form/datepicker/datetime-picker';
import DetailField from 'components/form/field/field';
import {
  renderDropdown,
  renderPhoneTextField,
  renderTextField,
  renderRadioGroup,
} from 'components/form/field/redux-field';
import SubHeader from 'components/form/header/subheader';
import { required, validateEmail, validatePhone } from 'components/form/validation/validation';
import { ValidationTypographyStyled } from 'components/form/validation/validation-typography';
import TalkdeskPhoneNumber from 'components/talkdesk-phone-number/talkdesk-phone-number';
import { EditAdd, EditClose, EditDelete } from 'components/icons/icons';
import {
  addressTypes,
  emailTypes,
  phoneTypes,
  contactEnrollment,
  states,
  auditContext,
  contactOptions,
} from 'constants/lists';
import { MobilePhoneSmsEnum, PhoneUseEnum } from 'constants/enums';
import {
  DECLINES_TO_PROVIDE_EMAIL,
  ENSURE_EMAIL_ADDRESSED,
  ENSURE_SMS_ADDRESSED,
  NO_EMAIL,
  ADD_ADDRESS_FORM,
} from 'constants/index';
import { Field } from 'redux-form';
import { isValidArray } from 'services/utils/common-service';
import {
  getLanguageDisplay,
  formatContactNameWithType,
  formatPatientNameShort,
} from 'services/utils/demographic-service';
import { yesNoToText, getFormattedProviderAddress } from 'services/utils/formatting';
import { getAddressForDisplay } from 'services/utils/task-service';
import { connect } from 'react-redux';
import AlertModal from 'components/alert-modal/alert-modal';

export const renderSpecialPopulations = ({
  fields,
  classes,
  change,
  formValues,
  idPrefix,
  specialPopulationsList,
  preferredField,
}) => {
  const preferredId = formValues[preferredField];
  let closeId = null;
  if (idPrefix) {
    closeId = `${idPrefix}_address_close_button`;
  }
  return (
    <>
      <Grid item>
        <SubHeader name="Special Populations" />
      </Grid>
      <Grid item xs className={classes.actionIconContainer}>
        <Button id="add_special_population" variant="outlined" onClick={() => fields.push({})}>
          <EditAdd />
          <Typography variant="body2">Special Population</Typography>
        </Button>
      </Grid>
      <>
        {fields.map((specialPopulation, index) => (
          <Grid item key={specialPopulation} xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={2}>
                <Field
                  id={`${specialPopulation}.population`}
                  name={`${specialPopulation}.population`}
                  label="Type"
                  width="100%"
                  validate={[required]}
                  component={renderDropdown}
                  fields={specialPopulationsList}
                />
              </Grid>
              <Grid item xs className={classes.actionIconContainer}>
                <Button
                  className={classes.closeButton}
                  onClick={() => {
                    if (index === preferredId) {
                      change(preferredField, 0);
                    }
                    fields.remove(index);
                  }}
                  id={idPrefix ? `${closeId}_${index}` : null}
                >
                  <EditClose />
                </Button>
              </Grid>
            </Grid>
            {index !== fields.length - 1 && <Divider />}
          </Grid>
        ))}
      </>
    </>
  );
};

export const RenderAddresses = ({
  fields,
  classes,
  change,
  preferredField,
  declineField,
  formValues,
  idPrefix,
  contactId,
  patient,
  numberOfPotentialRxDeliveryAddresses,
}) => {
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const preferredId = formValues[preferredField];
  const declinesToProvide = formValues[declineField];
  let closeId = null;
  if (idPrefix) {
    closeId = `${idPrefix}_address_close_button`;
  }

  const handleRemoveField = (index, key) => () => {
    if (
      key &&
      !contactId &&
      patient?.preferred_rx_delivery_patient &&
      patient?.preferred_rx_delivery_entity_key === key &&
      numberOfPotentialRxDeliveryAddresses > 1
    ) {
      setIsAlertOpen(true);
      return;
    }
    if (
      key &&
      !patient?.preferred_rx_delivery_patient &&
      patient?.preferred_rx_delivery_contact_id === contactId &&
      patient?.preferred_rx_delivery_entity_key === key &&
      numberOfPotentialRxDeliveryAddresses > 1
    ) {
      setIsAlertOpen(true);
      return;
    }
    fields.remove(index);
  };

  return (
    <>
      <Grid item>
        <SubHeader name="Addresses" />
      </Grid>
      <Grid item xs className={classes.actionIconContainer}>
        {fields.length === 0 && !declinesToProvide && (
          <Button
            id="decline_address"
            variant="outlined"
            onClick={() => change(declineField, DECLINES_TO_PROVIDE_EMAIL)}
            style={{ marginRight: 10 }}
          >
            <EditDelete />
            <Typography variant="body2">Declines to provide</Typography>
          </Button>
        )}
        <Button
          id="add_address"
          variant={declinesToProvide ? 'contained' : 'outlined'}
          onClick={() => fields.push({})}
          disabled={!!declinesToProvide}
        >
          <EditAdd />
          <Typography variant="body2">Address</Typography>
        </Button>
      </Grid>
      {declinesToProvide ? (
        <Grid container justifyContent="flex-end" style={{ marginTop: 10 }} spacing={7}>
          <Grid item>
            <Typography variant="body2">Declines to provide an address</Typography>
          </Grid>
          <Grid item>
            <Button
              id="remove_decline_address"
              style={{ marginTop: -8 }}
              onClick={() => change(declineField, 0)}
            >
              <EditDelete />
            </Button>
          </Grid>
        </Grid>
      ) : (
        <>
          {fields.map((address, index) => (
            <Grid item key={address} xs={12}>
              <Grid container spacing={1}>
                <Grid item>
                  <Radio
                    id={`preferred_address_${index}`}
                    style={{ marginTop: 18 }}
                    checked={index === preferredId}
                    color="primary"
                    onClick={() => change(preferredField, index)}
                    className={classes.preferredRadio}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Field
                    id={`${address}.use`}
                    name={`${address}.use`}
                    label="Type"
                    width="100%"
                    validate={[required]}
                    component={renderDropdown}
                    fields={addressTypes}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    name={`${address}.line1`}
                    label="Address"
                    validate={[required]}
                    component={AddressSearchBar}
                    setSuggestions={addr => {
                      change(`${address}.city`, addr.city);
                      change(`${address}.state`, addr.state);
                      change(`${address}.zip`, addr.zip);
                    }}
                  />
                </Grid>
                <Grid item xs={1}>
                  <Field
                    name={`${address}.line2`}
                    label={ADD_ADDRESS_FORM.line2.label}
                    component={renderTextField}
                    placeholder={ADD_ADDRESS_FORM.line2.placeholder}
                    width="100%"
                  />
                </Grid>
                <Grid item xs={1}>
                  <Field
                    name={`${address}.city`}
                    label="City"
                    width="100%"
                    component={renderTextField}
                    validate={[required]}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Field
                    id={`${address}.state`}
                    name={`${address}.state`}
                    label="State"
                    component={renderDropdown}
                    fields={states}
                    validate={[required]}
                  />
                </Grid>
                <Grid item xs={1}>
                  <Field
                    name={`${address}.zip`}
                    label="Zip"
                    width="100%"
                    component={renderTextField}
                    validate={[required]}
                  />
                </Grid>
                {fields.length === 1 || (fields.length > 1 && index !== preferredId) ? (
                  <Grid item xs className={classes.actionIconContainer}>
                    <Button
                      style={{ marginTop: 15 }}
                      onClick={handleRemoveField(index, fields.get(index).key)}
                      id={idPrefix ? `${closeId}_${index}` : null}
                    >
                      <EditClose />
                    </Button>
                  </Grid>
                ) : null}
              </Grid>
              {index !== fields.length - 1 && <Divider />}
            </Grid>
          ))}
        </>
      )}
      <AlertModal
        isOpen={isAlertOpen}
        setIsOpen={setIsAlertOpen}
        message="This address is selected as the patient's preferred rx delivery address. You must select another address as the preferred rx delivery address before removing this address."
      />
    </>
  );
};

export const renderAddress = ({ change, nameOverride, additionalFields }) => (
  <>
    <Grid item>
      <SubHeader name={nameOverride || 'Address'} />
    </Grid>
    <Grid item key="address" xs={12}>
      <Grid container spacing={1}>
        {additionalFields}
        <Grid item xs={2}>
          <Field
            id="addresses[0].use"
            name="addresses[0].use"
            label="Type *"
            width="100%"
            validate={[required]}
            component={renderDropdown}
            fields={addressTypes}
          />
        </Grid>
        <Grid item xs={3}>
          <Field
            name="addresses[0].line1"
            label="Address *"
            validate={[required]}
            component={AddressSearchBar}
            setSuggestions={addr => {
              change('addresses[0].city', addr.city);
              change('addresses[0].state', addr.state);
              change('addresses[0].ip', addr.zip);
            }}
          />
        </Grid>
        <Grid item xs={1}>
          <Field
            name="addresses[0].line2"
            label={ADD_ADDRESS_FORM.line2.label}
            component={renderTextField}
            placeholder={ADD_ADDRESS_FORM.line2.placeholder}
            width="100%"
          />
        </Grid>
        <Grid item xs={1}>
          <Field name="addresses[0].city" label="City" width="100%" component={renderTextField} />
        </Grid>
        <Grid item xs={2}>
          <Field
            id="addresses[0].state"
            name="addresses[0].state"
            label="State *"
            validate={[required]}
            component={renderDropdown}
            fields={states}
          />
        </Grid>
        <Grid item xs={1}>
          <Field name="addresses[0].zip" label="Zip" width="100%" component={renderTextField} />
        </Grid>
      </Grid>
    </Grid>
  </>
);

export const renderEmails = ({
  fields,
  classes,
  change,
  formValues,
  preferredField,
  declineField,
  idPrefix,
}) => {
  const preferredId = formValues[preferredField];
  const emailOptions = formValues[declineField];
  let emailMes;
  let closeId = null;
  if (idPrefix) {
    closeId = `${idPrefix}_email_close_button`;
  }
  if (emailOptions === DECLINES_TO_PROVIDE_EMAIL) {
    emailMes = 'Declines to provide';
  } else if (emailOptions === NO_EMAIL) {
    emailMes = 'No email to provide';
  }
  return (
    <>
      <Grid item>
        <Grid container alignItems="center">
          <Audit
            iconType="warning"
            auditRules={[ENSURE_EMAIL_ADDRESSED]}
            context={auditContext.Warning}
          />
          <SubHeader name="Emails" />
        </Grid>
      </Grid>
      <Grid item xs className={classes.actionIconContainer}>
        {fields.length === 0 && !emailMes && (
          <>
            <Button
              id="no_email"
              variant="outlined"
              onClick={() => change(declineField, NO_EMAIL)}
              style={{ marginRight: 10 }}
            >
              <EditDelete />
              <Typography variant="body2">No email</Typography>
            </Button>
            <Button
              id="decline_email"
              variant="outlined"
              onClick={() => change(declineField, DECLINES_TO_PROVIDE_EMAIL)}
              style={{ marginRight: 10 }}
            >
              <EditDelete />
              <Typography variant="body2">Declines to provide</Typography>
            </Button>
          </>
        )}
        <Button
          id="add_email"
          variant={emailMes ? 'contained' : 'outlined'}
          onClick={() => {
            change(declineField, 0);
            fields.push({ enrollment: 'Not Specified' });
          }}
          disabled={!!emailMes}
        >
          <EditAdd />
          <Typography variant="body2">Email</Typography>
        </Button>
      </Grid>
      {emailMes ? (
        <Grid container justifyContent="flex-end" style={{ marginTop: 10 }}>
          <Grid item>
            <Typography variant="body2">{emailMes}</Typography>
          </Grid>
          <Grid item>
            <Button
              id="remove_decline_email"
              style={{ marginTop: -8 }}
              onClick={() => change(declineField, null)}
            >
              <EditDelete />
            </Button>
          </Grid>
        </Grid>
      ) : (
        <>
          {fields.map((email, index) => (
            <Grid item key={email} xs={12}>
              <Grid container spacing={1}>
                <Grid item>
                  <Radio
                    id={`preferred_email_${index}`}
                    style={{ marginTop: 18 }}
                    checked={index === preferredId}
                    color="primary"
                    onClick={() => change(preferredField, index)}
                    className={classes.preferredRadio}
                  />
                </Grid>
                <Grid item xs={10}>
                  <Grid container>
                    <Grid item xs={3} className={classes.dropdownContainer}>
                      <Field
                        id={`${email}.use`}
                        name={`${email}.use`}
                        label="Type"
                        validate={[required]}
                        component={renderDropdown}
                        fields={emailTypes}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Field
                        name={`${email}.value`}
                        label="Email"
                        width="90%"
                        validate={[required, validateEmail]}
                        component={renderTextField}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={5}
                      onClick={e => {
                        e.stopPropagation();
                      }}
                    >
                      <Field
                        label="Email Enrollment"
                        name={`${email}.enrollment`}
                        radioMap={contactEnrollment}
                        component={renderRadioGroup}
                        className={classes.input}
                        validate={[required]}
                        width="100%"
                        caption
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs className={classes.actionIconContainer}>
                  <Button
                    style={{ marginTop: 15 }}
                    onClick={() => {
                      if (index === preferredId) {
                        change(preferredField, 0);
                      }
                      fields.remove(index);
                    }}
                    id={idPrefix ? `${closeId}_${index}` : null}
                  >
                    <EditClose />
                  </Button>
                </Grid>
              </Grid>
              {index !== fields.length - 1 && <Divider />}
            </Grid>
          ))}
        </>
      )}
    </>
  );
};

const handleSMSChange = (newValue, fields, change, index) => {
  if (newValue === contactOptions.OPT_IN) {
    const fieldsName = fields.map(name => name);
    fieldsName.forEach((fieldName, currentIndex) => {
      const currentField = fields.get(currentIndex);
      if (currentIndex !== index && currentField.sms === contactOptions.OPT_IN) {
        change(`${fieldName}.sms`, contactOptions.OPT_OUT);
      }
    });
  }
};

export const renderPhones = props => {
  const {
    fields,
    classes,
    change,
    formValues,
    preferredField,
    declineField,
    idPrefix,
    meta,
    isTypeContact = false,
  } = props;

  const preferredId = formValues[preferredField];
  const declinesToProvide = formValues[declineField];

  let closeId = null;
  if (idPrefix) {
    closeId = `${idPrefix}_phone_close_button`;
  }

  return (
    <>
      <Grid item>
        <Grid container alignItems="center">
          <Audit
            iconType="warning"
            auditRules={[ENSURE_SMS_ADDRESSED]}
            context={auditContext.Warning}
          />
          <SubHeader name={`Phones${props.required ? ' *' : ''}`} />
          <ValidationTypographyStyled
            touched={meta.touched || meta.submitFailed}
            error={meta.error ? `\u00A0${meta.error}` : undefined}
          />
        </Grid>
      </Grid>
      <Grid item xs className={classes.actionIconContainer}>
        {fields.length === 0 && !declinesToProvide && (
          <Button
            id="decline_phone"
            variant="outlined"
            onClick={() => change(declineField, DECLINES_TO_PROVIDE_EMAIL)}
            style={{ marginRight: 10 }}
          >
            <EditDelete />
            <Typography variant="body2">Declines to provide</Typography>
          </Button>
        )}
        <Button
          id="add_phone"
          variant={declinesToProvide ? 'contained' : 'outlined'}
          onClick={() =>
            fields.push({
              fromTime: '08:00',
              toTime: '20:00',
              sms: 'Not Specified',
            })
          }
          disabled={!!declinesToProvide}
        >
          <EditAdd />
          <Typography variant="body2">Phone</Typography>
        </Button>
      </Grid>
      {declinesToProvide ? (
        <Grid container justifyContent="flex-end" style={{ marginTop: 10 }}>
          <Grid item>
            <Typography variant="body2">Declines to provide a phone</Typography>
          </Grid>
          <Grid item>
            <Button
              id="remove_decline_phone"
              style={{ marginTop: -8 }}
              onClick={() => change(declineField, 0)}
            >
              <EditDelete />
            </Button>
          </Grid>
        </Grid>
      ) : (
        <>
          {fields.map((phone, index) => {
            const phoneValue = fields.get(index);
            return (
              <Grid item key={phone} xs={12}>
                <Grid container spacing={1}>
                  <Grid item>
                    <Radio
                      id={`preferred_phone_${index}`}
                      style={{ marginTop: 18 }}
                      checked={index === preferredId}
                      color="primary"
                      onClick={() => change(preferredField, index)}
                      className={classes.preferredRadio}
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <Grid container>
                      <Grid item xs={3} className={classes.dropdownContainer}>
                        <Field
                          id={`${phone}.use`}
                          name={`${phone}.use`}
                          label="Type"
                          validate={[required]}
                          component={renderDropdown}
                          fields={phoneTypes}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Field
                          name={`${phone}.value`}
                          label="Phone"
                          validate={[required, validatePhone]}
                          component={renderPhoneTextField}
                          width="90%"
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <Grid container>
                          <Grid item xs={6}>
                            <Field
                              name={`${phone}.fromTime`}
                              label="Availability Start"
                              component={renderTimePicker}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Field
                              name={`${phone}.toTime`}
                              label="Availability End"
                              component={renderTimePicker}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      {phoneValue.use === PhoneUseEnum.Mobile && !isTypeContact && (
                        <Grid
                          item
                          xs={5}
                          onClick={e => {
                            e.stopPropagation();
                          }}
                        >
                          <Field
                            label="SMS Enrollment"
                            name={`${phone}.sms`}
                            radioMap={contactEnrollment}
                            component={renderRadioGroup}
                            className={classes.input}
                            validate={[required]}
                            onChange={(_, newValue) =>
                              handleSMSChange(newValue, fields, change, index)
                            }
                            width="100%"
                            caption
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs className={classes.actionIconContainer}>
                    <Button
                      style={{ marginTop: 15 }}
                      onClick={() => {
                        if (index === preferredId) {
                          change(preferredField, 0);
                        }
                        fields.remove(index);
                      }}
                      id={idPrefix ? `${closeId}_${index}` : null}
                    >
                      <EditClose />
                    </Button>
                  </Grid>
                </Grid>
                {index !== fields.length - 1 && <Divider />}
              </Grid>
            );
          })}
        </>
      )}
    </>
  );
};

export function AddressRow(props) {
  const { address, index, classes } = props;
  const street = address.line2 ? `${address.line1}, ${address.line2}` : address.line1;
  return (
    <Grid container display="inline-flex" alignItems="center" spacing={7} key={address.key}>
      <Grid item xs={1}>
        {index === 0 && (
          <div>
            <Typography variant="caption">Preferred</Typography>
            <Radio checked disabled color="primary" className={classes.preferredRadio} />
          </div>
        )}
      </Grid>
      <Grid item xs={2}>
        <DetailField fieldName="Type" field={address.use} />
      </Grid>
      <Grid item xs={3}>
        <DetailField fieldName="Street" field={street} />
      </Grid>
      <Grid item xs={2}>
        <DetailField fieldName="City" field={address.city} />
      </Grid>
      <Grid item xs={1}>
        <DetailField fieldName="State" field={address.state} variant="raw" />
      </Grid>
      <Grid item xs={1}>
        <DetailField fieldName="Zip" field={address.zip} />
      </Grid>
    </Grid>
  );
}

export function EmailRow(props) {
  const { email, index, classes } = props;
  return (
    <Grid container display="inline-flex" alignItems="center" spacing={7} key={email.key}>
      <Grid item xs={1}>
        {index === 0 && (
          <div>
            <Typography variant="caption">Preferred</Typography>
            <Radio checked disabled color="primary" className={classes.preferredRadio} />
          </div>
        )}
      </Grid>
      <Grid item xs={2}>
        <DetailField fieldName="Type" field={email.use} />
      </Grid>
      <Grid item xs={2}>
        <DetailField fieldName="Email Address" field={email.value} variant="raw" />
      </Grid>
      <Grid item xs={2}>
        <DetailField fieldName="Enrollment" field={email.enrollment} variant="raw" />
      </Grid>
    </Grid>
  );
}

const StyledButton = styled(Button)(({ theme }) => ({
  fontSize: theme.font.extraSmallFontSize,
  marginTop: 8,
}));

// eslint-disable-next-line @typescript-eslint/naming-convention
export function PhoneRow(props) {
  const {
    phone,
    index,
    handleSendOptInSms,
    classes,
    meta,
    owner,
    isTypeContact = false,
    sourceNumber,
  } = props;
  const smsStr = phone.patient_choice ? `${phone.sms} (Patient Choice)` : phone.sms;
  const optInBtnStr = phone.is_opt_in_sent ? 'Resend opt-in message' : 'Send opt-in message';
  return (
    <Grid container display="inline-flex" alignItems="center" spacing={7} key={phone.key}>
      <Typography>{meta?.error}</Typography>
      <Grid item xs={1}>
        {index === 0 && (
          <div>
            <Typography variant="caption">Preferred</Typography>
            <Radio checked disabled color="primary" className={classes.preferredRadio} />
          </div>
        )}
      </Grid>

      <Grid item xs={2}>
        <DetailField fieldName="Type" field={phone.use} />
      </Grid>
      <Grid item xs={2}>
        <TalkdeskPhoneNumber number={phone.value} headerText="Number" owner={owner} />
      </Grid>
      <Grid item xs={2}>
        <DetailField fieldName="Available" field={phone.period} />
      </Grid>
      {!isTypeContact && phone.sms !== undefined && (
        <>
          <Grid item xs={2}>
            {phone.use === PhoneUseEnum.Mobile && <DetailField fieldName="SMS" field={smsStr} />}
          </Grid>
          <Grid item xs={2}>
            {phone.use === PhoneUseEnum.Mobile &&
              (phone.sms === MobilePhoneSmsEnum.OptOut ? (
                <Tooltip
                  placement="top-end"
                  title={
                    <span style={{ display: 'block', padding: '4px' }}>
                      For Opt In Patient need to
                      <br /> send start to {sourceNumber}
                    </span>
                  }
                >
                  <span>
                    <StyledButton
                      onClick={() => handleSendOptInSms(phone.value)}
                      size="small"
                      color="primary"
                      variant="contained"
                      disabled
                    >
                      {optInBtnStr}
                    </StyledButton>
                  </span>
                </Tooltip>
              ) : (
                <StyledButton
                  onClick={() => handleSendOptInSms(phone.value)}
                  size="small"
                  color="primary"
                  variant="contained"
                >
                  {optInBtnStr}
                </StyledButton>
              ))}
          </Grid>
        </>
      )}
    </Grid>
  );
}

export function SelectPreferredPhone(props) {
  const { index, sms, phone, preferredId, preferredHandler, preferredField, classes, owner } =
    props;
  return (
    <Grid container spacing={7} key={phone.key}>
      <Grid item>
        <Radio
          id={`communication_preferred_phone_${index}`}
          disabled={sms && phone.sms !== 'Opt in'}
          checked={index === preferredId}
          color="primary"
          onClick={() => preferredHandler(preferredField, index)}
          className={classes.radio}
        />
      </Grid>
      <Grid item xs={2}>
        <DetailField fieldName="Type" field={phone.use} />
      </Grid>
      <Grid item xs={2}>
        <TalkdeskPhoneNumber number={phone.value} headerText="Number" owner={owner} />
      </Grid>
      <Grid item xs={2}>
        <DetailField fieldName="Available" field={phone.period} />
      </Grid>
      {phone.sms !== undefined && (
        <Grid item xs={2}>
          <DetailField fieldName="SMS" field={phone.sms} />
        </Grid>
      )}
    </Grid>
  );
}

export function SelectPreferredEmail(props) {
  const { email, index, preferredId, preferredField, preferredHandler, classes } = props;
  return (
    <Grid container spacing={7} key={email.key}>
      <Grid item>
        <Radio
          id={`communication_preferred_email_${index}`}
          disabled={email.enrollment !== 'Opt in'}
          checked={index === preferredId}
          color="primary"
          onClick={() => preferredHandler(preferredField, index)}
          className={classes.radio}
        />
      </Grid>
      <Grid item xs={2}>
        <DetailField fieldName="Type" field={email.use} />
      </Grid>
      <Grid item xs={2}>
        <DetailField fieldName="Email Address" field={email.value} variant="raw" />
      </Grid>
      <Grid item xs={2}>
        <DetailField fieldName="Enrollment" field={email.enrollment} variant="raw" />
      </Grid>
    </Grid>
  );
}

export function ContactRow(props) {
  const { id, classes, contact, preferredId, preferredField, preferredHandler } = props;
  let fullName;
  if (id === 0) {
    fullName = formatPatientNameShort(contact);
  } else {
    fullName = formatContactNameWithType(contact);
  }
  fullName = fullName === 'null' ? '-' : fullName;

  return (
    <Grid container spacing={7}>
      <Grid item xs={1}>
        <Radio
          id={`preferred_contact_${id}`}
          checked={(!preferredId && id === 0) || id === preferredId}
          color="primary"
          onClick={() => preferredHandler(preferredField, id)}
          className={classes.radio}
        />
      </Grid>
      <Grid item xs={3}>
        <DetailField fieldName="Name" field={fullName} />
      </Grid>
      <Grid item xs={2}>
        {id === 0 ? (
          <DetailField fieldName="" field="Patient" />
        ) : (
          <DetailField fieldName="Relationship to patient" field={contact.relationship} />
        )}
      </Grid>
      <Grid item xs={2}>
        <DetailField fieldName="Languages" field={getLanguageDisplay(contact.languages)} />
      </Grid>
      <Grid item xs={2}>
        <DetailField fieldName="Interpreter Needed" field={yesNoToText(contact.need_interpreter)} />
      </Grid>
    </Grid>
  );
}

export function ProviderRow(props) {
  const { id, classes, provider, preferredId, preferredField, preferredHandler } = props;

  return (
    <Grid container spacing={7} key={`provider-row-${id}`}>
      <Grid item xs={1}>
        <Radio
          id={`preferred_provider_${id}`}
          checked={(!preferredId && id === 0) || id === preferredId}
          color="primary"
          onClick={() => preferredHandler(preferredField, id)}
          className={classes.radio}
        />
      </Grid>
      <Grid item xs={3}>
        <DetailField fieldName="Name" field={provider.provider_office_name} />
      </Grid>
      <Grid item xs={2}>
        <DetailField fieldName="Interpreter Needed" field={getFormattedProviderAddress(provider)} />
      </Grid>
    </Grid>
  );
}

export function RxDeliveryAddressRow(props) {
  const {
    itemProps: {
      id,
      classes,
      contact,
      preferredId,
      preferredIdKey,
      preferredField,
      preferredHandler,
      subkey,
      index,
    },
    input,
    meta,
  } = props;
  let fullName;
  if (id === 0) {
    fullName = formatPatientNameShort(contact);
  } else {
    fullName = formatContactNameWithType(contact);
  }
  fullName = fullName === 'null' ? '-' : fullName;
  const preferredAddress =
    isValidArray(contact.filteredAddresses) && contact.filteredAddresses[index];
  const preferredHandlerWithKeyIndex = (preferredField, id, subkey) => {
    preferredHandler(preferredField, id);
    preferredHandler('preferred_rx_delivery_entity_key', subkey);
  };
  return (
    <Grid container spacing={7} key={`rx-delivery-row-${id}`}>
      <Grid item xs={1}>
        <Radio
          name={input.name}
          id={`preferred_delivery_address_${id}`}
          checked={
            (!preferredId && id === 0 && preferredIdKey === preferredAddress.key) ||
            (id === preferredId && preferredIdKey === preferredAddress.key)
          }
          color="primary"
          onClick={() => preferredHandlerWithKeyIndex(preferredField, id, subkey)}
          className={classes.radio}
          value={input.value}
          onChange={() => input.onChange(id, subkey)}
        />
      </Grid>
      <Grid item xs={4}>
        <DetailField fieldName="Name" field={fullName} />
      </Grid>
      <Grid item xs={7}>
        <DetailField
          fieldName="Address"
          field={
            contact.is_declined_to_provide_addresses
              ? 'Decline to provide address'
              : getAddressForDisplay(preferredAddress, null, contact.provider_office_name)
          }
          variant="raw"
        />
        {meta.submitFailed && meta.error && (
          <Typography className={classes.fieldErrorText}>{meta.error}</Typography>
        )}
      </Grid>
    </Grid>
  );
}
