import React, { lazy, Suspense } from 'react';
import { QuestionnaireInstance } from '../types';
import QuestionnaireNotFound from './questionnaire_not_found';

const FireDrillQuestionnaireV1 = lazy(() => import('./fire_drill_questionnaire_v1'));
const DisasterManagementQuestionnaire = lazy(() => import('./disaster_management_questionnaire'));
const AnnualPharmacySafetyChecklistQuestionnaireV1 = lazy(
  () => import('./annual_pharmacy_safety_checklist_questionnaire_v1'),
);
const InitialShippingProfileTestingV1 = lazy(() => import('./initial_shipping_profile_testing_v1'));
const SummerHotShippingProfileTestingV1 = lazy(
  () => import('./summer_hot_shipping_profile_testing_v1'),
);
const WinterColdShippingProfileTestingV1 = lazy(
  () => import('./winter_cold_shipping_profile_testing_v1'),
);
const SummerShipperAssessmentV1 = lazy(
  () => import('./summer_shipper_assessment_v1'),
);
const WinterShipperAssessmentV1 = lazy(
  () => import('./winter_shipper_assessment_v1'),
);

const componentLookup: Record<string, any> = {
  DisasterManagementv1: DisasterManagementQuestionnaire,
  FireDrill_v1: FireDrillQuestionnaireV1,
  AnnualPharmacySafetyChecklist_v1: AnnualPharmacySafetyChecklistQuestionnaireV1,
  InitialShippingProfileTesting_v1: InitialShippingProfileTestingV1,
  SummerHotShippingProfileTesting_v1: SummerHotShippingProfileTestingV1,
  WinterColdShippingProfileTesting_v1: WinterColdShippingProfileTestingV1,
  SummerShipperAssessment_v1: SummerShipperAssessmentV1,
  WinterShipperAssessment_v1: WinterShipperAssessmentV1,
};

interface QuestionnaireMapperProps {
  closeModal: () => void;
  questionnaire: QuestionnaireInstance | null;
}

export function questionnaireMapper({ closeModal, questionnaire }: QuestionnaireMapperProps) {
  const Component =
    (questionnaire && componentLookup[questionnaire.questionnaire_type_identifier]) ||
    QuestionnaireNotFound;
  return (
    <Suspense fallback={null}>
      <Component closeModal={closeModal} questionnaire={questionnaire} />
    </Suspense>
  );
}
