import React, { useState } from 'react';
import {
  Table,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Grid,
  Box,
} from '@mui/material';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { fetchArchivedTasksForTaskTabv2 } from 'actions/action-tasks';
import { useDispatch } from 'react-redux';
import { buildQaIdProp } from 'utils/build-qa-id';

import withStyles from '@mui/styles/withStyles';
import { ITask } from 'interfaces/redux/ITasks';
import { ITherapy } from 'interfaces/redux/ITherapy';
import { ReactSelect } from 'components/form/field/react-select';
import { styles } from './table.styles';
import TasksTableRow from './tasks-table-row';
import { ITaskFilterItem, ITherapyFilterItem, TaskTableContainerProps } from './interfaces';
import { CATEGORY_DONE, CATEGORY_ARCHIVED, CLEAR_ARCHIVED_TASKS } from '../../../../constants';
import { GroupedArchivedTaskTabs } from '../grouped-archived-task-tabs';
import { getTaskKey } from './utils';

const universalTaskTypes = ['AR', 'DUR', 'INT', 'OR', 'RS', 'QRE'];
const therapyLevelTaskTypes = ['CSL', 'DC', 'FA', 'FC', 'FDC', 'PA', 'TPR'];
const qaIdProp = buildQaIdProp('archived-tasks-table');
const filterArchivedTasks = (
  tasks: Record<string, ITask>,
  filteredTaskTypes: ITaskFilterItem[],
  filteredTherapies: ITherapyFilterItem[],
): Record<string, ITask> => {
  const filteredTasks: Record<string, ITask> = {};
  for (const key in tasks) {
    const task = tasks[key];
    const isComplete = task.status_category_id
      ? [CATEGORY_DONE, CATEGORY_ARCHIVED].indexOf(task.status_category_id) > -1
      : false;
    const isMatchingTherapyFilter = filteredTherapies.length
      ? filteredTherapies.some((item: ITherapyFilterItem) => {
          if (item.value === task.therapy_id) {
            return true;
          }
          if (
            task.therapy_ids &&
            task.therapy_ids
              .split(',')
              .map(x => Number(x))
              .includes(item.value)
          ) {
            return true;
          }
          return false;
        })
      : true;
    const isMatchingTaskTypeFilter = filteredTaskTypes.length
      ? filteredTaskTypes.some((item: ITaskFilterItem) => item.value === task.taskType)
      : true;
    if (isComplete && isMatchingTherapyFilter && isMatchingTaskTypeFilter) {
      filteredTasks[key] = task;
    }
  }
  return filteredTasks;
};

const allTaskTypesSorted = universalTaskTypes.concat(therapyLevelTaskTypes).sort();

const TasksTableArchivedContainer: React.FC<TaskTableContainerProps & { classes: any }> = (
  props,
): JSX.Element | null => {
  const {
    classes,
    toggleActiveArchived,
    taskActiveArchivedOptions,
    taskActiveArchivedSelected,
    display,
  } = props;
  const tasks = useTypedSelector(state => state.tasks.data);
  const dispatch = useDispatch();
  const [filteredTaskTypes, setFilteredTaskTypes] = useState<ITaskFilterItem[]>([]);
  const [filteredTherapies, setFilteredTherapies] = useState<ITherapyFilterItem[]>([]);
  const [filteredTasks, setFilteredTasks] = useState<ITask[]>([]);
  const [selectAll, setSelectAll] = useState<'none' | 'all' | 'partial'>('none');
  const [searchingForArchivedTasks, setSearchingForArchivedTasks] = useState<boolean>(false);
  const archivedTasks = Object.values(
    filterArchivedTasks(tasks, filteredTaskTypes, filteredTherapies) || {},
  );
  const therapies = Object.values(useTypedSelector(state => state.therapies.data));
  const patient = useTypedSelector(state => state.patient);
  const patientId = patient.id;

  const [selectedTasks, setSelectedTasks] = useState<ITask[]>([]);
  const [triggerRowUpdate, setTriggerRowUpdate] = useState<ITask[]>([]);

  const getArchivedTasks = () => {
    dispatch({ type: CLEAR_ARCHIVED_TASKS });
    setSearchingForArchivedTasks(true);
    setSelectedTasks([]);
    setSelectAll('none');
    const filteredTherapyIds = filteredTherapies.map(
      (therapy: ITherapyFilterItem) => therapy.value,
    );
    const allTherapyIds = therapies.map((therapy: ITherapy) => therapy.id);
    const useAllTherapyIds = filteredTherapyIds.length === 0 && filteredTaskTypes.length > 0;

    const idsForTherapySearch = useAllTherapyIds ? allTherapyIds : filteredTherapyIds;

    const patientLevelTaskFilters = filteredTaskTypes
      .filter((task: ITaskFilterItem) => universalTaskTypes.indexOf(task.value as string) > -1)
      .map((item: ITaskFilterItem) => item.value);

    fetchArchivedTasksForTaskTabv2(
      patientId,
      idsForTherapySearch,
      patientLevelTaskFilters,
      filteredTaskTypes,
    ).then(results => {
      dispatch(results);
      setSearchingForArchivedTasks(false);
    });
  };

  React.useEffect(() => {
    setFilteredTaskTypes([]);
    setFilteredTherapies([]);
  }, [patientId]);

  React.useEffect(() => {
    getArchivedTasks();
  }, [filteredTherapies, filteredTaskTypes]);

  React.useEffect(() => {
    let filtered: ITask[] = [];
    const selected: ITask[] = [];
    if (filteredTaskTypes.length) {
      archivedTasks.forEach((task: any) => {
        const x = filteredTaskTypes.some((type: any) => type.label === task.taskType);
        if (x) {
          filtered.push(task);
        }
      });
    } else {
      filtered = archivedTasks;
    }

    setFilteredTasks(filtered);

    filtered.forEach((ftask: ITask) => {
      const found = selectedTasks.some(
        (task: ITask) => ftask.id === task.id && ftask.taskType == task.taskType,
      );
      if (found) {
        selected.push(ftask);
      }
    });
    setSelectedTasks(selected);
  }, [tasks]);

  const handleTaskTagFilterChange = (value: ITaskFilterItem[] | undefined) => {
    setFilteredTaskTypes(value || []);
  };

  const handleTherapyFilterChange = (value: ITherapyFilterItem[] | undefined) => {
    setFilteredTherapies(value || []);
  };

  const handleOnSelectedRow = (task: ITask) => {
    const selectedIndex = selectedTasks.findIndex(
      item => item.id === task.id && item.taskType === task.taskType,
    );

    if (selectedIndex === -1) {
      const newSelected = [...selectedTasks, task];
      setSelectedTasks(newSelected);
      if (newSelected.length === filteredTasks.length) {
        setSelectAll('all');
      } else {
        setSelectAll('partial');
      }
    } else {
      const newList = selectedTasks.filter(
        item => `${item.taskType}-${item.id}` !== `${task.taskType}-${task.id}`,
      );
      setSelectedTasks(newList);
      if (newList.length === 0) {
        setSelectAll('none');
      } else if (newList.length === filteredTasks.length) {
        setSelectAll('all');
      } else {
        setSelectAll('partial');
      }
    }
  };

  const handleSelectAllClick = () => {
    const newState = selectAll === 'none' || selectAll === 'partial' ? 'all' : 'none';
    setSelectAll(newState);
    if (newState === 'none') {
      setSelectedTasks([]);
    } else {
      setSelectedTasks(filteredTasks);
    }
  };

  const handleSelectedTasksChange = (unSelectedTasks: ITask[]) => {
    const newListOfSelectedTasks = selectedTasks.filter(
      task =>
        !unSelectedTasks.some(
          changedTask => changedTask.id === task.id && changedTask.taskType === task.taskType,
        ),
    );

    setSelectedTasks(newListOfSelectedTasks);
    if (newListOfSelectedTasks.length === 0) {
      setSelectAll('none');
    } else {
      setSelectAll('partial');
    }
    setTriggerRowUpdate(unSelectedTasks);
  };

  const selectFiltersMessage =
    !searchingForArchivedTasks &&
    filteredTaskTypes.length === 0 &&
    (filteredTherapies?.length || 0) === 0;

  const noMatchingTasks =
    !searchingForArchivedTasks &&
    filteredTasks.length === 0 &&
    ((filteredTherapies?.length || 0) > 0 || filteredTaskTypes.length > 0);

  const displayTasks =
    filteredTaskTypes.length > 0 || (filteredTherapies && filteredTherapies?.length > 0);

  return display ? (
    <>
      <Box sx={{ display: 'flex', gap: 3, alignItems: 'flex-end' }}>
        {taskActiveArchivedOptions && (
          <Grid item xs={4} className={classes.filterTaskStyling}>
            <ReactSelect
              defaultValues={null}
              isMulti={false}
              label="Task"
              value={taskActiveArchivedSelected}
              handleOnChange={toggleActiveArchived}
              fields={taskActiveArchivedOptions}
            />
          </Grid>
        )}
        <Grid xs={5}>
          <ReactSelect
            defaultValues={null}
            label="Therapy"
            value={filteredTherapies}
            handleOnChange={(value: ITherapyFilterItem[] | undefined) =>
              handleTherapyFilterChange(value)
            }
            fields={therapies
              .filter((therapy: ITherapy) => therapy.administration_status_category_id !== 4)
              .map((therapy: ITherapy) => {
                const therapyDosage =
                  therapy && therapy.strength && therapy.strength_unit_of_measure
                    ? `- ${therapy.strength} ${therapy.strength_unit_of_measure}`
                    : '';

                const therapyNameFull = `${therapy.drug_name} ${therapyDosage || ''}`;
                return {
                  value: therapy.id,
                  label: therapyNameFull,
                };
              })}
          />
        </Grid>
        <Grid xs={4}>
          <ReactSelect
            defaultValues={null}
            isMulti
            label="Task Type"
            value={filteredTaskTypes}
            handleOnChange={(value: ITaskFilterItem[] | undefined) =>
              handleTaskTagFilterChange(value)
            }
            fields={allTaskTypesSorted.map((taskType: string) => ({
              value: taskType,
              label: taskType,
            }))}
          />
        </Grid>
      </Box>
      <TableContainer className={classes.table}>
        <Table {...qaIdProp('tasks-table')}>
          <TableHead>
            <TableRow className={classes.header}>
              <TableCell align="left" className={classes.headerNarrowColumn}>
                <Checkbox
                  className={selectAll == 'partial' ? classes.partialTaskSelectionSet : ''}
                  color="primary"
                  checked={selectAll !== 'none'}
                  onClick={handleSelectAllClick}
                  disabled={filteredTasks.length === 0}
                />
              </TableCell>
              <TableCell align="left" className={classes.headerColumn}>
                {`Task ${filteredTasks.length && displayTasks ? `(${filteredTasks.length})` : ''}`}
              </TableCell>
              <TableCell align="left" className={classes.headerColumn}>
                Therapy
              </TableCell>
              <TableCell align="left" className={classes.headerColumn}>
                Status
              </TableCell>
              <TableCell align="left" className={classes.headerColumn}>
                Date
              </TableCell>
              <TableCell align="left" className={classes.headerColumn} />
              <TableCell align="left" className={classes.headerNarrowColumn}>
                Note
              </TableCell>
              <TableCell align="left" className={classes.headerNarrowColumn}>
                File
              </TableCell>
              <TableCell align="left" className={classes.headerNarrowColumn} />
            </TableRow>
          </TableHead>
          <TableBody>
            {!searchingForArchivedTasks &&
              displayTasks &&
              filteredTasks.length > 0 &&
              filteredTasks.map((task: ITask, index: number) => (
                <TasksTableRow
                  key={`${task.taskType}-${task.id}`}
                  taskKey={getTaskKey(task)}
                  index={index}
                  onSelectedRow={handleOnSelectedRow}
                  updatedTasks={triggerRowUpdate}
                  setTriggerRowUpdate={setTriggerRowUpdate}
                  hideNBDColumn
                  version="archived"
                  patient={patient}
                  isChecked={selectedTasks.map(getTaskKey).includes(getTaskKey(task))}
                />
              ))}
          </TableBody>
        </Table>
        {noMatchingTasks && (
          <div className={classes.messageContainer}>No Tasks Found for Selected Filters</div>
        )}
        {selectFiltersMessage && (
          <div className={classes.messageContainer}>Select Filter Values</div>
        )}
        {searchingForArchivedTasks && (
          <div className={classes.messageContainer}>Loading Task Information</div>
        )}
      </TableContainer>
      {selectedTasks && selectedTasks.length > 0 && (
        <GroupedArchivedTaskTabs
          selectedTasks={selectedTasks}
          onSelectedTasksChange={handleSelectedTasksChange}
        />
      )}
    </>
  ) : null;
};

export default withStyles(styles)(TasksTableArchivedContainer);
