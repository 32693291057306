import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import React from 'react';
import Validation from 'components/form/validation/validation';
import { Controller, useForm } from 'react-hook-form';
import { Grid } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { nameOfFactory } from 'utils/types-util';
import { ReactSelect } from 'components/form/field/react-select';
import { NumberOption } from 'interfaces/forms/types';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { LicensedStatesTypeFormStyles } from './licensed-states-form.styles';
import { IFormFields } from './types';

type LicensedStatesTypeFormFields = IFormFields<NumberOption[]>;

interface ILicensedStatesFormProps extends WithStyles<typeof LicensedStatesTypeFormStyles> {
  initialValue: string;
  handleCancel: () => void;
  handleSubmit: (value: number[]) => void;
}

const getFieldName = nameOfFactory<LicensedStatesTypeFormFields>();

const LicensedStatesForm: React.FC<ILicensedStatesFormProps> = (
  props: ILicensedStatesFormProps,
): JSX.Element => {
  const { classes } = props;

  // #region component state
  const {
    handleSubmit,
    control,
    setError,

    formState: { errors },
  } = useForm<any>();
  const states = useTypedSelector(state => state.lookups.states);

  const licenseOptions = React.useMemo<NumberOption[]>(() => {
    return states.map<NumberOption>(state => {
      return {
        label: state.name,
        value: state.id,
      };
    });
  }, [states]);
  // #endregion

  // #region helpers
  const onSubmit = (formValues: LicensedStatesTypeFormFields) => {
    if (formValues.selectedValue.length === 0) {
      setError(getFieldName('selectedValue'), { type: 'manual' });
    } else {
      const formattedValue = formValues.selectedValue.map(x => x.value);

      props.handleSubmit(formattedValue);
    }
  };

  const onCancel = () => {
    props.handleCancel();
  };

  const getDefaultValue = (): NumberOption[] => {
    const splitInitialValues = props.initialValue;
    const defaultValues = licenseOptions.filter(x => splitInitialValues.includes(x.label));
    return defaultValues;
  };
  // #endregion

  return (
    <Grid container direction="column" className={classes.container}>
      <Grid item>
        <form>
          <Controller
            control={control}
            name={getFieldName('selectedValue')}
            rules={{ required: true }}
            defaultValue={getDefaultValue()}
            render={(fieldProps: any) => (
              <>
                <ReactSelect
                  name={getFieldName('selectedValue')}
                  value={fieldProps.value}
                  fields={licenseOptions}
                  menuPosition="relative"
                  handleOnChange={(value: NumberOption) => fieldProps.onChange(value)}
                />
                <Validation touched={errors.selectedValue} error="Required" />
              </>
            )}
          />
        </form>
      </Grid>
      <Grid item>
        <ConfirmationPanel
          handleSubmit={handleSubmit(onSubmit)}
          handleCancel={() => {
            onCancel();
          }}
        />
      </Grid>
    </Grid>
  );
};

export const LicensedStatesFormStyled = withStyles(LicensedStatesTypeFormStyles)(
  LicensedStatesForm,
);
