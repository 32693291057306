/* eslint-disable react/function-component-definition */
import React, { useEffect, useState } from 'react';
import {
  TextField,
  Typography,
  Button,
  Box,
  IconButton,
  Tooltip,
  CircularProgress,
} from '@mui/material';
import { Close, Download, Image } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import mime from 'mime';
import { notifyError } from 'actions/action-notifications';
import { acceptedFileExtensions } from 'services/utils/document-service';
import SelectFileForm from 'components/document/file-forms/select-file-form';
import { AnswerComponent, DocumentAnswerValue, UploadDocumentBody } from '../types';
import { useColors, styles } from './question.styles';
import { readFileAsync } from '../utils';

const getFilename = (value: string, fileExtension: string) => {
  const ext = `.${fileExtension.split('.')[0]}`;
  const basename = value.trim().split(/\.[a-z0-9]+$/i)[0];
  const cleanBasename = basename
    .replace(/(^[^a-z0-9]+|[^a-z0-9]+$)/gim, '')
    .replace(/[^\w\s-()]+/gi, '_') // allow dashes, parens
    .replace(/[\s]+/g, ' ');
  return cleanBasename + ext;
};

export const DocumentAnswer: AnswerComponent = ({
  value,
  answerKey,
  onUploadDocument,
  onRemoveDocument,
  onDownloadDocument,
  readOnly,
}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isLoading, setLoading] = useState(false);
  const [valueState, setValueState] = useState(value as DocumentAnswerValue);
  const colors = useColors();
  const dispatch = useDispatch();
  const setFileNameExt = React.useCallback(() => {
    const ext = selectedFile && mime.getExtension(selectedFile.type);
    const filename = valueState.filename ?? (value as DocumentAnswerValue).filename;
    if (ext) {
      setValueState(state => ({
        ...state,
        filename: filename ? getFilename(filename, ext) : null,
      }));
    }
  }, [selectedFile, valueState, value, setValueState]);
  useEffect(() => setFileNameExt(), [selectedFile]);
  const onFileDrop = (files: File[]) => setSelectedFile(files[0]);
  const onFileDropError = () => {
    dispatch(notifyError('There was a problem uploading the selected file.'));
  };
  const onRemoveFile = async () => {
    if (valueState.isUploaded && valueState.uuid) {
      await onRemoveDocument?.(valueState.uuid);
    }
    setSelectedFile(null);
    setValueState(value as DocumentAnswerValue);
  };
  const onUpload = async () => {
    if (selectedFile === null) return;
    if (valueState.filename === null) return;
    if (!answerKey) return;
    setLoading(true);
    try {
      const dataUri = await readFileAsync(selectedFile);
      const payload: UploadDocumentBody = {
        document: dataUri,
        filename: valueState.filename,
        answerKey,
      };
      if (valueState.note) {
        payload.note = valueState.note;
      }
      await onUploadDocument?.(payload);
      setSelectedFile(null);
    } catch (err: unknown) {
      dispatch(notifyError((err as Error).message));
    }
    setLoading(false);
  };
  const onCancelFile = () => {
    setSelectedFile(null);
    setValueState(value as DocumentAnswerValue);
  };
  const onDownload = () => {
    if (valueState.isUploaded && valueState.uuid) {
      onDownloadDocument?.(valueState.uuid);
    }
  };
  useEffect(() => {
    setValueState(value as DocumentAnswerValue);
  }, [value]);
  if (readOnly && !valueState.isUploaded) {
    return (
      <div>
        <Typography variant="body2">No file selected.</Typography>
      </div>
    );
  }
  if (valueState.isUploaded && valueState.filename) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Tooltip title={readOnly ? 'Download Document' : ''} placement="right">
          <Button
            sx={{
              ...styles.documentUploadedContainer,
              'display': 'flex',
              'flexDirection': 'row',
              'gap': '8px',
              'minWidth': '300px',
              'color': colors.blueDark,
              'textTransform': 'none',
              '& .download-icon': {
                transition: 'opacity 0.2s ease-in-out',
                opacity: 0.2,
              },
              '&:hover': {
                'background': colors.infoBg,
                '& .download-icon': {
                  opacity: 1,
                },
              },
              '&:disabled': {
                color: colors.blueDark,
              },
            }}
            disabled={!readOnly}
            onClick={readOnly ? onDownload : undefined}
            aria-label="Download Document"
          >
            <Image />
            <Typography sx={{ marginRight: 'auto' }}>{valueState.filename}</Typography>
            {readOnly ? <Download className="download-icon" /> : null}
          </Button>
        </Tooltip>
        {readOnly ? null : (
          <Tooltip title="Remove Document" placement="right">
            <IconButton aria-label="Remove Document" onClick={onRemoveFile} size="large">
              <Close />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    );
  }
  if (selectedFile) {
    return (
      <Box
        sx={{
          ...styles.documentUploadedContainer,
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          maxWidth: '580px',
          marginRight: 'auto',
          width: '100%',
          alignItems: 'flex-start',
        }}
      >
        <Typography
          variant="body2"
          fontSize="12px"
          color={colors.labelText}
          component="label"
          sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '8px' }}
        >
          File Name *
          <TextField
            value={valueState.filename ?? ''}
            variant="standard"
            onBlur={setFileNameExt}
            sx={{ minWidth: '100%' }}
            onChange={e => {
              setValueState(state => ({ ...state, filename: e.target.value ?? null }));
            }}
          />
        </Typography>
        <Typography
          variant="body2"
          fontSize="12px"
          color={colors.labelText}
          component="label"
          sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '16px' }}
        >
          Note
          <TextField
            value={valueState.note ?? ''}
            variant="standard"
            sx={{ minWidth: '100%' }}
            onChange={e => {
              setValueState(state => ({ ...state, note: e.target.value ?? null }));
            }}
          />
        </Typography>
        <Box display="flex" justifyContent="flex-end" width="100%" gap="8px">
          <Button onClick={onCancelFile} size="large" color="primary" variant="text">
            Cancel
          </Button>
          <Button
            onClick={onUpload}
            size="large"
            color="primary"
            variant="contained"
            disabled={!valueState.filename || isLoading}
            startIcon={isLoading ? <CircularProgress size="" /> : undefined}
          >
            {isLoading ? (
              <CircularProgress size={24} sx={{ color: colors.blue, padding: '0 20px' }} />
            ) : (
              'Upload'
            )}
          </Button>
        </Box>
      </Box>
    );
  }
  return (
    <div style={{ flex: '1 1 auto', width: '100%', maxWidth: '580px' }}>
      <SelectFileForm
        style={{ marginTop: 0, marginBottom: 0 }}
        handleAcceptedDrop={onFileDrop}
        handleRejectedDrop={onFileDropError}
        dropAreaText="Drag a file or select it from your computer."
        acceptedFileExtensions={acceptedFileExtensions}
      />
    </div>
  );
};
DocumentAnswer.displayName = 'DocumentAnswer';
