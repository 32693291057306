import React from 'react';
import IconWithTooltip from './icon-with-tooltip';

interface Props {
  expiration?: string;
}

const PaymentMethodStatus: React.FC<Props> = ({ expiration }) => {
  if (!expiration) {
    return (
      <IconWithTooltip state="iconInactive" icon="cancel" tooltip="No payment method on file" />
    );
  }

  const [month, year] = expiration.split('/');
  const expirationDate = new Date(Number(year), Number(month) - 1, 1);
  const now = new Date();
  const nextMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1);
  const monthAfterNext = new Date(now.getFullYear(), now.getMonth() + 2, 1);

  if (expirationDate < now) {
    return (
      <IconWithTooltip
        state="notProvided"
        icon="cancel"
        tooltip="Payment method on file but it has expired"
      />
    );
  }
  if (expirationDate >= nextMonth && expirationDate < monthAfterNext) {
    return (
      <IconWithTooltip
        state="yellow"
        icon="info"
        tooltip="Payment method on file but expiring next month"
      />
    );
  }

  return <IconWithTooltip state="provided" tooltip="Payment method on file" />;
};

export default PaymentMethodStatus;
