import {
  Button,
  Grid,
  Typography,
  Switch,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  Divider,
  Popover,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import {
  Call,
  Email,
  Face,
  Sms,
  MailOutline,
  Print,
  Computer,
  Comment,
  Dehaze,
} from '@mui/icons-material';
import { fetchServiceGroups, fetchUsers } from 'actions/action-lookups';
import {
  addNote,
  fetchNotesSidebar,
  togglePinnedNote,
  updateNoteBar,
  filterNotesSidebar,
  fetchArchivedNotes,
  setNotesView,
} from 'actions/action-notes';
import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import { EditClose, MoreNotesIcon, ArchivedNoteIcon } from 'components/icons/icons';
import { ReactComponent as FilterIcon } from 'lib/logos/Filter.svg';
import { ADD_NOTE_FORM, NOTE_PAGE_LIMIT } from 'constants/index';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { bindActionCreators } from 'redux';
import { Field } from 'redux-form';
import { Loader } from 'components/loading-overlay/loading-overlay';
import { translateUsers } from 'services/utils/users-service';
import { parseNote, parseNoteToDb } from 'services/utils/note-service';
import { Notes } from 'constants/GA';
import { logEvent } from 'services/utils/analytics';
import createMemoReduxForm from 'utils/create-memo-redux-form';
import NoteDetail from './note-detail';
import NoteTreeView from './note-tree-view';
import { styles } from './note-styles';
import NoteArea from './NoteArea';

const NoteFilterOptions = {
  ALL: {
    id: 'ALL',
    display: 'All Types',
    icon: <Dehaze />,
  },
  NOTES: {
    id: 'NOTES',
    display: 'Notes',
    icon: <Comment />,
  },
  PHONE: {
    id: 'PHONE',
    display: 'Phone',
    icon: <Call />,
    communication_type_id: 4,
  },
  IN_PERSON: {
    id: 'IN_PERSON',
    display: 'In Person',
    icon: <Face />,
    communication_type_id: 1,
  },
  EMAIL: {
    id: 'EMAIL',
    display: 'Email',
    icon: <Email />,
    communication_type_id: 2,
  },
  SMS: {
    id: 'SMS',
    display: 'SMS',
    icon: <Sms />,
    communication_type_id: 3,
  },
  LETTER: {
    id: 'LETTER',
    display: 'Letter',
    icon: <MailOutline />,
    communication_type_id: 5,
  },
  FAX: {
    id: 'FAX',
    display: 'Fax',
    icon: <Print />,
    communication_type_id: 6,
  },
  EHR: {
    id: 'EHR',
    display: 'EHR',
    icon: <Computer />,
    communication_type_id: 7,
  },
};

class NoteBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showArchived: false,
      toggleFilter: false,
      filterAnchorEl: null,
      filters: [NoteFilterOptions.ALL.id],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleTogglePin = this.handleTogglePin.bind(this);
    this.toggleShowAllNotes = this.toggleShowAllNotes.bind(this);
    this.loadNextPage = this.loadNextPage.bind(this);
    this.handleShowArchived = this.handleShowArchived.bind(this);
    this.handleShowTreeSwitch = this.handleShowTreeSwitch.bind(this);
  }

  componentDidMount() {
    const { userPreferencesShowTreeView, selectedPatientId, tagTypeId, tagResourceId } = this.props;
    const { showArchived } = this.state;
    this.setState({ showTreeView: userPreferencesShowTreeView });

    if (!showArchived) {
      filterNotesSidebar({
        patient_id: selectedPatientId,
        tag_type_id: tagTypeId,
        tag_resource_id: tagResourceId,
        therapy_id: tagTypeId === 2 ? tagResourceId : null,
        showAllNotes: false,
      });
    }
  }

  componentWillUnmount() {
    const { reset } = this.props; // eslint-disable-line
    reset();
  }

  handleFilterToggle = value => {
    const { filters } = this.state;
    const currentIndex = filters.indexOf(value);
    let newChecked = [...filters];

    if (currentIndex === -1) {
      if (value === NoteFilterOptions.ALL.id) {
        newChecked = [NoteFilterOptions.ALL.id];
      } else {
        newChecked.push(value);
        newChecked = newChecked.filter(id => id !== NoteFilterOptions.ALL.id);
      }
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      filters: newChecked,
    });
  };

  toggleFilter(e) {
    const { openFilter } = this.state;
    this.setState({
      openFilter: !openFilter,
      filterAnchorEl: e.currentTarget,
    });
  }

  closeFilter() {
    this.setState({
      openFilter: false,
    });
  }

  handleShowTreeSwitch() {
    const { showTreeView } = this.state;
    const { setNotesView } = this.props; // eslint-disable-line no-shadow
    logEvent(
      Notes.category,
      Notes.actions.switch_notes_view,
      !showTreeView ? Notes.labels.tree_view : Notes.labels.date_view,
    );
    setNotesView(!showTreeView);
    this.setState(prevState => ({
      showTreeView: !prevState.showTreeView,
    }));
  }

  handleShowArchived() {
    const { fetchArchivedNotes, selectedPatientId } = this.props; //eslint-disable-line
    fetchArchivedNotes({
      patient_id: selectedPatientId,
      group_by_tag: true,
      archive: true,
    });
    this.setState(prevState => ({
      showArchived: !prevState.showArchived,
    }));
  }

  loadNextPage() {
    const {
      selectedPatientId,
      noteBar,
      fetchNotesSidebar, // eslint-disable-line
      updateNoteBar, // eslint-disable-line
      filterNotesSidebar, // eslint-disable-line
      existingTasks,
    } = this.props; // eslint-disable-line
    const { tagNoteLoadedNum, allNoteLoadedNum, tagTypeId, tagResourceId, showAllNotes, therapy } =
      noteBar;
    const { showArchived } = this.state;
    updateNoteBar({
      ...noteBar,
      loadingMoreNotes: true,
    });
    if (showAllNotes) {
      fetchNotesSidebar(
        {
          patient_id: selectedPatientId,
          limit: NOTE_PAGE_LIMIT + 1, // return 1 more note to check if loaded all
          offset: allNoteLoadedNum,
        },
        existingTasks,
      ).then(action => {
        if (!showArchived) {
          filterNotesSidebar({
            patient_id: selectedPatientId,
            tag_type_id: tagTypeId,
            tag_resource_id: tagResourceId,
            therapy_id: therapy ? therapy.id : null,
          });
        }
        const noteArr =
          action && action.payload && action.payload.data ? action.payload.data.notes : []; // eslint-disable-line
        if (noteArr.length < NOTE_PAGE_LIMIT + 1) {
          // return notes less than NOTE_PAGE_LIMIT + 1 means we already
          // loaded all notes for this tag, set tagNotesReachEnd to true
          updateNoteBar({
            ...noteBar,
            allNoteLoadedNum: allNoteLoadedNum + noteArr.length,
            allNotesReachEnd: true,
            loadingMoreNotes: false,
          });
        } else {
          updateNoteBar({
            ...noteBar,
            allNoteLoadedNum: allNoteLoadedNum + NOTE_PAGE_LIMIT,
            loadingMoreNotes: false,
          });
        }
      });
    } else {
      fetchNotesSidebar(
        {
          patient_id: selectedPatientId,
          tag_type_id: tagTypeId,
          tag_resource_id: tagResourceId,
          limit: NOTE_PAGE_LIMIT + 1,
          offset: tagNoteLoadedNum,
        },
        existingTasks,
      ).then(action => {
        const noteArr =
          action && action.payload && action.payload.data ? action.payload.data.notes : []; // eslint-disable-line
        if (noteArr.length < NOTE_PAGE_LIMIT + 1) {
          updateNoteBar({
            ...noteBar,
            tagNoteLoadedNum: tagNoteLoadedNum + noteArr.length,
            tagNotesReachEnd: true,
          });
        } else {
          updateNoteBar({
            ...noteBar,
            tagNoteLoadedNum: tagNoteLoadedNum + NOTE_PAGE_LIMIT,
          });
        }
      });
    }
  }

  toggleShowAllNotes() {
    const {
      selectedPatientId,
      fetchNotesSidebar, // eslint-disable-line
      updateNoteBar, // eslint-disable-line
      noteBar,
      filterNotesSidebar, // eslint-disable-line
      existingTasks,
    } = this.props; // eslint-disable-line
    const { allNoteLoadedNum, allNotesLoaded, tagTypeId, tagResourceId, showAllNotes } = noteBar;
    const { showArchived } = this.state;
    if (!allNotesLoaded) {
      updateNoteBar({
        ...noteBar,
        loadingNotes: true,
      });
      fetchNotesSidebar(
        {
          patient_id: selectedPatientId,
          limit: NOTE_PAGE_LIMIT + 1,
        },
        existingTasks,
      ).then(action => {
        const noteArr =
          action && action.payload && action.payload.data ? action.payload.data.notes : []; // eslint-disable-line
        if (noteArr.length < NOTE_PAGE_LIMIT + 1) {
          updateNoteBar({
            ...noteBar,
            showAllNotes: !noteBar.showAllNotes,
            allNoteLoadedNum: allNoteLoadedNum ? allNoteLoadedNum + noteArr.length : noteArr.length,
            loadingNotes: false,
            allNotesLoaded: true,
            allNotesReachEnd: true,
          });
        } else {
          updateNoteBar({
            ...noteBar,
            showAllNotes: !noteBar.showAllNotes,
            allNoteLoadedNum: allNoteLoadedNum
              ? allNoteLoadedNum + NOTE_PAGE_LIMIT
              : NOTE_PAGE_LIMIT,
            loadingNotes: false,
            allNotesLoaded: true,
          });
        }

        if (!showArchived) {
          filterNotesSidebar({
            patient_id: selectedPatientId,
            tag_type_id: tagTypeId,
            tag_resource_id: tagResourceId,
            therapy_id: tagTypeId === 2 ? tagResourceId : null,
            showAllNotes: !showAllNotes,
          });
        }
      });
    } else {
      updateNoteBar({
        ...noteBar,
        showAllNotes: !noteBar.showAllNotes,
      });

      if (!showArchived) {
        filterNotesSidebar({
          patient_id: selectedPatientId,
          tag_type_id: tagTypeId,
          tag_resource_id: tagResourceId,
          therapy_id: tagTypeId === 2 ? tagResourceId : null,
          showAllNotes: !showAllNotes,
        });
      }
    }
  }

  handleSubmit(values) {
    const newValues = parseNoteToDb(values);
    const {
      addNote, // eslint-disable-line
      selectedPatientId,
      noteBar,
      reset, // eslint-disable-line
      updateNoteBar, // eslint-disable-line
    } = this.props;
    const { tagTypeId, tagResourceId, tagNoteLoadedNum, allNoteLoadedNum, showAllNotes } = noteBar;
    const tags = [{ tag_type_id: tagTypeId, resource_id: tagResourceId }];
    const payload = {
      patient_id: selectedPatientId,
      note_text: newValues.note_text,
      is_pinned: 0,
      mentions: newValues.mentions,
      tags,
    };
    addNote(payload).then(() => {
      if (showAllNotes) {
        updateNoteBar({
          ...noteBar,
          allNoteLoadedNum: allNoteLoadedNum ? allNoteLoadedNum + 1 : 1,
        });
      } else {
        updateNoteBar({
          ...noteBar,
          tagNoteLoadedNum: tagNoteLoadedNum ? tagNoteLoadedNum + 1 : 1,
        });
      }
    });
    reset();
  }

  handleCancel() {
    const { reset } = this.props; // eslint-disable-line
    reset();
  }

  handleTogglePin(note) {
    const { togglePinnedNote } = this.props; // eslint-disable-line
    togglePinnedNote(note);
  }

  filterNotes(notes) {
    const { filters } = this.state;
    return notes.filter(note => {
      let show = false;
      filters.forEach(filter => {
        if (filter === NoteFilterOptions.ALL.id) {
          show = true;
        } else if (filter === NoteFilterOptions.NOTES.id) {
          if (note.communication === undefined) {
            show = true;
          }
        } else if (
          note.communication &&
          note.communication.type_id === NoteFilterOptions[filter].communication_type_id
        ) {
          show = true;
        }
      });
      return show;
    });
  }

  renderWrapper(children) {
    const { classes } = this.props;
    return (
      <div className={classes.noteWrapper} data-qa-id="notes-panel">
        {children}
      </div>
    );
  }

  render() {
    const {
      closeHandler,
      classes,
      handleSubmit,
      pristine,
      notes,
      users,
      serviceGroups,
      noteBar,
      archivedNotes,
      allNotes,
    } = this.props;

    const {
      tag,
      loadingNotes,
      loadingMoreNotes,
      showAllNotes,
      tagNotesReachEnd,
      allNotesReachEnd,
    } = noteBar;

    const { showArchived, showTreeView, openFilter, filterAnchorEl, filters } = this.state;

    const userData = users && users.length > 0 && translateUsers(users);

    const tagStr = `#${tag}`;
    // eslint-disable-next-line
    const displayMoreBtn =
      (!showAllNotes && !tagNotesReachEnd) || (showAllNotes && !allNotesReachEnd);

    return this.renderWrapper(
      <>
        <Grid container className={classes.noteBarTop}>
          <Grid item xs={2}>
            <Typography className={classes.noteTitle} data-qa-id="notes-panel-title">
              Notes
            </Typography>
          </Grid>
          <Grid item xs={2} />
          <Grid item xs={8}>
            {!showTreeView && (
              <Grid container alignItems="center" justifyContent="flex-end">
                <Button
                  className={classes.noteBtn}
                  variant="outlined"
                  onClick={this.toggleShowAllNotes}
                >
                  <Typography className={classes.allNotesButtonText}>
                    {showAllNotes ? tagStr : 'All Notes'}
                  </Typography>
                </Button>
                <Button
                  id="filterPanelButton"
                  name="filter_panel_button"
                  variant="outlined"
                  size="small"
                  onClick={e => this.toggleFilter(e)}
                  className={classes.noteBtn}
                >
                  <FilterIcon />
                </Button>
                <Button id="note_close" className={classes.closeBtn} onClick={closeHandler}>
                  <EditClose />
                </Button>
              </Grid>
            )}
          </Grid>
          <Divider />
          {openFilter && (
            <List className={classes.noteFilterContainer}>
              {Object.values(NoteFilterOptions).map(option => {
                const { id, display, icon } = option;
                const labelId = `note-filter-list-label-${id}`;
                return (
                  <ListItem key={id} dense button onClick={() => this.handleFilterToggle(id)}>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={filters.indexOf(id) !== -1}
                        tabIndex={-1}
                        color="primary"
                        disableRipple
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                      <div className={classes.noteFilterIcon}>{icon}</div>
                    </ListItemIcon>
                    <ListItemText id={labelId} primary={display} />
                  </ListItem>
                );
              })}
            </List>
          )}
          <Grid item xs={12}>
            <form onSubmit={handleSubmit(this.handleSubmit)} autoComplete="off">
              <Grid item xs={12} className={classes.noteFieldContainer}>
                <Typography
                  className={classes.tag}
                  component="span"
                  data-qa-id="notes-panel-subtitle"
                >
                  {tagStr}
                </Typography>
                <Field name="note_text" data={userData} component={NoteArea} />
              </Grid>
              {!pristine && (
                <Grid item xs={12} className={classes.confirmationPanelContainer}>
                  <ConfirmationPanel handleCancel={this.handleCancel} disableSubmit={pristine} />
                </Grid>
              )}
            </form>
          </Grid>
          <Divider />
          <Grid item xs={12}>
            <Grid
              container
              alignItems="center"
              justifyContent="flex-end"
              className={classes.treeSwitchContainer}
            >
              <Typography className={classes.tag} component="span">
                Tree
              </Typography>
              <Switch
                checked={!showTreeView}
                onChange={this.handleShowTreeSwitch}
                value="showTree"
                color="primary"
              />
              <Typography className={classes.tag} component="span">
                Date
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <div className={classes.noteBar}>
          {!!showArchived && (
            <Grid item xs={12} className={classes.noteDisplay}>
              <Grid container alignItems="center">
                <ArchivedNoteIcon inlineArchivedTop />
                <Typography
                  variant="caption"
                  component="span"
                  className={classes.noteArchivedTextTop}
                >
                  Archived Notes
                </Typography>
              </Grid>
            </Grid>
          )}
          <div className={classes.loadContainer}>
            <Loader loaded={users.length > 0 && serviceGroups.length > 0 && !loadingNotes}>
              {!showTreeView ? (
                <div>
                  {showAllNotes ? (
                    <div>
                      {showArchived ? (
                        <div>
                          {archivedNotes.map(note => {
                            if (note.is_archived) {
                              return (
                                <div>
                                  <NoteDetail
                                    key={note.id}
                                    toggle={this.handleTogglePin}
                                    note={parseNote(note, users, serviceGroups)}
                                    classes={classes}
                                    isAllNotes
                                  />
                                </div>
                              );
                            }
                            return null;
                          })}
                        </div>
                      ) : (
                        <div>
                          {this.filterNotes(allNotes).map(note => {
                            if (!note.is_archived) {
                              return (
                                <div>
                                  <NoteDetail
                                    key={note.id}
                                    toggle={this.handleTogglePin}
                                    note={parseNote(note, users, serviceGroups)}
                                    classes={classes}
                                    isAllNotes
                                  />
                                </div>
                              );
                            }
                            return null;
                          })}
                        </div>
                      )}
                    </div>
                  ) : (
                    <div>
                      {showAllNotes ? (
                        <div>
                          {showArchived ? (
                            <div>
                              {archivedNotes.map(note => (
                                <div>
                                  <NoteDetail
                                    key={note.id}
                                    toggle={this.handleTogglePin}
                                    note={parseNote(note, users, serviceGroups)}
                                    classes={classes}
                                  />
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div>
                              {notes.map(note => (
                                <div>
                                  <NoteDetail
                                    key={note.id}
                                    toggle={this.handleTogglePin}
                                    note={parseNote(note, users, serviceGroups)}
                                    classes={classes}
                                  />
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      ) : (
                        <div>
                          {!showArchived ? (
                            <div>
                              {this.filterNotes(notes).map(note => {
                                if (!note.is_archived) {
                                  return (
                                    <div key={note.id}>
                                      <NoteDetail
                                        key={note.id}
                                        toggle={this.handleTogglePin}
                                        note={parseNote(note, users, serviceGroups)}
                                        classes={classes}
                                      />
                                    </div>
                                  );
                                }
                                return null;
                              })}
                            </div>
                          ) : (
                            <div>
                              {archivedNotes.map(note => {
                                if (note.is_archived) {
                                  return (
                                    <div key={note.id}>
                                      <NoteDetail
                                        key={note.id}
                                        toggle={this.handleTogglePin}
                                        note={parseNote(note, users, serviceGroups)}
                                        classes={classes}
                                      />
                                    </div>
                                  );
                                }
                                return null;
                              })}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  {showArchived ? (
                    <div>
                      {archivedNotes.map(note => {
                        if (note.is_archived) {
                          return (
                            <div>
                              <NoteDetail
                                key={note.id}
                                toggle={this.handleTogglePin}
                                note={parseNote(note, users, serviceGroups)}
                                classes={classes}
                                isAllNotes
                              />
                            </div>
                          );
                        }
                        return null;
                      })}
                    </div>
                  ) : (
                    <NoteTreeView />
                  )}
                </div>
              )}

              {displayMoreBtn && (
                <div className={classes.loadMoreContainer}>
                  <Loader
                    loaded={users.length > 0 && serviceGroups.length > 0 && !loadingMoreNotes}
                  >
                    <Grid
                      item
                      xs={12}
                      onClick={this.loadNextPage}
                      className={classes.moreNotesBtnContainer}
                    >
                      <Typography component="span">More Notes</Typography>
                      <MoreNotesIcon />
                    </Grid>
                  </Loader>
                </div>
              )}
              <Grid
                item
                xs={12}
                onClick={this.loadNextPage}
                className={classes.archivedNoteButtonContainer}
              >
                <Grid container alignItems="center" justifyContent="flex-end">
                  <Button
                    className={classes.noteArchiveBtn}
                    variant="outlined"
                    onClick={this.handleShowArchived}
                  >
                    {!showArchived && <ArchivedNoteIcon />}
                    <Typography className={classes.noteArchivedText}>
                      {!showArchived ? 'Show Archived Notes' : 'Show Notes'}
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </Loader>
          </div>
        </div>
      </>,
    );
  }
}

function mapStateToProps(state) {
  const { notes, noteBar, patient, selectedPatientId } = state;

  const existingTasks = Object.values(state.tasks?.data || {});

  return {
    patient,
    selectedPatientId,
    notes: notes && notes.notes ? notes.notes.notesToDisplay : [],
    allNotes:
      notes && notes.notes ? notes.notes.allPinnedNotes.concat(notes.notes.allNonPinnedNotes) : [],
    archivedNotes: notes && notes.notes ? notes.notes.archivedNotes : [],
    noteBar,
    users: state.lookups.users,
    serviceGroups: state.lookups.serviceGroups,
    userPreferencesShowTreeView: state.userPreferences.show_tree_view,
    existingTasks,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchNotesSidebar,
      filterNotesSidebar,
      fetchUsers,
      addNote,
      fetchServiceGroups,
      togglePinnedNote,
      updateNoteBar,
      fetchArchivedNotes,
      setNotesView,
    },
    dispatch,
  );
}

NoteBar.displayName = 'NoteBar';

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
  createMemoReduxForm({ form: ADD_NOTE_FORM }),
)(NoteBar);
