import React, { useEffect } from 'react';
import { Button, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  benefitsInvestigationSubmissionFormActions,
  loadDrugPricing,
} from 'slices/benefits-investigation-modal-slice';
import { formValueSelector } from 'redux-form';
import { useStyles } from './styles';
import { ITherapy } from '../../../../interfaces/redux/ITherapy';
import { ADD_PATIENT_THERAPY_FORM } from '../../../../constants';
import { benefitsInvestigationDetailedHistoryActions } from '../../../../slices/benefits-investigation-detailed-history-slice';
import { EditHistory } from '../../../../components/icons/icons';
import { useTypedSelector } from '../../../../hooks/use-typed-selector';
import { fetchPatientBi } from '../../../../actions/action-patient';

interface IProps {
  therapy: ITherapy;
  ndc: string;
  physician: any;
  disabled?: boolean;
  daysSupply: number;
  needsByDate: string;
  drug: any;
  arTaskId: number;
}

export const RunBiFromAR: React.FC<IProps> = (props: IProps): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const state = useTypedSelector(state => state);
  const formId = props.drug
    ? `${ADD_PATIENT_THERAPY_FORM}-${props.drug.id}`
    : ADD_PATIENT_THERAPY_FORM;

  useEffect(() => {
    if (!state.selectedPatientId) {
      return;
    }
    dispatch(fetchPatientBi(state.selectedPatientId));
  }, [state.selectedPatientId]);

  const selector = formValueSelector(formId);
  const { arTaskId } = props;

  const therapy = {
    ...props.therapy,
    prescribing_physician_name: props.physician?.name,
    prescribing_physician_npi: props.physician?.npi,
    needsby_date: selector(state, 'needsby_date'),
    days_supply: selector(state, 'days_supply'),
    dispensing_pharmacy_name: selector(state, 'dispensing_pharmacy_name'),
  };

  return (
    <>
      <Typography variant="caption">
        <Button
          className={classes.historyButton}
          onClick={() => {
            dispatch(benefitsInvestigationDetailedHistoryActions.openFromAr(arTaskId));
          }}
        >
          <EditHistory />
        </Button>
      </Typography>
      <Button
        className={classes.button}
        onClick={() => {
          dispatch(loadDrugPricing(props.ndc));
          dispatch(
            benefitsInvestigationSubmissionFormActions.openFromArForm({ therapy, arTaskId }),
          );
        }}
        disabled={props.disabled}
      >
        Run Benefit Investigation
      </Button>
    </>
  );
};
