import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import React from 'react';
import Validation from 'components/form/validation/validation';
import { Controller, useForm } from 'react-hook-form';
import { Grid } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { nameOfFactory } from 'utils/types-util';
import { ReactSelect } from 'components/form/field/react-select';
import { GTD_SPECIALITY_TYPES } from '../../../constants/index';
import { IFormFields } from './types';
import { IOption } from '../../../interfaces/forms/IOption';
import { SpecialityTypeFormStyles } from './speciality-type-form.styles';

type StringOption = IOption<string>;
type SpecialityTypeFormFields = IFormFields<StringOption[]>;

interface ISpecialityTypeFormProps extends WithStyles<typeof SpecialityTypeFormStyles> {
  initialValue: string;
  handleCancel: () => void;
  handleSubmit: (value: string) => void;
}

const options = GTD_SPECIALITY_TYPES.map(x => {
  return { label: x.label, value: x.value };
});

const getFieldName = nameOfFactory<SpecialityTypeFormFields>();

const SpecialityTypeform: React.FC<ISpecialityTypeFormProps> = (
  props: ISpecialityTypeFormProps,
): JSX.Element => {
  const { classes } = props;

  // #region component state
  const {
    handleSubmit,
    control,
    setError,

    formState: { errors },
  } = useForm<any>();
  // #endregion

  // #region helpers
  const onSubmit = (formValues: SpecialityTypeFormFields) => {
    if (formValues.selectedValue.length === 0) {
      setError(getFieldName('selectedValue'), { type: 'manual' });
    } else {
      const formattedValue = formValues.selectedValue.map(x => x.value).join(',');
      props.handleSubmit(formattedValue);
    }
  };

  const onCancel = () => {
    props.handleCancel();
  };

  const getDefaultValue = (): StringOption[] => {
    const splitInitiailValues = props.initialValue.split(',');
    const defaultValues = options.filter(x => splitInitiailValues.includes(x.value));
    return defaultValues;
  };
  // #endregion

  return (
    <Grid container direction="column" className={classes.container}>
      <Grid item>
        <form>
          <Controller
            control={control}
            name={getFieldName('selectedValue')}
            rules={{ required: true }}
            defaultValue={getDefaultValue()}
            render={(fieldProps: any) => (
              <>
                <ReactSelect
                  name={getFieldName('selectedValue')}
                  value={fieldProps.value}
                  fields={options}
                  menuPosition="relative"
                  handleOnChange={(value: StringOption) => fieldProps.onChange(value)}
                />
                <Validation touched={errors.selectedValue} error="Required" />
              </>
            )}
          />
        </form>
      </Grid>
      <Grid item>
        <ConfirmationPanel
          handleSubmit={handleSubmit(onSubmit)}
          handleCancel={() => {
            onCancel();
          }}
        />
      </Grid>
    </Grid>
  );
};

export const SpecialityTypeFormStyled = withStyles(SpecialityTypeFormStyles)(SpecialityTypeform);
