import { PropsWithChildren } from 'react';
import {
  MedicationListColumns,
  IMedicationRowWithCustomDose,
} from 'containers/patient/clinical/medication-list/gpi-medication-group/types';

export type ComponentWithChildren = (props: PropsWithChildren<any>) => JSX.Element;

export interface ICustomComponents {
  headerCell?: ComponentWithChildren;
  parentRowCell?: ComponentWithChildren;
  childRowCell?: ComponentWithChildren;
  parentAction?: ComponentWithChildren;
  childAction?: ComponentWithChildren;
}

export enum TriStateSortOrder {
  Asc = 'asc',
  Desc = 'desc',
  DispenseStatus = 'dispense_status',
}

export enum CellAlign {
  Left = 'left',
  Center = 'center',
  Right = 'right',
}

export interface IColumn {
  columnName: MedicationListColumns;
  displayName?: string;
  sortable?: boolean;
  customComponents?: Omit<ICustomComponents, 'parentAction' | 'childAction'>;
}

export interface IRow {
  identifier: number;
  rowData: IMedicationRowWithCustomDose;
}

export interface IGroup {
  parent: IRow;
  children: IRow[];
}

export interface IProps {
  columns: IColumn[];
  groups: IGroup[];
  customComponents?: ICustomComponents;
  defaultSortedBy?: MedicationListColumns;
  defaultSortOrder?: TriStateSortOrder;
  size?: 'small' | 'medium';
  sortHandler?: (column: MedicationListColumns, sortOrder: TriStateSortOrder) => void;
  expandHandler?: (group: IGroup | null, expanded: boolean) => void;
  hideChildren: boolean;
}
