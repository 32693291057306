/* eslint-disable react/destructuring-assignment */
import React from 'react';
import cx from 'classnames';
import withStyles from '@mui/styles/withStyles';
import { styles } from './display-styles';

const DetailField = props => {
  const { labelType, classes, labelName, noMaxWidth, charmBack, charmFront, ...restProps } = props;
  let styleDef = null;
  let maxWidthStyle = null;
  switch (labelType) {
    case 'overdue':
      styleDef = classes.overdueLabel;
      break;
    case 'clinic':
      styleDef = classes.clinicLabel;
      break;
    case 'complete':
      styleDef = classes.completeLabel;
      break;
    case 'incomplete':
      styleDef = classes.incompleteLabel;
      break;
    case 'pinned':
      styleDef = classes.pinnedLabel;
      break;
    case 'taskSidebarDescriptor':
      styleDef = classes.taskSidebarDescriptorLabel;
      break;
    case 'warning':
      styleDef = classes.warningLabel;
      break;
    case 'warning-pointer':
      styleDef = classes.warningLabelPointer;
      break;
    case 'special-populations':
      styleDef = classes.specialPopulations;
      break;
    case 'phone':
      styleDef = classes.phoneLabel;
      break;
    case 'disabled-phone':
      styleDef = classes.disabledPhoneLabel;
      break;
    case 'urgent':
      styleDef = classes.urgent;
      break;
    default:
      styleDef = classes.onTimeLabel;
      break;
  }
  if (noMaxWidth) {
    maxWidthStyle = classes.useMaxWidth;
  }
  return (
    <div {...restProps} className={cx(styleDef, props.className, maxWidthStyle)}>
      {charmFront || null}
      {labelName}
      {charmBack || null}
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(DetailField);
