/* eslint-disable react/destructuring-assignment */
import {
  Button,
  Grid,
  Modal,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import cx from 'classnames';
import { EditHistory, EditPencil, CheckmarkIcon } from 'components/icons/icons';
import React, { Component } from 'react';
import Anchor from 'components/anchor/anchor';
import { getExpanded, getChecked } from 'services/utils/task-service';
import History from 'containers/common/history';
import compose from 'recompose/compose';
import { CSL, TASK_TITLE_COLORS, PA, FA, INT, MR } from 'constants/index';
import { getUserById } from 'services/utils/users-service';
import { connect } from 'react-redux';
import { getModalStyle } from 'services/utils/styles-service';
import { convertToArborDate } from 'models/time/arbor-date';
import { getActionedByUserText } from 'utils/user-utils';
import { TaskStatusFactory } from 'factories/task-status-factory/task-status-factory';
import { styles } from './task-detail-styles';
import TaskProperties from './task-properties';
import { CounselingStatus } from '../../interfaces/enums/TaskStatuses/CounselingStatus';
import { fetchPaymentMethodsState } from '../../slices/patient-payment-methods-slice';

class TaskContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayEdit: false,
      editHistoryModalOpen: false,
      expanded: getExpanded(props.task.status_id, props.statuses),
      fillCycles: [
        {
          id: 0,
          fill_cycle: props.task.fill_cycle_number,
          needsby_date: props.task.needsby_date,
        },
      ],
    };
    this.handleEdit = this.handleEdit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleEditHistoryModal = this.handleEditHistoryModal.bind(this);
    this.toggleExpansionPanel = this.toggleExpansionPanel.bind(this);
  }

  componentDidMount() {
    const { forceExpand } = this.props;
    if (forceExpand === true) {
      this.setState(() => ({
        expanded: true,
      }));
    }
  }

  handleEdit() {
    this.setState(({ displayEdit }) => {
      return {
        displayEdit: !displayEdit,
        expanded: true,
      };
    });
    const paymentMethodIds = this.props.task.payment_method_ids ?? [];
    const { paymentMethods } = this.props;
    const paymentMethodsNeeded = paymentMethods
      ? paymentMethodIds.filter(id => typeof paymentMethods[id] === 'undefined')
      : [];
    if (paymentMethodsNeeded.length) {
      this.props.fetchPaymentMethods({
        patientId: this.props.patient.id,
        paymentMethodIds: paymentMethodsNeeded,
      });
    }
  }

  handleEditHistoryModal(e) {
    e.stopPropagation();
    this.setState(prevState => ({
      editHistoryModalOpen: !prevState.editHistoryModalOpen,
    }));
  }

  toggleExpansionPanel() {
    const { editHistoryModalOpen } = this.state;
    if (!editHistoryModalOpen) {
      this.setState(prevState => ({
        expanded: !prevState.expanded,
      }));
    }
  }

  handleCancel() {
    if (
      this.props.task.taskType === CSL &&
      this.props.task.status_id === CounselingStatus.Completed
    ) {
      this.toggleExpansionPanel();
    }
    this.setState({
      displayEdit: false,
    });
  }

  render() {
    const {
      children,
      theme,
      statuses,
      task,
      classes,
      highlighted,
      users,
      taskCounts,
      archived,
      therapyTaskid,
      therapy,
      tprHubs,
      allowEdition = true,
      fillCycles,
    } = this.props;

    if (!children && !this.props.renderChildren) {
      return null;
    }

    const { editHistoryModalOpen, expanded, displayEdit } = this.state;

    const { updated, updated_by: updatedBy, id, order } = task;

    const updatedUser = users && users.length > 0 && getUserById(updatedBy, users);

    const getHistoryURL = faId => `/tasks/${task.taskType}/${faId}`;

    const className = cx(classes.taskBlock, {
      [classes.highlighted]: highlighted,
      [classes.newTask]: order === -1,
    });
    const checked = getChecked(task.status_id, statuses);

    const overdue = convertToArborDate(task.followup_dt, true).isBeforeToday();

    let taskTitle = `${taskCounts[task.taskType].task_description}`;
    if ((task.taskType === PA || task.taskType === FA) && task.is_renewal) {
      taskTitle = `${taskTitle} - Renewal`;
    }

    if (task.taskType === INT && !!task.interacting_drugs) {
      taskTitle = `${taskTitle} - ${task.interacting_drugs}`;
    }
    taskTitle = `${taskTitle} ${therapy ? `(${therapy.drug_name})` : ''}`;

    const titleColor = (() => {
      const { SUCCESS, DANGER, INFO } = TASK_TITLE_COLORS;

      if (!archived) {
        if (checked) {
          return SUCCESS;
        }
        if (overdue) {
          return DANGER;
        }
      }
      return INFO;
    })();

    const taskStatusProcessor = TaskStatusFactory.getProcessor(task.taskType);

    return (
      <Accordion
        square
        elevation={0}
        expanded={!!expanded}
        onChange={this.toggleExpansionPanel}
        className={className}
        classes={{
          root: classes.expansionPanelRoot,
          expanded: classes.expanded,
        }}
        TransitionProps={
          task.taskType === CSL && taskStatusProcessor.taskShouldRenderCustomForm(task)
            ? { unmountOnExit: true, mountOnEnter: true }
            : {}
        }
      >
        <AccordionSummary
          className={archived ? classes.expansionPanelSummaryArchived : null}
          classes={{
            content: classes.expansionPanelSummaryContent,
          }}
        >
          <Grid container alignItems="center" className={classes.expansionPanelSummaryContentInner}>
            <div>
              <Anchor id={`${task.taskType}_${id}`}>
                <Grid container>
                  {checked && (
                    <Grid item className={classes.iconTitle}>
                      <CheckmarkIcon
                        color={
                          archived ? theme.palette.primary.formLabel : theme.palette.primary.success
                        }
                      />
                    </Grid>
                  )}
                  <Typography
                    className={cx(
                      therapy ? classes.titleOriginalCase : classes.title,
                      classes[`title${titleColor}`],
                    )}
                    variant="subtitle1"
                    id={`${therapyTaskid}_taskDescription`}
                  >
                    {taskTitle}
                  </Typography>
                </Grid>
              </Anchor>
            </div>
            <div className={classes.taskPropertiesContainer}>
              <TaskProperties task={task} tprHubs={tprHubs} />
            </div>
            <div>
              <Grid
                container
                justifyContent="flex-end"
                alignItems="center"
                onClick={e => e.stopPropagation()}
              >
                <Typography variant="caption" className={classes.taskTitleDetailsUpdated}>
                  {getActionedByUserText({ date: updated, user: updatedUser, action: 'Updated' })}
                </Typography>
                <Button
                  className={classes.button}
                  onClick={this.handleEditHistoryModal}
                  data-qa-id="button-lastModifiedTask-table"
                >
                  <EditHistory />
                </Button>
                <Modal open={editHistoryModalOpen} onClose={this.handleEditHistoryModal}>
                  <div style={getModalStyle()} className={classes.Modal}>
                    <History url={getHistoryURL(task.id)} />
                  </div>
                </Modal>
                {allowEdition &&
                  taskStatusProcessor &&
                  taskStatusProcessor.taskIsEditable(task) &&
                  task.taskType !== MR && (
                    <Button
                      className={classes.button}
                      onClick={this.handleEdit}
                      data-qa-id="button-editTask"
                      id={`${therapyTaskid}_editTask`}
                    >
                      <EditPencil />
                    </Button>
                  )}
              </Grid>
            </div>
          </Grid>
        </AccordionSummary>
        <AccordionDetails className={classes.expansionPanelDetails}>
          {children ??
            this.props.renderChildren({
              displayEdit,
              handleCancel: this.handleCancel,
              fillCycles,
            })}
        </AccordionDetails>
      </Accordion>
    );
  }
}

function mapStateToProps(state) {
  const { lookups, taskCounts, patient, fillCycles, paymentMethods } = state;
  return {
    users: lookups.users,
    tprHubs: lookups.tprHubs,
    taskCounts: taskCounts.data,
    fillCycles,
    patient,
    paymentMethods,
  };
}

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, dispatch => ({
    fetchPaymentMethods: args => dispatch(fetchPaymentMethodsState(args)),
  })),
)(TaskContainer);
