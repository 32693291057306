import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import { Grid, Typography, Backdrop, Modal } from '@mui/material';
import { InfoIcon } from 'components/icons/icons';
import compose from 'recompose/compose';
import { bindActionCreators } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { renderDatePicker } from 'components/form/datepicker/datetime-picker';
import { ADD_INCOME_FORM } from 'constants/index';
import { addIncome, fetchExistingIncomeYears, fetchIncomes } from 'actions/action-financials';
import {
  validateDate,
  required,
  validateNumber,
  validateYear,
  validateDollarValue,
} from 'components/form/validation/validation';
import { getModalStyle } from 'services/utils/styles-service';
import { totalSumIncome } from 'services/utils/financials-service';
import { convertToArborDate } from 'models/time/arbor-date';
import { stripFormattedDollarValue } from 'services/utils/formatting';
import ConfirmationPanel from 'components/form/confirmation/confirmation-panel';
import SubHeader from 'components/form/header/subheader';
import Divider from 'components/divider';
import { SmallSpacer } from 'components/spacer/spacer';
import { styles } from '../financials-styles';
import { validateUniqueIncomeYear } from '../../../../components/form/validation/validation';
import {
  renderTextField,
  renderYesNoDropdown,
  renderMoneyTextField,
  renderDropdown,
  renderCheckboxHorizontal,
} from '../../../../components/form/field/redux-field';
import { FederalPovertyTable } from './federal-poverty-table';

class AddIncome extends Component {
  constructor(props) {
    super(props);

    this.state = {
      noIncome: false,
      povertyModalOpen: false,
      incomes: {},
    };

    this.setNoIncome = this.setNoIncome.bind(this);
    this.handlePovertyModal = this.handlePovertyModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { fetchExistingIncomeYears, patientId } = this.props; // eslint-disable-line
    fetchExistingIncomeYears(patientId);
    this.setYearsValidator();
  }

  componentDidUpdate(prevState) {
    const { incomeYears } = this.props;
    if (incomeYears && incomeYears !== prevState.incomeYears) {
      this.setYearsValidator();
    }
  }

  setYearsValidator() {
    const { incomeYears } = this.props;
    if (incomeYears) {
      this.validateUniqueIncomeYear = validateUniqueIncomeYear(
        Object.keys(incomeYears).map(key => incomeYears[key].income_year),
      );
    }
  }

  setNoIncome() {
    this.setState(prevState => ({
      noIncome: !prevState.noIncome,
    }));
  }

  handlePovertyModal() {
    this.setState(prevState => ({
      povertyModalOpen: !prevState.povertyModalOpen,
    }));
  }

  updateTotalSumIncome(input) {
    const { change } = this.props;
    this.setState(prevState => {
      const newIncomes = { ...prevState.incomes };
      const result = totalSumIncome(newIncomes, input);
      change('total_sum_income', result.sumIncome);
      return {
        incomes: result.incomes,
      };
    });
  }

  handleSubmit(values) {
    const {
      addIncome, // eslint-disable-line
      fetchIncomes, // eslint-disable-line
      cancel,
      patientId,
      verifyOnSubmit,
    } = this.props;

    const payload = {
      ...values,
      patient_id: patientId || values.patient_id,
      pension_401k_retirement_income: values.pension_401k_retirement_income
        ? stripFormattedDollarValue(values.pension_401k_retirement_income)
        : null,
      taxable_income: values.taxable_income ? stripFormattedDollarValue(values.taxable_income) : 0,
      is_no_income: values.is_no_income,
      gross_business_income: values.gross_business_income,
      transcript_tax_form_completed: values.transcript_tax_form_completed,
      extension_filed: values.extension_filed,
      unemployment_income: values.unemployment_income
        ? stripFormattedDollarValue(values.unemployment_income)
        : null,
      unemployment_income_period_id: values.unemployment_income_period_id
        ? values.unemployment_income_period_id
        : null,
      unemployment_start_date: values.unemployment_start_date
        ? convertToArborDate(values.unemployment_start_date).getUtcDate()
        : null,
      unemployment_end_date: values.unemployment_end_date
        ? convertToArborDate(values.unemployment_end_date).getUtcDate()
        : null,
      patient_ssi: values.patient_ssi ? stripFormattedDollarValue(values.patient_ssi) : null,
      patient_ssi_income_period_id: values.patient_ssi_income_period_id
        ? values.patient_ssi_income_period_id
        : null,
      spouse_ssi: values.spouse_ssi ? stripFormattedDollarValue(values.spouse_ssi) : null,
      spouse_ssi_income_period_id: values.spouse_ssi_income_period_id
        ? values.spouse_ssi_income_period_id
        : null,
      dependent_ssi: values.dependent_ssi ? stripFormattedDollarValue(values.dependent_ssi) : null,
      dependent_ssi_income_period_id: values.dependent_ssi_income_period_id
        ? values.dependent_ssi_income_period_id
        : null,
      longterm_disability_income: values.longterm_disability_income
        ? stripFormattedDollarValue(values.longterm_disability_income)
        : null,
      longterm_disability_income_period_id: values.longterm_disability_income_period_id
        ? values.longterm_disability_income_period_id
        : null,
      longterm_disability_company: values.longterm_disability_company
        ? values.longterm_disability_company
        : null,
      retirement_income: values.retirement_income
        ? stripFormattedDollarValue(values.retirement_income)
        : null,
      retirement_income_period_id: values.retirement_income_period_id
        ? values.retirement_income_period_id
        : null,
      family_size: values.family_size,
      number_of_dependents: values.number_of_dependents,
      has_income: values.has_income ? 1 : 0,
      income_year: values.income_year,
      is_verified: verifyOnSubmit ? 1 : 0, // Assume verified if entered in UI
    };

    addIncome(payload).then(() => {
      fetchIncomes(patientId);
    });

    cancel();
  }

  render() {
    const { handleSubmit, pristine, submitting, classes, incomeTimePeriods, cancel, theme } =
      this.props;

    let mappedIncomeTimePeriods = [];
    if (incomeTimePeriods && incomeTimePeriods.length > 0) {
      mappedIncomeTimePeriods = incomeTimePeriods.map(incomeTimePeriod => ({
        ...incomeTimePeriod,
        value: incomeTimePeriod.id,
        label: incomeTimePeriod.time_period,
      }));
    }

    const { noIncome, povertyModalOpen } = this.state;

    return (
      <Grid
        container
        justifyContent="center"
        spacing={7}
        className={(classes.formRowContainer, classes.formContainer)}
      >
        <Grid item xs={12}>
          <form
            onSubmit={handleSubmit(this.handleSubmit)}
            className={classes.addFormContainer}
            autoComplete="off"
          >
            <Grid container rowSpacing={3}>
              <Grid item xs={12} className={classes.addFormHeader}>
                <Typography variant="h6">New Income</Typography>
              </Grid>
              <Grid item xs={3} className={classes.addFormHeader}>
                <Field
                  name="income_year"
                  label="Income Year *"
                  component={renderTextField}
                  validate={[required, validateNumber, validateYear, this.validateUniqueIncomeYear]}
                  id="incomeYearField"
                />
              </Grid>
              <Grid item xs={6} className={classes.addFormHeader}>
                <Field
                  name="is_no_income"
                  label="No Income"
                  component={renderCheckboxHorizontal}
                  id="noIncomeField"
                  onChange={this.setNoIncome}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} paddingBottom={1} className={classes.noIncomeBackdropWrapper}>
              <Grid container alignItems="flex-end">
                <Backdrop open={noIncome} className={classes.noIncomeBackdrop} />
                <Grid item xs={3}>
                  <Field
                    name="taxable_income"
                    label="Total Income From Wages *"
                    component={renderMoneyTextField}
                    validate={noIncome ? [validateDollarValue] : [required, validateDollarValue]}
                    id="taxableIncomeField"
                    onChange={e => this.updateTotalSumIncome(e.target)}
                  />
                </Grid>
                <Grid item xs={3} className={classes.formDropdown}>
                  <Field
                    name="gross_business_income"
                    label="Gross Business Income (Schedule C)"
                    component={renderYesNoDropdown}
                    id="grossBusinessIncomeField"
                  />
                </Grid>
                <Grid item xs={3} className={classes.formDropdown}>
                  <Field
                    name="transcript_tax_form_completed"
                    label="4506-T Completed"
                    component={renderYesNoDropdown}
                    id="transcriptTaxFormCompletedField"
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    name="extension_filed"
                    label="Extension Filed"
                    component={renderYesNoDropdown}
                    id="extensionFiledField"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Divider />
            <Grid container paddingY={1} alignItems="flex-end">
              <Grid item xs={12}>
                <SubHeader name="Unemployment" />
                <SmallSpacer />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="unemployment_income"
                  label="Income (Patient or Spouse)"
                  component={renderMoneyTextField}
                  validate={[validateDollarValue]}
                  id="unemploymentIncomeField"
                  onChange={e => this.updateTotalSumIncome(e.target)}
                />
              </Grid>
              <Grid item xs={3} className={classes.formDropdown}>
                <Field
                  name="unemployment_income_period_id"
                  component={renderDropdown}
                  label="Per Period"
                  fields={mappedIncomeTimePeriods}
                  validate={[validateNumber]}
                  id="unemploymentIncomePeriodIdField"
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="unemployment_start_date"
                  label="Benefit Start Date"
                  component={renderDatePicker}
                  validate={[validateDate]}
                  id="unemploymentStartDateField"
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="unemployment_end_date"
                  label="Benefit End Date"
                  component={renderDatePicker}
                  validate={[validateDate]}
                  id="unemploymentEndDateField"
                />
              </Grid>
            </Grid>
            <Divider />
            <Grid paddingTop={1} item xs={12}>
              <SubHeader name="Retirement" />
              <SmallSpacer />
            </Grid>
            <Grid item paddingBottom={1} xs={3}>
              <Field
                name="pension_401k_retirement_income"
                label="Pension/401K/Retirement Income"
                component={renderMoneyTextField}
                validate={[validateDollarValue]}
                id="pension401kField"
                onChange={e => this.updateTotalSumIncome(e.target)}
              />
            </Grid>
            <Divider />
            <Grid paddingY={1} container alignItems="flex-end">
              <Grid item xs={12}>
                <SubHeader name="Social Security" />
                <SmallSpacer />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="patient_ssi"
                  label="Patient Social Security Income"
                  component={renderMoneyTextField}
                  validate={[validateDollarValue]}
                  id="patientSocialSecurityIncomeField"
                  onChange={e => this.updateTotalSumIncome(e.target)}
                />
              </Grid>
              <Grid item xs={3} className={classes.formDropdown}>
                <Field
                  name="patient_ssi_income_period_id"
                  component={renderDropdown}
                  label="Per Period"
                  fields={mappedIncomeTimePeriods}
                  validate={[validateNumber]}
                  id="patientSSIIncomePeriodField"
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="dependent_ssi"
                  label="Dependent Social Security Income"
                  component={renderMoneyTextField}
                  validate={[validateDollarValue]}
                  id="dependentSocialSecurityIncomeField"
                  onChange={e => this.updateTotalSumIncome(e.target)}
                />
              </Grid>
              <Grid item xs={3} className={classes.formDropdown}>
                <Field
                  name="dependent_ssi_income_period_id"
                  component={renderDropdown}
                  label="Per Period"
                  fields={mappedIncomeTimePeriods}
                  validate={[validateNumber]}
                  id="dependentSSIIncomePeriodField"
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="spouse_ssi"
                  label="Spouse Social Security Income"
                  component={renderMoneyTextField}
                  validate={[validateDollarValue]}
                  id="spouseSSIField"
                  onChange={e => this.updateTotalSumIncome(e.target)}
                />
              </Grid>
              <Grid item xs={3} className={classes.formDropdown}>
                <Field
                  name="spouse_ssi_income_period_id"
                  component={renderDropdown}
                  label="Per Period"
                  fields={mappedIncomeTimePeriods}
                  validate={[validateNumber]}
                  id="spouseSSIIncomePeriodField"
                />
              </Grid>
            </Grid>
            <Divider />
            <Grid paddingY={1} container alignItems="flex-end">
              <Grid item xs={12}>
                <SubHeader name="Long Term Disability" />
                <SmallSpacer />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="longterm_disability_company"
                  label="Insurer"
                  component={renderTextField}
                  validate={[]}
                  id="longtermDisabilityCompanyField"
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="longterm_disability_income"
                  label="Benefit"
                  component={renderMoneyTextField}
                  validate={[validateDollarValue]}
                  id="longtermDisabilityIncomeField"
                  onChange={e => this.updateTotalSumIncome(e.target)}
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="longterm_disability_income_period_id"
                  component={renderDropdown}
                  label="Per Period"
                  fields={mappedIncomeTimePeriods}
                  validate={[validateNumber]}
                  id="longtermDisabilityIncomePeriodField"
                />
              </Grid>
            </Grid>
            <Divider />
            <Grid paddingY={1} container alignItems="flex-end">
              <Grid item xs={12}>
                <SubHeader name="Family" />
                <SmallSpacer />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="family_size"
                  label="Family Size *"
                  component={renderTextField}
                  validate={[required, validateNumber]}
                  id="familySizeField"
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="number_of_dependents"
                  label="Dependents *"
                  component={renderTextField}
                  validate={[required, validateNumber]}
                  id="numberOfDependentsField"
                />
              </Grid>
            </Grid>
            <Divider />
            <Grid paddingY={1} container alignItems="flex-end">
              <Grid item xs={12}>
                <SubHeader name="Total" />
                <SmallSpacer />
              </Grid>
              <Grid item xs={3}>
                <Field
                  name="total_sum_income"
                  label="Total Income"
                  component={renderMoneyTextField}
                  id="totalSumIncome"
                  disabled
                />
              </Grid>
              <Grid item xs={3} onClick={this.handlePovertyModal}>
                <div className={classes.povertyInfoWrapper}>
                  <InfoIcon color={theme.palette.primary.formLabel} />
                  <p className={classes.povertyInfoLabel}>Federal Poverty Levels</p>
                </div>
              </Grid>
            </Grid>
            <Divider />
            <ConfirmationPanel handleCancel={cancel} disableSubmit={pristine || submitting} />
          </form>
          <Modal open={povertyModalOpen} onClose={this.handlePovertyModal}>
            <div style={getModalStyle()} className={classes.Modal}>
              <FederalPovertyTable />
            </div>
          </Modal>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  const { patient } = state;
  return {
    patient,
    enableReinitialize: true,
    incomeTimePeriods: state.lookups.incomeTimePeriods,
    incomeYears: state.financials.income_years,
    initialValues: {
      patient_id: null,
      pension_401k_retirement_income: null,
      taxable_income: null,
      is_no_income: false,
      gross_business_income: null,
      transcript_tax_form_completed: null,
      extension_filed: null,
      unemployment_income: null,
      unemployment_income_period_id: null,
      unemployment_start_date: null,
      unemployment_end_date: null,
      patient_ssi: null,
      patient_ssi_income_period_id: null,
      dependent_ssi: null,
      dependent_ssi_income_period_id: null,
      spouse_ssi: null,
      spouse_ssi_income_period_id: null,
      longterm_disability_income: null,
      longterm_disability_income_period_id: null,
      retirement_income: null,
      retirement_income_period_id: null,
      family_size: null,
      number_of_dependents: null,
      has_income: null,
      income_year: null,
      is_verified: null,
      verified_dt: null,
      verified_by: null,
      total_sum_income: 0,
    },
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addIncome,
      fetchExistingIncomeYears,
      fetchIncomes,
    },
    dispatch,
  );
}

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, mapDispatchToProps),
)(reduxForm({ form: ADD_INCOME_FORM })(AddIncome));
