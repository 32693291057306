import { ITherapy, AdministrationStatus } from 'interfaces/redux/ITherapy';
import { ILabelValueNumber } from 'interfaces/ILabelValue';
import moment from 'moment';
import { IPatient } from 'interfaces/redux/IPatient';
import { MED_SYNC_STATUS } from 'constants/index';
import { StringUtils } from './string-utils';

class TherapyUtil {
  /**
   * Given a therapy with:
   *  drug_name: 'THE DRUG',
   *  strength: '25'
   *  strength_unit_of_measure: 'MG'
   * will return 'THE DRUG 25 MG'
   * @param therapy The therapy to format for paragraph content.
   */
  static getParagraphFormat = (therapy: ITherapy): string => {
    const result = StringUtils.concatenate(
      ' ',
      therapy?.drug_name || '',
      therapy?.strength || '',
      therapy?.strength_unit_of_measure || '',
    );
    return result;
  };

  /**
   * Given a therapy with:
   *  diagnosis_description: 'Urticaria, unspecified'
   *  diagnosis_code: 'L50.9'
   * will return 'Urticaria, unspecified L50.9'
   * @param therapy The therapy to get the diagnosis text from
   */
  static getDiagnosisText = (therapy: ITherapy): string => {
    return `${therapy.diagnosis_description} ${therapy.diagnosis_code}`;
  };

  /**
   * Returns true iff the comma-separated specialtyTypes string contains the target specialty type
   * @param specialtyTypes
   * @param targetSpecialtyType
   * @returns
   */
  static hasSpecialtyType = (specialtyTypes: string, targetSpecialtyType: string): boolean => {
    const therapySpecialtyTypes = (specialtyTypes || '').split(',');
    return therapySpecialtyTypes.includes(targetSpecialtyType);
  };

  static therapyFormatDosage = (therapy: ITherapy): string =>
    therapy ? `${therapy.drug_name} ${therapy.strength}${therapy.strength_unit_of_measure}` : '';

  static therapyFormatDiagnosisCode = (therapy: ITherapy): string =>
    therapy
      ? `${therapy.drug_name} - ${therapy.diagnosis_code} - ${therapy.strength} ${therapy.strength_unit_of_measure} ${therapy.administration_status}`
      : '';

  static therapyFormatDosageAndForm = (therapy: ITherapy): string =>
    therapy
      ? `${therapy.drug_name} ${therapy.dosage_form ? `${therapy.dosage_form} ` : ''}${
          therapy.strength
        }${therapy.strength_unit_of_measure}`
      : '';

  /**
  *Given an array of therapies
  return labelvalue array of therapys with dosage info
    */

  static therapiesListWithDosage = (therapies: ITherapy[]): ILabelValueNumber[] =>
    therapies
      ? therapies.map(therapy => ({
          value: therapy.id,
          label: TherapyUtil.therapyFormatDosage(therapy),
        }))
      : [];

  static therapiesListWithDiagnosisCode = (therapies: ITherapy[]): ILabelValueNumber[] =>
    therapies
      ? therapies
          .filter(
            therapy =>
              ![AdministrationStatus.NoGo, AdministrationStatus.Discontinued].includes(
                therapy.administration_status,
              ),
          )
          .map(therapy => ({
            value: therapy.id,
            label: TherapyUtil.therapyFormatDiagnosisCode(therapy),
          }))
      : [];

  static diseaseStateList = (
    diseaseStateData: { id: number; name: string; icd: string }[],
    therapies: ITherapy[],
  ): ILabelValueNumber[] => {
    const listOfctiveTherapies = therapies
      .filter(
        therapy =>
          ![AdministrationStatus.NoGo, AdministrationStatus.Discontinued].includes(
            therapy.administration_status,
          ),
      )
      .map(therapy => {
        return therapy.diagnosis_code;
      });
    const res = diseaseStateData.reduce((acc, item) => {
      if (!acc.map(i => i.value).includes(item.id) && listOfctiveTherapies.includes(item.icd)) {
        acc.push({
          value: item.id,
          label: item.name,
        });
      }
      return acc;
    }, [] as { value: number; label: string }[]);
    return res;
  };

  static checkDaysSupply = (
    daysSupplyValue: number | string,
    { dispensing_status_id, in_med_sync }: ITherapy,
    { anchor_date, sync_time_frame }: IPatient,
  ): { daysSupplyInConflict: boolean; daysSupplyToolTip: string } => {
    const checkValues = {
      daysSupplyInConflict: false,
      daysSupplyToolTip: '',
    };
    const daysValue =
      typeof daysSupplyValue === 'string' ? parseInt(daysSupplyValue, 10) : daysSupplyValue;
    if (
      // eslint-disable-next-line no-restricted-globals
      !isNaN(daysValue) &&
      sync_time_frame &&
      anchor_date &&
      dispensing_status_id === MED_SYNC_STATUS.OPT_IN &&
      in_med_sync
    ) {
      const isDaySupplyInSyncTimeframe = daysValue % sync_time_frame === 0;
      if (isDaySupplyInSyncTimeframe) {
        const providedAnchorDate = moment.utc(anchor_date, 'YYYY-MM-DD');
        const current = moment.utc().startOf('day');
        const daysUntillAnchorDate = moment.duration(providedAnchorDate.diff(current)).asDays();
        checkValues.daysSupplyInConflict = false;
        checkValues.daysSupplyToolTip = `${daysUntillAnchorDate} days until anchor date`;
      } else {
        checkValues.daysSupplyInConflict = true;
        checkValues.daysSupplyToolTip = `This day supply doesn't meet the current med sync of ${sync_time_frame} days`;
      }
    }
    return checkValues;
  };
}

export { TherapyUtil };
