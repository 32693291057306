/* eslint-disable */
import React, { useCallback, useState } from 'react';
import {
  Divider,
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Box,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { CheckmarkIcon } from 'components/icons/icons';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { fetchTherapyArchivedTasks } from 'actions/action-patient';
import { noteTagTypes, taskListOrder } from 'constants/lists';
import { INT, TPR, PA, FA, MR, FC, QRE, OR, CSL, FDC, DC } from 'constants/index';
import {
  getArchivedStatus,
  getInProgressStatus,
  getStatusCategoryByStatusId,
} from 'services/utils/task-service';
import { getDisplayNotes } from 'services/utils/note-service';
import { getDocumentsForResource } from 'services/utils/document-service';
import {
  isDurInterventionTask,
  shouldRenderDcInIntervention,
} from 'components/dynamic-form/helpers';
import { ArrayUtils } from 'utils/array-utils';
import { styles } from './task-detail-styles';
import PaDetail from './pa/pa-detail';
import FaDetail from './fa/fa-detail';
import CounselingDetail from './counseling/counseling-detail';
import InterventionDetail from './interventions/intervention-detail';
import IncidentDetail from './incidents/incident-detail';
import OutreachDetail from './outreach/outreach-detail';
import ThirdPartyReferralDetail from './third-party-referral/third-party-referral-detail';
import DataCollectDetail from './data-collect/data-collect-detail';
import MedicationReviewDetail from './medication-review/medication-review-detail';
import AddTherapyTask from './add-therapy-task';
import FillCoordinationDetail from './fill-coordination/fill-coordination-detail';
import FillDeliveryConfirmationDetail from './fill-delivery-confirmation/fill-delivery-confirmation-detail';
import TaskContainer from './task-container';
import { FillCoordinationStatus } from 'interfaces/enums/TaskStatuses/FillCoordinationStatus';

const TherapyTaskList = props => {
  const {
    theme,
    patient,
    paStatuses,
    faStatuses,
    fillDeliveryConfirmationStatuses,
    thirdPartyReferralStatuses,
    fillCoordinationStatuses,
    dataCollectStatuses,
    medicationReviewStatuses,
    classes,
    therapy,
    taskCounts,
    update,
    selectedTask,
    users,
    serviceGroups,
    interventionStatuses,
    incidentStatuses,
    outreachStatuses,
    counselingStatuses,
    isTaskSidebar,
    defaultServiceGroup,
    taskStatusCategoriesToDisplay,
    displayAddForm,
    handleCancelClick,
    handleArchivedClick,
    statuses,
    combinedTasks,
    archivedExpanded,
    allPinnedNotes,
    allNonPinnedNotes,
    therapyIndex,
    documents,
    drugName,
    links,
    tasks,
    // eslint-disable-next-line no-shadow
    fetchTherapyArchivedTasks,
  } = props;

  const [archivedTasks, setArchivedTasks] = useState({
    loading: false,
    loaded: false,
  });

  const handleExpansionChange = useCallback(
    (e, expanded) => {
      if (expanded && !archivedTasks.loaded && !archivedTasks.loading) {
        fetchTherapyArchivedTasks(patient.id, therapy.id).then(() => {
          setArchivedTasks({
            loading: false,
            loaded: true,
          });
        });
        setArchivedTasks({
          ...archivedTasks,
          loading: true,
        });
      }
    },
    [archivedTasks, fetchTherapyArchivedTasks, patient.id, therapy.id],
  );

  const noteDisplayCommonProps = {
    patientId: patient.id,
    isInContext: true,
    containerClasses: classes,
    maxLine: '1',
  };

  const renderPA = (task, innerProps = {}) => {
    if (!paStatuses || !paStatuses.length) {
      return null;
    }
    if (
      !taskStatusCategoriesToDisplay.some(
        s => s.text === getStatusCategoryByStatusId(task.status_id, paStatuses),
      )
    ) {
      return null;
    }
    const { value: paTypeId, displayLabel: paTypeLabel } = noteTagTypes.find(
      tag => tag.label === PA,
    );

    const displayNotes = getDisplayNotes(
      allPinnedNotes,
      allNonPinnedNotes,
      paTypeId,
      task.id,
      users,
      serviceGroups,
      2,
    );

    const displayDocuments = getDocumentsForResource(
      documents,
      [{ tagTypeId: paTypeId, resourceId: task.id }],
      1,
    );

    const notesWithProps = {
      therapy,
      tagName: PA,
      tagTypeId: paTypeId,
      notes: displayNotes,
      ...noteDisplayCommonProps,
      patient,
    };
    return (
      <PaDetail
        task={task}
        tagTypeId={paTypeId}
        tagTypeLabel={paTypeLabel}
        statuses={paStatuses}
        therapy={therapy}
        update={update}
        notes={notesWithProps}
        documents={displayDocuments}
        highlighted={selectedTask === `PA_${task.id}`}
        serviceGroups={serviceGroups}
        defaultServiceGroup={defaultServiceGroup}
        taskIDPrefix={`${therapyIndex}_PA_${task.id}`}
        drugName={drugName}
        {...innerProps}
      />
    );
  };

  const renderFA = (task, innerProps = {}) => {
    if (!faStatuses || !faStatuses.length) {
      return null;
    }
    if (
      !taskStatusCategoriesToDisplay.some(
        s => s.text === getStatusCategoryByStatusId(task.status_id, faStatuses),
      )
    ) {
      return null;
    }
    const { value: faTypeId, displayLabel: faTypeLabel } = noteTagTypes.find(
      tag => tag.label === FA,
    );
    const displayNotes = getDisplayNotes(
      allPinnedNotes,
      allNonPinnedNotes,
      faTypeId,
      task.id,
      users,
      serviceGroups,
      2,
    );

    const displayDocuments = getDocumentsForResource(
      documents,
      [{ tagTypeId: faTypeId, resourceId: task.id }],
      1,
    );

    const notesWithProps = {
      therapy,
      tagName: FA,
      tagTypeId: faTypeId,
      notes: displayNotes,
      ...noteDisplayCommonProps,
      patient,
    };
    return (
      <FaDetail
        task={task}
        tagTypeId={faTypeId}
        tagTypeLabel={faTypeLabel}
        statuses={faStatuses}
        therapy={therapy}
        update={update}
        notes={notesWithProps}
        documents={displayDocuments}
        highlighted={selectedTask === `FA_${task.id}`}
        serviceGroups={serviceGroups}
        defaultServiceGroup={defaultServiceGroup}
        taskIDPrefix={`${therapyIndex}_FA_${task.id}`}
        drugName={drugName}
        archived
        {...innerProps}
      />
    );
  };

  const renderThirdPartyReferralTask = (task, innerProps = {}) => {
    if (!thirdPartyReferralStatuses || !thirdPartyReferralStatuses.length) {
      return null;
    }
    if (
      !taskStatusCategoriesToDisplay.some(
        s => s.text === getStatusCategoryByStatusId(task.status_id, thirdPartyReferralStatuses),
      )
    ) {
      return null;
    }
    const { value: tprTypeId, displayLabel: tprTypeLabel } = noteTagTypes.find(
      tag => tag.label === TPR,
    );
    const displayNotes = getDisplayNotes(
      allPinnedNotes,
      allNonPinnedNotes,
      tprTypeId,
      task.id,
      users,
      serviceGroups,
      2,
    );
    const displayDocuments = getDocumentsForResource(
      documents,
      [{ tagTypeId: tprTypeId, resourceId: task.id }],
      1,
    );
    const notesWithProps = {
      therapy,
      tagName: TPR,
      tagTypeId: tprTypeId,
      notes: displayNotes,
      ...noteDisplayCommonProps,
      patient,
    };
    return (
      <ThirdPartyReferralDetail
        task={task}
        tagTypeId={tprTypeId}
        tagTypeLabel={tprTypeLabel}
        statuses={thirdPartyReferralStatuses}
        therapy={therapy}
        update={update}
        notes={notesWithProps}
        documents={displayDocuments}
        highlighted={selectedTask === `TPR_${task.id}`}
        serviceGroups={serviceGroups}
        defaultServiceGroup={defaultServiceGroup}
        taskIDPrefix={`${therapyIndex}_TPR_${task.id}`}
        drugName={drugName}
        {...innerProps}
      />
    );
  };

  const renderMedicationReviewTask = (task, innerProps = {}) => {
    // We still want to be showing MRs under patient therapies for the moment
    // if (window.FEATURES.hide_mr) {
    //   return null;
    // }
    if (!medicationReviewStatuses || !medicationReviewStatuses.length) {
      return null;
    }
    if (
      !taskStatusCategoriesToDisplay.some(
        s => s.text === getStatusCategoryByStatusId(task.status_id, medicationReviewStatuses),
      )
    ) {
      return null;
    }
    const { value: mrTypeId, displayLabel: mrTypeLabel } = noteTagTypes.find(
      tag => tag.label === MR,
    );

    const displayNotes = getDisplayNotes(
      allPinnedNotes,
      allNonPinnedNotes,
      mrTypeId,
      task.id,
      users,
      serviceGroups,
      2,
    );

    const displayDocuments = getDocumentsForResource(
      documents,
      [{ tagTypeId: mrTypeId, resourceId: task.id }],
      1,
    );

    const notesWithProps = {
      therapy,
      tagName: MR,
      tagTypeId: mrTypeId,
      notes: displayNotes,
      ...noteDisplayCommonProps,
      patient,
    };
    return (
      <MedicationReviewDetail
        task={task}
        tagTypeId={mrTypeId}
        tagTypeLabel={mrTypeLabel}
        statuses={medicationReviewStatuses}
        therapy={therapy}
        update={update}
        notes={notesWithProps}
        documents={displayDocuments}
        highlighted={selectedTask === `MR_${task.id}`}
        serviceGroups={serviceGroups}
        defaultServiceGroup={defaultServiceGroup}
        taskIDPrefix={`${therapyIndex}_MR_${task.id}`}
        drugName={drugName}
        {...innerProps}
      />
    );
  };

  const renderFillCoordinationTask = (task, innerProps = {}) => {
    if (!fillCoordinationStatuses || !fillCoordinationStatuses.length) {
      return null;
    }
    if (
      !taskStatusCategoriesToDisplay.some(
        s => s.text === getStatusCategoryByStatusId(task.status_id, fillCoordinationStatuses),
      )
    ) {
      return null;
    }
    const { value: fcTypeId, displayLabel: fcTypeLabel } = noteTagTypes.find(
      tag => tag.label === FC,
    );
    const displayNotes = getDisplayNotes(
      allPinnedNotes,
      allNonPinnedNotes,
      fcTypeId,
      task.id,
      users,
      serviceGroups,
      2,
    );
    const displayDocuments = getDocumentsForResource(
      documents,
      [{ tagTypeId: fcTypeId, resourceId: task.id }],
      1,
    );
    const notesWithProps = {
      therapy,
      tagName: FC,
      tagTypeId: fcTypeId,
      notes: displayNotes,
      ...noteDisplayCommonProps,
      patient,
    };
    return (
      <FillCoordinationDetail
        task={task}
        tagTypeId={fcTypeId}
        tagTypeLabel={fcTypeLabel}
        statuses={fillCoordinationStatuses}
        documents={displayDocuments}
        therapy={therapy}
        update={update}
        notes={notesWithProps}
        highlighted={selectedTask === `FC_${task.id}`}
        serviceGroups={serviceGroups}
        defaultServiceGroup={defaultServiceGroup}
        taskIDPrefix={`${therapyIndex}_FC_${task.id}`}
        drugName={drugName}
        {...innerProps}
      />
    );
  };

  const renderInterventionTask = (task, innerProps = {}) => {
    if (!interventionStatuses || !interventionStatuses.length) {
      return null;
    }
    if (
      !taskStatusCategoriesToDisplay.some(
        s => s.text === getStatusCategoryByStatusId(task.status_id, interventionStatuses),
      )
    ) {
      return null;
    }
    const { value: intTypeId, displayLabel: intTypeLabel } = noteTagTypes.find(
      tag => tag.label === INT,
    );
    const displayNotes = getDisplayNotes(
      allPinnedNotes,
      allNonPinnedNotes,
      intTypeId,
      task.id,
      users,
      serviceGroups,
      2,
    );
    const displayDocuments = getDocumentsForResource(
      documents,
      [{ tagTypeId: intTypeId, resourceId: task.id }],
      1,
    );
    const notesWithProps = {
      therapy,
      tagName: INT,
      tagTypeId: intTypeId,
      notes: displayNotes,
      ...noteDisplayCommonProps,
      patient,
    };
    return (
      <InterventionDetail
        task={task}
        statuses={interventionStatuses}
        therapy={therapy}
        update={update}
        notes={notesWithProps}
        documents={displayDocuments}
        highlighted={selectedTask === `INT_${task.id}`}
        serviceGroups={serviceGroups}
        defaultServiceGroup={defaultServiceGroup}
        tagTypeId={intTypeId}
        tagTypeLabel={intTypeLabel}
        taskIDPrefix={`${therapyIndex}_INT_${task.id}`}
        drugName={drugName}
        {...innerProps}
      />
    );
  };

  const renderIncidentTask = (task, innerProps = {}) => {
    const { value: qreTypeId, displayLabel: intTypeLabel } = noteTagTypes.find(
      tag => tag.label === QRE,
    );
    const displayNotes = getDisplayNotes(
      allPinnedNotes,
      allNonPinnedNotes,
      qreTypeId,
      task.id,
      users,
      serviceGroups,
      2,
    );
    const displayDocuments = getDocumentsForResource(
      documents,
      [{ tagTypeId: qreTypeId, resourceId: task.id }],
      1,
    );
    const notesWithProps = {
      therapy,
      tagName: QRE,
      tagTypeId: qreTypeId,
      notes: displayNotes,
      ...noteDisplayCommonProps,
      patient,
    };
    return (
      <IncidentDetail
        task={task}
        statuses={incidentStatuses}
        therapy={therapy}
        update={update}
        notes={notesWithProps}
        documents={displayDocuments}
        highlighted={selectedTask === `QRE_${task.id}`}
        serviceGroups={serviceGroups}
        defaultServiceGroup={defaultServiceGroup}
        tagTypeId={qreTypeId}
        tagTypeLabel={intTypeLabel}
        taskIDPrefix={`${therapyIndex}_QRE_${task.id}`}
        drugName={drugName}
        {...innerProps}
      />
    );
  };

  const renderOutreachTask = (task, innerProps = {}) => {
    if (!outreachStatuses || !outreachStatuses.length) {
      return null;
    }
    if (
      !taskStatusCategoriesToDisplay.some(
        s => s.text === getStatusCategoryByStatusId(task.status_id, outreachStatuses),
      )
    ) {
      return null;
    }
    const { value: orTypeId, displayLabel: orTypeLabel } = noteTagTypes.find(
      tag => tag.label === OR,
    );
    const displayNotes = getDisplayNotes(
      allPinnedNotes,
      allNonPinnedNotes,
      orTypeId,
      task.id,
      users,
      serviceGroups,
      2,
    );

    const displayDocuments = getDocumentsForResource(
      documents,
      [{ tagTypeId: orTypeId, resourceId: task.id }],
      1,
    );

    const notesWithProps = {
      therapy,
      tagName: OR,
      tagTypeId: orTypeId,
      notes: displayNotes,
      ...noteDisplayCommonProps,
      patient,
    };
    return (
      <OutreachDetail
        task={task}
        statuses={outreachStatuses}
        therapy={therapy}
        update={update}
        notes={notesWithProps}
        documents={displayDocuments}
        highlighted={selectedTask === `OR_${task.id}`}
        serviceGroups={serviceGroups}
        defaultServiceGroup={defaultServiceGroup}
        tagTypeId={orTypeId}
        tagTypeLabel={orTypeLabel}
        taskIDPrefix={`${therapyIndex}_OR_${task.id}`}
        drugName={drugName}
        {...innerProps}
      />
    );
  };

  const renderCounselingTask = (task, innerProps = {}) => {
    if (!counselingStatuses || !counselingStatuses.length) {
      return null;
    }
    if (
      !taskStatusCategoriesToDisplay.some(
        s => s.text === getStatusCategoryByStatusId(task.status_id, counselingStatuses),
      )
    ) {
      return null;
    }
    const { value: cslTypeId, displayLabel: cslTypeLabel } = noteTagTypes.find(
      tag => tag.label === CSL,
    );
    const displayNotes = getDisplayNotes(
      allPinnedNotes,
      allNonPinnedNotes,
      cslTypeId,
      task.id,
      users,
      serviceGroups,
      2,
    );
    const displayDocuments = getDocumentsForResource(
      documents,
      [{ tagTypeId: cslTypeId, resourceId: task.id }],
      1,
    );
    const notesWithProps = {
      therapy,
      tagName: CSL,
      tagTypeId: cslTypeId,
      notes: displayNotes,
      ...noteDisplayCommonProps,
      patient,
    };
    return (
      <CounselingDetail
        task={task}
        tagTypeId={cslTypeId}
        tagTypeLabel={cslTypeLabel}
        statuses={counselingStatuses}
        therapy={therapy}
        therapyIndex={therapyIndex}
        update={update}
        notes={notesWithProps}
        documents={displayDocuments}
        serviceGroups={serviceGroups}
        highlighted={selectedTask === `CSL_${task.id}`}
        taskIDPrefix={`${therapyIndex}_CSL_${task.id}`}
        drugName={drugName}
        {...innerProps}
      />
    );
  };

  const renderFillDeliveryConfirmationTask = (task, innerProps = {}) => {
    if (!fillDeliveryConfirmationStatuses || !fillDeliveryConfirmationStatuses.length) {
      return null;
    }
    if (
      !taskStatusCategoriesToDisplay.some(
        // eslint-disable-next-line
        s =>
          s.text === getStatusCategoryByStatusId(task.status_id, fillDeliveryConfirmationStatuses),
      )
    ) {
      return null;
    }
    const { value: fdcTypeId, displayLabel: fdcTypeLabel } = noteTagTypes.find(
      tag => tag.label === FDC,
    );
    const displayNotes = getDisplayNotes(
      allPinnedNotes,
      allNonPinnedNotes,
      fdcTypeId,
      task.id,
      users,
      serviceGroups,
      2,
    );
    const notesWithProps = {
      therapy,
      tagName: FDC,
      tagTypeId: fdcTypeId,
      notes: displayNotes,
      ...noteDisplayCommonProps,
      patient,
    };
    const displayDocuments = getDocumentsForResource(
      documents,
      [{ tagTypeId: fdcTypeId, resourceId: task.id }],
      1,
    );
    return (
      <FillDeliveryConfirmationDetail
        task={task}
        tagTypeId={fdcTypeId}
        tagTypeLabel={fdcTypeLabel}
        statuses={fillDeliveryConfirmationStatuses}
        therapy={therapy}
        update={update}
        notes={notesWithProps}
        documents={displayDocuments}
        highlighted={selectedTask === `FDC_${task.id}`}
        serviceGroups={serviceGroups}
        defaultServiceGroup={defaultServiceGroup}
        taskIDPrefix={`${therapyIndex}_FDC_${task.id}`}
        drugName={drugName}
        {...innerProps}
      />
    );
  };

  const renderDataCollectTask = (task, innerProps = {}) => {
    if (!dataCollectStatuses || !dataCollectStatuses.length) {
      return null;
    }
    if (
      !taskStatusCategoriesToDisplay.some(
        s => s.text === getStatusCategoryByStatusId(task.status_id, dataCollectStatuses),
      )
    ) {
      return null;
    }
    const { value: dcTypeId, displayLabel: dcTypeLabel } = noteTagTypes.find(
      tag => tag.label === DC,
    );
    const displayNotes = getDisplayNotes(
      allPinnedNotes,
      allNonPinnedNotes,
      dcTypeId,
      task.id,
      users,
      serviceGroups,
      2,
    );
    const notesWithProps = {
      therapy,
      tagName: DC,
      tagTypeId: dcTypeId,
      notes: displayNotes,
      ...noteDisplayCommonProps,
      patient,
    };
    const displayDocuments = getDocumentsForResource(
      documents,
      [{ tagTypeId: dcTypeId, resourceId: task.id }],
      1,
    );
    return (
      <DataCollectDetail
        task={task}
        tagTypeId={dcTypeId}
        tagTypeLabel={dcTypeLabel}
        statuses={dataCollectStatuses}
        therapy={therapy}
        update={update}
        notes={notesWithProps}
        documents={displayDocuments}
        highlighted={selectedTask === `DC_${task.id}`}
        serviceGroups={serviceGroups}
        defaultServiceGroup={defaultServiceGroup}
        taskIDPrefix={`${therapyIndex}_DC_${task.id}`}
        drugName={drugName}
        patient={patient}
        {...innerProps}
      />
    );
  };

  const renderTask = (task, archived = false) => {
    const renderer = {
      CSL: renderCounselingTask,
      INT: renderInterventionTask,
      QRE: renderIncidentTask,
      OR: renderOutreachTask,
      PA: renderPA,
      FA: renderFA,
      TPR: renderThirdPartyReferralTask,
      MR: renderMedicationReviewTask,
      FC: renderFillCoordinationTask,
      FDC: renderFillDeliveryConfirmationTask,
      DC: renderDataCollectTask,
      RS: null, // Do not render RS tasks on therapies tab
    };
    if (!renderer[task.taskType]) {
      return null;
    }
    const isRequiredFCTask =
      task.taskType === FC && task.status_id === FillCoordinationStatus.Required;
    const allowEdition =
      !(task.taskType === INT && isDurInterventionTask(task, tasks)) && !isRequiredFCTask;
    return (
      <div
        key={`${task.taskType}${task.id}`}
        data-qa-id={`therapy-task-${task.taskType}-${task.id}`}
      >
        <Divider className={classes.divider} />
        <TaskContainer
          task={task}
          statuses={statuses}
          highlighted={selectedTask === `${task.taskType}_${task.id}`}
          archived={archived}
          therapyTaskid={`${therapyIndex}_therapyTask_${task.taskType}_${task.id}`}
          allowEdition={allowEdition}
          renderChildren={innerProps => renderer[task.taskType](task, innerProps)}
        />
      </div>
    );
  };

  const allCombinedTasks = [...combinedTasks];
  let newListOfCombinedTasks = [];

  // In case this kind of complex functionality fails, revert back to the old logic
  try {
    // inprogress tasks
    let inProgressTasks = allCombinedTasks.filter(task =>
      getInProgressStatus().includes(task.status_category_id),
    );
    // filter these tasks by the task type, then by the order
    inProgressTasks = ArrayUtils.sortArrayIdeallyAndMulti(
      inProgressTasks,
      'taskType',
      taskListOrder,
      ['order'],
    );
    newListOfCombinedTasks.push(...inProgressTasks);
    // archived tasks
    const archTasks = allCombinedTasks.filter(x =>
      getArchivedStatus().includes(x.status_category_id),
    );
    newListOfCombinedTasks.push(...archTasks);
  } catch (error) {
    newListOfCombinedTasks = [];
    newListOfCombinedTasks.push(...allCombinedTasks);
    newListOfCombinedTasks.sort((a, b) => a.order - b.order);
  }

  // Remove embedded INT
  newListOfCombinedTasks = newListOfCombinedTasks.filter(
    task => task.taskType !== INT || !shouldRenderDcInIntervention(task, links, tasks),
  );

  const archivedTasksList = newListOfCombinedTasks.filter(task =>
    getArchivedStatus().includes(task.status_category_id),
  );

  const activeTasksList = newListOfCombinedTasks.filter(
    task => !getArchivedStatus().includes(task.status_category_id),
  );

  return (
    <div>
      {/* Header & Add Task Button */}
      <Grid container className={classes.addTherapyTaskContainer} alignItems="center">
        <Grid item xs={12}>
          {displayAddForm && (
            <AddTherapyTask
              taskCounts={taskCounts}
              therapy={therapy}
              handleCancel={handleCancelClick}
              isTaskSidebar={isTaskSidebar}
              serviceGroups={serviceGroups}
              defaultServiceGroup={defaultServiceGroup}
              therapyIndex={therapyIndex}
            />
          )}
        </Grid>
      </Grid>
      {/* Render Task List */}
      {activeTasksList.map(task => renderTask(task))}
      <Accordion
        elevation={0}
        className={classes.archivedContainer}
        expanded={archivedExpanded}
        onChange={handleExpansionChange}
      >
        <AccordionSummary
          className={classes.expansionPanelSummaryArchived}
          onClick={handleArchivedClick}
        >
          <Grid container className={classes.archivedToggleInner}>
            <Grid item xs>
              <Grid container>
                <Grid item className={classes.archivedIconTitle}>
                  <CheckmarkIcon color={theme.palette.primary.formLabel} />
                </Grid>
                <Typography className={classes.archivedTitle} variant="h6">
                  {archivedTasks.loaded
                    ? `ARCHIVED TASKS (${archivedTasksList.length})`
                    : 'ARCHIVED TASKS'}
                </Typography>
              </Grid>
            </Grid>
            <Grid item className={classes.expansionPanelLabel}>
              {archivedExpanded ? 'Less' : 'More'}
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails className={classes.archivedInner}>
          {archivedTasks.loading && (
            <Box m={1} p={1}>
              Loading...
            </Box>
          )}
          {archivedTasks.loaded && archivedTasksList.map(task => renderTask(task, true))}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

function mapStateToProps(state) {
  // Convert tasks array to object
  const { notes, uploadedDocuments, links, tasks } = state;
  return {
    patient: state.patient,
    allPinnedNotes: notes && notes.notes ? notes.notes.allPinnedNotes : [],
    allNonPinnedNotes: notes && notes.notes ? notes.notes.allNonPinnedNotes : [],
    notes: notes && notes.notes ? notes.notes.notesToDisplay : [],
    documents: uploadedDocuments.documents || [],
    taskCounts: state.taskCounts.data,
    statuses: state.taskStatuses.statuses,
    paStatuses: state.taskStatuses.statuses.pa,
    faStatuses: state.taskStatuses.statuses.fa,
    interventionStatuses: state.taskStatuses.statuses.int,
    outreachStatuses: state.taskStatuses.statuses.or,
    counselingStatuses: state.taskStatuses.statuses.csl,
    thirdPartyReferralStatuses: state.taskStatuses.statuses.tpr,
    fillCoordinationStatuses: state.taskStatuses.statuses.fc,
    fillDeliveryConfirmationStatuses: state.taskStatuses.statuses.fdc,
    dataCollectStatuses: state.taskStatuses.statuses.dc,
    medicationReviewStatuses: state.taskStatuses.statuses.mr,
    incidentStatuses: state.taskStatuses.statuses.qre,
    users: state.lookups.users,
    serviceGroups: state.lookups.serviceGroups,
    links: links.data,
    tasks: tasks.data,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchTherapyArchivedTasks: (patientId, therapyId) =>
      dispatch(fetchTherapyArchivedTasks(patientId, therapyId)),
  };
}

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, mapDispatchToProps),
)(TherapyTaskList);
