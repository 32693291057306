export enum InitialTaskStatuses {
  PA = 2021,
  FA = 3001,
  FC = 4001,
  TPR = 5001,
  DC = 6001,
  INT = 8001,
  CSL = 9001,
  OR = 13001,
  RS = 17001,
}
