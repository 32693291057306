import { Socket } from 'socket.io-client';
import { IFaGrantNotification } from '../interfaces/redux/IFaGrantNotification';

export enum SocketActionsEnum {
  FA_GRANT_NOTIFICATION = 'FA_GRANT_NOTIFICATION',
}

export type FaGrantNotificationActionPayload = {
  socket: Socket;
  notification: IFaGrantNotification;
  forceTaskType: 'FA';
};

export interface IAction {
  type: SocketActionsEnum;
  payload: FaGrantNotificationActionPayload;
}

export function handleFaGrantNotification(payload: FaGrantNotificationActionPayload): IAction {
  return {
    type: SocketActionsEnum.FA_GRANT_NOTIFICATION,
    payload,
  };
}
