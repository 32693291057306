export const styles = theme => ({
  button: {
    margin: theme.spacing(1),
  },
  editFormContainer: {
    margin: '15px 15px 15px 0px',
  },
  formRowContainer: {
    marginBottom: 15,
  },
  input: {
    display: 'flex',
    padding: 0,
  },
  modalActionBtnContainer: {
    justifyContent: 'center',
  },
  npiLookupButtonContainer: {
    position: 'relative',
  },
  npiLookupButton: {
    position: 'absolute',
    right: 4,
    bottom: 20,
  },
  alertWording: {
    textAlign: 'center',
    fontSize: theme.font.largeFontSize,
  },
  hidden: {
    visibility: 'hidden',
  },
  dosisRegimenField: {
    marginBottom: 20,
    marginTop: -15,
  },
  subtitle: {
    fontSize: '12px',
    fontWeight: '300',
    color: theme.palette.primary.formLabel,
    marginTop: 5,
    marginBottom: -15,
  },
  daySupplyConflict: {
    '& p, input, div': {
      color: theme.palette.primary.darkRed,
      borderColor: theme.palette.primary.darkRed,
    },
  },
  daysSupplyInfo: {
    float: 'left',
    position: 'relative',
    top: theme.spacing(-8),
    paddingLeft: theme.spacing(12),
    cursor: 'pointer',
    color: theme.palette.primary.rollingStone,
  },
  wideRow: {
    paddingTop: theme.spacing(),
  },
  icdValidatorIcon: {
    float: 'left',
    position: 'relative',
    top: theme.spacing(-7),
    paddingLeft: theme.spacing(10),
    cursor: 'pointer',
    color: theme.palette.primary.valenciaRed,
  },
  icdValidator: {
    '& div, span': {
      color: theme.palette.primary.valenciaRed,
    },
  },
  icdValidatorText: {
    color: theme.palette.primary.black,
    fontWeight: '500',
  },
  dialogActions: {
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
});
