import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { convertToArborDate } from 'models/time/arbor-date';
import { getStatusNameByStatusId } from 'services/utils/task-service';
import 'components/form/datepicker/datetime-picker.css';
import { StringUtils } from 'utils/string-utils';
import { DISPENSING_STATUSES, CSL, FC, INT } from 'constants';
import { PatientTherapiesClient } from '../../../clients/patient-therapies';

const useStyles = makeStyles(theme => ({
  error: {
    color: theme.palette.primary.valenciaRed,
    fontSize: 10,
    margin: 0,
  },
}));

const PatientReenrollTherapyTable = props => {
  const { therapyId, therapyDispensingStatus, handleRowSelection, control, errors } = props;
  const classes = useStyles();

  const [tasks, setTasks] = useState(
    useSelector(state =>
      Object.values(state?.tasks?.data || {}).filter(
        task =>
          task.therapy_id === therapyId &&
          task.status_id_prior_to_discharge !== null &&
          (therapyDispensingStatus !== DISPENSING_STATUSES.OPT_OUT ||
            (task.taskType !== CSL && task.taskType !== FC)),
      ),
    ),
  );

  const excludeRSIntTask = useMemo(async () => {
    const tasksWithoutRsInt = [];
    for (const task of tasks) {
      if (task.taskType === INT) {
        const isRsInt = await PatientTherapiesClient.isRsIntervention(task.id);
        if (isRsInt?.data?.result === false) {
          tasksWithoutRsInt.push(task);
        }
      } else {
        tasksWithoutRsInt.push(task);
      }
    }
    if (tasksWithoutRsInt.length !== tasks.length) {
      setTasks(tasksWithoutRsInt);
    }
  }, [tasks.length]);

  const taskStatuses = useSelector(state => state?.taskStatuses?.statuses);

  const [selectedRows, setSelectedRows] = useState({});

  const selectAllRows = () => {
    const tmp = {};
    tasks.forEach((t, idx) => {
      tmp[idx] = t;
    });
    setSelectedRows(tmp);
  };

  const toggleRowSelection = rowIdx => {
    const tmp = { ...selectedRows };
    if (selectedRows[rowIdx]) {
      delete tmp[rowIdx];
    } else {
      tmp[rowIdx] = tasks[rowIdx];
    }
    setSelectedRows(tmp);
  };

  const updateTaskFollowUpDate = (idx, followupDate) => {
    const tmp = [...tasks];
    tmp[idx].followup_dt = followupDate;
    setTasks(tmp);
  };

  useEffect(() => {
    selectAllRows();
  }, []);

  useEffect(() => {
    handleRowSelection(selectedRows);
  }, [selectedRows]);

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox">
              <Checkbox
                checked={Object.keys(selectedRows).length === tasks.length}
                color="primary"
                onChange={() => {
                  if (Object.keys(selectedRows).length === tasks.length) {
                    setSelectedRows({});
                  } else {
                    selectAllRows();
                  }
                }}
              />
            </TableCell>
            <TableCell>Task</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Follow Up Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tasks.map((task, idx) => (
            <TableRow key={`${task.therapy_id}-${task.taskType}-${task.id}`}>
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  checked={!!selectedRows[idx]}
                  onChange={() => {
                    toggleRowSelection(idx);
                  }}
                />
              </TableCell>
              <TableCell component="th" scope="row">
                {StringUtils.upperCaseFirstLetter(task.type.toLowerCase())}
              </TableCell>
              <TableCell>
                {getStatusNameByStatusId(task.status_id_prior_to_discharge, taskStatuses)}
              </TableCell>
              <TableCell
                data-qa-id={`${therapyId}-${idx}-patient-reenroll-therapy-table-followupDate-controller`}
              >
                <Controller
                  control={control}
                  name={`${therapyId}-${idx}-followupDate`}
                  rules={{ required: Boolean(selectedRows[idx]) }}
                  defaultValue=""
                  render={ctrlProps => (
                    <DatePicker
                      placeholderText="mm/dd/yyyy"
                      onChange={e => {
                        ctrlProps?.field?.onChange(e);
                        updateTaskFollowUpDate(idx, convertToArborDate(e).getUtcDatetime());
                      }}
                      onBlur={ctrlProps?.field?.onBlur}
                      selected={ctrlProps?.field?.value}
                    />
                  )}
                />

                {errors[`${therapyId}-${idx}-followupDate`] && (
                  <Typography className={classes.error}>REQUIRED</Typography>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PatientReenrollTherapyTable;
