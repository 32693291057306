import React, { useCallback, useState } from 'react';
import { groupBy } from 'helpers/misc';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import { ITask } from 'interfaces/redux/ITasks';
import {
  shouldRenderDcInIntervention,
  isDurInterventionTask,
  getIdOfDcOrRsInterventionId,
} from 'components/dynamic-form/helpers-tasks';
import { DUR, DC, RS, TASK_RISK_STRAT, INT } from 'constants/index';
import { useDispatch } from 'react-redux';
import { IState } from 'interfaces/redux/IState';
import { noteTagTypes } from 'constants/lists';
import { EditClose } from 'components/icons/icons';
import { resetDcProtocols } from 'actions/action-dc-protocols';
import { Styles } from './index.styles';
import { GroupedTaskTabsForm } from './grouped-task-tabs-form';
import { IIntPairs } from './types';
import { selectSelectedTasks } from './selectors';
import { useDeepDiffSelector } from '../../../utils/deep-diff-props';

export type CombinedTasks = Record<ITask['taskType'], ITask[]>;

export type TaskType = ITask['taskType'];

export function TabPanel(props: any) {
  const { children, value, index } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const selectCombinedTasks = (state: IState) => {
  const selectedTasks = selectSelectedTasks(state);
  const links = state.links.data;
  const tasks = state.tasks.data;
  let updatedSelectedTasks = selectedTasks;
  const tempIntPairs: IIntPairs[] = [];
  if (
    selectedTasks &&
    selectedTasks.some(
      x => shouldRenderDcInIntervention(x, links, tasks) || isDurInterventionTask(x, tasks),
    )
  ) {
    updatedSelectedTasks = selectedTasks
      .map(t => {
        if (isDurInterventionTask(t, tasks)) {
          return tasks[`${DUR}${t.task_drug_utilization_review_id}`];
        }
        if (shouldRenderDcInIntervention(t, links, tasks)) {
          const dcOrRsId = getIdOfDcOrRsInterventionId(t, links);
          if (dcOrRsId) {
            const taskToBeUsed =
              links?.find(it => it.resource_one_id === dcOrRsId)?.resource_one_type ===
              TASK_RISK_STRAT
                ? tasks[`${RS}${dcOrRsId}`]
                : tasks[`${DC}${dcOrRsId}`];
            if (t.taskType === INT) {
              tempIntPairs.push({
                taskType: taskToBeUsed.taskType,
                taskTypeId: taskToBeUsed.id,
                intId: t.id,
              });
            }
            return taskToBeUsed;
          }
        }
        return t;
      })
      .filter(x => !!x); // Ensure values
  }
  // use uniqBy instead?
  // updatedSelectedTasks = [...Array.from(new Set(updatedSelectedTasks))];
  const combinedTask = groupBy(updatedSelectedTasks, 'taskType') as CombinedTasks;
  return {
    combinedTask,
    intPairs: tempIntPairs,
  };
};

interface GroupedTaskTabsProps {
  onUnSelectTasks: (tasks: ITask[]) => void;
  onSelectTaskIds: (
    newSelectedTasks: { newSelectedTaskId: number; newSelectedTaskType: string }[],
  ) => void;
  handleOnSelectedRow(task: ITask): void;
}

function makeTabProps(index: number) {
  return {
    'id': `tab-taskType-${index}`,
    'aria-controls': `tabpanel-taskType-${index}`,
  };
}

export const GroupedTaskTabs = ({
  onUnSelectTasks,
  onSelectTaskIds,
  handleOnSelectedRow,
}: GroupedTaskTabsProps) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const { intPairs, combinedTask } = useDeepDiffSelector(selectCombinedTasks);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleCancel = useCallback(
    (canceledTasks: ITask[]) => {
      onUnSelectTasks(canceledTasks);
      if (value > Number(Object.keys(combinedTask).length - 2) && value > 0) {
        // -1 zero offset, -1 from removed task type
        setValue(value - 1);
      }
      dispatch(resetDcProtocols());
    },
    [combinedTask, dispatch, onUnSelectTasks, value],
  );

  return (
    <Box sx={{ width: '100%', my: 2 }}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="task tab groups"
        sx={Styles.tabsStyles}
      >
        {Object.keys(combinedTask).map((taskType, index) => {
          const tasksLength = combinedTask[taskType as TaskType].length;
          const tasksLengthLabel = tasksLength > 1 ? ` (${tasksLength})` : '';
          return (
            <Tab
              key={`taskTabItem-${taskType}`}
              value={index}
              {...makeTabProps(index)}
              sx={value === index ? Styles.selectedTab : Styles.tab}
              label={
                <Typography sx={value === index ? Styles.tabTextSelected : Styles.tabText}>
                  {`${
                    noteTagTypes.find(tag => tag.label === taskType)
                      ? noteTagTypes.find(tag => tag.label === taskType)?.displayLabel
                      : taskType
                  }${tasksLengthLabel}`}
                  <EditClose
                    onClick={(e: Event) => {
                      e.stopPropagation();
                      handleCancel(combinedTask[taskType as TaskType]);
                    }}
                  />
                </Typography>
              }
            />
          );
        })}
      </Tabs>
      <Box sx={Styles.formsContainer}>
        {Object.keys(combinedTask).map((taskType, index) => (
          <TabPanel value={value} index={index} key={`taskTabPanel-${taskType}`}>
            <GroupedTaskTabsForm
              selectedTasks={combinedTask[taskType as TaskType]}
              handleCancel={handleCancel}
              onSelectTaskIds={onSelectTaskIds}
              taskType={taskType}
              intPairs={intPairs}
              classes={null}
              handleOnSelectedRow={handleOnSelectedRow}
            />
          </TabPanel>
        ))}
      </Box>
    </Box>
  );
};
