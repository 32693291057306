/* eslint-disable max-len */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable camelcase */
import React, { Component } from 'react';
import {
  Button,
  Card,
  CardContent,
  CardActions,
  Grid,
  Typography,
  Divider,
  Modal,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import {
  filterTasksByUniqueTherapy,
  filterTasksByUniqueTherapyAndFillCycle,
  findTaskInTasks,
  getDaysSupplyForTask,
} from 'services/utils/task-service';
import { getDisplayNotes } from 'services/utils/note-service';
import { combineObjects, buildObjectForJson } from 'helpers/misc';
import { logEvent } from 'services/utils/analytics';
import { TaskStatusFactory } from 'factories/task-status-factory/task-status-factory';
import { convertMapToList } from 'reducers/helper-reducer';
import { Tasks } from 'constants/GA';
import SelectFileForm from 'components/document/file-forms/select-file-form';
import TaskDetailForm from 'components/dynamic-form/task-forms/task-detail-form';
import { change, formValueSelector, getFormValues } from 'redux-form';
import {
  getFileExtension,
  acceptedFileExtensions,
  fileTypeErrorCaption,
  fileTypeError,
} from 'services/utils/document-service';
import { windowFeatureIsEnabled } from 'config/window-features';
import { definedBoolean } from 'components/form/validation/validation';
import { updateDocumentBar } from 'actions/action-uploaded-documents';
import { uploadFile } from 'services/utils/upload-document-service';
import PendingDocument from 'components/document/pending-document';
import ConfirmationDialogPanel from 'components/form/confirmation/confirmation-dialog-panel';
import InterventionCopyContentButton from 'components/intervention-button/intervention-copy-content-button';
import { ConditionalWrapper } from 'utils/conditional-wrapper';
import { PillIcon } from 'components/icons/icons';
import { TaskStatusUtil } from 'utils/task-status-util';
import { DurStatus } from 'interfaces/enums/TaskStatuses/DrugUtilizationReviewStatus';
import { buildQaId } from 'utils/build-qa-id';
import FollowUpDataCollectModal from 'components/dynamic-form/task-forms/follow-up-data-collect-modal';
import { TaskExpirationDate } from 'components/task-expiration-date';
import _ from 'lodash';
import { DataCollectStatus } from 'interfaces/enums/TaskStatuses/DataCollectStatus';
import store from '../../store';
import {
  UPLOAD_DOC_FILENAME_FIELD,
  UPLOAD_DOCUMENT_FORM,
  UPLOAD_DOC_FILENOTE_FIELD,
  UPLOAD_DOC_FILETYPE_FIELD,
  DUR,
  INT,
  RS,
  PA,
  FA,
  DRUG_UTILIZATION_REVIEW_TASK_ID,
  DC,
  INTERVENTION_SEVERITIES,
  STATUS_FA_ALREADY_IN_PLACE,
  STATUS_FA_APPROVED,
} from '../../constants';
import BundleTaskStatusForm from './task-forms/bundle-task-status-form';
import { styles } from './dynamic-form-styles';
import {
  changeStatusToNextLogical,
  combineTasks,
  bundleInteractionsForDur,
  interventionsHaveRecommendationsAndOutcomesOrUnchecked,
  hasSelectedAndOpenIntervention,
} from './helpers-tasks';
import { FillCoordinationDescription } from '../../models/tasks/index';
import FileSelectedForm from '../document/file-forms/file-selected-form';
import { noteTagTypes } from '../../constants/lists';
import {
  canFAWithFundingAvailableBeApproved,
  isFAExpired,
} from '../../containers/tasks/fa/fa-utils';

const qaIdBuilder = buildQaId('dosing-regimen', '.');
const NO_THERAPY = 'no-therapy';

class BundleTaskForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: {},
      deliveryValues: {},
      valid: {},
      bundledDelivery: true,
      pendingDocument: undefined,
      uploadedDocuments: [],
      uploadProcessing: false,
      pendingDocumentLoss: false,
      lostData: [],
      dialogCancel: undefined,
      dialogContinue: undefined,
      fileErrorMessage: undefined,
      fileErrorCaption: undefined,
      generateDocumentAfterSave: false,
      markAsReviewed: false,
      taskIsSubmitting: false,
      openInterventionAlertModal: false,
    };
    this.fileSelected = this.fileSelected.bind(this);
    this.fileRejected = this.fileRejected.bind(this);
  }

  componentDidMount() {
    // eslint-disable-next-line
    const { taskIds } = this.props;
    this.onTasksChange(taskIds, taskIds, []);
  }

  componentDidUpdate(prevProps, prevState) {
    const { taskIds, fileFormName, taskType } = this.props;
    if (prevProps.taskIds !== taskIds) {
      const addedTasks = taskIds.filter(id => prevProps.taskIds.find(oldId => oldId === id));
      const removedTasks = prevProps.taskIds.filter(oldId => taskIds.find(id => oldId === id));
      this.onTasksChange(taskIds, addedTasks, removedTasks);
    }

    if (prevState.pendingDocument && prevState.pendingDocument.fileName !== fileFormName) {
      this.onPendingDocumentFileNameChange(fileFormName);
    }

    // Ensure validity isn't combining from previous DUR form from medications view
    if (prevProps.taskType === DUR && taskType === DUR) {
      const resetValidity =
        prevProps.taskIds &&
        prevProps.taskIds.length === 1 &&
        taskIds &&
        taskIds.length === 1 &&
        prevProps.taskIds[0] < taskIds[0]; // After reviewing it passes an old DUR form id
      if (resetValidity) {
        this.resetValidity();
      }
    }
  }

  onPendingDocumentFileNameChange(newFileName) {
    this.setState(prevState => ({
      pendingDocument: {
        ...prevState.pendingDocument,
        ...{ fileName: newFileName },
      },
    }));
  }

  onTasksChange(newTaskIds) {
    const { bundledDelivery } = this.state;
    // combine delivery
    if (bundledDelivery) {
      this.setState(prevState => {
        const values = prevState.deliveryValues;
        const valuesAsList = Object.keys(values).map(key => values[key]);
        const masterObject = combineObjects(...valuesAsList);
        const newDeliveryValues = newTaskIds.reduce((acc, taskId) => {
          acc[taskId] = { ...masterObject, id: taskId };
          return acc;
        }, {});
        return {
          deliveryValues: newDeliveryValues,
        };
      });
    }
  }

  getTaskTypeName(task) {
    const { taskCounts } = this.props;
    return (taskCounts[task.taskType] || {}).task_description || '';
  }

  getTherapyName(task) {
    const { therapies } = this.props;
    if (task && therapies) {
      const matchingTherapy = therapies[task.therapy_id];
      if (matchingTherapy) {
        return matchingTherapy.drug_name;
      }
    }
    return undefined;
  }

  resetValidity() {
    this.setState({
      valid: {},
    });
  }

  updateDeliveryForm(taskIds, values) {
    this.setState(prevState => {
      const newValues = { ...prevState.deliveryValues };
      taskIds.forEach(taskId => {
        newValues[taskId] = { ...newValues[taskId], ...values };
      });
      return { deliveryValues: newValues };
    });
  }

  updateValues(taskIds, values) {
    this.setState(prevState => {
      const updatedValues = { ...prevState.values };
      taskIds.forEach(taskId => {
        updatedValues[taskId] = { ...updatedValues[taskId], ...values };
      });
      return { values: updatedValues };
    });
  }

  updateFormTasks(taskIds, values) {
    this.updateValues(taskIds, values);
  }

  handleSubmit(generateDocumentAfterSave, markAsReviewed = false) {
    this.setState({
      taskIsSubmitting: true,
    });
    const { pendingDocument } = this.state;
    const generateState = { generateDocumentAfterSave };

    if (markAsReviewed) {
      this.setState({ markAsReviewed });
    }
    if (pendingDocument) {
      this.setState({
        ...generateState,
        pendingDocumentLoss: true,
        lostData: [`Pending: ${pendingDocument.fileName}.${pendingDocument.fileExtension}`],
        dialogCancel: () => {
          this.setState({ pendingDocumentLoss: false });
        },
        dialogContinue: () => {
          this.finalizeSubmit();
        },
      });
      return;
    }

    this.setState(generateState, this.finalizeSubmit);
  }

  convertedValues() {
    const { values, deliveryValues, markAsReviewed } = this.state;
    const { taskType, patientId, taskIds, tasks } = this.props;
    const tasksList = convertMapToList(tasks);
    const filteredTaskIds = taskIds.filter(taskId => findTaskInTasks(tasksList, taskType, taskId));
    const convertedValues = Object.keys(values)
      .filter(id => filteredTaskIds.indexOf(Number(id)) > -1)
      .map(id => {
        const task = findTaskInTasks(tasksList, taskType, Number(id)) || {};
        let retVal = {
          ...values[id],
          delivery: { ...deliveryValues[id] },
          id: Number(id),
          patient_id: patientId,
          taskType,
          therapy_id: task.therapy_id,
          service_group_id: task.service_group_id,
          old_status_id: task.status_id,
          documents: task.documents,
        };

        if (taskType === DUR) {
          const intForms = bundleInteractionsForDur(store.getState().form);
          if (markAsReviewed) {
            retVal = { ...retVal, status_id: DurStatus.Reviewed, interactions: intForms };
          } else {
            retVal = { ...retVal, interactions: intForms };
          }
        }
        if (taskType === RS) {
          retVal = { ...retVal, patientId, statusId: task.status_id };
        }
        return retVal;
      });
    return convertedValues;
  }

  finalizeSubmit() {
    const { onSubmit, taskType, taskIds, tasks, combinedTask, currentTaskStatusIds } = this.props;
    const { uploadedDocuments, deliveryValues, generateDocumentAfterSave, markAsReviewed, valid } =
      this.state;

    const tasksList = convertMapToList(tasks);
    const filteredTaskIds = taskIds.filter(taskId => findTaskInTasks(tasksList, taskType, taskId));

    // GA tracking
    const selectedTaskTypes = Object.keys(combinedTask).join(',');
    if (filteredTaskIds.length > 1) {
      logEvent(
        Tasks.category,
        Tasks.actions.multi_tasks_selected,
        `Edited: $(taskType), Selected: ${selectedTaskTypes}`,
      );
    } else {
      logEvent(Tasks.category, Tasks.actions.single_task_selected, `Edited: ${taskType}`);
    }

    const convertedValues = this.convertedValues();

    convertedValues.forEach(convertedTaskValue => {
      if (
        currentTaskStatusIds &&
        currentTaskStatusIds[convertedTaskValue.id] &&
        !convertedTaskValue.status_id
      ) {
        convertedTaskValue.status_id = currentTaskStatusIds[convertedTaskValue.id];
      }
    });

    const { Completed } = FillCoordinationDescription.statusMap;
    // combine deliveries when they have same data
    const orders = Object.keys(deliveryValues).reduce((acc, taskId) => {
      const fcTask = findTaskInTasks(tasksList, 'FC', Number(taskId));
      const fcValues = convertedValues.find(it => it.id === Number(taskId));
      if (
        fcTask &&
        fcValues &&
        fcValues.old_status_id !== Completed &&
        fcValues.status_id === Completed
      ) {
        const order = deliveryValues[taskId];
        const existing = acc[0];
        if (existing) {
          existing.taskIds.push(parseInt(taskId, 10));
        } else {
          acc.push({
            ...order,
            taskIds: [parseInt(taskId, 10)],
          });
        }
      }
      return acc;
    }, []);
    if (orders && orders[0] && orders[0].taskIds && orders[0].taskIds.length > 0) {
      orders[0].taskIds.forEach(taskId => {
        if (convertedValues.some(updatedFc => updatedFc.id === taskId)) {
          const indexOfUpdatedFc = convertedValues.findIndex(updatedFc => updatedFc.id === taskId);
          if (orders[0].address) {
            convertedValues[indexOfUpdatedFc].preferred_shipping_address = orders[0].address;
          }
          if (orders[0].delivery_dt) {
            convertedValues[indexOfUpdatedFc].delivery_dt = orders[0].delivery_dt;
          }
          if (orders[0].method) {
            convertedValues[indexOfUpdatedFc].preferred_rx_delivery_method = orders[0].method;
          }
          if (orders[0].phone) {
            convertedValues[indexOfUpdatedFc].preferred_phone_number = orders[0].phone;
          }
          if (orders[0].ship_dt) {
            convertedValues[indexOfUpdatedFc].ship_date = orders[0].ship_dt;
          }
          if (orders[0].signature_required) {
            convertedValues[indexOfUpdatedFc].signature_required = orders[0].signature_required;
          }
          if (orders[0].urgent) {
            convertedValues[indexOfUpdatedFc].urgent = definedBoolean(orders[0].signature_required)
              ? Number(orders[0].signature_required)
              : null;
          }
          if (orders[0].cold_chain_packing_recommended) {
            convertedValues[indexOfUpdatedFc].cold_chain_packing_recommended = definedBoolean(
              orders[0].cold_chain_packing_recommended,
            )
              ? Number(orders[0].cold_chain_packing_recommended)
              : null;
          }
          if (orders[0].cold_chain_packing_required) {
            convertedValues[indexOfUpdatedFc].cold_chain_packing_required = definedBoolean(
              orders[0].cold_chain_packing_required,
            )
              ? Number(orders[0].cold_chain_packing_required)
              : null;
          }
          if (orders[0].ancillary_supplies) {
            convertedValues[indexOfUpdatedFc].ancillary_supplies = orders[0].ancillary_supplies;
          }
          if (orders[0].medicaid_only) {
            convertedValues[indexOfUpdatedFc].medicaid_only = definedBoolean(
              orders[0].medicaid_only,
            )
              ? Number(orders[0].medicaid_only)
              : null;
          }
          if (orders[0].order_notes) {
            convertedValues[indexOfUpdatedFc].order_notes = orders[0].order_notes;
          }
          if (orders[0].payment_method_on_file) {
            convertedValues[indexOfUpdatedFc].payment_method_on_file = definedBoolean(
              orders[0].payment_method_on_file,
            )
              ? Number(orders[0].payment_method_on_file)
              : null;
          }
          if (orders[0].patient_advised_pickup) {
            convertedValues[indexOfUpdatedFc].patient_advised_pickup = definedBoolean(
              orders[0].patient_advised_pickup,
            )
              ? Number(orders[0].patient_advised_pickup)
              : null;
          }
          if (orders[0].special_instructions) {
            convertedValues[indexOfUpdatedFc].special_instructions = orders[0].special_instructions;
          }
          if (orders[0].order_type_address) {
            convertedValues[indexOfUpdatedFc].order_type_address = orders[0].order_type_address;
          }
          if (orders[0].to_patient) {
            convertedValues[indexOfUpdatedFc].to_patient = definedBoolean(orders[0].to_patient)
              ? Number(orders[0].to_patient)
              : null;
          }
          if (orders[0].to_md) {
            convertedValues[indexOfUpdatedFc].to_md = definedBoolean(orders[0].to_md)
              ? Number(orders[0].to_md)
              : null;
          }
          if (orders[0].patient_acknowledges) {
            convertedValues[indexOfUpdatedFc].patient_acknowledges = definedBoolean(
              orders[0].patient_acknowledges,
            )
              ? Number(orders[0].patient_acknowledges)
              : null;
          }
          if (orders[0].shipping_method) {
            convertedValues[indexOfUpdatedFc].shipping_method = buildObjectForJson(
              orders[0].shipping_method,
            );
          }
          if (orders[0].ups_packaging_type) {
            convertedValues[indexOfUpdatedFc].ups_packaging_type = orders[0].ups_packaging_type;
          }
          if (orders[0].email) {
            convertedValues[indexOfUpdatedFc].email = orders[0].email;
          }
          if (orders[0].weather) {
            convertedValues[indexOfUpdatedFc].weather = orders[0].weather;
          }
        }
      });
    }
    onSubmit(convertedValues, orders, uploadedDocuments, generateDocumentAfterSave);
    if (taskType === DUR && markAsReviewed === true) {
      // Ensure after marking reviewed not able to edit
      this.setState({
        valid: Object.keys(valid).reduce((accumulator, key) => {
          return { ...accumulator, [key]: false };
        }, {}),
      });
    }
    this.setState({
      taskIsSubmitting: false,
      markAsReviewed: false,
    });
  }

  handleCancel() {
    const { uploadedDocuments, pendingDocument } = this.state;

    if ((uploadedDocuments && uploadedDocuments.length > 0) || pendingDocument) {
      const lostData = uploadedDocuments.map(ud => `Uploaded: ${ud.filename}`) || [];

      if (pendingDocument) {
        lostData.push(`Pending: ${pendingDocument.fileName}.${pendingDocument.fileExtension}`);
      }

      this.setState({
        pendingDocumentLoss: true,
        lostData,
        dialogCancel: () => {
          this.setState({ pendingDocumentLoss: false });
        },
        dialogContinue: () => {
          this.finalizeCancel();
        },
      });
    } else {
      this.finalizeCancel();
    }
  }

  finalizeCancel() {
    const { values } = this.state;
    const { onCancel, tasks, taskType, taskIds } = this.props;

    const filteredTaskIds = taskIds.filter(taskId =>
      findTaskInTasks(convertMapToList(tasks), taskType, taskId),
    );

    const selectTaskIds = Object.keys(values)
      .filter(id => filteredTaskIds.indexOf(Number(id)) > -1)
      .map(id => Number(id));

    onCancel(selectTaskIds, taskType);
  }

  toggleShipment() {
    this.setState(prevState => ({
      bundledDelivery: !prevState.bundledDelivery,
    }));
  }

  combineShipment() {
    this.setState(() => ({
      bundledDelivery: true,
    }));
  }

  splitShipment() {
    this.setState(() => ({
      bundledDelivery: false,
    }));
  }

  canSubmit(activeForms) {
    const { valid } = this.state;
    const { taskType, financialAssistances, taskIds, tasks, faFormValues } = this.props;
    if (taskType === FA) {
      const nextTransition = status =>
        (
          Object.values(faFormValues).filter(
            x => taskIds.some(v => x.taskId === v) && x.status_id === status,
          ) || []
        ).length > 0;
      const [currentForm] = faFormValues;
      const hasFinancialAssistance = Object.values(financialAssistances).some(
        x => x.task_fa_id === currentForm?.taskId && !isFAExpired(x),
      );

      if (
        (nextTransition(STATUS_FA_APPROVED) && hasFinancialAssistance) ||
        nextTransition(STATUS_FA_ALREADY_IN_PLACE)
      ) {
        return canFAWithFundingAvailableBeApproved(
          tasks,
          financialAssistances,
          taskIds,
          taskType,
          activeForms,
          valid,
        );
      }
    }
    return activeForms.reduce((v, formId) => v && valid[formId] !== false, true);
  }

  canSubmitAndReview(tasks, activeForms) {
    const { taskType, currentUser, resourceStates, durFormsWithSelectedINT } = this.props;
    if (!this.canSubmit(activeForms)) return false;
    switch (taskType) {
      case DUR: {
        const canTransitionDur = TaskStatusUtil.buildCanTransitionFromPermissions(
          currentUser?.permissions,
          (resourceStates || []).filter(resourceState => resourceState.resource_name === DUR),
        );
        const statusFilter = [
          DurStatus.Reviewed,
          DurStatus.Generated,
          DurStatus.ReadyForReview,
          DurStatus.Intervening,
        ];

        const durTasks = tasks.filter(task => statusFilter.includes(task.status_id));

        const intForms = bundleInteractionsForDur(store.getState().form);
        return durTasks.every(
          durTask =>
            (intForms.length === 0 ||
              interventionsHaveRecommendationsAndOutcomesOrUnchecked(intForms)) &&
            canTransitionDur(durTask, DurStatus.Reviewed),
        );
      }
      default:
        return false;
    }
  }

  handleDURSaveAndReviewSubmit(generateDocumentAfterSave, markAsReviewed = false) {
    const { durFormsWithSelectedINT } = this.props;
    const intForms = bundleInteractionsForDur(store.getState().form);

    const allInterventionsFormsCompleted = intForms
      .filter(({ intervention_checked: checked }) => !!checked)
      .every(
        ({ intervention }) =>
          !intervention || (!!intervention.outcome && !!intervention.recommendation),
      );

    const showIntWillRemainOpenModal = !allInterventionsFormsCompleted;

    if (durFormsWithSelectedINT?.length > 0 && showIntWillRemainOpenModal) {
      this.toggleInterventionModal();
    } else {
      this.handleSubmit(generateDocumentAfterSave, markAsReviewed);
    }
  }

  handleFileUploadCancel() {
    this.setState({ pendingDocument: undefined });
  }

  handleFileUploadSubmit() {
    this.setState({ uploadProcessing: true });
    const { pendingDocument } = this.state;
    const {
      selectedCustomerId,
      selectedPatientId,
      fileFormName,
      fileFormTypes,
      fileFormNote,
      taskType,
      taskIds,
    } = this.props;
    const tagType = noteTagTypes.find(tag => tag.label === taskType);
    uploadFile(
      pendingDocument.file,
      `${pendingDocument.fileName}.${pendingDocument.fileExtension}`,
      selectedCustomerId,
      selectedPatientId,
    )
      .then(result => {
        this.setState(prevState => ({
          uploadedDocuments: [
            ...prevState.uploadedDocuments,
            {
              uuid: result.uuid,
              taskTypeId: tagType.value,
              taskIds,
              filename: `${fileFormName}.${pendingDocument.fileExtension}`,
              labels: fileFormTypes.split(','),
              note: fileFormNote,
              tags: [tagType.label],
            },
          ],
          pendingDocument: undefined,
          uploadProcessing: false,
        }));
      })
      .catch(() => {
        this.setState({
          fileUploadErrorMessage: 'File could not be uploaded. Try again.',
        });
      });
  }

  fileSelected(event) {
    if (event && event.length > 0) {
      this.setState({
        fileErrorMessage: undefined,
        fileErrorCaption: undefined,
      });

      const { filePrefix, fileExtension } = getFileExtension(event[0].name);
      if (acceptedFileExtensions.includes(fileExtension.toLowerCase())) {
        const { changeField, fileUploadFormId } = this.props;
        const selectedFile = event[0];
        changeField(fileUploadFormId, UPLOAD_DOC_FILENAME_FIELD, filePrefix);
        this.setState({
          pendingDocument: {
            fileName: filePrefix,
            fileExtension,
            file: selectedFile,
          },
        });
      }
    }
  }

  fileRejected() {
    this.setState({
      fileErrorMessage: fileTypeError,
      fileErrorCaption: fileTypeErrorCaption,
    });
  }

  handleFilePaste(event) {
    if (event && event.clipboardData) {
      const { files } = event.clipboardData;
      if (files.length > 0) {
        this.fileSelected(files);
      } else {
        this.fileRejected();
      }
    }
  }

  toggleInterventionModal() {
    const { openInterventionAlertModal } = this.state;
    this.setState({ openInterventionAlertModal: !openInterventionAlertModal });
  }

  renderPillIcon(dosisRegimenText) {
    const { therapy } = this.props;
    return (
      <>
        {dosisRegimenText ? (
          <Grid container style={{ marginTop: 10 }} data-qa-id={qaIdBuilder('grid')}>
            <PillIcon therapy={therapy} />
            <Typography style={{ fontSize: 12, marginLeft: 10 }}>{dosisRegimenText}</Typography>
          </Grid>
        ) : null}
      </>
    );
  }

  render() {
    const {
      taskType,
      therapies,
      tasks: stateTasks,
      taskIds,
      notes,
      classes,
      defaultDocumentLabels,
      fileUploadFormId,
      shouldShowPreviewButtonFn,
      submitButtonTextFn,
      hideCard,
      hideTitles,
      hidePatientLevelTitle,
      hideTherapyLevelTitle,
      currentTaskStatusIds,
      users,
      serviceGroups,
      allPinnedNotes,
      allNonPinnedNotes,
      hideCancel,
      fillCycles,
      selectedPatientId,
      intValuesToCopy,
      auth,
      selectedWagTaskKeys,
    } = this.props;
    const {
      values,
      pendingDocument,
      fileUploadErrorMessage,
      uploadProcessing,
      uploadedDocuments,
      pendingDocumentLoss,
      lostData,
      dialogCancel,
      dialogContinue,
      fileErrorCaption,
      fileErrorMessage,
      taskIsSubmitting,
    } = this.state;

    const taskStatusIds = currentTaskStatusIds || {};
    const forms = [];

    const filteredTasks = taskIds
      .map(taskId => stateTasks[`${taskType}${taskId}`])
      .filter(it => it);

    const tasks = combineTasks(taskType, changeStatusToNextLogical(filteredTasks));

    const allTaskIds = tasks.map(it => it.id);

    const assignSeverityForINTTask = task => {
      const { taskStatuses } = this.props;
      const severityId =
        task.severity_id === null ? INTERVENTION_SEVERITIES.HIGH.valueOf() : task.severity_id;
      const severity = taskStatuses?.intervention_severities?.find(type => type.id === severityId);
      return {
        ...task,
        severity_name: severity?.severity_name,
      };
    };

    const assignRSAcceptedDate = task => {
      if (task.acceptedDate) return task;
      return { ...task, acceptedDate: new Date().toISOString() };
    };

    const assignDefaultData = task => {
      switch (task.taskType) {
        case INT:
          return assignSeverityForINTTask(task);

        case RS:
          return assignRSAcceptedDate(task);

        default:
          return task;
      }
    };

    const standardTasks = [];
    const readonlyTasks = [];
    let tasksByUniqueTherapy = [];
    let wagSelectedTask = null;
    let itemToBeAdded = [];
    // put the readonly tasks in one bucket, rest in another
    tasks.forEach(task => {
      if (TaskStatusFactory.getProcessor(task.taskType).taskIsReadOnly(task)) {
        readonlyTasks.push(task);
      } else {
        standardTasks.push(task);
      }
    });

    if (tasks.length) {
      if (standardTasks.length) {
        tasksByUniqueTherapy = filterTasksByUniqueTherapy(standardTasks);
        const patientLevelTask =
          standardTasks.find(it => (therapies[it.therapy_id] || {}).is_specialty) ||
          standardTasks[0];
        // patient level fields
        const patientLevelTitle =
          hideTitles || hidePatientLevelTitle
            ? undefined
            : `${this.getTaskTypeName(patientLevelTask)} - Patient`;
        const dosisRegimenText = therapies[patientLevelTask.therapy_id]?.dosis_regimen;
        forms.push({
          title: patientLevelTitle,
          formName: 'edit',
          formId: `tasks-edit-${taskType}-${selectedPatientId}-patient`,
          data: assignDefaultData(patientLevelTask),
          level: 'patient',
          linkedTasks: allTaskIds,
          notes,
          onFormChange: v => this.updateFormTasks(allTaskIds, v),
          taskStatusId: taskStatusIds[patientLevelTask.id],
          dosisRegimenText,
        });
        // therapy level fields
        tasksByUniqueTherapy.forEach((task, index) => {
          const dosisRegimenText = therapies[task.therapy_id]?.dosis_regimen; // eslint-disable-line no-shadow
          const therapyName = therapies[task.therapy_id]
            ? therapies[task.therapy_id].drug_name
            : index + 1;
          let therapyLevelTitle = therapyName;
          therapyLevelTitle = hideTitles || hideTherapyLevelTitle ? undefined : therapyLevelTitle;
          forms.push({
            title: therapyLevelTitle,
            formName: 'edit',
            formId: `tasks-edit-${taskType}-${task.therapy_id || NO_THERAPY}-therapy`,
            linkedFormIds: {
              patient: `tasks-edit-${taskType}-${selectedPatientId}-patient`,
            },
            data: assignDefaultData(task),
            dosisRegimenText,
            level: 'therapy',
            notes,
            linkedTasks: task.taskIds,
            onFormChange: v => this.updateFormTasks(task.taskIds, v),
            therapyLevel: true,
            taskStatusId: taskStatusIds[task.id],
          });
        });
        // patient level fields part 2
        forms.push({
          title: undefined,
          formName: 'edit',
          formId: `tasks-edit-${taskType}-${selectedPatientId}-patient-2`,
          data: assignDefaultData(patientLevelTask),
          level: 'patient2',
          linkedFormIds: {
            patient: `tasks-edit-${taskType}-${selectedPatientId}-patient`,
          },
          linkedTasks: allTaskIds,
          onFormChange: v => this.updateFormTasks(allTaskIds, v),
          taskStatusId: taskStatusIds[patientLevelTask.id],
          dosisRegimenText,
        });
        // fill cycle level fields
        filterTasksByUniqueTherapyAndFillCycle(tasks).forEach(task => {
          const title = hideTitles
            ? undefined
            : `${this.getTaskTypeName(task)} - Fill Cycle ${task.fill_cycle_number}`;
          const dosisRegimenText = therapies[task.therapy_id]?.dosis_regimen; // eslint-disable-line no-shadow
          forms.push({
            title,
            formName: 'edit',
            formId: `tasks-edit-${taskType}-${task.therapy_id || NO_THERAPY}-${
              task.fill_cycle_number
            }-cycle`,
            linkedFormIds: {
              patient: `tasks-edit-${taskType}-${patientLevelTask.patient_id}-patient`,
              therapy: `tasks-edit-${taskType}-${task.therapy_id}-therapy`,
            },
            data: assignDefaultData(task),
            level: 'fillcycle',
            linkedTasks: task.taskIds,
            onFormChange: v => this.updateFormTasks(task.taskIds, v),
            taskStatusId: taskStatusIds[task.id],
            dosisRegimenText,
          });
        });

        const listOfDataCollectItems = [];
        const listOfTaskIds = tasks.map(t => t.id);
        for (const tempDcTask of tasks) {
          if (
            tempDcTask.taskType &&
            tempDcTask.taskType === DC &&
            selectedWagTaskKeys.includes(`${DC}${tempDcTask.id}`) &&
            wagSelectedTask === null
          ) {
            const tempTask = values[tempDcTask.id];
            if (
              tempTask &&
              Object.keys(tempTask).length &&
              this.props.selectedTasksIds.includes(tempDcTask.id)
            ) {
              const wagTaskList = tasks
                .filter(a => a.taskType === DC)
                .filter(b => selectedWagTaskKeys.includes(`${DC}${b.id}`));
              const wagTaskIds = wagTaskList.map(c => c.id);
              for (const tId of wagTaskIds) {
                if (
                  this.state.values[tId]?.data_collect &&
                  this.state.values[tId]?.data_collect.length
                ) {
                  for (const dataCollecItem of this.state.values[tId]?.data_collect) {
                    if (
                      dataCollecItem &&
                      Object.keys(dataCollecItem).length &&
                      !listOfDataCollectItems
                        .map(a => a.clinicalDataTypeId)
                        .includes(dataCollecItem.clinicalDataTypeId)
                    ) {
                      listOfDataCollectItems.push(dataCollecItem);
                    }
                  }
                }
              }
              if (listOfDataCollectItems && listOfDataCollectItems.length) {
                wagSelectedTask = {
                  ...tempTask,
                  data_collect: listOfDataCollectItems,
                  work_as_group: wagTaskList.map(a => a.therapy_id),
                  work_as_group_tasks: wagTaskList.map(b => {
                    return {
                      task_id: b.id,
                      therapy_id: b.therapy_id,
                      service_group_id: b.service_group_id,
                    };
                  }),
                  wagSelectedId: tempDcTask.id,
                };
                if (values && values[tempDcTask.id]?.data_collect?.length) {
                  const itemToAdd = listOfDataCollectItems.filter(
                    a =>
                      !values[tempDcTask.id]?.data_collect
                        ?.map(b => b.clinicalDataTypeId)
                        .includes(a.clinicalDataTypeId),
                  );
                  if (itemToAdd && itemToAdd.length) {
                    itemToBeAdded = itemToBeAdded.concat(itemToAdd);
                  }
                }
              }
            }
          }
        }
        // task level fields
        for (const task of tasks) {
          let titleAddition = '';
          if (taskType === RS) {
            titleAddition = `CDM - ${task.specialtyDiagnosisName}`;
          } else {
            titleAddition = (taskType === 'FDC' ? task.therapy_ids : `${task.therapy_id}`)
              .split(',')
              .map(id => {
                const therapy = therapies[id];
                return therapy
                  ? `${therapy.drug_name} - ${therapy.strength} ${therapy.strength_unit_of_measure}`
                  : '';
              })
              .join(', ');
          }
          const dosisRegimenText = therapies[task.therapy_id]?.dosis_regimen; // eslint-disable-line no-shadow
          const textTitle = hideTitles
            ? undefined
            : `${this.getTaskTypeName(task)} - ${titleAddition}`;
          const title = (
            <>
              <Typography className={classes.formTitleHeaderWrapperItem1}>{textTitle}</Typography>
              {taskType === 'FC' && dosisRegimenText ? this.renderPillIcon(dosisRegimenText) : ''}
            </>
          );

          if (
            wagSelectedTask &&
            Object.keys(wagSelectedTask).length &&
            wagSelectedTask.work_as_group_tasks?.map(t => t.task_id).includes(task.id)
          ) {
            if (task.id === wagSelectedTask.wagSelectedId) {
              forms.push({
                title,
                formName: 'edit',
                formId: `tasks-edit-${task.therapy_id || NO_THERAPY}-${taskType}-${task.id}-task`,
                formValues: {
                  ...values[task.id],
                  work_as_group: wagSelectedTask.work_as_group,
                  work_as_group_tasks: wagSelectedTask.work_as_group_tasks,
                  data_collect: wagSelectedTask.data_collect,
                },
                linkedFormIds: {
                  patient: `tasks-edit-${taskType}-${patientLevelTask.patient_id}-patient`,
                  therapy: `tasks-edit-${taskType}-${task.therapy_id}-therapy`,
                  cycle: `tasks-edit-${taskType}-${task.therapy_id}-${task.fill_cycle_number}-cycle`,
                },
                notes,
                data: assignDefaultData(task),
                level: 'task',
                linkedTasks: [task.id],
                onFormChange: v =>
                  this.updateFormTasks([task.id], {
                    ...v,
                    data_collect: v.data_collect?.concat(itemToBeAdded) ?? v.data_collect,
                    work_as_group: wagSelectedTask.work_as_group,
                    work_as_group_tasks: wagSelectedTask.work_as_group_tasks,
                  }),
                // onFormChange: v => this.updateFormTasks([task.id], { ...v, work_as_group: wagSelectedTask.work_as_group, work_as_group_tasks: wagSelectedTask.work_as_group_tasks }),
                // onFormChange: v => this.updateFormTasks([task.id], v),
                taskStatusId: taskStatusIds[task.id],
                dosisRegimenText,
              });
            }
          } else {
            forms.push({
              title,
              formName: 'edit',
              formId: `tasks-edit-${task.therapy_id || NO_THERAPY}-${taskType}-${task.id}-task`,
              formValues: values[task.id],
              linkedFormIds: {
                patient: `tasks-edit-${taskType}-${patientLevelTask.patient_id}-patient`,
                therapy: `tasks-edit-${taskType}-${task.therapy_id}-therapy`,
                cycle: `tasks-edit-${taskType}-${task.therapy_id}-${task.fill_cycle_number}-cycle`,
              },
              notes,
              data: assignDefaultData(task),
              level: 'task',
              linkedTasks: [task.id],
              onFormChange: v => this.updateFormTasks([task.id], v),
              taskStatusId: taskStatusIds[task.id],
              dosisRegimenText,
            });
          }
        }
      }
    }

    let durProps = {};
    let doDurNotes = false;

    if (taskType === DUR) {
      doDurNotes = true;
      durProps = {
        tagName: 'DUR',
        tagTypeId: 26,
      };
    }

    const getDurNotes = taskId =>
      getDisplayNotes(
        allPinnedNotes,
        allNonPinnedNotes,
        DRUG_UTILIZATION_REVIEW_TASK_ID,
        taskId,
        users,
        serviceGroups,
        2,
      );

    const renderExpirationDate = (taskType, form) => {
      if ((taskType === PA || taskType === FA) && form.data.is_renewal) {
        return (
          <Grid item xs={12} className={classes.customFormField}>
            <TaskExpirationDate task={form.data} />
          </Grid>
        );
      }
      return null;
    };

    const currentLevelProps = { ...this.props };
    const { taskState, updateTaskState, handleOnSubmit } = this.props;
    return (
      <Grid container>
        {taskState?.followUpTherapyId && (
          <FollowUpDataCollectModal
            open
            onClose={() => updateTaskState({ followUpTherapyId: null })}
            therapyId={taskState.followUpTherapyId}
            dataCollectedDate={taskState.followUpDataCollectedDate}
            minAssessmentDate={taskState.followUpMinAssessmentDate}
            therapyDuration={taskState.followUpTherapyDuration}
            onSubmit={values2 => {
              const tasklists = taskState?.value.taskList.map(task =>
                task.taskType === DC ? { ...values2, ...task } : task,
              );
              updateTaskState({
                followUpTherapyId: null,
                followUpDataCollectedDate: null,
                followUpMinAssessmentDate: null,
                followUpTherapyDuration: null,
              });
              handleOnSubmit(
                tasklists,
                taskState.value.orders,
                taskState.value.type,
                taskState.existingNotes,
                taskState.newDocuments,
              );
            }}
          />
        )}
        {Boolean(standardTasks.length) && (
          <ConditionalWrapper condition={!hideCard} wrapper={child => child}>
            <div data-qa-id="card-content">
              {forms.map(form => (
                <BundleTaskStatusForm
                  key={form.formId}
                  onValidityChange={v => {
                    this.setState(prevState => ({
                      valid: {
                        ...prevState.valid,
                        [form.formId]:
                          form.formId.includes(DUR) &&
                          form.data &&
                          (form.data.status_id === DurStatus.Reviewed ||
                            form.data.status_id === DurStatus.Canceled)
                            ? false
                            : v,
                      },
                    }));
                  }}
                  type={taskType}
                  linkedFormIds={form.linkedFormIds}
                  {...form}
                  forceNextStatus
                  fileUploadFormId={`${form.formId}-document`}
                  {...durProps}
                  notes={doDurNotes ? getDurNotes(form.data.id) : []}
                  tagResourceId={form.data.id}
                  additionalDocumentProps={currentLevelProps}
                  drawBorder
                  taskIsSubmitting={taskIsSubmitting}
                  auth={auth}
                  renderBeforeFields={() => renderExpirationDate(taskType, form)}
                  paymentMethods={this.props.paymentMethods}
                  therapies={this.props.therapies}
                  lookups={this.props.lookups}
                />
              ))}

              {taskType !== DUR && (
                <>
                  {!pendingDocument && (
                    <Grid container className={classes.customFormField}>
                      <Grid item xs={6}>
                        <SelectFileForm
                          acceptedFileExtensions={acceptedFileExtensions}
                          handleAcceptedDrop={this.fileSelected}
                          handleRejectedDrop={this.fileRejected}
                          handleFilePaste={e => this.handleFilePaste(e)}
                          errorMessage={fileErrorMessage}
                          errorCaption={fileErrorCaption}
                        />
                      </Grid>
                    </Grid>
                  )}
                  {pendingDocument && (
                    <Grid container>
                      <Grid item xs={6} className={classes.customFormField}>
                        <FileSelectedForm
                          documentLabels={defaultDocumentLabels.map(dt => ({
                            label: dt.label,
                            value: dt.label,
                          }))}
                          submitButtonText="Upload"
                          fileName={pendingDocument.fileName}
                          fileExtension={pendingDocument.fileExtension}
                          form={fileUploadFormId}
                          formSubmit={e => this.handleFileUploadSubmit(e)}
                          onCancel={() => this.handleFileUploadCancel()}
                          errorMessage={fileUploadErrorMessage}
                          isProcessing={uploadProcessing}
                        />
                      </Grid>
                    </Grid>
                  )}
                  {uploadedDocuments && (
                    <Grid container>
                      {uploadedDocuments.map(document => (
                        <PendingDocument documentData={document} />
                      ))}
                    </Grid>
                  )}
                </>
              )}
              <ConfirmationDialogPanel
                open={pendingDocumentLoss}
                title="Wait! You might lose some data."
                content={
                  <>
                    <Typography gutterBottom>
                      If you continue you will lose the following documents:
                    </Typography>
                    {lostData.map(ld => (
                      <Typography>{ld}</Typography>
                    ))}
                  </>
                }
                backdropClick={dialogCancel}
                cancelText="Cancel"
                continueText="Continue"
                onCancel={dialogCancel}
                onContinue={dialogContinue}
              />
            </div>
            {this.props.showActionsPanel && (
              <>
                <Divider className={classes.formDivider} />

                <CardActions className={classes.rightAlignedConfirmation} style={{ width: '100%' }}>
                  {taskType === 'INT' && (
                    <Grid
                      container
                      className={classes.leftAlignedActionButtons}
                      xs={6}
                      justifyContent="flex-start"
                    >
                      <InterventionCopyContentButton multipleForms={intValuesToCopy} />
                    </Grid>
                  )}
                  <Grid container item xs={6} justifyContent="flex-end">
                    {!hideCancel && (
                      <Button
                        name="Cancel"
                        type="button"
                        className={classes.cancelButton}
                        onClick={() => this.handleCancel()}
                        id={`cancel_button_${taskType}`}
                      >
                        Cancel
                      </Button>
                    )}
                    <Button
                      style={{ marginLeft: 30 }}
                      name="Save"
                      type="button"
                      onClick={() =>
                        // Don't show or generate a new document if we were unable to preview the doc.
                        this.handleSubmit(
                          shouldShowPreviewButtonFn && shouldShowPreviewButtonFn(tasks, values),
                        )
                      }
                      disabled={!this.canSubmit(forms.map(form => form.formId))}
                      color="primary"
                      variant="contained"
                      id={`save_button_${taskType}`}
                    >
                      {`${
                        (submitButtonTextFn && submitButtonTextFn(tasks, values)) || 'Save'
                      } ${taskType}`}
                    </Button>
                    {taskType && taskType === DUR && (
                      <Button
                        name="saveAndReview"
                        type="button"
                        onClick={() =>
                          this.handleDURSaveAndReviewSubmit(
                            shouldShowPreviewButtonFn && shouldShowPreviewButtonFn(tasks, values),
                            true,
                          )
                        }
                        disabled={
                          !this.canSubmitAndReview(
                            tasks,
                            forms.map(form => form.formId),
                          )
                        }
                        color="primary"
                        variant="contained"
                        id={`save_and_review_${taskType}`}
                      >
                        Save and Mark As Reviewed
                      </Button>
                    )}
                  </Grid>
                  <Modal open={this.state.openInterventionAlertModal}>
                    <Grid container className={classes.modal}>
                      <Grid item xs={12}>
                        <Typography component="span">
                          The DUR will be complete but the interventions attached will remain open
                          and will require follow up.
                        </Typography>
                      </Grid>
                      <Grid item xs={12} className={classes.modalButton}>
                        <Button
                          type="button"
                          data-qa-id="cancelDurWithIntervention"
                          onClick={() => this.toggleInterventionModal()}
                        >
                          Cancel
                        </Button>
                        <Button
                          type="button"
                          color="primary"
                          variant="contained"
                          onClick={() => {
                            this.handleSubmit(
                              shouldShowPreviewButtonFn && shouldShowPreviewButtonFn(tasks, values),
                              true,
                            );
                          }}
                          disabled={this.taskIsSubmitting}
                          data-qa-id="submitDurWithIntervention"
                        >
                          Confirm
                        </Button>
                      </Grid>
                    </Grid>
                  </Modal>
                </CardActions>
              </>
            )}
          </ConditionalWrapper>
        )}
        {Boolean(readonlyTasks.length) && (
          <>
            {readonlyTasks.map(roTask => (
              <Card>
                <CardContent data-qa-id="card-content-readonly">
                  <>
                    <Typography variant="h6">
                      {`${this.getTaskTypeName(roTask)} - ${this.getTherapyName(
                        roTask,
                        therapies,
                      )}`}
                    </Typography>
                    <TaskDetailForm
                      taskType={roTask.taskType}
                      task={{ ...roTask, days_supply: getDaysSupplyForTask(roTask, fillCycles) }}
                    />
                  </>
                </CardContent>
              </Card>
            ))}
          </>
        )}
      </Grid>
    );
  }
}

function handleFormName(taskType, taskIds) {
  return `${UPLOAD_DOCUMENT_FORM}-${taskType}-${taskIds[0]}`;
}

function mapStateToProps(state, props) {
  const { taskStatuses, auth, tasks } = state;
  const { selectedWagTaskKeys } = tasks;
  const fileUploadFormId = handleFormName(props.taskType, props.taskIds);
  const selector = formValueSelector(fileUploadFormId);
  const fileFormName = selector(state, UPLOAD_DOC_FILENAME_FIELD);
  const fileFormTypes = selector(state, UPLOAD_DOC_FILETYPE_FIELD);
  const fileFormNote = selector(state, UPLOAD_DOC_FILENOTE_FIELD);
  const selectedTasksIds = tasks?.selectedTaskKeys?.length
    ? tasks.selectedTaskKeys
        .map(k => tasks.data[k])
        .filter(i => !!i)
        .map(t => t.id)
    : [];

  let intValuesToCopy = null;

  // For each INT type form save information to copy
  if (props.taskType === INT) {
    intValuesToCopy = [];
    props.combinedTask[INT].forEach(task => {
      const formValues = getFormValues(
        `tasks-edit-${task.therapy_id || NO_THERAPY}-INT-${task.id}-task`,
      )(state);
      if (formValues) {
        const intType =
          taskStatuses.intervention_types.find(type => type.id === formValues.type_id) || null;
        return intValuesToCopy.push({
          type: intType ? intType.type_name : null,
          details: formValues.details,
        });
      }
      return null;
    });
  }

  if (props.taskType === DC) {
    props.combinedTask[DC].forEach(task => {
      const formValues = getFormValues(
        `tasks-edit-${task.therapy_id || NO_THERAPY}-DC-${task.id}-task`,
      )(state);
      if (formValues) {
        if ([DataCollectStatus.Collected].includes(formValues.status_id)) {
          formValues.data_collected_date =
            formValues.data_collected_date ?? new Date().toISOString();

          formValues.completed_dt = null;
          formValues.reviewed_dt = null;
          formValues.canceled_dt = null;
        }
        if ([DataCollectStatus.Reviewed].includes(formValues.status_id)) {
          formValues.data_collected_date =
            formValues.data_collected_date ?? new Date().toISOString();
          formValues.completed_dt = formValues.completed_dt ?? new Date().toISOString();
          formValues.reviewed_dt = formValues.reviewed_dt ?? new Date().toISOString();

          formValues.canceled_dt = null;
        }
        if ([DataCollectStatus.Canceled].includes(formValues.status_id)) {
          formValues.canceled_dt = formValues.canceled_dt ?? new Date().toISOString();
        }
      }
      return null;
    });
  }

  const faFormValues = [];
  if (props.taskType === FA) {
    props.combinedTask[FA].forEach(task => {
      const formValues = getFormValues(
        `tasks-edit-${task.therapy_id || NO_THERAPY}-FA-${task.id}-task`,
      )(state);
      if (formValues) {
        faFormValues.push({ ...formValues, taskId: task.id });
      }
    });
  }

  // For now, only meant to hide Save/Save and Marks as Review buttons for DUR
  let showActionsPanel = true;
  if (props.taskType === DUR && props.combinedTask) {
    const completedDurs =
      props.combinedTask[DUR]?.filter(task =>
        [DurStatus.Reviewed, DurStatus.Canceled].includes(task.status_id),
      ) || [];
    showActionsPanel = completedDurs.length === 0;
  }

  return {
    currentUser: state?.auth?.currentUser,
    therapies: state?.therapies?.data,
    fillCycles: state.fillCycles,
    taskCounts: state?.taskCounts?.data,
    orders: state?.orders?.list,
    tasks: state?.tasks?.data,
    notes: state.notes,
    allPinnedNotes: state?.notes?.notes?.allPinnedNotes,
    allNonPinnedNotes: state?.notes?.notes?.allNonPinnedNotes,
    users: state?.lookups?.users,
    serviceGroups: state?.lookups?.serviceGroups,
    defaultDocumentLabels: state?.lookups?.defaultDocumentLabels,
    resourceStates: state?.lookups?.resourceStates,
    selectedPatientId: state.selectedPatientId,
    selectedCustomerId: state?.filters?.selectedCustomerId,
    durFormsWithSelectedINT: state?.tasks?.durFormsWithSelectedINT || [],
    financialAssistances: state?.financials?.financial_assistances?.data || [],
    fileUploadFormId,
    fileFormName,
    fileFormTypes,
    fileFormNote,
    intValuesToCopy,
    auth,
    showActionsPanel,
    lookups: state?.lookups,
    paymentMethods: state?.paymentMethods ?? {},
    selectedTasksIds,
    faFormValues,
    selectedWagTaskKeys,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    documentBarUpdate: state => dispatch(updateDocumentBar(state)),
    changeField: (formName, fieldName, value) => dispatch(change(formName, fieldName, value)),
  };
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles, { withTheme: true }),
)(BundleTaskForm);
