import React, { useState } from 'react';

const QUESTIONS_PAGE = 1;

const DataCollectPage = props => {
  const { setPage, data_collects } = props;
  const [dcIndex, setDCIndex] = useState(0);

  window.addEventListener('message', event => {
    if (event?.data?.status === 'dc_questionnaire_completed') {
      if (dcIndex < data_collects.length - 1) {
        setDCIndex(dcIndex + 1);
      } else {
        setPage(QUESTIONS_PAGE);
      }
    }
  });
  return (
    <iframe
      style={{ width: '100vw', height: '99vh', border: 'none', overflow: 'hidden' }}
      title="Arbor"
      src={`${window.location.origin}/questionnaires/${data_collects[dcIndex]}`}
    />
  );
};

export default DataCollectPage;
