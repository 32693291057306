import React, { PropsWithChildren, useState, useEffect } from 'react';

import { Grid, Typography } from '@mui/material';

import useUuid from 'hooks/useUuid';

import {
  GroupTable,
  IColumn,
  ICustomComponents,
  IGroup,
} from 'components/medication-list-group-table';
import { TriStateSortOrder } from 'components/medication-list-group-table/types';
import { useDispatch } from 'react-redux';
import { fetchMedicationsForIdentifier } from 'actions/action-medications';
import { IMedicationListItem } from 'interfaces/redux/IMedications';
import MedicationLink from '../medication-link';
import EditableCell from '../editable-cell';

import {
  sortByColInOrder,
  buildMedGroups,
  buildMedicationIdLookup,
  disableStatusTransition,
} from './utils';

import { IProps, MedicationListColumns } from './types';
import { useTypedStyles } from './gpi-medication-group.styles';

export const GpiMedicationGroup: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { classes } = useTypedStyles();
  const id = useUuid();

  // #region component state
  const [originalMedGroups, setOriginalMedGroups] = useState<IGroup[] | false>(false);

  const [medsLookupById, setMedsLookupById] = useState<Record<number, IMedicationListItem>>(
    buildMedicationIdLookup(props.medicationGroups, props.medications),
  );

  const [sortDirection, setSortDirection] = useState<TriStateSortOrder>(
    TriStateSortOrder.DispenseStatus,
  );

  const [sortColumn, setSortColumn] = useState<MedicationListColumns>('drug_name');

  const [medGroups, setMedGroups] = useState<IGroup[]>(() => {
    const data = buildMedGroups(props.medicationGroups, props.medications, props.statusFilters);
    if (!originalMedGroups) {
      setOriginalMedGroups(data);
    }
    return data;
  });

  const [columns, setColumns] = useState<IColumn[]>([]);
  // #endregion component state

  // #region handlers
  const sortHandler = (column: MedicationListColumns, sortOrder: TriStateSortOrder): void => {
    if (sortDirection !== sortOrder) {
      setSortDirection(sortOrder);
    }
    if (sortColumn !== column) {
      setSortColumn(column);
    }
    if (sortOrder === TriStateSortOrder.DispenseStatus && column === 'drug_name') {
      setMedGroups(originalMedGroups as IGroup[]);
      return;
    }
    if (sortOrder === TriStateSortOrder.Asc || sortOrder === TriStateSortOrder.Desc) {
      setMedGroups(sortByColInOrder(medGroups, column, sortOrder));
    }
  };

  const dispatch = useDispatch();
  const expandHandler = (group: IGroup | null, expanded: boolean): void => {
    if (expanded && group) {
      dispatch(
        fetchMedicationsForIdentifier(group.parent.rowData?.patient_id, group.parent.rowData?.gpi),
      );
    }
  };

  // #endregion handlers

  // #region side effects
  useEffect(() => {
    setMedsLookupById(buildMedicationIdLookup(props.medicationGroups, props.medications));
  }, [props.medicationGroups, props.medications]);

  useEffect(() => {
    const data = buildMedGroups(props.medicationGroups, props.medications, props.statusFilters);
    setOriginalMedGroups(data);
    if (sortDirection !== TriStateSortOrder.DispenseStatus) {
      setMedGroups(sortByColInOrder(data, sortColumn, sortDirection));
    } else {
      setMedGroups(data);
    }
  }, [props.medicationGroups, props.medications, props.statusFilters]);

  useEffect(() => {
    const data = buildMedGroups(props.medicationGroups, props.medications, props.statusFilters);
    if (!originalMedGroups) {
      setOriginalMedGroups(data);
    }
    setMedGroups(data);
  }, [props.medicationGroups, props.medications, props.statusFilters]);

  // #endregion side effects

  // #region table config
  const renderTableHeader = (props: PropsWithChildren<{}>): JSX.Element => (
    <Typography className={classes.tableHeaderCell}>{props.children}</Typography>
  );

  const renderNonEditableParentRow = (props: PropsWithChildren<{}>): JSX.Element => (
    <Typography className={classes.parentRowCell}>{props.children}</Typography>
  );

  const renderNonEditableChildRow = (props: PropsWithChildren<{}>): JSX.Element => (
    <Typography className={classes.childRowCell}>{props.children}</Typography>
  );

  const getEditableCellRenderer = (label: string, name: string) => (
    innerProps: PropsWithChildren<{
      identifier: number;
      childrenIdentifiers?: number[];
      isParent?: boolean;
    }>,
  ) => {
    const medicationGroup = medsLookupById[innerProps.identifier];
    const key = `${id}-${innerProps.identifier}-${name}`;
    return (
      <EditableCell
        isGroup
        label={label}
        fieldName={name}
        key={key}
        id={key}
        medication={medicationGroup}
        medicationGroup={medicationGroup}
        disabled={props.readOnly || disableStatusTransition(medicationGroup, name)}
        displayInactiveTooltip={name === 'ndc'}
      />
    );
  };

  const getDrugNameRenderer = (scale: number, isParent: boolean) => (
    innerProps: PropsWithChildren<{ identifier: number }>,
  ): JSX.Element => {
    const med = medsLookupById[innerProps.identifier] || {};
    return (
      <div
        style={{
          transform: `scale(${scale})`,
        }}
      >
        <MedicationLink
          medication={med}
          id={innerProps.identifier}
          toggleTherapyHandler={props.toggleTherapyHandler}
          toggleAddTherapyHandler={props.toggleAddTherapyHandler}
          disabled={props.readOnly}
          displayPill={isParent}
          group
        />
      </div>
    );
  };

  const renderLinkIcon = (innerProps: PropsWithChildren<{ identifier: number }>): JSX.Element => {
    const med = medsLookupById[innerProps.identifier] || {};
    return (
      <MedicationLink
        medication={med}
        id={innerProps.identifier}
        toggleTherapyHandler={props.toggleTherapyHandler}
        toggleAddTherapyHandler={props.toggleAddTherapyHandler}
        disabled={props.readOnly}
        group
        type="actionIcon"
      />
    );
  };

  const components: ICustomComponents = {
    headerCell: renderTableHeader,
    parentRowCell: renderNonEditableParentRow,
    childRowCell: renderNonEditableChildRow,
    parentAction: renderLinkIcon,
  };

  const generateColumns = (): IColumn[] => {
    return [
      {
        columnName: 'drug_name',
        displayName: 'Drug Name',
        sortable: true,
        customComponents: {
          parentRowCell: getDrugNameRenderer(0.9, true),
          childRowCell: getDrugNameRenderer(0.8, false),
        },
      },
      {
        columnName: 'ndc',
        displayName: 'NDC',
        sortable: true,
        customComponents: {
          parentRowCell: getEditableCellRenderer('NDC', 'ndc'),
        },
      },
      {
        columnName: 'dose',
        displayName: 'Dose',
        sortable: true,
        customComponents: {
          parentRowCell: renderNonEditableChildRow,
        },
      },
      {
        columnName: 'dosage_form',
        displayName: 'Form',
        sortable: true,
        customComponents: {
          parentRowCell: renderNonEditableChildRow,
        },
      },
      {
        columnName: 'start_dt',
        displayName: 'Start Date',
        sortable: true,
        customComponents: {
          parentRowCell: getEditableCellRenderer('Start Date', 'start_dt'),
        },
      },
      {
        columnName: 'end_dt',
        displayName: 'End Date',
        sortable: true,
        customComponents: {
          parentRowCell: getEditableCellRenderer('End Date', 'end_dt'),
        },
      },
      {
        columnName: 'status_code',
        displayName: 'Status',
        sortable: true,
        customComponents: {
          parentRowCell: getEditableCellRenderer('Status', 'status_code'),
        },
      },
      {
        columnName: 'category_code',
        displayName: 'Category',
        sortable: true,
        customComponents: {
          parentRowCell: getEditableCellRenderer('Category', 'category_code'),
        },
      },
    ];
  };

  useEffect(() => {
    setColumns(generateColumns());
  }, [medsLookupById]);

  // #endregion table config

  // #region main render
  return (
    <Grid container>
      <Grid item xs={12}>
        <GroupTable
          key={`table-${id}`}
          columns={columns}
          groups={medGroups}
          customComponents={components}
          defaultSortedBy="drug_name"
          sortHandler={sortHandler}
          expandHandler={expandHandler}
          hideChildren={props.hideChildren}
        />
      </Grid>
    </Grid>
  );
  // #endregion main render
};
