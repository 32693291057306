import { takeEvery, select, call, all, put, takeLatest } from 'redux-saga/effects';
import { updateMedicationVerifications } from 'actions/action-medications';
import { fetchPatientAdherenceData } from 'actions/action-patient';
import {
  FETCH_FILL_CYCLES_REQUEST,
  FETCH_FILL_CYCLES_SUCCESS,
  FETCH_FILL_CYCLES_FAILURE,
  DATA_THERAPIES_REQUEST,
  DATA_THERAPIES_SUCCESS,
  DATA_THERAPIES_FAILURE,
  SELECTED_PATIENT_ID,
  BULK_TASKS_UPDATED,
  EDIT_THERAPY,
  EDIT_THERAPY_FLOW,
  EDIT_THERAPIES_NBD,
  DUR_TRIGGER_CHECK_INTERACTION_REQUEST,
  INTERACTION_FINDER_TRIGGER,
  NOTIFY_SUCCESS,
  NOTIFY_ERROR,
  UPDATE_MEDICATION_VERIFICATION_STATUS_SUCCESS,
  REFRESH_THERAPIES_AND_TASKS,
  CATEGORY_OUTSTANDING,
  CATEGORY_IN_PROGRESS,
  CATEGORY_DONE,
  CATEGORY_ARCHIVED,
  FETCH_AUDITS_REQUEST,
  EDIT_FC_SUCCESS,
} from '../constants';
import HTTP from '../services/http';

const fetchFillCycles = patientId => {
  const url = `/patients/${patientId}/fill_cycles`;
  return HTTP.get(url, {}).then(payload => ({
    ...payload,
    data: payload.data.fillCycles,
  }));
};

function* workerFillCyclesSaga() {
  const { selectedPatientId, selectedTherapyId } = yield select();

  // do not process if already cached
  if (!selectedPatientId) {
    return;
  }
  try {
    yield put({
      type: FETCH_FILL_CYCLES_REQUEST,
    });
    const response = yield call(fetchFillCycles, selectedPatientId);
    const { data } = response;
    yield put({
      type: FETCH_FILL_CYCLES_SUCCESS,
      payload: {
        data,
        selectedTherapyId,
      },
      meta: { patientId: selectedPatientId },
    });
  } catch (error) {
    yield put({ type: FETCH_FILL_CYCLES_FAILURE });
  }
}

function* workerFetchTherapies() {
  const { selectedPatientId } = yield select();
  if (selectedPatientId) {
    const url = `/patients/${selectedPatientId}/therapies`;
    yield put({
      type: DATA_THERAPIES_REQUEST,
      payload: {},
    });
    try {
      const responses = yield all([
        call(
          HTTP.get,
          `${url}?categories=${CATEGORY_OUTSTANDING},${CATEGORY_IN_PROGRESS}&specialty=1`,
        ),
        call(
          HTTP.get,
          `${url}?categories=${CATEGORY_OUTSTANDING},${CATEGORY_IN_PROGRESS}&specialty=0`,
        ),
        call(HTTP.get, `${url}?categories=${CATEGORY_DONE},${CATEGORY_ARCHIVED}&specialty=1`),
        call(HTTP.get, `${url}?categories=${CATEGORY_DONE},${CATEGORY_ARCHIVED}&specialty=0`),
      ]);

      // Aggregate API responses into one
      const response = {
        data: {
          therapies: responses.reduce((acc, r) => acc.concat(r.data.therapies), []),
        },
      };

      yield put({
        type: DATA_THERAPIES_SUCCESS,
        payload: response,
        meta: { patientId: selectedPatientId },
      });
    } catch (e) {
      yield put({ type: DATA_THERAPIES_FAILURE, payload: e });
    }
  }
}

function* workerEditTherapyFlow({ payload: { updatedTherapy, onSuccess, onFail } }) {
  try {
    const url = `/patients/${updatedTherapy.patient_id}/therapies/${updatedTherapy.therapy_id}`;
    const request = HTTP.patch(url, updatedTherapy, {});
    const response = yield call(() => request);
    const {
      therapies: { data: therapies },
    } = yield select();

    const foundTherapy = therapies[updatedTherapy.therapy_id];

    const ndcChanged =
      !!foundTherapy && !!updatedTherapy.ndc && foundTherapy.ndc !== updatedTherapy.ndc;

    const therapyStatusChanged =
      foundTherapy.administration_status_id !== updatedTherapy.administration_status_id;

    yield put({ type: EDIT_THERAPY, payload: response });
    // eslint-disable-next-line
    if (response?.data?.updatedTasks?.risk_strat && response.data.updatedTasks.risk_strat.length) {
      const res = {
        data: {
          updated_tasks: response.data.updatedTasks.risk_strat,
        },
      };
      yield put({ type: BULK_TASKS_UPDATED, payload: res });
    }

    if (
      // Only trigger DUR interactions when drug's NDC changed
      !!ndcChanged &&
      response &&
      response.data &&
      response.data.therapy &&
      response.data.therapy.length
    ) {
      yield put({
        type: DUR_TRIGGER_CHECK_INTERACTION_REQUEST,
        patientId: updatedTherapy.patient_id,
        source: INTERACTION_FINDER_TRIGGER.Therapy,
        sourceValue: response && response.data.therapy[0],
      });
    }
    if (therapyStatusChanged) {
      yield put(fetchPatientAdherenceData(updatedTherapy.patient_id));
    }

    if (response?.data?.updatedAuditWarnings) {
      yield put({
        type: FETCH_AUDITS_REQUEST,
      });
    }

    yield put({
      type: NOTIFY_SUCCESS,
      payload: 'Therapy Updated Successfully',
    });
    onSuccess();
  } catch (err) {
    onFail();
    yield put({
      type: NOTIFY_ERROR,
      payload: 'Therapy Update Failed',
    });
  }
}

function* workerEditTherapiesNBDFlow({ payload: { updatedTherapies, onSuccess, onFail } }) {
  try {
    const updateCalls = updatedTherapies.map(updatedTherapy => {
      const url = `/patients/${updatedTherapy.patient_id}/therapies/${updatedTherapy.therapy_id}`;
      const request = HTTP.patch(url, updatedTherapy, {});
      return call(() => request);
    });

    const response = yield all(updateCalls);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < response.length; i++) {
      yield put({ type: EDIT_THERAPY, payload: response[i] });
    }

    yield put({
      type: NOTIFY_SUCCESS,
      payload: 'Therapy Updated Successfully',
    });
    onSuccess();
  } catch (err) {
    onFail();
    yield put({
      type: NOTIFY_ERROR,
      payload: 'Therapy Update Failed',
    });
  }
}

function* workerEditMedicationNewTag(action) {
  const { payload } = action;
  if (payload && payload.data && payload.data.verification_status) {
    yield put(updateMedicationVerifications(payload.data.verification_status));
  }
}

export function* watcherTherapySaga() {
  yield takeEvery([SELECTED_PATIENT_ID, BULK_TASKS_UPDATED, EDIT_FC_SUCCESS], workerFillCyclesSaga);
  yield takeLatest([SELECTED_PATIENT_ID, REFRESH_THERAPIES_AND_TASKS], workerFetchTherapies);
  yield takeLatest([EDIT_THERAPY_FLOW], workerEditTherapyFlow);
  yield takeLatest([EDIT_THERAPIES_NBD], workerEditTherapiesNBDFlow);
  yield takeEvery([UPDATE_MEDICATION_VERIFICATION_STATUS_SUCCESS], workerEditMedicationNewTag);
}
