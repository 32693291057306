import { AxiosResponse, AxiosPromise } from 'axios';

import { IUpdateClinicalSuportRequest } from 'models/cdm/IUpdateClinicalSupportRequest';
import { IUpdateClinicalSuportResponse } from 'models/cdm/IUpdateClinicalSupportResponse';
import { TypedHttp } from 'services/typed-http';
import HTTP from '../services/http';

const getBaseUrl = (patientId: number): string => `/patients/${patientId}/therapies`;

export class PatientTherapiesClient {
  static batchUpdateClinicalSupport = async (
    patientId: number,
    requestData: IUpdateClinicalSuportRequest,
  ): Promise<IUpdateClinicalSuportResponse> => {
    const url = `${getBaseUrl(patientId)}/clinical_support`;
    const response: AxiosResponse<IUpdateClinicalSuportResponse> = await HTTP.patch(
      url,
      requestData,
    );
    return response.data;
  };

  static getTherapiesByPatientID = (patientId: number): AxiosPromise<any> => {
    const baseUrl = `/patients/${patientId}/therapies`;
    return HTTP.get(baseUrl, {});
  };

  static getTherapyWorkflow = (
    diagnosisCode: string,
    specialtyType: string | null,
  ): AxiosPromise<{ therapyWorkflow: number }> => {
    const baseUrl = `therapies/workflow/${diagnosisCode}?specialtyType=${specialtyType}`;
    return TypedHttp.GetPromise<{ therapyWorkflow: number }>(baseUrl);
  };

  static isRsIntervention = (intId: number): AxiosPromise<{ result: boolean }> => {
    const baseUrl = `/patients/isRsIntervention/${intId}`;
    return TypedHttp.GetPromise<{ result: boolean }>(baseUrl);
  };
}
