import React from 'react';
import { IGetDiseaseStateResponse } from 'models/application-manager/cpmp/IGetDiseaseStateResponse';
import { Grid, Button } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { ApplicationManagerClient } from 'clients/application-manager-client';
import { useDebounce } from 'use-debounce';
import { UserUtils } from 'utils/user-utils';
import { UserPermissions } from 'interfaces/user/Permissions';
import { useTypedSelector } from 'hooks/use-typed-selector';
import { DataTable } from './tables/data-table';
import { CpmpStyles } from '../index.styles';
import { SearchField } from '../../components/search-field';
import { SectionTitleStyled } from '../../components/section-title';
import { AddEditDiseaseStateModal } from './modal/add-edit-disease-state-modal';

const searchFieldPlaceholder = 'Search by ICD10 or Disease';
const initialSearchTerm = '';
const debounceTimeMs = 500;

type ICpmpProps = WithStyles<typeof CpmpStyles>

const CpmpDiseaseStates: React.FC<ICpmpProps> = props => {
  const { classes } = props;

  // #region useState
  const [searchTerm, setSearchTerm] = React.useState<string>(initialSearchTerm);
  const [reloadTrigger, setReloadTrigger] = React.useState<Date | undefined>(undefined);
  const [debouncedSearchTerm] = useDebounce<string | undefined>(searchTerm, debounceTimeMs);
  const [reloadDataTrigger, setReloadDataTrigger] = React.useState<Date | undefined>(undefined);
  const [diseaseState, setDiseaseState] = React.useState<IGetDiseaseStateResponse>({ result: [] });
  const [addModalOpen, setAddModalOpen] = React.useState<boolean>(false);
  const DataTableContext = React.createContext(diseaseState);
  // #endregion

  // # region redux state
  const user = useTypedSelector(state => state.auth.currentUser);
  const stateSelectedCustomerId = useTypedSelector(state => state.filters.selectedCustomerId);
  const customers = useTypedSelector(state => state.filters.customers);
  const [selectedCustomerId, setSelectedCustomerId] =
    React.useState<number>(stateSelectedCustomerId);
  // # endregion

  // # region memos
  const userManageUserLevel = React.useMemo<boolean>(() => {
    return UserUtils.userIsPermitted(UserPermissions.ApplicationManagerUsersManageUserLevel);
  }, [user]);

  const userHasPermissionToAddDcItem = React.useMemo<boolean>(() => {
    return UserUtils.userIsPermitted(UserPermissions.ApplicationManagerCpmpEdit);
  }, [user]);
  // # endregion

  // # region useEffect
  React.useEffect(() => {
    if (selectedCustomerId == null) {
      if (stateSelectedCustomerId != null) {
        setSelectedCustomerId(stateSelectedCustomerId);
      } else if (customers.length > 0) {
        setSelectedCustomerId(customers[0].id);
      }
    }
  }, [stateSelectedCustomerId]);

  React.useEffect(() => {
    let active = true;
    (async () => {
      const result = await ApplicationManagerClient.fetchDiseaseStates();
      if (result.data.result.length > 0) {
        setDiseaseState({ result: result.data.result });
      }
    })();
    return () => {
      active = false;
    };
  }, [reloadDataTrigger]);
  // # endregion

  const renderAddDiseaseStateButton = (): JSX.Element => {
    return (
      <Button
        variant="contained"
        className={classes.addButton}
        onClick={() => setAddModalOpen(true)}
      >
        Add Disease State
      </Button>
    );
  };

  const rightSideItems = [];
  if (userManageUserLevel || userHasPermissionToAddDcItem) {
    rightSideItems.push({
      element: renderAddDiseaseStateButton(),
      key: 'add-disease-state-item-button',
    });
  }

  return (
    <>
      {userHasPermissionToAddDcItem || userManageUserLevel ? (
        <SectionTitleStyled title="Disease States" rightSideItems={rightSideItems} />
      ) : (
        <SectionTitleStyled title="Disease States" />
      )}
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Grid container justifyContent="flex-end">
            <Grid item xs="auto">
              <Grid container spacing={2}>
                <Grid item>
                  <SearchField
                    width={48}
                    value={searchTerm}
                    onChange={setSearchTerm}
                    initialValue={initialSearchTerm}
                    placeholder={searchFieldPlaceholder}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <DataTableContext.Provider value={diseaseState}>
            <DataTable
              searchTerm={debouncedSearchTerm}
              triggerReload={reloadTrigger}
              handleReloadDataTrigger={date => setReloadDataTrigger(date)}
              userHasPermissionToEditIcdItem={userHasPermissionToAddDcItem || userManageUserLevel}
              activeAnyField={searchTerm.length > 0}
              diseaseStateList={diseaseState}
            />
          </DataTableContext.Provider>
        </Grid>
      </Grid>
      <AddEditDiseaseStateModal
        open={addModalOpen}
        onCancel={() => setAddModalOpen(false)}
        onSuccess={() => setReloadDataTrigger(new Date())}
      />
    </>
  );
};

export default withStyles(CpmpStyles)(CpmpDiseaseStates);
